import { uploadUserProfileApi, userApi } from "../../../utils/apis.utils";
import {
  startDashboardLoader,
  stopDashboardLoader,
} from "../dashboard/dashboard.slice";
import {
  addUser,
  fetchAllUser,
  deleteUser,
  editUser,
} from "../../../utils/apis.utils";
import { createQueryString } from "../../../utils/global/global";
import { showFailureToast, showSuccessToast } from "../toast/toast.slice";

import { fetchAllUserThunk } from "./addUsers.slice";

export const addUserHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(addUser, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const fetchAllUserHandler = async (data, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const query = createQueryString({
    page: data.page,
    query_term:data.param
  });
  const response = await user.get(fetchAllUser + query + "&limit=10");

  return { data: response.data, total: response.total };
};

export const deleteUserHandler = async (id, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.delete(deleteUser + "/" + id);
    thunkApi.dispatch(stopDashboardLoader());
    if (response.err === "") {
      thunkApi.dispatch(fetchAllUserThunk({ page: 1 }));
    }
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const editUserHandler = async ({ id, data }, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(editUser + "/" + id, { data });

    thunkApi.dispatch(stopDashboardLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const getUserByIdHandler = async (id, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.get(editUser + "/" + id);
    thunkApi.dispatch(stopDashboardLoader());

    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};
