import * as Yup from "yup";

export const milestoneValidationSchema = Yup.object({
  milestone_name: Yup.string().required("Milestone Name is required"),
  percentage_of_project: Yup.number()
    .typeError("Please Enter a number")
    .required("Percentage Release is required")
    .min(0, "Value should be between 0 and 100")
    .max(100, "Value should be between 0 and 100"),
  // milestone_amount: Yup.string().required("Milestone Amount is required"),
  // milestone_release_amount: Yup.string().required(
  //   "Milestone Release Amount is required"
  // ),
  // timeline: Yup.string().required("Timeline is required"),
  // expected_collection_month: Yup.string().required(
  //   "Collection Month is required"
  // ),
  type: Yup.string().required("Milestone Type is required"),
  // isActual: false,
  // actual_delivery_date: Yup.string().when("isActual", {
  //   is: true,
  //   then: Yup.string().required("Date is required"),
  // }),
});
export const milestoneDelayValidationSchema = Yup.object({
  milestone_delayed_reason: Yup.array()
    .min(1, "Milestone delayed reason is required.")
    .required("Milestone delayed reason is required.")
    .nullable(),
});

export const addUserValidation = Yup.object({
  first_name: Yup.string()
    .matches(/^[A-Za-z]+$/, "First Name should only contain letters")
    .required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email Id is required"),
  designation: Yup.string()
    .matches(/^[A-Za-z]+$/, "Designation should only contain letters")
    .required("Designation is Required"),
  role: Yup.string().required("Role is Required"),
});

export const statusChangeValidationSchema = Yup.object().shape({
  status_change_reason: Yup.string().required("Reason is Required"),
  // status: Yup.string(),
  // refunded_amount: Yup.string().when("status", {
  //   is: "refunded",
  //   then: Yup.string().required("Refund amount is required"),
  // }),
});

export const statusChangeValidationSchemaVendorRefund = Yup.object().shape({
  vendor_change_dropdown: Yup.string().required("Reason is Required"),
  status_change_reason: Yup.string().when("vendor_change_dropdown", {
    is: "Other",
    then: Yup.string().required("Other is required"),
  }),
  vendor_invoice_number: Yup.string().required(
    "Vendor Invoice Number is Required"
  ),
  vendor_refunded_amount: Yup.string().required(
    "Vendor Refund Amount is Required"
  ),
});

export const statusChangeValidationSchemaClientRefund = Yup.object().shape({
  client_change_dropdown: Yup.string().required("Client Reason is Required"),
  client_reason: Yup.string().when("client_change_dropdown", {
    is: "Other",
    then: Yup.string().required("Other is required"),
  }),
  client_invoice_number: Yup.string().required(
    "Client Invoice Nummber is Required"
  ),
  client_refunded_amount: Yup.string().required(
    "Client Refund Amount is Required"
  ),
  upload_cancelled_cheque: Yup.string().required("Cancel Cheque is Required"),
});

export const piValidation = Yup.object().shape({
  pi_amount: Yup.string().required("PI is Required"),
  milestone: Yup.string().required("Milestone is Required"),
});

export const PurchaseOrderValidation = Yup.object().shape({
  po_amount: Yup.number().required("PO Amount is Required"),
  vendor_invoice_number: Yup.string().required(
    "Vendor Invoice Number is Required"
  ),
  vendor_invoice_date: Yup.string().required("Vendor Invoice Date is Required"),
  current_vendor:Yup.string().required("Current vender name is Required"),
  milestone_id: Yup.string().required("Milestone is Required"),
  vendor_invoice: Yup.string().required("Vendor Invoice is Required"),
  suggested_take_rate: Yup.string()
    .matches(
      /^\d+(\.\d{1,2})?$/,
      "Suggested Take Rate must have up to two decimal places"
    )
    .required("Suggested Take Rate is Required"),
  email_screenshot: Yup.string().required("Email Screenshot is Required"),
});
export const EditPurchaseOrderValidation = Yup.object().shape({
  po_amount: Yup.number().required("PO Amount is Required"),
  vendor_invoice_number: Yup.string().required("Vendor Invoice is Required"),
  vendor_invoice_date: Yup.string().required("Vendor Invoice date is Required"),
  current_vendor:Yup.string().required("Current vender name is Required"),
  // milestone_id: Yup.string().required("milstone id is Required"),
  vendor_invoice: Yup.string().required("vendor invoice is Required"),
  suggested_take_rate: Yup.string()
    .matches(
      /^\d+(\.\d{1,2})?$/,
      "Suggested Take Rate must have up to two decimal places"
    )
    .required("Suggested Take Rate is Required"),
  email_screenshot: Yup.string().required("Email Screenshot is Required"),
});

export const ApprovalValidation = Yup.object().shape({
  remarks: Yup.string().required("Reason is Required"),
});

export const poTakeApprovalvalidation = Yup.object().shape({
  newTakeRate: Yup.string()
    .matches(
      /^\d+(\.\d{1,2})?$/,
      "New Take Rate must have up to two decimal places"
    )
    .required("New Take Rate is required"),
  newTakeRateReason: Yup.string().required("Reason is Required"),
});

export const takeRateReasonValidation = Yup.object().shape({
  newTakeRateReason: Yup.string().required("Reason is Required"),
});
