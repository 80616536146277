import React, { useMemo, useEffect, useState } from "react";
import Badge from "@mui/material/Badge";
// import Table from "../../TimelineDashboard/ProjectTable/Table";
import Table from "./Table";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
// import Drawer from "./Drawer";
import Drawer from "../../TimelineDashboard/ProjectTable/Drawer";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { fetchSopCharterByIdThunk } from "../../../store/slices/chatter/chatter.slice";
import { useParams } from "react-router-dom";

function NewKamTable() {
  const [showDrawer, setShowDrawer] = useState(false);
  const [timelineState, setTimelineState] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  // const { timeline } = useSelector(({ char }) => project.selectedTimeline);
  const { timeline } = useSelector(({ charter }) => charter.selectedTimeline);

  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(fetchSopCharterByIdThunk(params.id));
  }, [dispatch, params.id]);

  const mainSectionData = useMemo(() => {
    const newState = _.cloneDeep(timeline.sections);
    const columnId = timeline.columnsIds[0];
    newState.forEach((currentSection) => {
      const rows = currentSection.rows;
      Object.keys(rows).forEach((rowId) => {
        const row = rows[rowId];
        const count = row[timeline.columnsIds[0]].comments_count;
        row[columnId].addRowExtra = {
          icon: (
            <>
              <Badge
                badgeContent={count}
                color="primary"
                sx={{ marginRight: "1rem" }}
              >
                <AddCommentOutlinedIcon
                  sx={{ color: "rgb(157 167 177 / 47%)" }}
                />
              </Badge>
            </>
          ),
          onClick: (data) => {
            setTimelineState(data);
            setShowDrawer(true);
          },
        };
      });
    });
    return newState;
  }, [timeline, isUpdate]);

  return (
    <div>
      <Table
        {...timeline}
        sections={mainSectionData}
        dispalyIconProperty="none"
      />
      {/* {timelineState && (
        <Drawer
          showDrawer={showDrawer}
          setShowDrawer={() => {
            setShowDrawer(false);
            setTimelineState(null);
          }}
          sectionIdx={timelineState?.sectionIdx}
          rowIdx={timelineState?.rowIdx}
        />
      )} */}
    </div>
  );
}

export default NewKamTable;
