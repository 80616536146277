import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAllFinanceApprovalHandler,
  FinanceRejectionHandler,
  FinanceApprovedHandler
} from "./financeApproval.thunk";

const initialState = {
  FinanceApprovalData: [],
  totalFinanceApprovalData:0,
};



export const fetchAllFinanceApprovalThunk = createAsyncThunk(
  "fetchFinanceApproval",
  fetchAllFinanceApprovalHandler
);

export const fetchFinanceRejectionThunk = createAsyncThunk(
  "FinanceRejection",
  FinanceRejectionHandler
);

export const fetchFinanceApprovedThunk = createAsyncThunk(
  "FinanceApproved",
  FinanceApprovedHandler
);

const financeApprovalSlice = createSlice({
  name: "financeApproval",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    
      .addCase(fetchAllFinanceApprovalThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchAllFinanceApprovalThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.FinanceApprovalData = action.payload.data;
        state.totalFinanceApprovalData = action.payload.total;
        return state;
      })
      .addCase(fetchAllFinanceApprovalThunk.rejected, (state) => {
        state.isLoading = false;
      })
      
  },
});

export default financeApprovalSlice.reducer;
