import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchExpectationDataByIdApiThunk } from "../../store/slices/milestoneMetrics/milestoneMetrics.slice";
import { getProjectExpectationMetrics } from "../../utils/apis.utils";
import { userAxios } from "../../utils/axios/user.axios";
import { getFormattedTime } from "../../utils/time.util";
import classes from "./ExpectationsData.module.css";
const ExpectationData = (props) => {
  //   const [data, setData] = useState({
  //     advance_amount_received: "",
  //     closing_date: "",
  //     sku_codes: [],
  //     take_rate: "",
  //     taxable_project_amount: "",
  //     total_timeline_of_project: "",
  //     vendor_amount: "",
  //     vendor_name: "",
  //   });
  //   console.log(data);
  const dispatch = useDispatch();
  const data = useSelector(
    ({ milestonemetrics }) => milestonemetrics.ExpectationsData
  );
  const { id } = useParams();

  function checkIsNull(text) {
    if (text == null) {
      return "";
    }
    return text;
  }

  useEffect(() => {
    dispatch(fetchExpectationDataByIdApiThunk(id));
  }, [id]);
  return (
    <div className={classes["ED__container"]}>
      <div className={classes["ED__title"]}>
        <h4>Expectation data </h4>
      </div>
      <div className={classes["ED__data"]}>
        <div className={classes["ED__data--col"]}>
          <div className={classes["ED__data--text"]}>
            <h6>Deal Amount : &nbsp;</h6>

            <span> {checkIsNull(data?.deal_amount)}</span>
          </div>
          <div className={classes["ED__data--text"]}>
            <h6>Deal Amount With GST : &nbsp;</h6>
            <span>{checkIsNull(data?.deal_amount_with_gst)}</span>
          </div>
          <div className={classes["ED__data--text"]}>
            <h6>Taxable Project Amount : &nbsp;</h6>
            <span>{checkIsNull(data?.taxable_project_amount)}</span>
          </div>
          <div className={classes["ED__data--text"]}>
            <h6>Take Rate (%) : &nbsp;</h6>
            <span>{checkIsNull(data?.take_rate)}</span>
          </div>
          <div className={classes["ED__data--text"]}>
            <h6>Closing Date : &nbsp;</h6>
            <span>{checkIsNull(getFormattedTime(data?.closing_date))}</span>
          </div>
          <div className={classes["ED__data--text"]}>
            <h6>Total Time (Working days) : &nbsp;</h6>
            <span>{checkIsNull(data?.total_timeline_of_project)}</span>
          </div>
          <div className={classes["ED__data--text"]}>
            <h6>Remaining Collection : &nbsp;</h6>
            <span>{checkIsNull(data?.remaining_collection)}</span>
          </div>
        </div>
        <div className={classes["ED__data--col"]}>
          <div className={classes["ED__data--text"]}>
            <h6>Vendor Amount (Inclusive GSTIN) : &nbsp;</h6>
            <span>{checkIsNull(data?.vendor_amount_inc_gst)}</span>
          </div>
          <div className={classes["ED__data--text"]}>
            <h6>Vendor Amount (Exclusive GSTIN) : &nbsp;</h6>
            <span>{checkIsNull(data?.vendor_amount)}</span>
          </div>
          <div className={classes["ED__data--text"]}>
            <h6>SKU Codes : &nbsp;</h6>
            <span>{data?.sku_codes.join(", ")}</span>
          </div>
          <div className={classes["ED__data--text"]}>
            <h6>Advance Amount Received : &nbsp;</h6>
            <span>{checkIsNull(data?.advance_amount_received)}</span>
          </div>
          <div className={classes["ED__data--text"]}>
            <h6>Number Of Calendar Days Delayed : &nbsp;</h6>
            <span>
              {checkIsNull(data?.total_number_of_calendar_days_delayed)}
            </span>
          </div>
          <div className={classes["ED__data--text"]}>
            <h6>Vendor Name : &nbsp;</h6>
            <span>{checkIsNull(data?.vendor_name)}</span>
          </div>
          <div className={classes["ED__data--text"]}>
            <h6>Remaining Realisation : &nbsp;</h6>
            <span>{checkIsNull(data?.reamining_realisation)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExpectationData;
