import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import { Droppable } from "react-beautiful-dnd";

import Item from "./Item";

function Col({ column, tasks }) {
  var currentTasks = tasks.filter((task) => task.content);

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Box
        sx={{
          width: "280px",
          height: "100%",
          backgroundColor: "#F4F4F4",
          borderRadius: "10px",
        }}
      >
        <Box p={3}>
          <Typography sx={{ fontSize: 14 }} color="text.secondary">
            {column.title?.toUpperCase()} - {currentTasks.length} TASK
          </Typography>
        </Box>
        <Droppable droppableId={column.id}>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ minHeight: "100vh" }}
            >
              {currentTasks.map((task, index) => (
                <Item key={task.id} task={task} index={index} />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </Box>
    </Stack>
  );
}

export default Col;
