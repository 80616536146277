import React, { useEffect, useState } from "react";
import { Box, Grid, Button } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import {} from "./upload.css";
import Element from "../Form/Element";
import { inputType } from "../../utils/enum";
import { useFormik } from "formik";
import { approvalReasonThunk } from "../../store/slices/project/project.slice";
import { ApprovalValidation } from "../../utils/validation";
export default function ApprovalModal({
  selectedStatus,
  closeModal,
  fncReason,
  clickedFrom,
  projectId,
  invoice_id,
  page,
  rowsPerPage,
  setSearch,
  cancelButton
}) {
  const INIT_STATE = {
    remarks: "",
  };
  const params = useParams();

  // {
  //     “:“121",
  //     “approve”:true,
  //     “remarks”:“Test Remarks”
  // }

  function onSubmit(data) {
   
    const projectId = params.id;
    setSearch("");
    const newData = {
      invoice_id: invoice_id,
      approve: clickedFrom == "APPROVED" ? true : false,
      remarks: data.remarks,
    };
   
    dispatch(approvalReasonThunk({ projectId, newData, page, rowsPerPage }));
    closeModal();
  }

  const dispatch = useDispatch();
  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: INIT_STATE,
      onSubmit: onSubmit,
      validationSchema: ApprovalValidation,
    });
  return (
    <>
      <Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} px={3}>
            <Grid item md={12} pb={2}>
              <Element
                eletype={inputType.textarea}
                label="Reason"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "remarks",
                }}
                errorText={touched.remarks && errors.remarks}
                value={values.remarks}
              />
            </Grid>

            <Grid item md={12} pb={2} sx={{ textAlign: "right" }}>
              <Button
                size="large"
                sx={{ marginRight: "2rem" }}
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button size="large" type="submit" variant="contained">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}
