// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiDialog-paper {
  overflow-y: inherit;
}

/* .MuiTableContainer-root::-webkit-scrollbar{
  display: none;
} */`, "",{"version":3,"sources":["webpack://./src/components/Modal/GlobalModal.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;;GAEG","sourcesContent":[".MuiDialog-paper {\n  overflow-y: inherit;\n}\n\n/* .MuiTableContainer-root::-webkit-scrollbar{\n  display: none;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
