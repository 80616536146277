import React, { useEffect, useState } from "react";
import {
  Grid,
  Stack,
  Typography,
  Card,
  Container,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import Dropdown from "../../components/Form/Dropdown";
import { FormControl } from "@mui/material";

import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  addUserThunk,
  editUserThunk,
  getUserByIdThunk,
} from "../../store/slices/users/addUsers.slice";
import { addUserValidation } from "../../utils/validation";
import data from "@iconify/icons-eva/menu-2-fill";
import AddUserSearch from "../../components/Form/AddUserSearch";

const AddUser = () => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      role: "",
      first_name: "",
      last_name: "",
      email: "",
      designation: "",
      rm_user: "",
    },
    validationSchema: addUserValidation,
    onSubmit: onSubmit,
  });

  const dispatch = useDispatch();
  const params = useParams();
  const [status, setStatus] = useState("");
  const [isNew, setIsNew] = useState(true);
  const [rmUserId, setRmUserId] = useState("");
  const [searchUserId, setSearchUserId] = useState("");
  let { UserData } = useSelector(({ adduser }) => adduser);

  useEffect(() => {
    if (params.id) {
      dispatch(getUserByIdThunk(params.id))
        .unwrap()
        .then((data) => {
          setValues(data);
          setStatus(data.role);
        });
      return setIsNew(false);
    }
    setIsNew(true);
  }, [params.id, UserData]);

  const navigate = useNavigate();

  function onSubmit(da) {
    if (isNew) {
      dispatch(addUserThunk({ ...da, role: status, rm_user: rmUserId }))
        .unwrap()
        .then((res) => navigate("/dashboard/users"))
        .catch((err) => navigate("/dashboard/users/adduser"));
    } else {
      var updateData = {
        ...da,
        role: status,
        rm_user: rmUserId,
      };
      dispatch(editUserThunk({ id: params.id, data: updateData }))
        .unwrap()
        .then((res) => navigate("/dashboard/users"))
        .catch((err) => navigate("/dashboard/users/" + params.id));
    }
  }
  const optionArray = [
    {
      label: "Admin",
      value: "admin",
    },
    {
      label: "Normal",
      value: "normal",
    },
    {
      label: "CL",
      value: "cl",
    },
    {
      label: "HOD",
      value: "hod",
    },
  ];

  function handleItemClick(id) {
    console.log("id-------", id);
    setRmUserId(id);
  }

  return (
    <Container>
      <Stack mb={5}>
        <Typography variant="h4" gutterBottom>
          {isNew ? "Create a new user" : "Edit User"}
        </Typography>
        {/* <Breadcrumb /> */}
      </Stack>

      <Stack>
        <Card sx={{ overflow: "inherit" }} p={5}>
          <Container style={{ marginTop: "30px" }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} sx={{ padding: "40px" }}>
                <Grid item xs={6}>
                  <Element
                    label="First Name"
                    inputProps={{
                      type: "text",
                      placeholder: "First Name",
                      onChange: handleChange,
                      onBlur: handleBlur,
                      name: "first_name",
                    }}
                    eletype={inputType.input}
                    value={values.first_name}
                    errorText={touched.first_name && errors.first_name}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Element
                    label="Last Name"
                    inputProps={{
                      type: "text",
                      placeholder: "Last Name",
                      onChange: handleChange,
                      onBlur: handleBlur,
                      name: "last_name",
                    }}
                    eletype={inputType.input}
                    value={values.last_name}
                    errorText={touched.last_name && errors.last_name}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Element
                    label="Email"
                    inputProps={{
                      type: "text",
                      placeholder: "Email",
                      onChange: handleChange,
                      onBlur: handleBlur,
                      name: "email",
                    }}
                    eletype={inputType.input}
                    value={values.email}
                    errorText={touched.email && errors.email}
                  />
                </Grid>{" "}
                <Grid item xs={6}>
                  <Element
                    label="Designation"
                    inputProps={{
                      type: "text",
                      placeholder: "Designation",
                      onChange: handleChange,
                      onBlur: handleBlur,
                      name: "designation",
                    }}
                    eletype={inputType.input}
                    value={values.designation}
                    errorText={touched.designation && errors.designation}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <Dropdown
                      label="Permission"
                      styles={{
                        color: "#212529",
                        width: "100%",
                        border: "1px solid #ced4da",
                        height: "50px",
                        display: "block",
                        outline: "none",
                        padding: "12px",
                        fontSize: "1rem",
                        fontWeight: "400",
                        borderRadius: "0.25rem",
                        mozAppearance: "none",
                        backgroundClip: "padding-box",
                        backgroundColor: "#fff",
                        webkitAppearance: "none",
                      }}
                      options={optionArray}
                      icons
                      inputProps={{
                        handleChange: (e) => {
                          setFieldValue("role", e.target.value, true);
                          setStatus(e.target.value);
                        },
                        name: "role",
                      }}
                      param="ADDUSER"
                      errorText={touched.role && errors.role}
                      value={status}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <AddUserSearch
                      label={"Search Reporting Manager"}
                      handleItemClick={handleItemClick}
                      defaultVal={values.rm_user.name}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  pb={5}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button size="large" type="submit" variant="contained">
                    {isNew ? "Add User" : "Save"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Card>
      </Stack>
    </Container>
  );
};

export default AddUser;
