import React, { useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, TablePagination } from "@mui/material";
import ProjectCard from "./ProjectCard";
import SearchNotFound from "../../NotFound/SearchNotFound";
import { fetchAllProjectsThunk } from "../../../store/slices/project/project.slice";
import _ from "lodash";
import { Box } from "@mui/system";

const ProjectGrid = ({ projectsData, searchValue }) => {
  let { projects, totalProjects } = useSelector(({ project }) => project);

  return (
    <>
      <Grid container spacing={3}>
        {projects.map((project) => (
          <Grid
            key={project.id}
            item
            xs={12}
            sm={6}
            md={4}
            sx={{ padding: "20px" }}
          >
            <ProjectCard project={project} searchValue={searchValue} />
          </Grid>
        ))}
        {projects?.length === 0 && <SearchNotFound />}
      </Grid>
    </>
  );
};

export default ProjectGrid;
