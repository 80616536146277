// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collectionTable {
    width: 100%;
    box-shadow: 0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%);
    border-radius: 16px;
    margin-bottom: 3em;
    margin-top: 1em;
    background-color: #fff;
    color: #212B36;
}

.collectionTable .MuiDataGrid-columnHeaders {
    background-color: #f3f6f8;
}

/* .collectionTable .MuiSvgIcon-root {
    display: none;
} */

.collectionTable .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ProjectDetail/Approval/Collection.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,qFAAqF;IACrF,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;;GAEG;;AAEH;IACI,wBAAwB;AAC5B","sourcesContent":[".collectionTable {\n    width: 100%;\n    box-shadow: 0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%);\n    border-radius: 16px;\n    margin-bottom: 3em;\n    margin-top: 1em;\n    background-color: #fff;\n    color: #212B36;\n}\n\n.collectionTable .MuiDataGrid-columnHeaders {\n    background-color: #f3f6f8;\n}\n\n/* .collectionTable .MuiSvgIcon-root {\n    display: none;\n} */\n\n.collectionTable .MuiDataGrid-root .MuiDataGrid-cell:focus-within {\n    outline: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
