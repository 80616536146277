import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { makeStyles } from "@mui/styles";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { green } from "@mui/material/colors";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import { Field } from "formik";

const useStyles = makeStyles({
  dropzoneFile: {
    outline: " none",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "center",
    padding: "20px 8px",
    borderRadius: "8px",
    backgroundColor: "rgb(244, 246, 248)",
    textAlign: "center",
    border: "2px #919eab52 dashed",
    minHeight: "200px",
    "&:hover": {
      opacity: 0.72,
      cursor: "pointer",
    },
    zIndex: "1000",
  },
  DragFont: {},

  UploadImg: {
    width: "40vh",
    height: "auto",
    float: "right",
  },
});
export default function MyDropzone({ addAttachment, allowAll }) {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles(
        [...files, ...acceptedFiles].map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            file: file,
          })
        )
      );
    },
    [files]
  );

  function onSubmit() {
    addAttachment({
      file: files.map((file) => file.file),
    });
    setFiles([]);
  }

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    accept: allowAll
      ? ".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,.zip,.rar,.7zip"
      : ".jpg,.png,.jpeg,.svg",
    multiple: true,
    noClick: true,
  });

  useEffect(() => {
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  function handleDelete(e, id) {
    e.stopPropagation();
    const updatedFiles = [...files];
    updatedFiles.splice(id, 1);
    setFiles(updatedFiles);
  }

  return (
    <>
      <div className={classes.dropzoneFile} {...getRootProps()} onClick={open}>
        <Stack
          direction="column"
          alignItems={files.length === 0 ? "center" : "flex-start"}
          justifyContent={files.length === 0 ? "center" : "flex-start"}
        >
          {files.length === 0 && (
            <div style={{ paddingBottom: "20px" }}>
              <img
                src="https://client-dashboard-9tg27827g-exmyb.vercel.app/static/mock-images/covers/drop-file.svg"
                className={classes.UploadImg}
                alt=""
              />
            </div>
          )}

          <div
            className={classes.titleDiv}
            style={{ float: "left", display: "flex", gap: "10px" }}
          >
            <input
              {...getInputProps()}
              accept={
                allowAll
                  ? ".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,.zip,.rar,.7zip"
                  : ".jpg,.png,.jpeg,.svg"
              }
            />
            {files.length > 0 ? (
              files.map((file, idx) => (
                <>
               
                  {file.type ===
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        background: "#fff",
                        padding: "1rem",
                        borderRadius: "1rem",
                        marginBottom: "1rem",
                        display: "flex",
                        flexDirection: "column-reverse",
                      }}
                      onClick={(e) => handleDelete(e, idx)}
                    >
                      {file.name}
                      <img
                        height="100"
                        width="100"
                        src="/static/icons/xls.svg"
                        alt=""
                        style={{ margin: "0 auto" }}
                      />
                      <CancelOutlinedIcon
                        sx={{
                          position: "absolute",
                          top: "0",
                          right: "0",
                          color: "#55607f",
                        }}
                      />
                    </div>
                  ) : file.type ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        background: "#fff",
                        padding: "1rem",
                        borderRadius: "1rem",
                        marginBottom: "1rem",
                        display: "flex",
                        flexDirection: "column-reverse",
                      }}
                      onClick={(e) => handleDelete(e, idx)}
                    >
                      {file.name}
                      <img
                        height="100"
                        width="100"
                        src="/static/icons/doc.svg"
                        alt=""
                        style={{ margin: "0 auto" }}
                      />
                      <CancelOutlinedIcon
                        sx={{
                          position: "absolute",
                          top: "0",
                          right: "0",
                          color: "#55607f",
                        }}
                      />
                    </div>
                  ) : file.type === "application/zip" ? (
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        background: "#fff",
                        padding: "1rem",
                        borderRadius: "1rem",
                        marginBottom: "1rem",
                        display: "flex",
                        flexDirection: "column-reverse",
                      }}
                      onClick={(e) => handleDelete(e, idx)}
                    >
                      {file.name}
                      <img
                        height="100"
                        width="100"
                        src="/static/icons/zip.svg"
                        alt=""
                        style={{ margin: "0 auto" }}
                      />
                      <CancelOutlinedIcon
                        sx={{
                          position: "absolute",
                          top: "0",
                          right: "0",
                          color: "#55607f",
                        }}
                      />
                    </div>
                  ) : file.type === "application/pdf" ? (
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        background: "#fff",
                        padding: "1rem",
                        borderRadius: "1rem",
                        marginBottom: "1rem",
                        display: "flex",
                        flexDirection: "column-reverse",
                      }}
                      onClick={(e) => handleDelete(e, idx)}
                    >
                      {file.name}
                      <img
                        height="100"
                        width="100"
                        src="/static/icons/pdf.svg"
                        alt=""
                        style={{ margin: "0 auto" }}
                      />
                      <CancelOutlinedIcon
                        sx={{
                          position: "absolute",
                          top: "0",
                          right: "0",
                          color: "#55607f",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        background: "#fff",
                        padding: "1rem",
                        borderRadius: "1rem",
                        marginBottom: "1rem",
                      }}
                      onClick={(e) => handleDelete(e, idx)}
                    >
                      <img
                        height="100"
                        width="100"
                        src="/static/icons/image.svg"
                        alt=""
                        style={{ margin: "0 auto" }}
                      />
                      <CancelOutlinedIcon
                        sx={{
                          position: "absolute",
                          top: "0",
                          right: "0",
                          color: "#55607f",
                        }}
                      />
                    </div>
                  )}
                </>
              ))
            ) : (
              <Typography
                variant="body1"
                className={classes.DragFont}
                sx={{
                  fontSize: { xs: "14px", sm: "14px", md: "16px" },
                  marginLeft: { xs: "0px", sm: "0px", md: "10px" },
                }}
              >
                {" "}
                <strong>
                  {isDragActive ? "Drop the file here" : "Drop or Select File "}
                </strong>
              </Typography>
            )}
          </div>
        </Stack>
      </div>
      <Box sx={{ textAlign: "right", padding: "1rem" }}>
        <Button
          color="secondary"
          onClick={onSubmit}
          loadingPosition="start"
          variant="contained"
          sx={{
            background: green[500],
            ":hover": { background: green[300] },
          }}
        >
          Upload
        </Button>
      </Box>
    </>
  );
}
