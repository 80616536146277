import React, { useEffect, useState } from "react";
import { Box, Grid, Button, Typography, Stack } from "@mui/material";
import Element from "../Form/Element";
import { inputType } from "../../utils/enum";
import useForm from "../../hooks/useForm";
import { useFormik } from "formik";
import { milestoneDelayValidationSchema } from "../../utils/validation";
import { useDispatch, useSelector } from "react-redux";
import {
  markAsMilestoneComplete,
  fetchAllCollectionsByProjectThunk,
} from "../../store/slices/project/project.slice";
import { useParams } from "react-router-dom";
import {} from "./upload.css";
import CheckboxList from "../Form/CheckBoxGroup";
import { markMilestoneCompleteHandler } from "../../utils/global/global";
import { showFailureToast } from "../../store/slices/toast/toast.slice";

export default function MilestoneDelayedReasonModal({
  closeModal,
  dataFromChild,
  selectedData,
}) {
  const checkboxGroupOptions = [
    {
      name: "OIE",
      value: "OIE",
    },
    {
      name: "DESIGN",
      value: "DESIGN",
    },
    {
      name: "CLIENT",
      value: "CLIENT",
    },
    {
      name: "TECHNICAL",
      value: "TECHNICAL",
    },
    {
      name: "SM",
      value: "SM",
    },
  ];
  const INIT_STATE = {
    milestone_delayed_reason: [],
  };
  const dispatch = useDispatch();
  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: INIT_STATE,
      onSubmit: onSubmit,
      validationSchema: milestoneDelayValidationSchema,
    });

  const params = useParams();

  function onSubmit(data) {
   
    let allReason = data?.milestone_delayed_reason.join(",");
    
    markMilestoneCompleteHandler(params.id, selectedData.id, allReason)
      .then(() => {
        dispatch(markAsMilestoneComplete(selectedData.id));
        dataFromChild();
        closeModal();
        dispatch(fetchAllCollectionsByProjectThunk(params.id));
      })
      .catch((err) => {
        dispatch(
          showFailureToast({
            message: err,
            vertical: "top",
            horizontal: "right",
          })
        );
      });
  }

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} px={3}>
            {/* <Grid item md={12}>
              <Typography variant="h5">Milestone Info</Typography>
            </Grid> */}

            <Grid item md={12} pb={2}>
              <Element
                eletype={inputType.checkboxgroup}
                label="Select reason for delayed"
                options={checkboxGroupOptions}
                onChange={handleChange}
                name="milestone_delayed_reason"
                value={values.milestone_delayed_reason}
                errorText={
                  touched.milestone_delayed_reason &&
                  errors.milestone_delayed_reason
                }
              />
            </Grid>

            <Grid item md={12} pb={2} sx={{ textAlign: "right" }}>
              <Button
                size="large"
                sx={{ marginRight: "2rem" }}
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button size="large" type="submit" variant="contained">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}
