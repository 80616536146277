// material
import { Box, Grid, Container, Typography } from "@mui/material";

// components
import Page from "../../components/Page.js";
import ActiveProjectLoader from "./ActiveProjectLoader.js";

import TotalProjectLoader from "./TotalProjectLoader.js";

// ----------------------------------------------------------------------

export default function DashboardAppLoader() {
  return (
    <Page title="KAM Dashboard | EMB">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Welcome to KAM Dashboard </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <TotalProjectLoader />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TotalProjectLoader />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TotalProjectLoader />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TotalProjectLoader />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TotalProjectLoader />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <ActiveProjectLoader />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
