import React from "react";
import Scrollbar from "../../components/Scrollbar";
import USERLIST from "../../mock-data/user";
import { DateTime } from "luxon";
import UserMoreMenu from "../../components/_dashboard/user/UserMoreMenu";
import { mockImgCover } from "./../../utils/mockImages";
import DeleteAction from "../../components/common/DeleteAction";

import {
  Card,
  Box,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Container,
  Grid,
  Avatar,
  TableHead,
  Paper,
} from "@mui/material";
import { css } from "@emotion/css";
import { Link, useNavigate } from "react-router-dom";
import ListHead from "../../components/common/ListHead";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  allQuestionThunk,
  deleteQuestionThunk,
  setSelectedQuestion,
} from "../../store/slices/questions/question.slice";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import SearchNotFound from "../../components/NotFound/SearchNotFound";
import { capitalCase } from "../../utils/change-case";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

//declaring column heading
const columns = [
  {
    id: "question",
    label: "FAQ's Question",
    align: "left",
    minWidth: 200,
  },
  {
    id: "type",
    label: "Question Type",
    align: "center",
    minWidth: 150,
  },
  { id: "created_at", label: "Created On", align: "center", minWidth: 150 },
  // { id: "ques_id", label: "", align: "center", minWidth: 50 },
  { id: "action", label: "Action ", alignRight: false },
];

const QuestionTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allQuestion = useSelector(({ questions }) => questions.data);

  useEffect(() => {
    dispatch(allQuestionThunk());
  }, [dispatch]);

  function handleEdit(question) {
    dispatch(setSelectedQuestion(question));
    navigate({
      pathname: "/dashboard/questions/" + question.ques_id,
    });
  }

  function handleDelete(id) {
    dispatch(deleteQuestionThunk(id));
  }

  return (
    <Container>
      <div>
        <Box mb={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
            p={1}
          >
            <Paper>
              <Typography variant="h4" mb={2}>
                All Question's
              </Typography>
            </Paper>
            <Paper>
              <Button
                variant="contained"
                startIcon={<Icon icon={plusFill} />}
                sx={{ float: "right" }}
                onClick={() =>
                  navigate({ pathname: "/dashboard/questions/add" })
                }
              >
                Add Question
              </Button>
            </Paper>
          </Stack>
        </Box>

        <Card>
          <Scrollbar>
            <TableContainer component={Paper}>
              {allQuestion.length === 0 ? (
                <SearchNotFound />
              ) : (
                <Table aria-label="simple table">
                  <TableHead sx={{ backgroundColor: "rgb(243, 246, 248)" }}>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ top: 57, minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allQuestion.map((row) => {
                      const { ques_id, question, type, created_at } = row;

                      return (
                        <TableRow key={ques_id}>
                          <TableCell
                            align="left"
                            onClick={() => handleEdit(row)}
                          >
                            {/* {question} */}
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar
                                sx={{
                                  bgcolor: "#eff8f1",
                                  color: "#00aa55",
                                  fontSize: ".8rem",
                                  fontWeight: "600",
                                  cursor: "pointer",
                                }}
                              >
                                <QuestionAnswerIcon />
                              </Avatar>
                              <Link
                                to="/"
                                className={css`
                                  text-decoration: none;
                                  color: black;
                                `}
                              >
                                <Typography variant="subtitle2" noWrap>
                                  {question}
                                </Typography>
                              </Link>
                            </Stack>
                          </TableCell>
                          <TableCell align="center">
                            {capitalCase(type)}
                          </TableCell>
                          <TableCell align="center">
                            {DateTime.fromISO(created_at).toFormat("DD")}
                          </TableCell>

                          <TableCell align="left">
                            <DeleteAction
                              handleDelete={() => handleDelete(ques_id)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
      </div>
    </Container>
  );
};

export default QuestionTable;
