import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchRefundStatusHandler } from "./refundStatus.thunk";

const initialState = {
  RefundStatusData: [],
  totalrefundStatusData: 0,
};


export const fetchRefundStatusThunk = createAsyncThunk(
  "FetchrefundStatus",
  fetchRefundStatusHandler
);


const refundStatusSlice = createSlice({
  name: "refundStatus",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    
      .addCase(fetchRefundStatusThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchRefundStatusThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.RefundStatusData = action.payload.data;
        state.totalrefundStatusData = action.payload.total;
        return state;
      })
      .addCase(fetchRefundStatusThunk.rejected, (state) => {
        state.isLoading = false;
        
      })
  },
});

export default refundStatusSlice.reducer;
