import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  badge_blue: {
    color: "#15AC58",
    background: "rgb(239 248 241)",
    borderColor: "transparent",
    display: "inline-block",
    fontSize: "75%",
    fontWeight: "600",
    lineHeight: 1,
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "baseline",
  },
  badge: {
    lineHeight: 1.7,
    padding: "0.25em 0.7em",
    borderRadius: "10rem",
  },

  attachMedia: {
    display: "flex",
    alignItems: "center",
  },

  attachFileHeading: {
    color: "#212B36",
    whiteSpace: "nowrap",
    marginRight: "10px",
  },
  closeFile: {
    position: "absolute",
    top: "-20px",
    right: "-75px",
    color: "#D4DADF",
    cursor: "pointer",
    height: "16px",
    background: "#fff",
  },
  AvtarImg: {
    position: " absolute",
    right: "-60px",
    top: 0,
    width: "30px",
    height: "30px",
    borderRadius: "25px",
  },
  svgIconSize: {
    fontSize: "14px",
  },
  attachePropsal: {
    height: "auto",
    background: "#F4F6F8 0% 0% no-repeat padding-box",
    borderRadius: " 20px",
    padding: "40px",
  },
  parentCard: {
    padding: "35px",
    borderRadius: " 20px",
    zIndex: "-1",
    display: "flex",
    width: "100%",
  },
  propsalContent: {
    display: "flex",
  },
  proposalTitle: {
    textAlign: "left",
    fontSize: "17px",
    letterSpacing: "0px",
    color: "#252B34",
    opacity: 1,
  },
  vl: {
    borderLeft: "3px solid #64A565",
    height: "40px",
    marginLeft: "50px",
  },
  propsalBtn: {
    color: "#ffff !important",
    fontSize: "16px !important",
    background: "#3359B1 0% 0% no-repeat padding-box !important",
    borderRadius: "9px !important",
    boxShadow: "none",
    opacity: 1,
    display: "inline-block",
    padding: "6px 12px",
    cursor: "pointer",
    "&:hover": {
      shadow: "none",
      boxShadow: "none",
    },
  },
  inputBtn: {
    '&:[type="file"]': {
      display: "none",
    },
  },
  "@media (max-width:900px)": {
    dateInMobile: {
      display: "block",
    },
  },
});
