import React from "react";
import { useEffect } from "react";

import { useStyles } from "../Section.theme";
import { useState } from "react";
function NumberInput({ value, change, styles, isAdmin, status, columns }) {
  const classes = useStyles();

  const [dropStatus, setDropStatus] = useState("");
  const [colId, setColId] = useState("");

  useEffect(() => {
    if (columns != undefined) {
      for (var i = 0; i < Object.keys(columns).length; i++) {
        if (
          columns[Object.keys(columns)[i]].value.toUpperCase() === "STATUS" &&
          columns[Object.keys(columns)[i]].type.toUpperCase() === "DROPDOWN"
        ) {
          for (var j = 0; j < Object.keys(status).length; j++) {
            if (Object.keys(status)[j] == Object.keys(columns)[i]) {
              setDropStatus(status[Object.keys(status)[j]].value);
            }
          }
        } else if (
          columns[Object.keys(columns)[i]].key.toUpperCase() ===
          "end_date".toUpperCase()
        ) {
          setColId(Object.keys(columns)[i]);
        }
      }
    }
  }, [columns, status]);

  function handleKeyDown(event) {
    if (
      !(
        (event.key >= 0 && event.key <= 9) ||
        event.key === "Backspace" ||
        event.key === "Tab"
      )
    )
      event.preventDefault();
  }

  return (
    <div>
      {dropStatus === "Done" && isAdmin == false ? (
        <p className={classes.inputElement} style={styles}>
          {value}
        </p>
      ) : (
        <input
          className={classes.inputElement}
          value={value}
          onChange={(e) => change(e.target.value)}
          style={styles}
          onKeyDown={handleKeyDown}
        />
      )}
    </div>
  );
}

export default NumberInput;
