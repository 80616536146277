import React, { useEffect, useState } from "react";
import { Grid, Box, Tab, Tabs } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

const CustomTabs = ({ labels, query, viewAccessKey }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (e, val) => {
    setSelectedTab(val);
  };

  const location = useLocation();

  useEffect(() => {
    const idx = labels.findIndex((label) =>
      location.pathname.includes(label.value)
    );
    if (idx >= 0) {
      setSelectedTab(idx);
    }
  }, [labels, location.pathname]);

  function getIcon(label, i) {
    if (label) {
      if (selectedTab == i) {
        return <img src={label.activeIcon} alt="Commercials" width="17" />;
      }
      return <img src={label.icon} alt="Commercials" width="17" />;
    }

    return null;
  }

  return (
    <Grid>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="basic tabs example"
        >
          {labels &&
            labels.map((label, i) => {
              if (
                label.value === "approvedbyfinance" &&
                viewAccessKey === false
              ) {
                return <></>;
              }
              if (
                label.value === "rejectedbyfinance" &&
                viewAccessKey === false
              ) {
                return <></>;
              }
              return (
                <Tab
                  key={i}
                  label={label.label}
                  component={Link}
                  to={label.value + query}
                  icon={label.icon && getIcon(label, i)}
                  iconPosition="start"
                />
              );
            })}
        </Tabs>
      </Box>
    </Grid>
  );
};

export default CustomTabs;
