import React, { useEffect, useMemo } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Stack,
  Box,
  Typography,
  Button,
  Tabs,
  Tab,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import DownloadIcon from "@mui/icons-material/Download";
import Page from "../../components/Page";

import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  acceptLeadProposalThunk,
  fetchProposalByLeadIdThunk,
  rejectLeadProposalThunk,
  tabChange,
} from "../../store/slices/proposal/proposal.slice";
import SearchNotFound from "../../components/NotFound/SearchNotFound";
import { useParams } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <Container sx={{ my: 4, mx: "auto" }}>
      {value === index ? children : null}
    </Container>
  );
}

export default function ProposalDetails() {
  const { selectedTab, selectedProposal } = useSelector(
    ({ proposal }) => proposal
  );
  const dispatch = useDispatch();
  const params = useParams();

  function handleTabChange(e, value) {
    dispatch(tabChange(value));
  }

  useEffect(() => {
    dispatch(fetchProposalByLeadIdThunk(params.id));
  }, []);

  function handleAcceptLead() {
    const proposals = selectedProposal.proposals;
    const proposal_id = proposals[0]?.proposal_id;
    const lead_id = selectedProposal.lead_id;
    dispatch(acceptLeadProposalThunk({ lead_id, proposal_id }));
  }

  function handleRejectLead() {
    const proposals = selectedProposal.proposals;
    const proposal_id = proposals[0]?.proposal_id;
    const lead_id = selectedProposal.lead_id;
    dispatch(rejectLeadProposalThunk({ lead_id, proposal_id }));
  }

  let proposalData = selectedProposal?.proposals;
  proposalData = useMemo(() => {
    return proposalData.filter((proposal) => proposal.proposal_file);
  }, [proposalData]);

  return (
    <Page title="Proposal Details | KAM Dashboard">
      <Container sx={{ margin: 0 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Proposal Details
          </Typography>
          {/* <Breadcrumb /> */}
        </Stack>
        <Grid>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab label="Overview" />
              <Tab label="History" />
            </Tabs>
          </Box>
          <TabPanel value={selectedTab} index={0}>
            <Grid item xs={12} sm={12} md={12}>
              <Card mb={5}>
                <CardContent>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={5}
                  >
                    <Typography
                      gutterBottom
                      variant="h4"
                      component="h2"
                      sx={{
                        marginBottom: 0,
                        fontSize: { xs: "16px", sm: "16px" },
                      }}
                    >
                      {selectedProposal?.project?.project_name}
                    </Typography>
                  </Stack>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "text.secondary",
                      textAlign: "justify",
                      fontSize: { xs: "14px", sm: "14px" },
                    }}
                  >
                    Exercitationem laborum autem voluptatibus repellendus
                    numquam odit. Sunt odio nam. Incidunt voluptas et
                    consectetur et cupiditate. Est quasi illo rerum. Quidem
                    voluptas amet voluptatem. Eos et consequatur rerum dicta
                    doloremque pariatur. Est consequatur ex nihil quo animi eos
                    iste. Laudantium quos numquam temporibus voluptatibus enim
                    et atque facilis sit. Nulla doloribus occaecati nihil omnis
                    error ut et odit.Assumenda beatae unde architecto cum
                    praesentium rerum numquam. Vel accusantium odit. Minus
                    voluptatibus eos maxime nemo. Vitae sequi inventore
                    distinctio voluptatem in omnis quia aperiam porro.
                  </Typography>
                  {/* {selectedProposal?.proposals &&
                    selectedProposal.proposals.length > 0 && (
                      <Stack
                        direction="row"
                        spacing={2}
                        my={3}
                        sx={{ float: "right" }}
                      >
                        <Button
                          variant="outlined"
                          onClick={handleRejectLead}
                          startIcon={<CloseIcon />}
                          sx={{
                            border: "1px solid red",
                            color: "red",
                            background: "transparent",
                            "&:hover": {
                              background: "rgb(255 0 0 / 7%)",
                              border: "1px solid red",
                            },
                          }}
                        >
                          Reject
                        </Button>
                        <Button
                          variant="outlined"
                          startIcon={<DoneIcon />}
                          onClick={handleAcceptLead}
                        >
                          Accept
                        </Button>
                      </Stack>
                    )} */}
                </CardContent>
              </Card>
            </Grid>
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <Grid container spacing={3} mt={5}>
              <Grid item xs={12} sm={12} md={12}>
                {proposalData &&
                  proposalData.map((proposal, id) => {
                    return (
                      <TimelineItem key={id}>
                        <TimelineOppositeContent
                          color="text.secondary"
                          sx={{ flex: "0.12" }}
                        >
                          28 Dec,2021
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot color="success" />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Card
                            mb={5}
                            sx={{
                              width: "100%",
                              borderRadius: "6px",
                            }}
                          >
                            <CardContent>
                              <Grid container>
                                <Grid item md={8}>
                                  <Grid container>
                                    <Grid item md={6}>
                                      <Typography
                                        variant="h6"
                                        sx={{ float: "left" }}
                                      >
                                        <span style={{ fontWeight: "500" }}>
                                          Client status:&nbsp;&nbsp;
                                        </span>
                                        <span
                                          style={{
                                            textTransform: "capitalize",
                                            backgroundColor:
                                              proposal?.status_by_client ===
                                              "accepted"
                                                ? "#c6f6d4"
                                                : "#fed6d7",
                                            color:
                                              proposal?.status_by_client ===
                                              "accepted"
                                                ? "#276749"
                                                : "#9b2c2c",
                                            fontSize: ".75rem",
                                            padding: "0.25rem 0.75rem",
                                            borderRadius: "20px",
                                          }}
                                        >
                                          {proposal?.status_by_client === ""
                                            ? "Pending"
                                            : proposal?.status_by_client}
                                        </span>
                                      </Typography>
                                    </Grid>
                                    <Grid item md={6}>
                                      <Typography
                                        variant="h6"
                                        sx={{ float: "left" }}
                                      >
                                        <span style={{ fontWeight: "500" }}>
                                          EMB status:&nbsp;
                                        </span>
                                        <span
                                          style={{
                                            textTransform: "capitalize",
                                            backgroundColor:
                                              proposal?.status_by_exmyb ===
                                              "accepted"
                                                ? "#c6f6d4"
                                                : "#fed6d7",
                                            color:
                                              proposal?.status_by_exmyb ===
                                              "accepted"
                                                ? "#276749"
                                                : "#9b2c2c",
                                            fontSize: ".75rem",
                                            padding: "0.25rem 0.75rem",
                                            borderRadius: "20px",
                                          }}
                                        >
                                          {proposal?.status_by_exmyb === ""
                                            ? "Pending"
                                            : proposal?.status_by_exmyb}
                                        </span>
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item md={4}>
                                  <Button
                                    startIcon={<DownloadIcon />}
                                    sx={{ float: "right" }}
                                    href={proposal.proposal_file}
                                    target="blank"
                                  >
                                    Download
                                  </Button>
                                </Grid>
                                <Grid item md={12}>
                                  <Typography variant="body2" my={2}>
                                    {proposal?.reason_for_rejection}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </TimelineContent>
                      </TimelineItem>
                    );
                  })}
                {(!proposalData || proposalData?.length === 0) && (
                  <SearchNotFound />
                )}
              </Grid>
            </Grid>
          </TabPanel>
        </Grid>
      </Container>
    </Page>
  );
}
