import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Scrollbar from "./../Scrollbar";
// import UserUpdate from "./UserUpdate";
import PurchaseOrderModal from "../Modal/PurchaseOrderModal";
import { DateTime } from "luxon";
import {
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TableHead,
  Button,
  Grid,
  TableFooter,
  TablePagination,
  Chip,
  IconButton,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import _ from "lodash";
import { css } from "@emotion/css";
import Modal from "../Modal/Modal";
import { Link } from "react-router-dom";
import ListHead from "./../common/ListHead";

import { useEffect, useState } from "react";
import { capitalCase } from "../../utils/change-case";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { fetchAllUsersHandler } from "../../utils/global/user.global";
import useUser from "../../hooks/useUser";
import { poStatus, roles } from "../../utils/enum";
import { useDispatch, useSelector } from "react-redux";
import { createQueryString } from "../../utils/global/global";
import { createSearchParams } from "react-router-dom";
import {
  fetchPurchaseOrderThunk,
  RequestForApprovalPOThunk,
  fetchPOSummationThunk,
} from "../../store/slices/project/project.slice";
import { fetchAllCollectionsByProjectThunk } from "../../store/slices/project/project.slice";
import { format } from "date-fns";
import SearchNotFound from "../NotFound/SearchNotFound";
import InfoIcon from "@mui/icons-material/Info";
import POInfoModal from "../Modal/POInfoModal";
import POEditModal from "../Modal/POEditModal";

const columns = [
  { id: "id", label: "Id", alignRight: false },
  { id: "created_at", label: "Requested Date & Time", alignRight: false },
  { id: "Requested_by", label: "Requested By", alignRight: false },
  { id: "PO_number", label: "PO Number", alignRight: false },
  { id: "PO_amount", label: "PO Amount (Excl. GST)", alignRight: false },
  { id: "PO_date ", label: "PO Date ", alignRight: false },
  { id: "current_vendor ", label: "Current Vender ", alignRight: false },
  { id: "Vendor_Invoice_file", label: "Vendor Invoice", alignRight: false },
  { id: "milestones", label: "Milestones", alignRight: false },
  {
    id: " vendor_invoice_date",
    label: "Vendor Invoice Date",
    alignRight: false,
  },
  {
    id: " vendor_invoice_number",
    label: "Vendor Invoice Number",
    alignRight: false,
  },

  { id: "PO_status", label: "PO Status", alignRight: false },
  { id: "bill_no", label: "Bill No", alignRight: false },
  { id: "bill_status", label: "Bill Status", alignRight: false },

  { id: "Rejection_reason", label: "Reason", alignRight: false },
  { id: "sys_take_rate", label: "System Take Rate", alignRight: false },
  { id: "updated_take_rate", label: "Updated Take Rate", alignRight: false },
  { id: "emb_cut_amt", label: "EMB Cut Amount", alignRight: false },

  // { id: "Rejection_reason", label: "Reason", alignRight: false },

  { id: "action", label: "Action", alignRight: false },
];

function getInitials(project_name) {
  return capitalCase(project_name.substring(0, 2));
}

const PurchaseOrder = () => {
  const navigate = useNavigate();
  const [users, setAllUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [performaId, setPerformaId] = useState(0);
  const [rowData, setRowData] = useState({});
  const handleOpen = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const dispatch = useDispatch();
  const param = useParams();

  let { purchaseOrder, poSummation } = useSelector(({ project }) => project);
  let { editSuccess } = useSelector(({ project }) => project);
  const pushingPO = useSelector(({ project }) => project.pushingPO);

  const { deal_origin } = useSelector(
    ({ project }) => project?.selectedProject
  );

  const handleInfoOpen = (id) => {
    setPerformaId(id);
    setShowInfoModal(true);
  };
  const handleInfoClose = () => {
    setShowInfoModal(false);
  };
  const handleEditOpen = (row) => {
    setRowData(row);
    setShowEditModal(true);
  };
  const handleEditClose = () => {
    setShowEditModal(false);
  };

  const handlePOSubmit = (PO) => {
    let project_id = PO.project_id;
    let purchase_order_id = PO.id;
    var data = {
      project_id: PO.project_id,
      status: "requested_for_approval",
    };
    dispatch(
      RequestForApprovalPOThunk({
        id: purchase_order_id,
        data,
        paramID: param.id,
      })
    );
  };

  useEffect(() => {
    fetchAllUsersHandler().then((response) => {
      setAllUsers(response.data);
    });
  }, []);

  useEffect(() => {
    dispatch(fetchPurchaseOrderThunk(param.id));
    dispatch(fetchAllCollectionsByProjectThunk(param.id));
  }, [dispatch, param.id]);

  useEffect(() => {
    if (editSuccess) {
      dispatch(fetchPurchaseOrderThunk(param.id));
      dispatch(fetchAllCollectionsByProjectThunk(param.id));
    }
  }, [editSuccess]);

  useEffect(() => {
    if (!pushingPO) {
      dispatch(fetchPurchaseOrderThunk(param.id));
      dispatch(fetchAllCollectionsByProjectThunk(param.id));
    }
  }, [pushingPO]);

  useEffect(() => {
    dispatch(fetchPOSummationThunk(param.id));
  }, [param.id]);

  console.log("posumme", poSummation);
  return (
    <div>
      <Grid item md={12} pb={2} sx={{ textAlign: "right" }}>
        <Button
          color="inherit"
          variant="contained"
          onClick={handleOpen}
          disabled={deal_origin && deal_origin === "IND" ? false : true}
        >
          Create PO
        </Button>
      </Grid>
      <Card>
        <Scrollbar>
          {purchaseOrder?.length === 0 ? (
            <Stack pt={5}>
              <SearchNotFound
                src="/static/no-found/project.png"
                title=""
                subtitle="No Purchase Order Created Yet."
              />
            </Stack>
          ) : (
            <TableContainer
              sx={{ minWidth: 800, overflowY: "auto", height: "500px" }}
            >
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: "rgb(243, 246, 248)",
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  <TableRow sx={{}}>
                    {columns.map((column) =>
                      column.id != "action" ? (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            top: 57,
                            minWidth: column.minWidth,
                            backgroundColor: "#f3f6f8",
                            paddingLeft: "15px",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ) : (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            backgroundColor: "#f3f6f8",
                            position: "sticky",
                            right: 0,
                          }}
                        >
                          {column.label}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {purchaseOrder.map((row) => {
                    const {
                      id,
                      po_amount,
                      po_date,
                      po_number,
                      po_status,
                      zbooks_bill_no,
                      zbooks_bill_status,
                      vendor_invoice_file_url,
                      milestones,
                      vendor_invoice_date,
                      vendor_invoice_number,
                      rejection_reason,
                      approval_rejection_reason,
                      created_at,
                      created_by_email,
                      current_vendor,
                      sys_take_rate,
                      updated_take_rate,
                      emb_cut_amt,
                    } = row;

                    return (
                      <TableRow
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell align="left" style={{ minWidth: "50px" }}>
                          {id}
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: "180px" }}>
                          {created_at
                            ? new Date(created_at).toLocaleDateString("en-IN", {
                                timeZone: "Asia/Kolkata",
                                hour12: false,
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              })
                            : "-"}
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: "180px" }}>
                          {created_by_email == null ? "-" : created_by_email}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          padding="none"
                          style={{ minWidth: "200px" }}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            {/* <Avatar
                            sx={{
                              bgcolor: "#eff8f1",
                              color: "#00aa55",
                              fontSize: ".9rem",
                              fontWeight: "600",
                              marginLeft: "20px",
                            }}
                          >
                            {getInitials("sourabh")}
                          </Avatar> */}

                            <Typography
                              variant="subtitle2"
                              noWrap
                              sx={{ marginLeft: "30px" }}
                            >
                              {po_number == null ? "-" : po_number}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: "200px" }}>
                          {po_amount == null ? "-" : po_amount}
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: "150px" }}>
                          {po_date
                            ? new Date(po_date).toLocaleDateString(undefined, {
                                timeZone: "Asia/Kolkata",
                              })
                            : "-"}
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: "200px" }}>
                          {current_vendor == null ? "-" : current_vendor}
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: "180px" }}>
                          {po_status === "draft" ? (
                            <IconButton
                              aria-label="info"
                              onClick={() => handleInfoOpen(id)}
                              sx={{ cursor: "pointer" }}
                            >
                              <InfoIcon />
                            </IconButton>
                          ) : vendor_invoice_file_url == null ? (
                            "-"
                          ) : (
                            <a target="_blank" href={vendor_invoice_file_url}>
                              Invoice
                            </a>
                          )}
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: "150px" }}>
                          <Typography>
                            {milestones[0] == null ? "-" : milestones[0]}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: "180px" }}>
                          <Typography>
                            {vendor_invoice_date == null
                              ? "-"
                              : vendor_invoice_date}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: "190px" }}>
                          <Typography>
                            {vendor_invoice_number == null
                              ? "-"
                              : vendor_invoice_number}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: "200px" }}>
                          <Typography sx={{ textTransform: "lowercase" }}>
                            {po_status == null ? "-" : poStatus[`${po_status}`]}
                          </Typography>
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: "150px" }}>
                          <Typography>
                            {zbooks_bill_no == null ? "-" : zbooks_bill_no}
                          </Typography>
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: "150px" }}>
                          <Typography>
                            {zbooks_bill_status == null
                              ? "-"
                              : zbooks_bill_status}
                          </Typography>
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: "180px" }}>
                          {approval_rejection_reason == null
                            ? "-"
                            : approval_rejection_reason}
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: "180px" }}>
                          {sys_take_rate == null ? "-" : sys_take_rate}
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: "180px" }}>
                          {updated_take_rate == null ? "-" : updated_take_rate}
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: "180px" }}>
                          {emb_cut_amt == null ? "-" : emb_cut_amt}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            minWidth: "150px",
                            backgroundColor: "white",
                            position: "sticky",
                            right: 0,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              cursor:
                                row.is_editable !== true
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                          >
                            <IconButton
                              disabled={row.is_editable !== true}
                              aria-label="edit"
                              onClick={() => handleEditOpen(row)}
                              sx={{
                                cursor: "pointer",
                                color: "black",
                                marginRight: "10px",
                              }}
                            >
                              <EditIcon />
                            </IconButton>

                            <Button
                              disabled={row.is_editable !== true}
                              variant="contained"
                              onClick={() => {
                                handlePOSubmit(row);
                              }}
                            >
                              {" "}
                              Req.Approval
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Scrollbar>
      </Card>

      <Modal
        content={
          <PurchaseOrderModal
            poSummation={poSummation}
            closeModal={handleClose}
          />
        }
        maxWidth="md"
        isOpen={showModal}
        closeModal={handleClose}
        title="Add Purchase Order"
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{ justifyContent: "center", marginBottom: 2 }}
      />
      <Modal
        maxWidth="md"
        isOpen={showInfoModal}
        closeModal={handleInfoClose}
        title="Vendor Invoice Info"
        content={
          <POInfoModal
            id={performaId}
            projectId={param.id}
            closeModal={handleInfoClose}
          />
        }
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{ justifyContent: "center", marginBottom: 2 }}
      />
      <Modal
        maxWidth="md"
        isOpen={showEditModal}
        closeModal={handleEditClose}
        title="Edit PO"
        content={
          <POEditModal
            poSummation={poSummation}
            rowData={rowData}
            closeModal={handleEditClose}
          />
        }
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{ justifyContent: "center", marginBottom: 2 }}
      />
    </div>
  );
};

export default PurchaseOrder;
