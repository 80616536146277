import React, { useEffect, useMemo, useState } from "react";
import {
  Stack,
  Card,
  Typography,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  TableHead,
  chip,
  Chip,
  Box,
  IconButton,
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from "react-router-dom";
import Page from "../../../components/Page";
import Scrollbar from "../../../components/Scrollbar";
import Modal from "../../../components/Modal/Modal";
import "./Collection.css";

import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";

import SearchNotFound from "../../../components/NotFound/SearchNotFound";

import { TablePagination } from "@mui/material";
import ApprovalModal from "../../../components/Modal/ApprovalModal";
import { fetchAllProformaInvoicePeginationThunk ,fetchAllCollectionThunk} from "../../../store/slices/project/project.slice";
import { de } from "faker/lib/locales";

export default function Collection() {
  

  const { collectionData,deal_amount } = useSelector(
    ({ project }) => project
  );
  
  const dispatch = useDispatch();
  const params = useParams();
  const projectId = params.id;

  useEffect(() => {
    dispatch(
      fetchAllCollectionThunk({
        projectId,
      })
    );
  }, []);

  // new table:
  const columns = [
    {field:"milestone_id",headerName:"Id",width:100},
    {field: "milestone_name", headerName: "Milestone Name", width:250,sortable: false},
    {field: "amount", headerName: "Milestone Amount(Inclusive GST)",width:250,sortable: false},
    {field: "ci_or_bill_number", headerName: "PO/CI Number",width:180,sortable: false, },
    {field: "cn_or_dn_number", headerName: "CN/DN Number",width:180,sortable: false, },
    {field: "created_at", headerName: "Created Date",width:200,
      renderCell:(params) =>{
        const dateObj = new Date(params.value);
        const year = dateObj.getUTCFullYear();
        const month = dateObj.getUTCMonth() + 1;
        const day = dateObj.getUTCDate();
        const hours = dateObj.getUTCHours();
        const minutes = dateObj.getUTCMinutes();
        const seconds = dateObj.getUTCSeconds();
        const dateStr = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        const timeStr = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        return `${dateStr}-${timeStr}`
      }
    },
    {field: "transaction_type", headerName: "Transaction Type", width:150,sortable: false,
    cellClassName:(params) => {
      if (params.value === "credit"){
        return "credit";
      }else if (params.value === "debit"){
        return "debit"
      }else if (params.value === "realised") {
        return "realised"
      }else if (params.value === "refunded") {
        return "refunded"
      }else if (params.value === "reversed") {
        return "reversed"
      }
      
    }
    }
  ]

  return (
    <>
      <Page title="Proforma Invoice | EMB">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={2}
        >
          <Typography variant="h5" gutterBottom>
            Collection
          </Typography>
          { deal_amount &&
            <Typography variant="h5" gutterBottom>
            Deal Amount: {deal_amount}
          </Typography>}
        </Stack>
        {collectionData.length == 0 ? (
          <SearchNotFound
            src="/static/no-found/milestones.png"
            subtitle="No PI to display."
            title=" "
          />
        ) : (
          <Box 
            className="collectionTable"
            sx ={{
              '& .credit': {
                color: 'green',
                fontWeight: 'bold',
                textTransform: 'uppercase',
              },
              '& .debit': {
                color: 'red',
                fontWeight: 'bold',
                textTransform: 'uppercase',
              },
              '& .realised': {
                color: 'orange',
                fontWeight: 'bold',
                textTransform: 'uppercase',
              },
              '& .refunded': {
                color: '#778fe5',
                fontWeight: 'bold',
                textTransform: 'uppercase',
              },
              '& .reversed': {
                color: '#e7ab51',
                fontWeight: 'bold',
                textTransform: 'uppercase',
              },
              '.MuiDataGrid-iconButtonContainer': {
                visibility: 'visible',
              },
              '.MuiDataGrid-sortIcon': {
                opacity: 'inherit !important',
              },
            }}
            >
            <DataGrid
              rows={collectionData}
              columns={columns}
              hideFooter
              autoHeight
              disableColumnMenu
              disableSelectionOnClick
              disableColumnSelector
              
            />
          </Box>
        )}
      </Page>
    </>
  );
}
