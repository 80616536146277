import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Container,
  Drawer,
  Grid,
  Stack,
  Card,
  Tab,
  Tabs,
  Typography,
  Avatar,
} from "@mui/material";
import MyDropzone from "../../DropZone";
import { useDispatch } from "react-redux";
import {
  addCommentThunk,
  addFilesThunk,
  fetchAllCommentsForTimelineThunk,
  fetchAllFilesForTimelineThunk,
} from "../../../store/slices/project/project.slice";
import { useParams } from "react-router-dom";
import { listViewType } from "../../../utils/enum";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <Container sx={{ my: 4, mx: "auto" }}>
      {value === index ? children : null}
    </Container>
  );
}

function CustomDrawer({ showDrawer, setShowDrawer, sectionIdx, rowIdx }) {
  const [value, setValue] = useState(0);
  const [comment, setComment] = useState("");
  const params = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchAllCommentsForTimelineThunk({
        section_id: sectionIdx,
        row_id: rowIdx,
        project_id: params.id,
        charter_type: listViewType.timeline,
      })
    );
    dispatch(
      fetchAllFilesForTimelineThunk({
        section_id: sectionIdx,
        row_id: rowIdx,
        project_id: params.id,
        charter_type: listViewType.timeline,
      })
    );
  }, [dispatch, params, sectionIdx, rowIdx]);

  const { files, comments } = useSelector(
    ({ project }) => project.selectedTimeline
  );

  function handleCommentSave() {
    dispatch(
      addCommentThunk({
        project_id: params.id,
        section_id: sectionIdx,
        row_id: rowIdx,
        charter_type: listViewType.timeline,
        comment,
      })
    );
    setComment("");
  }

  function addAttachment(file) {
    const formData = new FormData();
    file.file.forEach((ele) => {
      formData.append("file", ele);
    });
    formData.append("project_id", params.id);
    formData.append("section_id", sectionIdx);
    formData.append("row_id", rowIdx);
    formData.append("charter_type", listViewType.timeline);
    dispatch(addFilesThunk(formData));
  }

  return (
    <Drawer
      anchor="right"
      open={showDrawer}
      onClose={setShowDrawer}
      PaperProps={{
        style: { width: "600px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          flexDirection: "column",
        }}
      >
        <Grid>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              paddingLeft: "1rem",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                icon={<ForumOutlinedIcon />}
                iconPosition="start"
                label="Add Comments"
              />
              <Tab
                icon={<CloudUploadOutlinedIcon />}
                iconPosition="start"
                label="Upload Files"
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Stack direction="column" spacing={2} sx={{ width: "100px" }}>
              <textarea
                rows="5"
                cols="10"
                style={{
                  width: "500px",
                  display: "block",
                  outline: " none",
                  padding: "12px",
                  fontSize: "1rem",
                  fontWeight: "400",
                  resize: "none",
                  color: "#637381",
                  backgroundColor: "#f3f6f8",
                  backgroundClip: "padding-box",
                  border: "1px solid #ced4da",
                  webkitAppearance: "none",
                  mozAppearance: "none",
                  appearance: "none",
                  borderRadius: "0.25rem",
                  fontFamily: "Public Sans,sans-serif",
                }}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
              <Button variant="contained" onClick={handleCommentSave}>
                Comment
              </Button>
              {comments.map((comment) => (
                <Box sx={{ minWidth: 500 }}>
                  <Typography
                    variant="body2"
                    sx={{ color: "rgb(99, 115, 129)" }}
                  >
                    {comment.user_name},{" "}
                    {DateTime.fromISO(comment.created_at).toFormat("DD HH:mm")}
                  </Typography>
                  <div
                    style={{
                      padding: "12px",
                      minHeight: "50px",
                      marginTop: "4px",
                      borderRadius: "8px",
                      backgroundColor: "rgb(244, 246, 248)",
                    }}
                  >
                    <Typography variant="body1">{comment.comment}</Typography>
                  </div>
                </Box>
              ))}
            </Stack>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid container>
              <Grid item xs={12} sm={6} md={12} sx={{ margin: "50px 0" }}>
                <MyDropzone addAttachment={addAttachment} />
              </Grid>
              {files.length > 0 && (
                <>
                  <Grid item xs={12} sm={6} md={12} sx={{ margin: "10px 0" }}>
                    <span style={{ color: "green", fontWeight: "400" }}>
                      Recent File
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Stack direction="row" spacing={2} flexWrap="wrap">
                      {files.map((file) => (
                        <Card
                          sx={{
                            padding: "1.25rem",
                            margin: "10px",
                            maxWidth: "250px",
                            width: "100%",
                          }}
                        >
                          <Stack direction="row" spacing={2}>
                            <Avatar src="https://cdn-icons-png.flaticon.com/512/2921/2921768.png" />
                            <Stack direction="column">
                              <Typography variant={"subtitle2"}>
                                <a
                                  href={file?.file_url}
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                  }}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {file?.file_name}
                                </a>
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: "text.secondary",
                                  display: "block",
                                }}
                              >
                                {DateTime.fromISO(file.created_at).toFormat(
                                  "MM/dd/yyyy"
                                )}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Card>
                      ))}
                    </Stack>
                  </Grid>
                </>
              )}
            </Grid>
          </TabPanel>
        </Grid>
      </Box>
    </Drawer>
  );
}

export default CustomDrawer;
