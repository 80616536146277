import { visuallyHidden } from "@mui/utils";
import {
  Box,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import { Padding } from "@mui/icons-material";

export default function ListHead({
  headCheckBox,
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  styles,
  HeaderPaddingLeft,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={styles}>
      <TableRow>
        {headCheckBox && (
          <TableCell>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{
              paddingLeft: HeaderPaddingLeft,
              position: headCell.id === 'action' ? 'sticky' : 'static',
              right: headCell.id === 'action' ? 0 : 'auto',
              background:headCell.id === 'action' ? "white" : '',
            }}
            align={"center"}
            // align={headCell.alignRight ? "right" : "left"}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* <TableSortLabel
              hideSortIcon
              active={orderBy === headCell.id}
              // direction={orderBy === headCell.id ? order : "asc"}
              // onClick={createSortHandler(headCell.id)}
            > */}
            {headCell.label}
            {/* {orderBy === headCell.id ? (
                <Box sx={{ ...visuallyHidden }}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
