import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
} from "@mui/material";
import React from "react";

import { makeStyles } from "@mui/styles";
import "./GlobalModal.css";
// import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
export const useStyles = makeStyles({
  closeFile: {
    position: "absolute",
    right: "8px",
    top: "8px",
    color: "#b4bec7",
    cursor: "pointer",
    height: "16px",
    background: "#fff",
  },
});

function Modal({
  isOpen,
  closeModal,
  title,
  content,
  action,
  dialogStyle = {},
  contentStyle = {},
  actionStyle = {},
  titleStyle = {},
  dialogProps,
  maxWidth = "sm",
}) {
  const classes = useStyles();
  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={dialogStyle}
      {...dialogProps}
      maxWidth={maxWidth}
    >
      <Box
        varient="span"
        component="button"
        className={classes.closeFile}
        sx={{ border: "none" }}
        onClick={closeModal}
      >
        {/* <HighlightOffIcon sx={{ fontSize: "20px" }} /> */}
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {title && (
        <DialogTitle sx={titleStyle} id="alert-dialog-title">
         {title}
        </DialogTitle>
      )}
      {content && <DialogContent sx={contentStyle}>{content}</DialogContent>}
      {action && <DialogActions sx={actionStyle}>{action}</DialogActions>}
    </Dialog>
  );
}

export default Modal;
