import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  assignChatterToProjectHandler,
  createChatterHandler,
  deleteCharterHandler,
  fetchAllCharterHandler,
  fetchCharterByIdHandler,
  updateCharterHandler,
  fetchSopCharterByIdHandler,
  updateSopTableHandler,
} from "./chatter.thunk";
import { getSectionMatrix } from "../../../utils/timeline.utils";

var timeline = {
  sections: [],
  columnsIds: [],
};

const INIT_STATE = {
  charterList: [],
  selectedCharter: null,
  selectedSopCharter: null,
  selectedTimeline: {
    timeline: timeline,
    files: [],
    comments: [],
  },
  timelineRef: [],
  isProjectTablePublished: false,
  isLoading: false,

  projectPagination: {
    query_term: "",
    type: "all",
    page: 1,
    spoc: -1,
  },
  projects: [],
  totalProjects: [],
  projectLimit: 50,
  selectedProject: {
    comments: {
      comments_data: [],
    },
    tickets_client: [],
    tickets_vendor: [],
  },
  selectedTab: 0,

  gantt: [],

  tickets: [],
  reviewFeedback: [],

  collections: [],

  projectStatus: [],
};

export const createChatterThunk = createAsyncThunk(
  "createChatter",
  createChatterHandler
);
export const updateSopTableThunk = createAsyncThunk(
  "updateSopTable",
  updateSopTableHandler
);

export const fetchAllCharterThunk = createAsyncThunk(
  "fetchAllCharter",
  fetchAllCharterHandler
);

export const updateCharterThunk = createAsyncThunk(
  "updateCharter",
  updateCharterHandler
);

export const fetchCharterByIdThunk = createAsyncThunk(
  "fetchCharterById",
  fetchCharterByIdHandler
);

export const fetchSopCharterByIdThunk = createAsyncThunk(
  "fetchSopCharterById",
  fetchSopCharterByIdHandler
);

export const deleteCharterThunk = createAsyncThunk(
  "deleteCharter",
  deleteCharterHandler
);

const chatterSlice = createSlice({
  name: "chatter",
  initialState: INIT_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCharterThunk.fulfilled, (state, action) => {
        state.charterList = action.payload;
        return state;
      })
      .addCase(fetchCharterByIdThunk.fulfilled, (state, action) => {
        state.selectedCharter = action.payload;
        return state;
      })
      // .addCase(fetchSopCharterByIdThunk.fulfilled, (state, action) => {
      //   state.selectedSopCharter = action.payload;
      //   return state;
      // })

      .addCase(fetchSopCharterByIdThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchSopCharterByIdThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedTimeline.timeline = getSectionMatrix(action.payload);
        state.timelineRef = action.payload;

        state.isProjectTablePublished = true;

        return state;
      })
      .addCase(fetchSopCharterByIdThunk.rejected, (state) => {
        state.isLoading = false;
        state.selectedTimeline = {
          timeline: timeline,
          files: [],
          comments: [],
        };
        state.isProjectTablePublished = false;
        return state;
      })
      .addCase(deleteCharterThunk.fulfilled, (state, action) => {
        state.charterList = state.charterList.filter(
          (ele) => ele._id !== action.payload
        );
        return state;
      });
  },
});

export default chatterSlice.reducer;
