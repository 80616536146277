// routes
import NewRoutes from "./routes";
// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
import { BrowserRouter as Router } from "react-router-dom";
import Toast from "./components/Toast/Toast";

// components
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/charts/BaseOptionChart";
import { useEffect } from "react";
// import { getDeviceToken } from "./config/firebase.config";
import { setupMessaging } from "./config/firebase.config";


// ----------------------------------------------------------------------

export default function App() {
  useEffect(() => {
    setupMessaging();
  }, []);

  return (
    <ThemeConfig>
      <Router>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <NewRoutes />
        <Toast />
      </Router>
    </ThemeConfig>
  );
}
