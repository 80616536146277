import React, { useEffect, useState } from "react";
import { makeStyles, TextField, Button, Grid } from "@material-ui/core";
import Input from "@material-ui/core/Input/Input";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 2,
//     padding: theme.spacing(2),
//   },
//   textField: {
//     width: '400px',
//   },
//   button: {
//     marginTop: theme.spacing(2),
//   },
// }));

const textFieldStyle = {
  width: "513px",
};

const ReasonBox = ({ reasonBoxData, ApprovalreasonBoxData }) => {
  //   const classes = useStyles();
  const [comment, setComment] = useState("");
  

  const handleCommentChange = (event) => {
    event.preventDefault();
    const newComment = event.target.value;
    setComment(newComment);
    reasonBoxData(newComment);
    // poRejectionReason(newComment)
  };

  const handleSubmit = () => {
    // console.log("Submitted comment:", comment);
    // setComment("");
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Input
            id="comment"
            variant=""
            style={textFieldStyle}
            multiline
            rows={1} 
            value={comment}
            onChange={handleCommentChange}
            
          />
        </Grid>
        {/* <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleSubmit}
          >
            Submit Comment
          </Button>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default ReasonBox;


export const PoReasonBox = ({ PoReasonBoxData }) => {
    //   const classes = useStyles();
    const [comment, setComment] = useState("");
   
  
    const handleCommentChange = (event) => {
        event.preventDefault();
      const newComment = event.target.value;
      setComment(newComment);
      PoReasonBoxData(newComment);
      // poRejectionReason(newComment)
    };
  
    const handleSubmit = () => {
      // console.log("Submitted comment:", comment);
      // setComment("");
    };
  
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Input
              id="comment"
              variant="outlined"
              style={textFieldStyle}
              multiline
              rows={1} 
              value={comment}
              onChange={handleCommentChange}
              
            />
          </Grid>
          {/* <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleSubmit}
            >
              Submit Comment
            </Button>
          </Grid> */}
        </Grid>
      </div>
    );
  };
  
