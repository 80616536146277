import { useState } from "react";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { capitalCase } from "../../utils/change-case";
import { TablePagination } from "@mui/material";
import _ from "lodash";

import { DateTime } from "luxon";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
// components
import Page from "../../components/Page";
import Scrollbar from "../../components/Scrollbar";
import SearchNotFound from "../../components/NotFound/SearchNotFound";
import DeleteAction from "../../components/common/DeleteAction";
import { useDispatch, useSelector } from "react-redux";
import ListHead from "../../components/common/ListHead";
import ListSearchbar from "../../components/common/ListSearchbar";
// import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
// import { deleteUser } from "../../store/slices/user/user.slice";
import { useEffect } from "react";
import {
  deleteUserThunk,
  fetchAllUserThunk,
} from "../../store/slices/users/addUsers.slice";
import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";
import { useStyles } from "../../components/Form/Form.theme";

// ----------------------------------------------------------------------

function getInitials(project_name) {
  return capitalCase(project_name?.substring(0, 2));
}

function Users({ permToken }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const TABLE_HEAD = [
    { id: "first_name", label: "First Name", alignRight: false },
    { id: "last_name", label: "Last Name", alignRight: false },
    { id: "r-manager", label: "R-Manager", alignRight: false },
    { id: "email", label: "Email", alignRight: false },

    { id: "designation", label: "Designation", alignRight: false },
    { id: "role", label: "Role", alignRight: false },
    { id: "Action", label: "Action", alignRight: false, minWidth: 100 },
  ];

  useEffect(() => {
    dispatch(
      fetchAllUserThunk({
        page: 1,
        param: "",
      })
    );
  }, [dispatch]);

  let { UserData, totalUserData } = useSelector(({ adduser }) => adduser);

  const navigate = useNavigate();

  function handleEdit(id) {
    navigate("/dashboard/users/" + id);
  }

  // ------------------------------------ Pagination on table ----------------------

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [param, setParam] = useState("");

  const handleChangePage = (_, newPage) => {
    dispatch(
      fetchAllUserThunk({
        page: newPage + 1,
        param: param,
      })
    );
    setPage(newPage);
  };

  const handleUserSearch = (param, page) => {
    dispatch(
      fetchAllUserThunk({
        page: page + 1,
        param: param,
      })
    );
  };

  const handleChange = (e) => {
    setParam(e.target.value);
    
    const waitedSearch = _.debounce(() => {
      handleUserSearch(e.target.value, page);
    }, 1500);
  
    waitedSearch();
  };

  // ------------------------------------------- ends ------------------

  function handleDelete(id) {
    dispatch(deleteUserThunk(id));
  }

  console.log("UserData", UserData, param);

  return (
    <Page title="User | Minimal-UI">
      <Container>
        <Stack mb={2}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          {/* <Breadcrumb /> */}
        </Stack>

        <Card>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              display: {
                lg: "flex",
                md: "flex",
                sm: "block",
                xs: "block",
                margin: "30px",
                justifyContent: "right",
              },
            }}
          >
            {/* <ListSearchbar placeholder="Search users" /> */}
            <Stack
              mr={3}
              direction="row"
              spacing={2}
              sx={{
                alignItems: "end",
              }}
            >
              <input
                className={classes.formControl}
                placeholder="Search User ..."
                onChange={(e) => handleChange(e)}
                name="search_user"
                value={param}
              />

              <Button
                sx={{
                  width: "200px",
                  height: "50px",
                }}
                variant="contained"
                component={RouterLink}
                to="/dashboard/users/adduser"
                startIcon={<Icon icon={plusFill} />}
              >
                New User
              </Button>
            </Stack>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {UserData?.length === 0 ? (
                <TableBody align="center" sx={{ display: "block" }}>
                  <TableRow>
                    <TableCell colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <Table>
                  <ListHead headLabel={TABLE_HEAD} />
                  <TableBody>
                    {UserData?.map((row) => {
                      const {
                        id,
                        first_name,
                        last_name,
                        email,
                        designation,
                        createdBy,
                        role,
                        rem_user,
                      } = row;

                      return (
                        <TableRow
                          hover
                          key={email}
                          tabIndex={-1}
                          role="checkbox"
                        >
                          <TableCell component="th" scope="row">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar
                                sx={{
                                  fontSize: ".8rem",
                                  fontWeight: "600",
                                  backgroundColor: "#f3f6f8",
                                  color: "#637281",
                                }}
                              >
                                {getInitials("sourabh")}
                              </Avatar>
                              <Typography variant="subtitle2" noWrap>
                                {first_name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{last_name}</TableCell>
                          <TableCell align="left">
                            {rem_user ? rem_user.name : "-"}
                          </TableCell>
                          <TableCell align="left">{email}</TableCell>

                          <TableCell align="left">{designation}</TableCell>
                          <TableCell align="left">{role}</TableCell>

                          <TableCell align="left">
                            <IconButton color="success" component="span">
                              <EditIcon
                                color="success"
                                sx={{ cursor: "pointer" }}
                                onClick={() => handleEdit(id)}
                              />
                            </IconButton>

                            <IconButton color="error" component="span">
                              <DeleteAction
                                handleDelete={() => handleDelete(id)}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {/* <TableRow>
                      <TableCell colSpan={6} />
                    </TableRow> */}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
            <TablePagination
              sx={{ float: "right" }}
              count={totalUserData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPageOptions={false}
            />
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}

export default Users;
