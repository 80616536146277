import React, { useEffect, useState } from "react";
import { Box, Grid, Button } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
// import {} from "./upload.css";
import Element from "../Form/Element";
import { inputType } from "../../utils/enum";
import { useFormik } from "formik";
import { approvalReasonThunk } from "../../store/slices/project/project.slice";
import {
  ApprovalValidation,
  poTakeApprovalvalidation,
  takeRateReasonValidation,
} from "../../utils/validation";
import { fetchPOApprovedThunk } from "../../store/slices/poapproval/poapproval.slice";
export default function NewTakeRateForm({
  selectedStatus,
  closeModal,
  fncReason,
  clickedFrom,
  projectid,
  invoice_id,
  page,
  rowsPerPage,
  setSearch,
  cancelButton,
  status
}) {
    
  const INIT_STATE = {
    newTakeRate: "",
    newTakeRateReason: "",
  };

  const {project_id,  po_id} = projectid

  function onSubmit(data) {
  
    const newData = {
      status: status,
      project_id: project_id,
      updated_take_rate: data.newTakeRate,
      approval_rejection_reason: data.newTakeRateReason,
    };
   
    dispatch(fetchPOApprovedThunk({ id: po_id, data: newData }))

    closeModal()
    
  }

  const dispatch = useDispatch();
  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: INIT_STATE,
      onSubmit: onSubmit,
      validationSchema: poTakeApprovalvalidation,
    });
  return (
    <>
      <Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} px={1} sx={{paddingLeft: "69px", paddingRight: "69px"}}>
            <Grid item md={12} pb={2}>
              <Element
                eletype={inputType.number}
                label="% New Take Rate *"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "newTakeRate",
                }}
                errorText={touched.newTakeRate && errors.newTakeRate}
                value={values.newTakeRate}
              />
            </Grid>
            <Grid item md={12} pb={2}>
              <Element
                eletype={inputType.input}
                label="Reason For Take Rate Updation *"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "newTakeRateReason",
                 
                  
                }}
                errorText={
                  touched.newTakeRateReason && errors.newTakeRateReason
                }
                value={values.newTakeRateReason}
                
              />
            </Grid>

            <Grid item md={12} pb={4} sx={{ textAlign: "center" }}>
              {/* <Button
                size="large"
                sx={{ marginRight: "2rem" }}
                onClick={()=> cancelButton(false)}
              >
                Cancel
              </Button> */}
              <Button 
              size="large" 
              type="submit" 
              variant="contained"
              >
                Update Take Rate
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}

export const TakeRateApproveReason = ({ 

  cancelButton, 
  projectid, 
  closeModal, 
  successCallback, 
  headermessage ,
  status,
  updatedTakeRate


}) => {
  const INIT_STATE = {
    newTakeRateReason: "",
  };
  const {project_id,  po_id} = projectid
  function onSubmit(data) {
    const newData = {
        status: status,
        project_id: project_id,
        approval_rejection_reason: data.newTakeRateReason,
        updated_take_rate: updatedTakeRate
      };


      dispatch(fetchPOApprovedThunk({ id: po_id, data: newData }))
      closeModal()
  }

  const dispatch = useDispatch();
  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: INIT_STATE,
      onSubmit: onSubmit,
      validationSchema: takeRateReasonValidation,
    });

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} px={3} sx={{paddingRight: "60px"}}>
            <Grid item md={12} pb={2} style={{ height: "20px !important" }}>
              <Element
                eletype={inputType.input}
                label={headermessage}
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "newTakeRateReason",
                  
                }}
                errorText={
                  touched.newTakeRateReason && errors.newTakeRateReason
                }
                value={values.newTakeRateReason}
              />
            </Grid>

            <Grid item md={12} pb={4} sx={{ textAlign: "center" }}>
              {/* <Button
                size="large"
                sx={{ marginRight: "2rem" }}
                onClick={()=> cancelButton(false)}
              >
                Cancel
              </Button> */}
              <Button size="large" type="submit" variant="contained">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};
