import React, { useEffect, useState } from "react";
import { Box, Grid, Button, Typography, Stack } from "@mui/material";
import Element from "../Form/Element";
import { inputType } from "../../utils/enum";
import useForm from "../../hooks/useForm";
import { useFormik } from "formik";
import { piValidation } from "../../utils/validation";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {} from "./upload.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { showFailureToast } from "../../store/slices/toast/toast.slice";
import {
  createProformaInvoiceThunk,
  fetchAllCollectionsByProjectThunk,
  fetchPIInfoThunk,
  deletePIInfoThunk
} from "../../store/slices/project/project.slice";
import { deletePIInfo } from "../../store/slices/project/project.slice"; 
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    Paper,
    TableHead,
    chip,
    Chip,
    IconButton,
  } from "@mui/material";
  import Scrollbar from "../Scrollbar";

function DisplayAddress({ address, gst_no, phone, email }) {
  if (!Object.keys(address).length) {
    return '-';
  }

  return (
    <p>
      {address.address}, {address.city}, {address.state}, {address.country}, {address.zip}
      <br />
      GSTIN : {gst_no}
      <br />
      Phone : {phone}
      <br /> 
      Email : {email}
    </p>
  )
}


export default function PIInfoModal({ closeModal, id, projectId }) {

    const columns = [
        { id: "service", label: "Service", alignRight: false },
        { id: "milestone", label: "Milestone", alignRight: false },
        { id: "bill_to", label: "Bill To", alignRight: false },
        { id: "ship_to", label: "Ship To", alignRight: false },
        { id: "amount", label: "Amount (Incl. GST)", alignRight: false },
    ];

  const PIInfo = useSelector(({ project }) => project.PIPreview);

  const dispatch = useDispatch();
  //State
  const [milestoneDropdown, SetMilestoneDropdown] = useState([]);
  //Use effects---
  const params = useParams();
  useEffect(() => {
    dispatch(fetchPIInfoThunk({id,project_id:projectId}));

    return ()=>{
        dispatch(deletePIInfo())
    }
  }, [id]);

  useEffect(()=>{
    console.log("PIInfo",PIInfo);
  },[PIInfo])

  return (
    <>
      <Box>
      <Card sx={{ marginBottom: "3rem" }}>
            <Scrollbar>
              <TableContainer component={Paper} sx={{ minWidth: 800 }}>
                <Table aria-label="simple table">
                  <TableHead sx={{ backgroundColor: "rgb(243, 246, 248)" }}>
                    <TableRow>
                      {columns.map((column) =>
                        column.id != "action" ? (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              top: 57,
                              minWidth: column.minWidth,
                              backgroundColor: "#f3f6f8",
                              paddingLeft: "15px",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ) : (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              backgroundColor: "#f3f6f8",
                              position: "sticky",
                              right: 0,
                            }}
                          >
                            {column.label}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody key={new Date().toISOString()}>
                    {PIInfo.map(row=>{
                        const {
                            billing_address,
                            shipping_address,
                            pi_amount_incl_gst,
                            service,
                            email,
                            phone,
                            gst_no,
                            milestone

                          } = row;

                          return (
                            <TableRow
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              sx={{ cursor: "pointer" }}
                            >
                              <TableCell align="left" style={{ minWidth: "150px" }}>
                                {service ? service : "-"}
                              </TableCell>
                              <TableCell align="left" style={{ minWidth: "150px" }}>
                                {milestone[0] == null ? "-" : milestone[0]}
                              </TableCell>
                              <TableCell align="left" style={{ minWidth: "250px" }}>
                                <DisplayAddress address={billing_address} gst_no={gst_no} phone={phone} email={email} />
                              </TableCell>
                              <TableCell align="left" style={{ minWidth: "250px" }}>
                                <DisplayAddress address={shipping_address} gst_no={gst_no} phone={phone} email={email} />
                              </TableCell>
                              <TableCell align="left" style={{ minWidth: "150px" }}>
                                {pi_amount_incl_gst ? parseFloat(pi_amount_incl_gst).toFixed(2) : "-"}
                              </TableCell>
                            </TableRow>
                          );
                    })
                        
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
      </Box>
    </>
  );
}
