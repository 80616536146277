import React, { useMemo } from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Modal from "../Modal/Modal";
import TicketStatus from "./TicketStatus";
// material
import {
  Typography,
  Container,
  Box,
  Card,
  Stack,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  Avatar,
} from "@mui/material";

// components
import Page from "../../components/Page";
import Scrollbar from "../../components/Scrollbar";
//
import { useDispatch, useSelector } from "react-redux";
import ListSearchbar from "../../components/common/ListSearchbar";
import { getReportedIssueThunk } from "../../store/slices/project/project.slice";
import ListHead from "../../components/common/ListHead";
import SearchNotFound from "../NotFound/SearchNotFound";
import { capitalCase } from "../../utils/change-case";
import { DateTime } from "luxon";
import useSearch from "../../hooks/useSearch";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "title", label: "Title", alignRight: false },
  // { id: "description", label: "Description", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "createdOn", label: "Created On", alignRight: false },
];

// ----------------------------------------------------------------------

export default function Ticket() {
  const params = useParams();
  const dispatch = useDispatch();

  const tickets = useSelector(
    ({ project }) => project.selectedProject.tickets_vendor
  );
  const [search, setSearch] = useState("");
  const { handleTextChange } = useSearch(tickets, "title");

  useEffect(() => {
    dispatch(getReportedIssueThunk(params.id));
  }, [dispatch, params.id]);

  function getInitials(project_name) {
    return capitalCase(project_name.substring(0, 2));
  }

  const serachedTickets = useMemo(() => {
    if (!search) return tickets;
    return handleTextChange(search);
  }, [search, tickets]);

  return (
    <div>
      <Container>
        {/* <Typography variant="h4">Support Ticket</Typography> */}
        <Box mt={5}>
          <Card>
            <Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignContent="center"
                alignItems="center"
                p={1}
              >
                <Paper>
                  {serachedTickets?.length === 0 ? (
                    ""
                  ) : (
                    <ListSearchbar
                      placeholder="Search tickets"
                      handleSearchChange={(e) => setSearch(e.target.value)}
                    />
                  )}
                </Paper>
              </Stack>
            </Box>

            <Page title="Support | EMB">
              <Container>
                {serachedTickets?.length === 0 ? (
                  <div style={{ padding: "5rem" }}>
                    <SearchNotFound
                       src="/static/no-found/no_tickets.png"
                subtitle="No tickets raise yet."
                title=" "
                    />
                  </div>
                ) : (
                  <Card sx={{ boxShadow: "none" }}>
                    <Scrollbar>
                      <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                          <ListHead
                            headLabel={TABLE_HEAD}
                            rowCount={serachedTickets?.length}
                            styles={{ backgroundColor: "rgb(243, 246, 248)" }}
                          />
                          <TableBody>
                            {serachedTickets?.map((row) => {
                              const { id, title, state, created_at } = row;
                              return (
                                <TableRow
                                  key={id}
                                  tabIndex={-1}
                                  role="checkbox"
                                  sx={{ cursor: "pointer" }}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="none"
                                  >
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={1}
                                      >
                                        <Avatar
                                          sx={{
                                            // bgcolor: "#eff8f1",
                                            // color: "#00aa55",
                                            fontSize: ".8rem",
                                            fontWeight: "600",
                                            backgroundColor: "#f3f6f8",
                                            color: "#637281",
                                          }}
                                        >
                                          {getInitials(title)}
                                        </Avatar>
                                        <Typography
                                          variant="body2"
                                          noWrap
                                          sx={{ fontWeight: "700" }}
                                        >
                                          <Link
                                            to={`${id}`}
                                            style={{
                                              textDecoration: "none",
                                              color: "black",
                                            }}
                                          >
                                            {capitalCase(title)}
                                          </Link>
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  </TableCell>
                                  <TableCell
                                    // onClick={() =>
                                    //   handleOpen(capitalCase(title), id, state)
                                    // }
                                    align="left"
                                  >
                                    {state}
                                  </TableCell>

                                  <TableCell align="left">
                                    {DateTime.fromISO(created_at).toFormat(
                                      "DD"
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Scrollbar>
                  </Card>
                )}
              </Container>
            </Page>
          </Card>
        </Box>
      </Container>
    </div>
  );
}
