import React from "react";
import { Box, Button, Grid, Stack } from "@mui/material";

import DynamicTable from "./DynamicTable";

import { useDispatch } from "react-redux";
import {
  createChatterThunk,
  updateCharterThunk,
} from "../../store/slices/chatter/chatter.slice";
import { decodeSlugToValue } from "../../utils/global/global";
import { listViewType } from "../../utils/enum";
import {
  updateProjectCharterThunk,
  updateProjectTimelineThunk,
} from "../../store/slices/project/project.slice";
import _ from "lodash";
import AddIcon from "@mui/icons-material/Add";
import useCharter from "../../hooks/useCharter";

function ProjectChatter({
  title,
  data = [],
  saveBtnText = "Save Scope",
  type,
  updateData,
  onBackPress,
  onSavePress,
  realtime = false,
  templatePermissions = {},
  timelineId,
}) {
  const { addSection = true, ...rest } = templatePermissions;

  const {
    state,
    addRow,
    addColumn,
    deleteRow,
    deleteColumn,
    updateRowData,
    updateHeaderData,
    addNewSection,
    removeSection,
    handleSectionNameChange,
    addChoice,
    removeChoice,
    onSaveSuccess,
    handleSectionHeaderValueChange,
  } = useCharter({
    initState: data,
  });

  const dispatch = useDispatch();

  function handleChatterSave() {
    if (type === listViewType.mainCharter) {
      if (updateData?._id) {
        const newState = { ...updateData };
        newState.chatterData = state;
        return dispatch(
          updateCharterThunk({
            id: updateData._id,
            charterData: newState,
          })
        )
          .unwrap()
          .then(() => {
            if (_.isFunction(onSaveSuccess)) onSaveSuccess();
          });
      }

      return dispatch(
        createChatterThunk({
          data: {
            title: decodeSlugToValue(title),
            slug: title,
            chatterData: state,
            type: listViewType.charter,
          },
        })
      )
        .unwrap()
        .then(() => {
          if (_.isFunction(onSaveSuccess)) onSaveSuccess();
        });
    }

    if (type === listViewType.sop) {
      if (updateData?._id) {
        const newState = { ...updateData };
        newState.chatterData = state;
        return dispatch(
          updateCharterThunk({
            id: updateData._id,
            charterData: newState,
          })
        )
          .unwrap()
          .then(() => {
            if (_.isFunction(onSaveSuccess)) onSaveSuccess();
          });
      }

      return dispatch(
        createChatterThunk({
          data: {
            title: decodeSlugToValue(title),
            slug: title,
            chatterData: state,
            type: listViewType.sop,
          },
        })
      )
        .unwrap()
        .then(() => {
          if (_.isFunction(onSaveSuccess)) onSaveSuccess();
        });
    }
    if (type === listViewType.timeline) {
      const newState = [...state];

      newState.forEach((currentSection) => {
        const [headers, ...rows] = currentSection.sectionMatrix;
        headers[0] = _.omit(headers[0], "addRowExtra");
        rows.forEach((row) => {
          row[0] = _.omit(row[0], "addRowExtra");
        });
      });

      if (updateData?._id) {
        const newUpdatedData = _.cloneDeep(updateData);
        newUpdatedData.template.chatterData = newState;
        dispatch(
          updateProjectTimelineThunk({
            project_timeline_id: updateData._id,
            data: newUpdatedData,
          })
        );
        return;
      }
    }

    if (updateData?._id) {
      const newUpdatedData = _.cloneDeep(updateData);
      newUpdatedData.template.chatterData = state;
      return dispatch(
        updateProjectCharterThunk({
          project_charter_id: updateData._id,
          data: newUpdatedData,
        })
      );
    }
  }

  function updateTimeline(state) {
    const newState = [...state];

    newState.forEach((currentSection) => {
      const [headers, ...rows] = currentSection.sectionMatrix;
      headers[0] = _.omit(headers[0], "addRowExtra");
      rows.forEach((row) => {
        row[0] = _.omit(row[0], "addRowExtra");
      });
    });

    let data = {
      template: {},
    };
    data.template.chatterData = newState;
    data.template.templatePermissions = rest;
    data.template.type = listViewType.timeline;
    // TODO: This is done for bypassing and in future this needs to be removed
    data.permissions = {};
    dispatch(
      updateProjectTimelineThunk({
        project_timeline_id: timelineId,
        data: data,
      })
    );
  }

  return (
    <div style={{ padding: "10px 20px" }}>
      {addSection && (
        <Box sx={{ marginBottom: 4 }}>
          <Grid container>
            <Grid item md={2}>
              <Button
                variant="outlined"
                sx={{ borderColor: "#222f3e", color: "#576574" }}
                onClick={() => addNewSection(realtime, updateTimeline)}
              >
                <AddIcon /> New Section
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      <div>
        {state?.map((data) => (
          <div>
            <DynamicTable
              {...data}
              sectionIdx={data.id}
              updateHeaderData={updateHeaderData}
              addColumn={addColumn}
              addRow={addRow}
              type={type}
              updateRowData={updateRowData}
              removeSection={removeSection}
              deleteRow={deleteRow}
              deleteColumn={deleteColumn}
              handleSectionChange={handleSectionNameChange}
              addChoice={addChoice}
              removeChoice={removeChoice}
              realtime={realtime}
              sectionPermissions={{}}
              updateTimeline={updateTimeline}
              handleSectionHeaderValueChange={handleSectionHeaderValueChange}
            />
          </div>
        ))}
      </div>
      {state.length > 0 && (
        <Stack style={{ marginTop: "40px" }} direction="row" spacing={3}>
          {_.isFunction(onBackPress) && (
            <Button onClick={onBackPress} variant="contained">
              Back
            </Button>
          )}
          {!realtime && (
            <Button
              variant="contained"
              onClick={
                !onSavePress ? handleChatterSave : () => onSavePress(state)
              }
            >
              {saveBtnText}
            </Button>
          )}
        </Stack>
      )}
    </div>
  );
}

export default ProjectChatter;
