import {
  assignCharterToProjectApi,
  commentsCharterApi,
  createProjectTimelineApi,
  fetchAllProjectsApi,
  fetchProjectCharterByProjectIdApi,
  filesCharterApi,
  getProjectApi,
  getVendorsCharterListApi,
  updatePorjectCharterApi,
  uploadProjectAttachmentApi,
  reportedIssueApi,
  fetchAllClientSupportApi,
  fetchReviewFeedbackApi,
  updateProjectExpirationDateApi,
  updateProjectStatusApi,
  fetchProjectTimelineByProjectIdApi,
  createSingleCommentApi,
  getAllMilestonesApi,
  getPIInfoApi,
  pushPIToZoho,
  createMilestoneApi,
  createNewScopeForProjectApi,
  updateProjectApi,
  updateProjectAmountApi,
  ticketstatusApi,
  deleteMilestoneApi,
  projectStatusChangeApi,
  deliveryInternalCategory,
  deliveryProjectState,
  projectChangeStatus,
  projectStatusHistoryApi,
  milestoneDelayeReasonApi,
  createProformaInvoiceApi,
  fetchAllProformaInvoiceApi,
  purchaseOrderUpdateApi,
  purchaseOrderGetUpdateApi,
  refundUserData,
  approvalCreditNotes,
  approvalReasonApi,
  projectCollectionApi,
  pushPOToZoho,
  getPOInfoApi,
  editPOApi,
  rejectpoorder,
  sendClientEmailMilestoneApi,
  PoSummationBilled,
  PoSummationVoid,
  ValidatePiPerProject,
} from "../../../utils/apis.utils";
import { showFailureToast, showSuccessToast } from "../toast/toast.slice";
import { listViewType } from "../../../utils/enum";
import { createQueryString } from "../../../utils/global/global";
import {
  startDashboardLoader,
  stopDashboardLoader,
} from "../dashboard/dashboard.slice";

import {
  fetchExpectationDataByIdApiThunk,
  fetchAggregateCommercialsByIdThunk,
} from "../milestoneMetrics/milestoneMetrics.slice";
import {
  fetchAllProformaInvoicePeginationThunk,
  fetchPurchaseOrderThunk,
} from "./project.slice";

import {
  fetchAllProformaInvoiceThunk,
  fetchProjectTimelineThunk,
} from "./project.slice";
import _ from "lodash";
import { de } from "faker/lib/locales";
export const fetchAllProjectsHandler = async (data, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  let query = "";
  if (data.limit) {
    query = createQueryString({
      query_term: data.term,
      type: data.type,
      page: data.page,
      spoc: data.spoc,
      limit: data.limit,
    });
  } else {
    query = createQueryString({
      query_term: data.term,
      type: data.type,
      page: data.page,
      spoc: data.spoc,
    });
  }
  const response = await user.get(fetchAllProjectsApi + query);
  return {
    data: response.data,
    total: response.total,
    limit: response.limit,
    from: data.from,
    data_state: data,
  };
};

export const getProjectHandler = async (id, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(getProjectApi + "/" + id);
  return response.data;
};

export const fetchProjectCharterByProjectIdHandler = async (id, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const queryString = createQueryString({
    type: listViewType.charter,
    project_id: id,
  });
  const response = await user.get(
    fetchProjectCharterByProjectIdApi + queryString
  );
  return response.data;
};

export const fetchAllCommentsForTimelineHandler = async (data, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const queryString = createQueryString(data);
  const response = await user.get(commentsCharterApi + queryString);
  return response.data;
};

export const fetchAllFilesForTimelineHandler = async (data, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const queryString = createQueryString(data);
  const response = await user.get(filesCharterApi + queryString);
  return response.data;
};

export const addCommentHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const queryString = createQueryString(data);
    const response = await user.post(commentsCharterApi + queryString);
    thunkApi.dispatch(fetchProjectTimelineThunk(data.project_id));
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const addFilesHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(filesCharterApi, data);
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const addPurchaseOrderHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(purchaseOrderUpdateApi, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const fetchPurchaseOrderHandler = async (id, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.get(purchaseOrderGetUpdateApi + "/" + id);
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const fetchProjectTimelineHandler = async (id, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(
    fetchProjectTimelineByProjectIdApi + "/" + id
  );

  return response.data;
};

export const createProjectTimelineHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(createProjectTimelineApi, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const updateProjectTimelineHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(createProjectTimelineApi, data);
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const updateProjectStatusHandlerNew = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(ticketstatusApi, data);
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const updateVendorStatusHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(ticketstatusApi, data);
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const updateProjectCharterHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(updatePorjectCharterApi, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const assignCharterToProjectHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(assignCharterToProjectApi, {
      ...data,
      type: listViewType.charter,
    });
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const getVendorsCharterListHandler = async (projectId, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const query = createQueryString({
    type: listViewType.charter,
    project_id: projectId,
  });
  const response = await user.get(getVendorsCharterListApi + query);
  return response.data;
};

export const uploadProjectAttachmentHandler = async (data, thunkApi) => {
  try {
    const { id, file } = data;
    const { user } = thunkApi.extra.apiService;
    file.append("attachment_type", "kam_attachment");
    const response = await user.post(
      uploadProjectAttachmentApi + "/" + id,
      file
    );
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (error) {
    thunkApi.dispatch(showFailureToast({ message: error }));
  }
};
export const getReportedIssue = async (id, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const query = createQueryString({
      ticket_source: "vendor",
      project_id: id,
    });
    const response = await user.get(fetchAllClientSupportApi + query);
    const responseData = response.data;
    return responseData;
  } catch (err) {
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const getClientReportedIssue = async (id, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const query = createQueryString({
      ticket_source: "client",
      project_id: id,
    });
    const response = await user.get(fetchAllClientSupportApi + query);
    const responseData = response.data;
    return responseData;
  } catch (err) {
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const fetchAllSupportDataByTicketIdHanldler = async (
  { projectId, ticketId },
  thunkApi
) => {
  const { user } = thunkApi.extra.apiService;
  const query = createQueryString({
    project_id: projectId,
  });
  const response = await user.get(`${reportedIssueApi}/${ticketId}${query}`);
  return response.data;
};

export const createSupportTicketCommentHandler = async (data, thunkApi) => {
  try {
    // thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(createSingleCommentApi, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({ message: err, vertical: "top", horizontal: "right" })
    );
    return Promise.reject(err);
  } finally {
    // thunkApi.dispatch(stopDashboardLoader());
  }
};

export const fetchReviewFeedbackHandler = async (id, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const query = createQueryString({
    project_id: id,
  });
  const response = await user.get(fetchReviewFeedbackApi + query);
  return response.data;
};

export const updateProjectExpirationDateHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(
      updateProjectExpirationDateApi + "/" + data.id,
      {
        expected_closing_date: data.date,
      }
    );
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({ message: err, vertical: "top", horizontal: "right" })
    );
    return Promise.reject(err);
  }
};

export const updateProjectStatusHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(updateProjectStatusApi + "/" + data.id, {
      status: data.status,
    });
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return data.status;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({ message: err, vertical: "top", horizontal: "right" })
    );
    return Promise.reject(err);
  }
};

export const fetchAllCollectionsByProjectHandler = async (id, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const query = createQueryString({
    project_id: id,
    limit: 50,
  });
  const response = await user.get(getAllMilestonesApi + query);
  return response.data;
};

export const fetchPIInfoHandler = async ({ id, project_id }, thunkApi) => {
  const { user } = thunkApi.extra.apiService;

  try {
    const response = await user.post(getPIInfoApi, {
      project_id: project_id,
      performa_invoice_id: id,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const pushPIToZohoHandler = async (data, thunkApi) => {
  const { user } = thunkApi.extra.apiService;

  try {
    const response = await user.post(pushPIToZoho, {
      project_id: data.project_id,
      performa_invoice_id: data.performa_invoice_id,
    });

    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );

    if (response?.status) {
      thunkApi.dispatch(fetchAllProformaInvoiceThunk(data.project_id));
    }

    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    console.log(err);
  }
};
export const fetchPOInfoHandler = async ({ po_id, project_id }, thunkApi) => {
  const { user } = thunkApi.extra.apiService;

  try {
    const response = await user.get(getPOInfoApi + project_id + "/" + po_id);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchPOSummationHandler = async (id, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  try {
    const response = await user.get(PoSummationBilled + "/" + id);
    return response.data;
  } catch (error) {}
};

export const markPosummationToVoidHandler = async (data, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  try {
    const modData = {
      identifier_type: "id",
      value: data.performa_invoice_id,
    };
    const response = await user.put(PoSummationVoid, modData);
    thunkApi.dispatch(fetchAllProformaInvoiceThunk(data.id));
    return response.data;
  } catch (error) {}
};

export const pushPOToZohoHandler = async (data, thunkApi) => {
  const { user } = thunkApi.extra.apiService;

  try {
    const response = await user.post(pushPOToZoho, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const editPOHandler = async (data, thunkApi) => {
  const { user } = thunkApi.extra.apiService;

  try {
    const response = await user.put(editPOApi + data.zbooks_po_id, data.data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const RequestForApprovalPOHandler = async (
  { id, data, paramID },
  thunkApi
) => {
  const { user } = thunkApi.extra.apiService;

  try {
    const response = await user.put(rejectpoorder + "/" + id, { ...data });

    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchPurchaseOrderThunk(paramID));

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const createCollectionForProjectHandler = async (
  { id, ...rest },
  thunkApi
) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const data = rest;
    const response = await user.post(createMilestoneApi, {
      data,
      project_id: id,
    });
    thunkApi.dispatch(fetchExpectationDataByIdApiThunk(id));
    thunkApi.dispatch(fetchAggregateCommercialsByIdThunk(id));
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({ message: err, vertical: "top", horizontal: "right" })
    );
    return Promise.reject(err);
  }
};
export const updateCollectionForProjectHandler = async (
  { project_id, data },
  thunkApi
) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(createMilestoneApi + "/" + data.id, {
      data,
      project_id,
    });
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchExpectationDataByIdApiThunk(project_id));
    thunkApi.dispatch(fetchAggregateCommercialsByIdThunk(project_id));

    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({ message: err, vertical: "top", horizontal: "right" })
    );
    return Promise.reject(err);
  }
};

export const createNewScopeForProjectHandler = async (
  { data, project_id },
  thunkApi
) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(createNewScopeForProjectApi, {
      data: {
        chatterData: data,
      },
      project_id: project_id,
    });
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const changeDescriptionForAProjectHandler = async (
  { id, description },
  thunkApi
) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(updateProjectApi + "/" + id, {
      data: {
        description,
      },
    });
    thunkApi.dispatch(
      showSuccessToast({
        message: "Description Change Successfully",
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const updateProjectExpectedAmountHandler = async (
  { data, project_id },
  thunkApi
) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(updateProjectAmountApi + "/" + project_id, {
      ...data,
    });
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const deleteMilestoneHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.delete(deleteMilestoneApi + "/" + data.mid);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return data.mid;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({ message: err, vertical: "top", horizontal: "right" })
    );
    return Promise.reject(err);
  }
};
export const sendClientEmailMilestoneHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(
      sendClientEmailMilestoneApi + "/" + data.mid,
      { status: "requested_for_approval" }
    );
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return data.mid;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({
        message: "something wrong",
        vertical: "top",
        horizontal: "right",
      })
    );
    return Promise.reject(err);
  }
};

export const fetchAllProjectStatusHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.get(projectStatusChangeApi);
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({ message: err, vertical: "top", horizontal: "right" })
    );
    return Promise.reject(err);
  }
};

export const updateInternalCategoryForProjectHandler = async (
  data,
  thunkApi
) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(deliveryInternalCategory + "/" + data.id, {
      delivery_internal_category: data.category,
    });
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const updateDeliveryProjectStateForProjectHandler = async (
  data,
  thunkApi
) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(deliveryProjectState + "/" + data.id, {
      delivery_project_state: data.health,
    });
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const createProjectChangeStatusForProjectHandler = async (
  data,
  thunkApi
) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(projectChangeStatus + "/" + data.id, {
      status: data.status,
      reason: data.status_change_reason,
      // refunded_amount: data.refunded_amount === "" ? 0 : data.refunded_amount,
      // vendor_refunded_amount:
      //   data.vendor_refunded_amount === "" ? 0 : data.vendor_refunded_amount,
    });
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const refundByUserHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(refundUserData, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const fetchAllProjectStatusHistoryHandler = async (id, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.get(projectStatusHistoryApi + "/" + id);
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({ message: err, vertical: "top", horizontal: "right" })
    );
    return Promise.reject(err);
    // const { user } = thunkApi.extra.apiService;
  }
};

export const fetchAllProformaInvoiceHandler = async (id, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.get(fetchAllProformaInvoiceApi + "/" + id);
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({ message: err, vertical: "top", horizontal: "right" })
    );
    return Promise.reject(err);
  }
};

// export const getAllApprovalCreditNotes = async (id, thunkApi) => {
//   try {
//     const { user } = thunkApi.extra.apiService;
//     const response = await user.get(approvalCreditNotes + "/" + id);
//     return response.data;
//   } catch (err) {
//     thunkApi.dispatch(
//       showFailureToast({ message: err, vertical: "top", horizontal: "right" })
//     );
//     return Promise.reject(err);
//   }
// };
export const fetchAllProformaInvoicePeginationHandler = async (
  data,
  thunkApi
) => {
  const { user } = thunkApi.extra.apiService;
  const projectId = data.projectId;
  const query = createQueryString({
    page: data.page,
    limit: data.rowsPerPage,
    search_query: data.term !== undefined ? data.term : "",
  });

  const response = await user.get(
    approvalCreditNotes + "/" + projectId + query
  );

  return {
    data: response.data.all_refunds,
    total: response.data.total,
    show_approve_button: response.data.show_approve_button,
  };
};

//for collection
export const fetchAllCollectionHandler = async (data, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const projectId = data.projectId;
  const response = await user.get(projectCollectionApi + "/" + projectId);

  return {
    data: response.data.all_actual_collection_per_project,
    deal_amount: response.data.deal_amount,
  };
};

//collection end here

export const approvalReasonHandler = async (
  { projectId, newData, page, rowsPerPage },
  thunkApi
) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(approvalReasonApi + projectId, newData);
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );

    thunkApi.dispatch(
      fetchAllProformaInvoicePeginationThunk({
        page,
        projectId,
        rowsPerPage: rowsPerPage,
      })
    );

    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const createProformaInvoiceHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(createProformaInvoiceApi, {
      project_id: data.project_id,
      pi_amount: data.pi_amount,
      milestone_id: data.milestone_id,
      pi_remark: data.pi_remark,
    });
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

// valiudation pi api call
export const validatePiAmountHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.get(
      ValidatePiPerProject + "/" + data.project_id
    );

    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};
