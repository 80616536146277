import {
  allQuestion,
  deleteQuestion,
  fetchQuestionDetailsById,
} from "../../../utils/apis.utils";
import {
  startDashboardLoader,
  stopDashboardLoader,
} from "../dashboard/dashboard.slice";
import { showFailureToast, showSuccessToast } from "../toast/toast.slice";

export const allQuestionHandler = async (__, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.get(allQuestion);
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const deleteQuestionHandler = async (id, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(deleteQuestion + "/" + id);
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return id;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({ message: err, vertical: "top", horizontal: "right" })
    );
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const addQuestionHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(allQuestion, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({ message: err, vertical: "top", horizontal: "right" })
    );
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const editQuestionHandler = async (
  { id, data: { _id, ...rest } },
  thunkApi
) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(fetchQuestionDetailsById + "/" + id, {
      data: rest,
    });
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({ message: err, vertical: "top", horizontal: "right" })
    );
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const getQuestionDetailsByIdHandler = async (id, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(fetchQuestionDetailsById + "/" + id);
  return response.data;
};
