import React, { useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { useStyles } from "./DynamicTable.theme";
import {
  Button,
  Divider,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Stack,
} from "@mui/material";
import Element from "../../components/Form/Element";
import { chatterType, inputType, listViewType } from "../../utils/enum";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useModal from "../../hooks/useModal";
import Modal from "../../components/Modal/Modal";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";

function HeaderItem({ value, change, styles, placeholder, type }) {
  const classes = useStyles();
  return type === "header" ? (
    <div style={{ position: "relative" }}>
      <input
        className={classes.inputElement}
        value={value}
        onChange={(e) => change(e.target.value)}
        style={styles}
        placeholder={placeholder}
      />

      {type === "header" && (
        <BookmarkOutlinedIcon
          sx={{
            position: "absolute",
            top: "-5px",
            left: "-5px",
            color: "#00aa55",
          }}
        />
      )}
    </div>
  ) : (
    <input
      className={classes.inputElement}
      value={value}
      onChange={(e) => change(e.target.value)}
      style={styles}
      placeholder={placeholder}
    />
  );
}

function Item({ value, change, styles, addRowExtra = {}, ...rest }) {
  const { icon, onClick } = addRowExtra;
  const classes = useStyles();
  return (
    <div style={{ position: "relative" }}>
      <input
        className={classes.inputElement}
        value={value}
        onChange={(e) => change(e.target.value)}
        style={styles}
      ></input>
      {icon && (
        <div
          style={{
            position: "absolute",
            right: "3px",
            top: "10px",
            cursor: "pointer",
          }}
        >
          <span
            onClick={(e) => {
              e.stopPropagation();
              onClick(rest);
            }}
          >
            {icon}
          </span>
        </div>
      )}
    </div>
  );
}

function NumberInput({ value, change, styles, type }) {
  const classes = useStyles();

  function handleKeyDown(event) {
    if (
      !(
        (event.key >= 0 && event.key <= 9) ||
        event.key === "Backspace" ||
        event.key === "Tab"
      )
    )
      event.preventDefault();
  }

  return (
    <input
      className={classes.inputElement}
      value={value}
      onChange={(e) => change(e.target.value)}
      style={styles}
      onKeyDown={handleKeyDown}
    />
  );
}

function DatePicker({ value, change, styles }) {
  const classes = useStyles();

  function handleChange(date) {
    change(date);
  }

  const val = !value ? null : new Date(value);

  return (
    <ReactDatePicker
      dateFormat="MM/dd/yyyy"
      selected={val}
      onChange={handleChange}
      customInput={<input className={classes.inputElement} style={styles} />}
    />
  );
}

function Dropdown({ value, change, styles, choices = [] }) {
  const classes = useStyles();

  return (
    <select
      className={classes.inputElement}
      value={value}
      onChange={(e) => change(e.target.value)}
      style={styles}
    >
      <option disabled selected>
        --Select--
      </option>
      {choices.map((choice) => {
        return <option value={choice}>{choice}</option>;
      })}
    </select>
  );
}

function RowMenuList({
  setRowAnchorEl,
  isOpen,
  anchorEl,
  rowIdx,
  deleteRow,
  sectionIdx,
  realtime,
  updateTimeline,
}) {
  return (
    <Menu
      open={isOpen}
      onClose={() => setRowAnchorEl(null)}
      anchorEl={anchorEl}
      PaperProps={{
        sx: { width: 120, maxWidth: "100%" },
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <MenuItem sx={{ color: "text.secondary" }}>
        <ListItemText
          onClick={() => {
            setRowAnchorEl(null);
            deleteRow(sectionIdx, rowIdx, realtime, updateTimeline);
          }}
          primary="Delete"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
    </Menu>
  );
}

function ChoiceModal({
  choice,
  setChoice,
  headerMeta,
  removeChoice,
  sectionIdx,
  colIdx,
  handleChoiceSave,
}) {
  const classes = useStyles();
  return (
    <>
      <Divider sx={{ marginTop: 3 }} />
      {headerMeta.choices?.length > 0 ? (
        headerMeta.choices.map((choice) => {
          return (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ padding: "10px 10px" }}
              >
                <span style={{ color: "#aaa" }}>{choice}</span>
                <Button
                  onClick={() => removeChoice(sectionIdx, colIdx, choice)}
                >
                  Delete
                </Button>
              </Stack>
            </>
          );
        })
      ) : (
        <div style={{ margin: "20px 0px" }}>No Choice Aded Yet</div>
      )}
      <label className={classes.formLabel}>Choice</label>
      <input
        className={classes.inputElement}
        value={choice}
        onChange={(e) => setChoice(e.target.value)}
        placeholder="Choice Name"
      />
      <div style={{ textAlign: "right" }}>
        <Button
          variant="contained"
          sx={{ marginTop: "10px" }}
          onClick={() => handleChoiceSave(sectionIdx, colIdx, choice)}
        >
          Save
        </Button>
      </div>
    </>
  );
}

function ColumnMenuList({
  setColAnchorEl,
  isOpen,
  anchorEl,
  sectionIdx,
  colIdx,
  deleteColumn,
  headerMeta,
  addChoice,
  removeChoice,
  permissionsMatrix,
  realtime,
  updateTimeline,
}) {
  const [choice, setChoice] = useState(null);
  const { isModalOpen, showModal, hideModal } = useModal();

  function handleChoiceSave() {
    setChoice("");
    addChoice(sectionIdx, colIdx, choice, realtime, updateTimeline);
  }

  function handleChoiceRemove(...args) {
    removeChoice(...args, realtime, updateTimeline);
  }

  function handleModalClose() {
    setColAnchorEl(null);
    hideModal();
  }

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        closeModal={handleModalClose}
        title="Add Choice"
        content={
          <ChoiceModal
            choice={choice}
            setChoice={setChoice}
            removeChoice={handleChoiceRemove}
            sectionIdx={sectionIdx}
            colIdx={colIdx}
            headerMeta={headerMeta}
            handleChoiceSave={handleChoiceSave}
          />
        }
      />
      <Menu
        open={isOpen}
        onClose={() => {
          setColAnchorEl(null);
        }}
        anchorEl={anchorEl}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {headerMeta.type === chatterType.dropdown && (
          <MenuItem sx={{ color: "text.secondary" }}>
            <ListItemText
              primary="Add Choice"
              primaryTypographyProps={{ variant: "body2" }}
              onClick={showModal}
            />
          </MenuItem>
        )}
        <MenuItem sx={{ color: "text.secondary" }}>
          <ListItemText
            primary="Delete"
            primaryTypographyProps={{ variant: "body2" }}
            onClick={() => {
              setColAnchorEl(null);
              deleteColumn(sectionIdx, colIdx, realtime, updateTimeline);
            }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}

function DynamicTable({
  sectionMatrix,
  sectionIdx,
  updateHeaderData,
  addColumn,
  addRow,
  sectionName,
  handleSectionChange,
  removeSection,
  updateRowData,
  deleteRow,
  deleteColumn,
  addChoice,
  removeChoice,
  headerIds,
  realtime,
  sectionPermissions = { template: {}, permissionsMatrix: [[]] },
  updateTimeline,
  type,
  sectionHeaderValues = [],
  handleSectionHeaderValueChange,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hoveredRow, setHoveredRow] = React.useState(null);
  const [hoveredColumn, setHoveredColumn] = React.useState(null);
  const [colAnchorEl, setColAnchorEl] = React.useState(null);
  const [rowAnchorEl, setRowAnchorEl] = React.useState(null);

  const {
    canAddColumn = true,
    canAddRow = true,
    canDeleteSection = true,
  } = sectionPermissions.template ? sectionPermissions.template : {};

  const permissionsMatrix = sectionPermissions.permissionsMatrix;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const [headers = [], ...series] = sectionMatrix;

  function renderInitialTable() {
    return (
      <table style={{ position: "relative" }}>
        <thead>
          {type === listViewType.timeline &&
            headers.map((header, idx) => {
              if (header.type === chatterType.text)
                return (
                  <th>
                    <div style={{ marginBottom: "30px" }}>
                      <HeaderItem
                        type="header"
                        styles={{
                          backgroundColor: "#f3f6f8",
                          color: "#637381",
                          fontWeight: 600,
                        }}
                        value={sectionHeaderValues[idx]?.value}
                        placeholder="Section Name"
                        change={(value) => {
                          handleSectionHeaderValueChange(
                            sectionIdx,
                            idx,
                            value,
                            realtime,
                            updateTimeline
                          );
                        }}
                      />
                    </div>
                  </th>
                );
              if (header.type === chatterType.dropdown)
                return (
                  <th>
                    <div style={{ marginBottom: "30px" }}>
                      <Dropdown
                        type="header"
                        styles={{
                          backgroundColor: "#f3f6f8",
                          color: "#637381",
                          fontWeight: 600,
                        }}
                        choices={header.choices}
                        value={sectionHeaderValues[idx]?.value}
                        change={(value) => {
                          handleSectionHeaderValueChange(
                            sectionIdx,
                            idx,
                            value,
                            realtime,
                            updateTimeline
                          );
                        }}
                      />
                    </div>
                  </th>
                );
              if (header.type === chatterType.number)
                return (
                  <th>
                    <div style={{ marginBottom: "30px" }}>
                      <NumberInput
                        type="header"
                        styles={{
                          backgroundColor: "#f3f6f8",
                          color: "#637381",
                          fontWeight: 600,
                        }}
                        value={sectionHeaderValues[idx]?.value}
                        change={(value) => {
                          handleSectionHeaderValueChange(
                            sectionIdx,
                            idx,
                            value,
                            realtime,
                            updateTimeline
                          );
                        }}
                      />
                    </div>
                  </th>
                );
              return (
                <th>
                  <div style={{ marginBottom: "30px" }}>
                    <DatePicker
                      type="header"
                      styles={{
                        backgroundColor: "#f3f6f8",
                        color: "#637381",
                        fontWeight: 600,
                      }}
                      value={sectionHeaderValues[idx]?.value}
                      change={(value) => {
                        handleSectionHeaderValueChange(
                          sectionIdx,
                          idx,
                          value,
                          realtime,
                          updateTimeline
                        );
                      }}
                    />
                  </div>
                </th>
              );
            })}
        </thead>
        <thead>
          {headers.map((header, idx) => (
            <th
              style={{ position: "relative" }}
              onMouseEnter={() => setHoveredColumn(idx)}
              onMouseLeave={() => setColAnchorEl(null)}
            >
              {hoveredColumn === idx && (
                <div
                  style={{
                    position: "absolute",
                    right: "0",
                    top: "10px",
                    cursor: "pointer",
                  }}
                >
                  <ExpandMoreIcon
                    sx={{ color: "green" }}
                    onClick={(e) => setColAnchorEl(e.currentTarget)}
                  />
                  <ColumnMenuList
                    isOpen={Boolean(colAnchorEl)}
                    setColAnchorEl={setColAnchorEl}
                    anchorEl={colAnchorEl}
                    sectionIdx={sectionIdx}
                    colIdx={idx}
                    deleteColumn={deleteColumn}
                    headerMeta={header}
                    addChoice={addChoice}
                    removeChoice={removeChoice}
                    permissionsMatrix={permissionsMatrix}
                    realtime={realtime}
                    updateTimeline={updateTimeline}
                  />
                </div>
              )}
              <HeaderItem
                value={header.value}
                change={(value) =>
                  updateHeaderData(
                    sectionIdx,
                    idx,
                    value,
                    realtime,
                    updateTimeline
                  )
                }
                styles={{
                  fontWeight: "bold",
                  borderLeft: "5px solid rgb(69 170 242/60%)",
                }}
                headerMeta={header}
              />
            </th>
          ))}
          <th>
            {canAddColumn && (
              <AddCircleIcon
                ref={anchorEl}
                sx={{
                  cursor: "pointer",
                  fontSize: "25px",
                  position: "absolute",
                  top: "-10px",
                  right: "-30px",
                }}
                onClick={handleClick}
              />
            )}
            <Popover
              open={open}
              onClose={() => setAnchorEl(null)}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
            >
              <Stack
                sx={{ padding: "20px", width: "300px" }}
                spacing={2}
                direction="row"
                flexWrap="wrap"
              >
                <Button
                  onClick={() => {
                    setAnchorEl(null);
                    addColumn(
                      sectionIdx,
                      chatterType.number,
                      realtime,
                      updateTimeline
                    );
                  }}
                >
                  Number
                </Button>
                <Button
                  onClick={() => {
                    setAnchorEl(null);
                    addColumn(
                      sectionIdx,
                      chatterType.text,
                      realtime,
                      updateTimeline
                    );
                  }}
                >
                  Text
                </Button>
                <Button
                  onClick={() => {
                    setAnchorEl(null);
                    addColumn(
                      sectionIdx,
                      chatterType.date,
                      realtime,
                      updateTimeline
                    );
                  }}
                >
                  Date
                </Button>
                <Button
                  sx={{ marginLeft: "0px !important" }}
                  onClick={() => {
                    setAnchorEl(null);
                    addColumn(
                      sectionIdx,
                      chatterType.dropdown,
                      realtime,
                      updateTimeline
                    );
                  }}
                >
                  Dropdown
                </Button>
              </Stack>
            </Popover>
          </th>
        </thead>
        <tbody>
          {series.map((column, rowIdx) => (
            <tr
              style={{
                position: "relative",
              }}
              onMouseEnter={() => setHoveredRow(rowIdx)}
              onMouseLeave={() => setHoveredRow(null)}
            >
              {column.map((data, colIdx) => (
                <td>
                  {hoveredRow === rowIdx && (
                    <div
                      style={{
                        position: "absolute",
                        left: "-25px",
                        top: "8px",
                        cursor: "pointer",
                      }}
                    >
                      <ExpandMoreIcon
                        onClick={(e) => setRowAnchorEl(e.currentTarget)}
                        sx={{ color: "green" }}
                      />
                      <RowMenuList
                        isOpen={Boolean(rowAnchorEl)}
                        setRowAnchorEl={setRowAnchorEl}
                        anchorEl={rowAnchorEl}
                        deleteRow={deleteRow}
                        rowIdx={hoveredRow}
                        sectionIdx={sectionIdx}
                        realtime={realtime}
                        updateTimeline={updateTimeline}
                      />
                    </div>
                  )}
                  {headers[colIdx].type === chatterType.text && (
                    <Item
                      {...data}
                      value={data.value}
                      change={(value) =>
                        updateRowData(
                          sectionIdx,
                          rowIdx + 1,
                          colIdx,
                          value,
                          chatterType.text,
                          realtime,
                          updateTimeline
                        )
                      }
                      sectionIdx={sectionIdx}
                      rowIdx={headerIds[rowIdx]}
                      styles={{
                        backgroundColor: "rgb(243 246 248)",
                      }}
                    />
                  )}
                  {headers[colIdx].type === chatterType.number && (
                    <NumberInput
                      styles={{ backgroundColor: "rgb(243 246 248)" }}
                      value={data.value}
                      change={(value) =>
                        updateRowData(
                          sectionIdx,
                          rowIdx + 1,
                          colIdx,
                          value,
                          chatterType.number,
                          realtime,
                          updateTimeline
                        )
                      }
                    />
                  )}
                  {headers[colIdx].type === chatterType.date && (
                    <DatePicker
                      styles={{ backgroundColor: "rgb(243 246 248)" }}
                      value={data.value}
                      change={(value) =>
                        updateRowData(
                          sectionIdx,
                          rowIdx + 1,
                          colIdx,
                          value,
                          chatterType.date,
                          realtime,
                          updateTimeline
                        )
                      }
                    />
                  )}
                  {headers[colIdx].type === chatterType.dropdown && (
                    <Dropdown
                      styles={{ backgroundColor: "rgb(243 246 248)" }}
                      value={data.value}
                      change={(value) =>
                        updateRowData(
                          sectionIdx,
                          rowIdx + 1,
                          colIdx,
                          value,
                          chatterType.dropdown,
                          realtime,
                          updateTimeline
                        )
                      }
                      choices={headers[colIdx].choices}
                    />
                  )}
                </td>
              ))}
            </tr>
          ))}
          {canAddRow && (
            <tr style={{ position: "absolute" }}>
              <Button
                onClick={() => addRow(sectionIdx, realtime, updateTimeline)}
              >
                + Add Item
              </Button>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  return (
    <div style={{ marginBottom: "100px" }}>
      {type !== listViewType.timeline && (
        <Element
          eletype={inputType.input}
          inputProps={{
            placeholder: "Section Name",
            style: {
              width: "250px",
              padding: "10px",
              height: "40px",
              marginBottom: "20px",
              marginRight: "20px",
            },
            onChange: (e) =>
              handleSectionChange(
                sectionIdx,
                e.target.value,
                realtime,
                updateTimeline
              ),
          }}
          value={sectionName}
        />
      )}

      {canDeleteSection && (
        <div style={{ height: "50px" }}>
          <Button
            variant="contained"
            color="error"
            sx={{ backgroundColor: "#ee5253" }}
            onClick={() => removeSection(sectionIdx, realtime, updateTimeline)}
          >
            <DeleteOutlineIcon /> Delete
          </Button>
        </div>
      )}
      {renderInitialTable()}
    </div>
  );
}

export default DynamicTable;
