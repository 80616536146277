import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAllPOApprovalHandler,
  PoRejectionHandler,
  PoApprovedHandler
} from "./poapproval.thunk";

const initialState = {
  POApprovalData: [],
  totalapprovalData:0,
};



export const fetchAllPOApprovalThunk = createAsyncThunk(
  "fetchPOApproval",
  fetchAllPOApprovalHandler
);

export const fetchPORejectionThunk = createAsyncThunk(
  "PoRejection",
  PoRejectionHandler
);

export const fetchPOApprovedThunk = createAsyncThunk(
  "PoApproved",
  PoApprovedHandler
);

const poapprovalSlice = createSlice({
  name: "poapproval",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    
      .addCase(fetchAllPOApprovalThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchAllPOApprovalThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.POApprovalData = action.payload.data;
        state.totalapprovalData = action.payload.total;
        return state;
      })
      .addCase(fetchAllPOApprovalThunk.rejected, (state) => {
        state.isLoading = false;
      })

      // .addCase(fetchPOApprovedThunk.pending, (state) => {
      //   state.isLoading = true;
      //   return state;
      // })
      // .addCase(fetchPOApprovedThunk.fulfilled, (state, action) => {
      //   state.isLoading = false;
      //   state.POApprovalData.unshift(action.payload);
      //   return state;
      // })
      // .addCase(fetchPOApprovedThunk.rejected, (state) => {
      //   state.isLoading = false;
      // })
      
  },
});

export default poapprovalSlice.reducer;
