import { Fetchrefundstatus, uploadUserProfileApi, userApi } from "../../../utils/apis.utils";
import { createQueryString } from "../../../utils/global/global";


export const fetchRefundStatusHandler = async (data, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const query = createQueryString({
    project_id: data.project_id,
    page: data.page,
   
  });

  const response = await user.get(
    Fetchrefundstatus + query + "&limit=10"
  );

  return { data: response.data, total: response.total };
};


