import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAggregateCommercialsByIdHandler,
  fetchAggregateCommercialsByIdHandlerOld,
  fetchExpectationDataByIdHandler,
} from "./milestoneMetrics.thunk";

const INIT_STATE = {
  ExpectationsData: {
    advance_amount_received: "",
    closing_date: "",
    sku_codes: [],
    take_rate: "",
    taxable_project_amount: "",
    total_timeline_of_project: "",
    vendor_amount: "",
    vendor_name: "",
  },
  AggregateCommercials: {
    amount_received: "",
    amount_released: "",
    emb_net_realised: "",
    escrow_amount: "",
  },
  isLoading: false,
};

export const fetchExpectationDataByIdApiThunk = createAsyncThunk(
  "fetchExpectationDataById",
  fetchExpectationDataByIdHandler
);

export const fetchAggregateCommercialsByIdThunk = createAsyncThunk(
  "fetchAggregateCommercialsById",
  fetchAggregateCommercialsByIdHandler
);
export const fetchAggregateCommercialsByIdThunkOld = createAsyncThunk(
  "fetchAggregateCommercialsByIdOld",
  fetchAggregateCommercialsByIdHandlerOld
);


const milestoneMetricsSlice = createSlice({
  name: "milestonemetrics",
  initialState: INIT_STATE,
  reducers: {
    startMilestoneMetricsServiceLoader: (state) => {
      state.isLoading = true;
    },
    stopMilestoneMetricsServiceLoader: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExpectationDataByIdApiThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchExpectationDataByIdApiThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ExpectationsData = action.payload;
      })
      .addCase(fetchExpectationDataByIdApiThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchAggregateCommercialsByIdThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchAggregateCommercialsByIdThunk.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.AggregateCommercials = action.payload;
        }
      ).addCase(fetchAggregateCommercialsByIdThunkOld.pending,(state) =>{
        state.isLoading = true;
      })
      .addCase(
        fetchAggregateCommercialsByIdThunkOld.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.AggregateCommercials = action.payload;
        }
      );
      
  },
});

export const {
  startMilestoneMetricsServiceLoader,
  stopMilestoneMetricsServiceLoader,
} = milestoneMetricsSlice.actions;

export default milestoneMetricsSlice.reducer;
