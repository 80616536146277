import {
  fetchTakeRateData,
  uploadUserProfileApi,
  userApi,
} from "../../../utils/apis.utils";
import {
  startDashboardLoader,
  stopDashboardLoader,
} from "../dashboard/dashboard.slice";
import {
  fetchreqpurchaseorders,
  rejectpoorder,
} from "../../../utils/apis.utils";
import { createQueryString } from "../../../utils/global/global";
import { showFailureToast, showSuccessToast } from "../toast/toast.slice";

export const fetchAllTakeRateApprovalHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;

    const query = createQueryString({
      page: data.page,
    });

    const response = await user.get(fetchTakeRateData + "?manager_user=true");

    return { data: response.data, total: response.total };
  } catch (error) {
    console.log("error---->", error);
  }
};

export const fetchAllTakeRateApprovedHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const query = createQueryString({
      page: data.page,
      cgt_user:true,
      po_type:"approved"

    });
    const response = await user.get(
      fetchTakeRateData + query
    );
    return { data: response.data, total: response.total };
  } catch (error) {
    console.log("error", error);
  }
};
export const fetchAllTakeRateRejectedHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const query = createQueryString({
      page: data.page,
      cgt_user:true,
      po_type:"rejected"

    });
    const response = await user.get(
      fetchTakeRateData + query

    );
    return { data: response.data, total: response.total };
  } catch (error) {
    console.log("error", error);
  }
};
