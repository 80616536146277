import React from "react";
import {
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
  Box,
  Avatar,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchReviewFeedbackThunk } from "../../store/slices/project/project.slice";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { flexbox } from "@mui/system";
import SearchNotFound from "../../components/NotFound/SearchNotFound";

function Feedback() {
  const { reviewFeedback } = useSelector(({ project }) => project);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(fetchReviewFeedbackThunk(params.id));
  }, [dispatch, params]);

  const reviews = reviewFeedback?.review_data;

  if (reviews?.length === 0 || reviews === undefined) {
    return (
      <SearchNotFound
      width="300px"
        src="/static/empty.svg"
        title=""
        subtitle="You can submit feedback after closing your project."
      />
    );
  }

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card mb={5}>
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography
              gutterBottom
              variant="h5"
              sx={{ marginBottom: 0, fontSize: { xs: "16px", sm: "16px" } }}
            >
              Project Feedback
            </Typography>
          </Stack>
          {reviews?.map((review) => (
            <Box sx={{ display: "flex" }}>
              <Box>
                <Avatar
                  sx={{
                    bgcolor: "#eff8f1",
                    color: "#00aa55d9",
                    marginRight: "18px",
                    fontSize: ".8rem",
                    fontWeight: "600",
                  }}
                >
                  <QuestionAnswerIcon />
                </Avatar>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                    fontWeight: "600",
                    marginBottom: "3px",
                  }}
                >
                  {review.question}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "text.secondary",
                    fontWeight: "500",
                    paddingBottom: "10px",
                  }}
                >
                  {" "}
                  {review.type === "date"
                    ? DateTime.fromISO(review.review).toFormat("DD")
                    : review.review}
                </Typography>
              </Box>
            </Box>
          ))}
        </CardContent>
      </Card>
    </Grid>
  );
}

export default Feedback;
