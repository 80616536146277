import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Scrollbar from "./../../Scrollbar";
import UserUpdate from "./UserUpdate";
import {
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Button,
  TableFooter,
  TablePagination,
  Chip,
} from "@mui/material";
import _ from "lodash";
import { css } from "@emotion/css";
import Modal from "../../Modal/Modal";
import { Link } from "react-router-dom";
import ListHead from "./../../common/ListHead";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { capitalCase } from "../../../utils/change-case";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { fetchAllUsersHandler } from "../../../utils/global/user.global";
import useUser from "../../../hooks/useUser";
import { roles } from "../../../utils/enum";
import { useSelector } from "react-redux";
import { createQueryString } from "../../../utils/global/global";
import { createSearchParams } from "react-router-dom";

const TABLE_HEAD = [
  { id: "id", label: "Id", alignRight: false },
  { id: "project", label: "Project Name", alignRight: false },
  { id: "deal_origin", label: "Region", alignRight: false },

  { id: "services", label: "Services ", alignRight: false },
  { id: "link", label: "Zoho Link", alignRight: false },
  { id: "delivery_managers", label: "Delivery Manager", alignRight: false },
  // { id: "tcv_client", label: "TCV - Client ", alignRight: false },
  // {
  //   id: "tcv_vendor",
  //   label: "TCV - Vendor ",
  //   alignRight: false,
  // },

  { id: "sales_spoc", label: " Sales SPOC", alignRight: false },
  { id: "project_status", label: " Status", alignRight: false },
  { id: "created", label: "Created At", alignRight: false },
  { id: "action", label: "Action", alignRight: false },
];

function getInitials(project_name) {
  return capitalCase(project_name.substring(0, 2));
}

const ProjectTable = ({
  searchValue,
  data,
  projectStat,
  search,
  page,
  status,
  searchedUser,
}) => {
  const navigate = useNavigate();
  const [showProjectModal, setShowProjectModal] = useState(false);
  let { projects, totalProjects } = useSelector(({ project }) => project);
  const [allUsers, setAllUsers] = useState([]);
  const handleOpen = (project) => {
    setSelectedProject(project);
    setShowProjectModal(true);
  };
  const handleClose = () => setShowProjectModal(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const user = useUser();
  const columnsAvailable = user.role === roles.admin ? 8 : 7;

  console.log("selectedProject", selectedProject);
  useEffect(() => {
    fetchAllUsersHandler().then((response) => {
      setAllUsers(response.data);
    });
  }, []);

  function fncPageNavigation(id) {
    var query = createQueryString({
      search: search,
      page: page,
      status: status,
      searchedUser: searchedUser,
    });
    var data = {
      search: search,
      page: page,
      status: status,
      searchedUser: searchedUser,
      isNew: 0,
    };
    // navigate(`/dashboard/projects/${id}/overview` + query);
    navigate({
      pathname: `/dashboard/projects/${id}/overview`,
      search: `?${createSearchParams(data)}`,
    });
  }

  return (
    <div>
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ListHead
                headLabel={TABLE_HEAD.slice(0, columnsAvailable)}
                rowCount={projects.length}
                styles={{ backgroundColor: "rgb(243, 246, 248)" }}
              />
              <TableBody>
                {projects.map((row) => {
                  const {
                    id,
                    project_name,
                    services,
                    created_at,
                    sales_spoc,
                    project_status,
                    delivery_managers,
                    deal_zoho_id,
                    deal_origin,
                  } = row;

                  return (
                    <TableRow
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell
                        style={{ minWidth: "100px", paddingLeft: 25 }}
                        component="th"
                        scope="row"
                      >
                        <a onClick={() => fncPageNavigation(id)}>{id}</a>
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Avatar
                            sx={{
                              bgcolor: "#eff8f1",
                              color: "#00aa55",
                              fontSize: ".9rem",
                              fontWeight: "600",
                              marginLeft: "20px",
                            }}
                          >
                            {getInitials(project_name)}
                          </Avatar>
                          <a
                            onClick={() => fncPageNavigation(id)}
                            className={css`
                              text-decoration: none;
                              color: black;
                            `}
                          >
                            <Typography variant="subtitle2" noWrap>
                              {project_name}
                            </Typography>
                          </a>
                        </Stack>
                      </TableCell>
                      <TableCell
                        sx={{ paddingLeft: "30px", paddingRight: "10px" }}
                      >
                        <Typography>
                          {deal_origin === null
                            ? "-"
                            : deal_origin === "UAE"
                            ? "MENA"
                            : deal_origin}
                        </Typography>
                      </TableCell>

                      <TableCell align="left" style={{ minWidth: "300px" }}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <a
                            onClick={() => fncPageNavigation(id)}
                            className={css`
                              text-decoration: none;
                              color: black;
                            `}
                          >
                            {services
                              .map((data) => {
                                return data.service_name;
                              })
                              .join(" , ")}
                          </a>
                        </Stack>
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: "300px" }}>
                        <Typography variant="subtitle2" noWrap>
                          <a
                            href={`https://crm.zoho.in/crm/org60006590658/tab/Potentials/${deal_zoho_id}`}
                            target="_blank"
                            style={{ color: "#454545", cursor: "pointer" }}
                          >
                            {deal_zoho_id}
                          </a>
                        </Typography>
                      </TableCell>

                      <TableCell align="left" style={{ minWidth: "300px" }}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <a
                            onClick={() => fncPageNavigation(id)}
                            className={css`
                              text-decoration: none;
                              color: black;
                            `}
                          >
                            {delivery_managers?.length == 0
                              ? "-"
                              : delivery_managers
                                  ?.map((data) => {
                                    return (
                                      data?.first_name + " " + data?.last_name
                                    );
                                  })
                                  .join(" , ")}
                          </a>
                        </Stack>
                      </TableCell>
                      {/* <TableCell align="left" style={{ minWidth: "150px" }}>
                        {tcv_client ? tcv_client : "--"}
                      </TableCell> */}
                      {/* <TableCell align="left" style={{ minWidth: "150px" }}>
                        {tcv_vendor ? tcv_vendor : "--"}
                      </TableCell> */}

                      <TableCell align="center" style={{ minWidth: "150px" }}>
                        {sales_spoc ? sales_spoc : "--"}
                      </TableCell>
                      <TableCell align="left">
                        <Chip
                          size="small"
                          variant="outlined"
                          label={project_status}
                          sx={{
                            padding: 1,
                            fontSize: "12px",
                            border: "none",
                            textTransform: "capitalize",
                            color:
                              project_status === "active"
                                ? "#11A54A"
                                : "#fe5353",
                            backgroundColor:
                              project_status === "active"
                                ? "#EFF8F1"
                                : "#FDD8DE",
                          }}
                        />
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: "130px" }}>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={2}
                        >
                          <Typography
                            className={css`
                              font-size: 14px;
                            `}
                          >
                            {DateTime.fromISO(created_at).toFormat("DD")}
                          </Typography>
                        </Stack>
                      </TableCell>
                      {user.role === roles.admin && (
                        <TableCell align="center">
                          <AccountCircleIcon
                            onClick={() => handleOpen({ project_name, id })}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>

      <Modal
        isOpen={showProjectModal}
        closeModal={handleClose}
        title="Assign Delivery Manager"
        content={
          <UserUpdate projectName={selectedProject} allUsers={allUsers} />
        }
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{ justifyContent: "center", marginBottom: 2 }}
        contentStyle={{ overflowY: "initial" }}
        dialogStyle={{ overflowY: "inherit" }}
      />
    </div>
  );
};

export default ProjectTable;
