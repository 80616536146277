import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchProjectMatrixHandler } from "./dashboard.thunk";

const INIT_STATE = {
  main: {},
  isLoading: false,
};

export const fetchProjectMatrixThunk = createAsyncThunk(
  "fetchProjectMatrix",
  fetchProjectMatrixHandler
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: INIT_STATE,
  reducers: {
    startDashboardLoader: (state) => {
      state.isLoading = true;
    },
    stopDashboardLoader: (state) => {
      state.isLoading = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectMatrixThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProjectMatrixThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.main = action.payload;
      })
      .addCase(fetchProjectMatrixThunk.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { startDashboardLoader, stopDashboardLoader } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
