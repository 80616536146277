import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Outlet, useNavigate } from "react-router-dom";
// import { basicInfoPath, cvPath } from "../../utils/constant";
import {
  Container,
  Grid,
  Box,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import CustomTabs from "../../components/common/Tabs";

function Index() {
  const labels = [
    {
      label: "All Po",
      value: "allPO",
      icon: "/static/icons/overview.svg",
      activeIcon: "/static/icons/overview_active.svg",
    },
    {
      label: "Approved PO",
      value: "approvedbyfinance",
      icon: "/static/icons/overview.svg",
      activeIcon: "/static/icons/overview_active.svg",
    },
    {
      label: "Rejected PO",
      value: "rejectedbyfinance",
      icon: "/static/icons/overview.svg",
      activeIcon: "/static/icons/overview_active.svg",
    },
  ];


  let {userData} = useSelector(({user}) => user)
  return (
    <>
      <Grid>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <CustomTabs labels={labels} query={""} viewAccessKey={userData.profile.cgt_po_perm} />
        </Box>
        {/* {isLoading && (
            <TabPanel value={selectedTab} index={0}>
              <div style={{ position: "relative" }}>
                <Loader bg="rgba(255,255,255)" height="60vh" />
              </div>
            </TabPanel>
          )} */}
        <div style={{ marginBottom: "20px" }}></div>
        <Outlet />
      </Grid>
    </>
  );
}

export default Index;
