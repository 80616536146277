import { combineReducers } from "@reduxjs/toolkit";

import userReducer from "./slices/user/user.slice";
import projectReducer from "./slices/project/project.slice";
import dashboardReducer from "./slices/dashboard/dashboard.slice";
import initReducer from "./slices/init/init.slice";
import authReducer from "./slices/auth/auth.slice";
import chatterReducer from "./slices/chatter/chatter.slice";
import questiontReducer from "./slices/questions/question.slice";
import proposalReducer from "./slices/proposal/proposal.slice";
import toastReducer from "./slices/toast/toast.slice";
import collectionsReducer from "./slices/collections/collections.slice";
import milestoneMetricsReducer from "./slices/milestoneMetrics/milestoneMetrics.slice";
import addUsersReducer from "./slices/users/addUsers.slice";
import poapprovalReducer from "./slices/poapproval/poapproval.slice";
import financeApprovalReducer from "./slices/financeApproval/financeApproval.slice"
import refundApprovalReducer from './slices/RefunApproval/refundApproval.slice'
import refundStatusReducer from "./slices/refundStatus/refundStatus.slice";
import PoaggregatedReducer from "./slices/aggregated/aggregated.slice";
import takeRateSliceReducer from "./slices/takeRateApproval/takeRate.slice";

const rootReducer = combineReducers({
  takeRateApproval: takeRateSliceReducer,
  poAggregated : PoaggregatedReducer,
  refundStatus: refundStatusReducer,
  refundApproval: refundApprovalReducer,
  poapproval:poapprovalReducer,
  financeApproval:financeApprovalReducer,
  adduser: addUsersReducer,
  user: userReducer,
  project: projectReducer,
  dashboard: dashboardReducer,
  init: initReducer,
  auth: authReducer,
  charter: chatterReducer,
  questions: questiontReducer,
  proposal: proposalReducer,
  toast: toastReducer,
  collections: collectionsReducer,
  milestonemetrics: milestoneMetricsReducer,
});

export default rootReducer;
