import React, { useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { v4 as uuidv4 } from "uuid";

import { useStyles } from "../Section.theme";
import {
  Button,
  Divider,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { chatterType } from "../../../../utils/enum";
import "react-datepicker/dist/react-datepicker.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useModal from "../../../../hooks/useModal";
import Modal from "../../../Modal/Modal";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import TextInput from "./TextInput";
import NumberInput from "./NumberInput";
import DatePicker from "./DatePicker";
import Dropdown from "./Dropdown";
import { Box } from "@mui/system";

function RowMenuList({
  setRowAnchorEl,
  isOpen,
  anchorEl,
  rowIdx,
  sectionIdx,
  deleteRow,
}) {
  return (
    <Menu
      open={isOpen}
      onClose={() => setRowAnchorEl(null)}
      anchorEl={anchorEl}
      PaperProps={{
        sx: { width: 120, maxWidth: "100%" },
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <MenuItem sx={{ color: "text.secondary" }}>
        <ListItemText
          onClick={() => {
            setRowAnchorEl(null);
            deleteRow(sectionIdx, rowIdx);
          }}
          primary="Delete"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
    </Menu>
  );
}

function ChoiceModal({
  choice,
  setChoice,
  headerMeta,
  removeChoice,
  sectionIdx,
  colIdx,
  handleChoiceSave,
  type,
  choices,
}) {
  const classes = useStyles();

  return (
    <>
      <Divider sx={{ marginTop: 3 }} />
      {choices?.length > 0 ? (
        choices.map((choice) => {
          return (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ padding: "10px 10px" }}
              >
                <span style={{ color: "#aaa" }}>{choice}</span>
                <Button onClick={() => removeChoice(colIdx, choice, type)}>
                  Delete
                </Button>
              </Stack>
            </>
          );
        })
      ) : (
        <div style={{ margin: "20px 0px" }}>No Choice Added Yet</div>
      )}
      <label className={classes.formLabel}>Choice</label>
      <input
        className={classes.inputElement}
        value={choice}
        onChange={(e) => setChoice(e.target.value)}
        placeholder="Choice Name"
      />
      <div style={{ textAlign: "right" }}>
        <Button
          variant="contained"
          sx={{ marginTop: "10px" }}
          onClick={() => handleChoiceSave(sectionIdx, colIdx, choice)}
        >
          Save
        </Button>
      </div>
    </>
  );
}

function ColumnMenuList({
  setColAnchorEl,
  isOpen,
  anchorEl,
  sectionIdx,
  colIdx,
  deleteColumn,
  addChoice,
  removeChoice,
  type,
  choices,
}) {
  const [choice, setChoice] = useState(null);
  const { isModalOpen, showModal, hideModal } = useModal();

  function handleChoiceSave() {
    setChoice("");
    addChoice(colIdx, choice, type);
  }

  function handleChoiceRemove(...args) {
    removeChoice(...args);
  }

  function handleModalClose() {
    setColAnchorEl(null);
    hideModal();
  }

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        closeModal={handleModalClose}
        title="Add Choice"
        content={
          <ChoiceModal
            choice={choice}
            setChoice={setChoice}
            removeChoice={handleChoiceRemove}
            sectionIdx={sectionIdx}
            colIdx={colIdx}
            handleChoiceSave={handleChoiceSave}
            type={type}
            choices={choices}
          />
        }
      />
      <Menu
        open={isOpen}
        onClose={() => {
          setColAnchorEl(null);
        }}
        anchorEl={anchorEl}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {(type === chatterType.dropdown || type === chatterType.status) && (
          <MenuItem sx={{ color: "text.secondary" }}>
            <ListItemText
              primary="Add Choice"
              primaryTypographyProps={{ variant: "body2" }}
              onClick={showModal}
            />
          </MenuItem>
        )}
        <MenuItem sx={{ color: "text.secondary" }}>
          <ListItemText
            primary="Delete"
            primaryTypographyProps={{ variant: "body2" }}
            onClick={() => {
              setColAnchorEl(null);
              deleteColumn(colIdx);
            }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}

function Section({
  sectionIdx,
  rows,
  rowIds,
  columnIds,
  columns,
  addRow,
  removeSection,
  removeRow,
  addColumn,
  removeColumn,
  updateRowData,
  updateHeaderData,
  addChoice,
  removeChoice,
  isRowDragging,
  isColumnDragging,
  handleSectionNamChange,
  sectionName,
  index,
  dispalyIconProperty = "block",
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hoveredRow, setHoveredRow] = React.useState(null);
  const [hoveredColumn, setHoveredColumn] = React.useState(null);
  const [colAnchorEl, setColAnchorEl] = React.useState(null);
  const [rowAnchorEl, setRowAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const classes = useStyles();

  function renderInitialTable() {
    return (
      <table style={{ position: "relative" }}>
        <Droppable
          droppableId="timeline"
          isDropDisabled={isRowDragging || index > 0}
        >
          {(provider) => (
            <thead ref={provider.innerRef} {...provider.droppableProps}>
              {columnIds.map((colId, idx) => (
                <Draggable
                  draggableId={colId}
                  key={colId}
                  index={idx}
                  isDragDisabled={idx === 0 || isRowDragging}
                >
                  {(provider) => (
                    <th
                      className={classes.columnHeader}
                      onMouseEnter={() => setHoveredColumn(colId)}
                      onMouseLeave={() => setColAnchorEl(null)}
                      {...provider.draggableProps}
                      ref={provider.innerRef}
                    >
                      {hoveredColumn === colId && (
                        <div
                          style={{
                            position: "absolute",
                            right: "0",
                            top: "15px",
                            cursor: "pointer",
                            zIndex: 1000,
                          }}
                        >
                          <ExpandMoreIcon
                            sx={{ color: "green" }}
                            onClick={(e) => setColAnchorEl(e.currentTarget)}
                          />
                          <ColumnMenuList
                            isOpen={Boolean(colAnchorEl)}
                            setColAnchorEl={setColAnchorEl}
                            anchorEl={colAnchorEl}
                            sectionIdx={sectionIdx}
                            colIdx={colId}
                            deleteColumn={removeColumn}
                            addChoice={addChoice}
                            removeChoice={removeChoice}
                            type={columns[colId].type}
                            choices={columns[colId].choices}
                          />
                        </div>
                      )}
                      {/* {idx !== 0 && index === 0 && (
                        <div>
                          <DragHandleIcon />
                        </div>
                      )} */}
                      <TextInput
                        value={columns[colId]?.value}
                        change={(value) => {
                          updateHeaderData(colId, value, columns[colId]?.type);
                        }}
                        styles={{
                          fontWeight: "bold",
                          borderLeft: "5px solid rgb(69 170 242/60%)",
                        }}
                      />
                    </th>
                  )}
                </Draggable>
              ))}
              {provider.placeholder}
              <th>
                <AddCircleIcon
                  ref={anchorEl}
                  sx={{
                    cursor: "pointer",
                    fontSize: "25px",
                    position: "absolute",
                    top: "-10px",
                    right: "-30px",
                  }}
                  onClick={handleClick}
                />
                <Popover
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                  }}
                >
                  <Stack
                    sx={{ padding: "20px", width: "300px" }}
                    spacing={2}
                    direction="row"
                    flexWrap="wrap"
                  >
                    <Button
                      onClick={() => {
                        setAnchorEl(null);
                        addColumn(chatterType.number);
                      }}
                    >
                      Number
                    </Button>
                    <Button
                      onClick={() => {
                        setAnchorEl(null);
                        addColumn(chatterType.text);
                      }}
                    >
                      Text
                    </Button>
                    <Button
                      onClick={() => {
                        setAnchorEl(null);
                        addColumn(chatterType.date);
                      }}
                    >
                      Date
                    </Button>
                    <Button
                      sx={{ marginLeft: "0px !important" }}
                      onClick={() => {
                        setAnchorEl(null);
                        addColumn(chatterType.dropdown);
                      }}
                    >
                      Dropdown
                    </Button>
                    <Button
                      sx={{ marginLeft: "0px !important" }}
                      onClick={() => {
                        setAnchorEl(null);
                        addColumn(chatterType.status);
                      }}
                    >
                      Status
                    </Button>
                  </Stack>
                </Popover>
              </th>
            </thead>
          )}
        </Droppable>
        <Droppable droppableId={sectionIdx} isDropDisabled={isColumnDragging}>
          {(provider) => (
            <tbody
              ref={provider.innerRef}
              {...provider.droppableProps}
              style={{ height: "1px" }}
            >
              {rowIds.map((rowId, idx) => (
                <Draggable
                  draggableId={rowId}
                  key={rowId}
                  index={idx}
                  isDragDisabled={isColumnDragging}
                >
                  {(provider) => (
                    <tr
                      {...provider.draggableProps}
                      ref={provider.innerRef}
                      onMouseEnter={() => setHoveredRow(rowId)}
                      onMouseLeave={() => setHoveredRow(null)}
                    >
                      {columnIds.map((colId, idx) => (
                        <td style={{ position: "relative" }}>
                          {idx === 0 && (
                            <div
                              style={{
                                position: "absolute",
                                left: "-30px",
                                top: "15px",
                                display: dispalyIconProperty,
                              }}
                              {...provider.dragHandleProps}
                            >
                              <DragIndicatorIcon />
                            </div>
                          )}
                          {hoveredRow === rowId && idx === 0 && (
                            <div
                              style={{
                                position: "absolute",
                                left: "-55px",
                                top: "12px",
                                cursor: "pointer",
                                display: dispalyIconProperty,
                              }}
                            >
                              <ExpandMoreIcon
                                onClick={(e) => setRowAnchorEl(e.currentTarget)}
                                sx={{ color: "green" }}
                              />
                              <RowMenuList
                                isOpen={Boolean(rowAnchorEl)}
                                setRowAnchorEl={setRowAnchorEl}
                                anchorEl={rowAnchorEl}
                                rowIdx={hoveredRow}
                                sectionIdx={sectionIdx}
                                deleteRow={removeRow}
                              />
                            </div>
                          )}
                          {rows[rowId][colId].type === chatterType.text && (
                            <TextInput
                              {...rows[rowId][colId]}
                              change={(value) => {
                                updateRowData(
                                  sectionIdx,
                                  rowId,
                                  colId,
                                  value,
                                  chatterType.text
                                );
                              }}
                              sectionIdx={sectionIdx}
                              rowIdx={rowId}
                              styles={{
                                backgroundColor: "rgb(243 246 248)",
                              }}
                              id={rowId + "_" + colId}
                              type={rows[rowId][colId].type}
                            />
                          )}
                          {rows[rowId][colId].type === chatterType.number && (
                            <NumberInput
                              styles={{ backgroundColor: "rgb(243 246 248)" }}
                              {...rows[rowId][colId]}
                              change={(value) =>
                                updateRowData(
                                  sectionIdx,
                                  rowId,
                                  colId,
                                  value,
                                  chatterType.text
                                )
                              }
                            />
                          )}
                          {rows[rowId][colId].type === chatterType.date && (
                            <DatePicker
                              styles={{ backgroundColor: "rgb(243 246 248)" }}
                              {...rows[rowId][colId]}
                              change={(value) =>
                                updateRowData(
                                  sectionIdx,
                                  rowId,
                                  colId,
                                  value,
                                  chatterType.text
                                )
                              }
                            />
                          )}
                          {(rows[rowId][colId].type === chatterType.dropdown ||
                            rows[rowId][colId].type === chatterType.status) && (
                            <Dropdown
                              styles={{ backgroundColor: "rgb(243 246 248)" }}
                              {...rows[rowId][colId]}
                              choices={columns[colId]?.choices}
                              change={(value) => {
                                updateRowData(
                                  sectionIdx,
                                  rowId,
                                  colId,
                                  value,
                                  chatterType.text
                                );
                              }}
                            />
                          )}
                        </td>
                      ))}
                    </tr>
                  )}
                </Draggable>
              ))}
              {provider.placeholder}
              <tr style={{ position: "absolute" }}>
                <Button onClick={() => addRow(sectionIdx)}>+ Add Item</Button>
              </tr>
            </tbody>
          )}
        </Droppable>
      </table>
    );
  }

  return (
    <div style={{ marginBottom: "100px" }}>
      <div style={{ height: "50px" }}>
        <Button
          variant="contained"
          color="error"
          sx={{ backgroundColor: "#ee5253" }}
          onClick={() => removeSection(sectionIdx)}
        >
          <DeleteOutlineIcon /> Delete
        </Button>
      </div>
      <div>
        <Typography variant="body2" gutterBottom sx={{ color: "#55607f" }}>
          {/* Note : This section has been notified by Vendor */}
        </Typography>
      </div>
      <Box>
        <input
          class={classes.inputElement}
          placeholder="Section Name"
          style={{ width: "250px", marginBottom: "30px" }}
          value={sectionName}
          onChange={(e) => handleSectionNamChange(sectionIdx, e.target.value)}
        />
      </Box>
      {renderInitialTable()}
    </div>
  );
}

export default Section;
