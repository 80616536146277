import React, { useEffect, useMemo, useState } from "react";
import {
  Stack,
  Card,
  Typography,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  TableHead,
  chip,
  Chip,
  IconButton,
} from "@mui/material";
import { useParams } from "react-router-dom";
import Page from "../../../components/Page";
import Scrollbar from "../../../components/Scrollbar";
import Modal from "../../../components/Modal/Modal";
import { useDispatch } from "react-redux";
import {
  fetchAllProformaInvoiceThunk,
  createProformaInvoiceThunk,
  pushPIToZohoThunk,
  markPOSummationToVoidThunk,
  validatePiAmountThunk,
} from "../../../store/slices/project/project.slice";
import { useSelector } from "react-redux";
import { getFormattedTime } from "../../../utils/time.util";
import SearchNotFound from "../../../components/NotFound/SearchNotFound";
import AddIcon from "@mui/icons-material/Add";
import AddPIModal from "../../../components/Modal/AddPIModal";
import PIInfoModal from "../../../components/Modal/PIIInfoModal";
import { generateAndDownloadPdf } from "../../../utils/global/global";
import DownloadIcon from "@mui/icons-material/Download";
import InfoIcon from "@mui/icons-material/Info";
import { showSuccessToast } from "../../../store/slices/toast/toast.slice";

export default function ProformaInvoice() {
  const columns = [
    { id: "created_at", label: "Requested Date & Time", alignRight: false },
    { id: "created_by", label: "Requested by", alignRight: false },
    { id: "zb_pi_number", label: "PI number", alignRight: false },
    { id: "pi_amount", label: "PI Amount (Excl. GST)", alignRight: false },
    { id: "ci_amount", label: "CI Amount (Incl. GST)", alignRight: false },
    { id: "pi_url", label: "PI Pdf", alignRight: false },
    { id: "ci_url", label: "CI Pdf", alignRight: false },
    { id: "pi_status", label: "PI Status", alignRight: false },
    { id: "milestones", label: "Milestones", alignRight: false },

    { id: "ci_status", label: "CI Status", alignRight: false },
    { id: "remarks", label: "Remarks", alignRight: false },
    { id: "diff_amount", label: "Diff. amount", alignRight: false }, //pi-ci

    { id: "action", label: "Action", alignRight: false },
  ];

  const [showModal, setShowModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [performaId, setPerformaId] = useState(0);
  const proformaInvoice = useSelector(({ project }) => project.proformaInvoice);
  const pushingPI = useSelector(({ project }) => project.pushingPI);
  const piValidate = useSelector(({ project }) => project.piValidate);
  const { deal_origin } = useSelector(
    ({ project }) => project?.selectedProject
  );
  const userInfo = useSelector(({ user }) => user.userData);

  const dispatch = useDispatch();
  const params = useParams();

  const handleOpen = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const handleInfoOpen = (id) => {
    setPerformaId(id);
    setShowInfoModal(true);
  };
  const handleInfoClose = () => {
    setShowInfoModal(false);
  };

  const handlePushPIToZoho = (PI) => {
    let project_id = PI.project_id;
    let performa_invoice_id = PI.id;
    dispatch(validatePiAmountThunk({ project_id: project_id }));

    if (
      Number(PI.pi_amount) + Number(piValidate?.pi_made_exclusive_gst) >
      Number(piValidate?.total_deal_amount_exclusive_gst)
    ) {
      dispatch(
        showSuccessToast({
          message: "You have to create new PI, with correct amount",
          vertical: "top",
          horizontal: "right",
        })
      );
    } else {
      dispatch(pushPIToZohoThunk({ project_id, performa_invoice_id }));
    }
  };

  const handlePItoVoid = (PI) => {
    let project_id = PI.project_id;
    let performa_invoice_id = PI.id;
    let id = params.id;
    dispatch(markPOSummationToVoidThunk({ performa_invoice_id, id }));
  };

  useEffect(() => {
    if (!pushingPI) {
      dispatch(fetchAllProformaInvoiceThunk(params.id));
    }
  }, [pushingPI]);

  // useEffect(()=>{
  //   console.log(proformaInvoice);
  // },[proformaInvoice])

  useEffect(() => {
    dispatch(fetchAllProformaInvoiceThunk(params.id));
  }, [dispatch, params.id]);

  return (
    <>
      <Page title="Proforma Invoice | EMB">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={2}
        >
          <Typography variant="h5" gutterBottom>
            Proforma Invoice (PI)
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              handleOpen(true);
            }}
            disabled={deal_origin && deal_origin === "IND" ? false : true}
          >
            {" "}
            <AddIcon sx={{ fontSize: "1.35rem", mr: "0.5rem" }} />
            Proforma Invoice
          </Button>
          <Modal
            maxWidth="md"
            isOpen={showModal}
            closeModal={handleClose}
            title="Add Proforma Invoice"
            content={<AddPIModal closeModal={handleClose} />}
            dialogProps={{ fullWidth: true }}
            titleStyle={{ textAlign: "center", marginTop: 2 }}
            actionStyle={{ justifyContent: "center", marginBottom: 2 }}
          />
        </Stack>
        {proformaInvoice.length == 0 ? (
          <SearchNotFound
            src="/static/no-found/milestones.png"
            subtitle="No PI to display."
            title=" "
          />
        ) : (
          <Card sx={{ marginBottom: "3rem" }}>
            <Scrollbar>
              <TableContainer component={Paper} sx={{ minWidth: 800 }}>
                <Table aria-label="simple table">
                  <TableHead sx={{ backgroundColor: "rgb(243, 246, 248)" }}>
                    <TableRow>
                      {columns.map((column) =>
                        column.id != "action" ? (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              top: 57,
                              minWidth: column.minWidth,
                              backgroundColor: "#f3f6f8",
                              paddingLeft: "15px",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ) : (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              backgroundColor: "#f3f6f8",
                              position: "sticky",
                              right: 0,
                            }}
                          >
                            {column.label}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody key={new Date().toISOString()}>
                    {proformaInvoice.map((row) => {
                      const {
                        id,
                        zb_pi_number,
                        pi_amount,
                        pi_amount_with_gst = (
                          parseFloat(pi_amount) + parseFloat(pi_amount * 0.18)
                        ).toFixed(2),
                        milestones,
                        ci_amount,
                        pi_url,
                        ci_url,
                        pi_status,
                        ci_status,
                        remarks,
                        diff_amount = ci_amount == null ? (
                          "-"
                        ) : (
                          <>₹ {pi_amount_with_gst - ci_amount}</>
                        ),
                        created_at,
                        created_by_email,
                        zb_ci_number,
                        pi_push_status,
                      } = row;

                      return (
                        <TableRow
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell align="left" style={{ minWidth: "180px" }}>
                            {/* {created_at ? created_at : "-"} */}
                            {created_at
                              ? new Date(created_at).toLocaleDateString(
                                  "en-IN",
                                  {
                                    timeZone: "Asia/Kolkata",
                                    hour12: false,
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                  }
                                )
                              : "-"}
                          </TableCell>
                          <TableCell align="left" style={{ minWidth: "150px" }}>
                            {created_by_email ? created_by_email : "-"}
                          </TableCell>
                          <TableCell align="left" style={{ minWidth: "150px" }}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              {zb_pi_number ? zb_pi_number : "-"}
                            </Stack>
                          </TableCell>
                          <TableCell align="left" style={{ minWidth: "200px" }}>
                            {pi_amount ? <> ₹ {pi_amount}</> : "-"}
                          </TableCell>

                          <TableCell align="left" style={{ minWidth: "200px" }}>
                            {ci_amount ? <> ₹ {ci_amount}</> : "-"}
                          </TableCell>

                          <TableCell align="left" style={{ minWidth: "100px" }}>
                            {pi_push_status === "DRAFT" ? (
                              <IconButton
                                aria-label="info"
                                onClick={() => handleInfoOpen(id)}
                                sx={{ cursor: "pointer" }}
                              >
                                <InfoIcon />
                              </IconButton>
                            ) : pi_url ? (
                              <IconButton
                                aria-label="download"
                                onClick={() => {
                                  generateAndDownloadPdf(pi_url, zb_pi_number);
                                }}
                                sx={{ cursor: "pointer" }}
                              >
                                <DownloadIcon />
                              </IconButton>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          <TableCell align="left" style={{ minWidth: "100px" }}>
                            {ci_url ? (
                              <IconButton
                                aria-label="download"
                                onClick={() => {
                                  generateAndDownloadPdf(ci_url, zb_ci_number);
                                }}
                                sx={{ cursor: "pointer" }}
                              >
                                <DownloadIcon />
                              </IconButton>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          <TableCell align="left" style={{ minWidth: "150px" }}>
                            {pi_status ? pi_status : "-"}
                          </TableCell>
                          <TableCell align="left" style={{ minWidth: "150px" }}>
                            {milestones[0] == null ? "-" : milestones[0]}
                          </TableCell>

                          <TableCell align="left" style={{ minWidth: "150px" }}>
                            {ci_status ? ci_status : "-"}
                          </TableCell>
                          <TableCell align="left" style={{ minWidth: "150px" }}>
                            {remarks ? remarks : "-"}
                          </TableCell>
                          <TableCell align="left" style={{ minWidth: "150px" }}>
                            {diff_amount ? diff_amount : "-"}
                          </TableCell>
                          {/* <TableCell align="left" style={{ minWidth: "150px" }}>
                            {created_by_email ? created_by_email : "-"}
                          </TableCell> */}
                          <TableCell
                            align="left"
                            style={{
                              minWidth: "150px",
                              backgroundColor: "white",
                              position: "sticky",
                              right: 0,
                              display: "flex",
                              gap: "10px",
                            }}
                          >
                            {userInfo?.profile?.email.toLowerCase() ===
                              "psnayal08@gmail.com" ||
                            userInfo?.profile?.email.toLowerCase() ===
                              "himanshu.ratra@exmyb.com" ||
                            userInfo?.profile?.email.toLowerCase() ===
                              "himanshu@exmyb.com" ? (
                              <Button
                                disabled={
                                  row.ci_created === true ||
                                  row.pi_status === "void" ||
                                  row.pi_push_status === "DRAFT"
                                }
                                variant="contained"
                                onClick={() => {
                                  handlePItoVoid(row);
                                }}
                              >
                                {" "}
                                Void
                              </Button>
                            ) : (
                              <></>
                            )}
                            <Button
                              disabled={row.pi_push_status !== "DRAFT"}
                              variant="contained"
                              onClick={() => {
                                handlePushPIToZoho(row);
                              }}
                            >
                              {" "}
                              Submit
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        )}
      </Page>

      <Modal
        maxWidth="md"
        isOpen={showInfoModal}
        closeModal={handleInfoClose}
        title="PI Info"
        content={
          <PIInfoModal
            id={performaId}
            projectId={params.id}
            closeModal={handleInfoClose}
          />
        }
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{ justifyContent: "center", marginBottom: 2 }}
      />
    </>
  );
}
