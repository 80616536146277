import React, { useEffect, useState } from "react";
import { Box, Grid, Button, Typography, Stack } from "@mui/material";
import Element from "../Form/Element";
import { inputType } from "../../utils/enum";
import useForm from "../../hooks/useForm";
import { useFormik } from "formik";
import { PurchaseOrderValidation } from "../../utils/validation";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import {} from "./upload.css";
import { showFailureToast } from "../../store/slices/toast/toast.slice";
import { ContactlessOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { addPurchaseOrderThunk } from "../../store/slices/project/project.slice";
import { fetchAllCollectionsByProjectThunk } from "../../store/slices/project/project.slice";
export const useStyles = makeStyles({
  dropdownIconCss: {
    "& svg": {
      bottom: "14px !important",
    },
  },
  "@media (max-width:600px)": {
    dropdownIconCss: {
      padding: "0px  !important",
    },
  },
});

const checkboxGroupOptions = [
  {
    name: "OIE",
    value: "OIE",
  },
  {
    name: "DESIGN",
    value: "DESIGN",
  },
  {
    name: "CLIENT",
    value: "CLIENT",
  },
  {
    name: "TECHNICAL",
    value: "TECHNICAL",
  },
  {
    name: "SM",
    value: "SM",
  },
];

export default function PurchaseOrderModal({
  selectedStatus,
  closeModal,
  fncReason,
  clickedFrom,
  poSummation,
}) {
  const collection = useSelector(({ project }) => project.collections);
  const [ddlOption, setDdlOption] = useState([]);
  const [errorForPo, setErrorForPo] = useState(null);
  const [submit, setSubmit] = useState(true);

  const dispatch = useDispatch();
  const INIT_STATE = {
    po_amount: "",
    milestone_id: "",
    vendor_invoice: "",
    vendor_invoice_date: "",
    vendor_invoice_number: "",
    email_screenshot: "",
    suggested_take_rate: "",
  };

  const classes = useStyles();

  //   const dispatch = useDispatch();
  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: INIT_STATE,
      onSubmit: onSubmit,
      validationSchema: PurchaseOrderValidation,
    });

  function handleInputBlur(e) {
    if (
      Number(e) + Number(poSummation.total_released_po_amount) >
      Number(poSummation.total_vendor_amount)
    ) {
      setErrorForPo("PO amount exceeded");
      setSubmit(false);
    } else {
      setErrorForPo(null);
      setSubmit(true);
    }
  }

  const params = useParams();

  useEffect(() => {
    var ddl = collection.map((d, i) => {
      return {
        label: d?.milestone_name,
        value: d?.id,
      };
    });
    setDdlOption(ddl);
  }, [collection]);

  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  }
  function onSubmit(data) {
    // debugger;
    var formdata = new FormData();
    formdata.append("project_id", params.id);
    formdata.append("po_amount", data.po_amount);
    formdata.append("milestone_id", data.milestone_id);
    formdata.append("partner_invoice_file", data.vendor_invoice);
    formdata.append(
      "vendor_invoice_date",
      convertDate(data.vendor_invoice_date)
    );
    formdata.append("vendor_invoice_number", data.vendor_invoice_number);
    formdata.append("email_screenshot", data.email_screenshot);
    formdata.append("suggested_take_rate", data.suggested_take_rate);
    formdata.append("current_vendor",data.current_vendor)

    dispatch(addPurchaseOrderThunk(formdata));

    closeModal();
  }

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} px={3}>
            <Grid item md={12} pb={2}>
              <Element
                eletype={inputType.number}
                label="PO Amount (Excl. of GST)"
                isStar="*"
                inputProps={{
                  onChange: (e) => {
                    handleInputBlur(e.target.value);
                    handleChange(e);
                  },
                  onBlur: (e) => {
                    handleInputBlur(e.target.value);
                    handleBlur(e);
                  },
                  name: "po_amount",
                }}
                value={values.po_amount}
                errorText={touched.po_amount && errors.po_amount}
              />
              {errors.po_amount ? (
                <></>
              ) : errorForPo ? (
                <div
                  style={{
                    color: "red",
                    fontSize: "14px",
                    marginLeft: "8px",
                    marginTop: "-12px",
                  }}
                >
                  {errorForPo}
                </div>
              ) : (
                <></>
              )}
            </Grid>

            <Grid item md={12} pb={2}>
              <Element
                eletype={inputType.input}
                label="Current Vendor Name"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "current_vendor",
                }}
                isStar="*"
                value={values.current_vendor}
                errorText={
                  touched.current_vendor && errors.current_vendor
                }
              />
            </Grid>

            <Grid item md={12} pb={2}>
              <Element
                eletype={inputType.input}
                label="Vendor Invoice Number"
                isStar="*"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "vendor_invoice_number",
                }}
                value={values.vendor_invoice_number}
                errorText={
                  touched.vendor_invoice_number && errors.vendor_invoice_number
                }
              />
            </Grid>

            <Grid style={{ zIndex: 2001 }} item md={12} pb={2}>
              <Element
                eletype={inputType.date}
                label="Vendor Invoice Date"
                isStar="*"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "vendor_invoice_date",
                  placeholder: "DD/MM/YYYY",
                }}
                value={values.vendor_invoice_date}
                errorText={
                  touched.vendor_invoice_date && errors.vendor_invoice_date
                }
              />
            </Grid>

            <Grid
              style={{ zIndex: 2000 }}
              item
              md={12}
              className={classes.dropdownIconCss}
            >
              <Element
                eletype={inputType.select}
                label="Milestone dropdown"
                isStar="*"
                value={values.milestone_id}
                inputProps={{
                  name: "milestone_id",
                  handleChange: handleChange,
                  handleBlur: handleBlur,
                }}
                placeholder="Select a MileStone"
                errorText={touched.milestone_id && errors.milestone_id}
                options={ddlOption}
                icons={
                  <ArrowDropDownIcon
                    sx={{
                      position: "absolute",
                      right: 20,
                      bottom: 14,
                    }}
                  />
                }
              />
            </Grid>

            <Grid item md={12} pb={2}>
              {/* <Element
                label="Proof of Work"
                inputProps={{
                  type: "file",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "invoice_files",
                }}
                extraArguments={{
                  upload_type: "gst_file",
                }}
                eletype={inputType.file}
                value={values.invoice_files}
                errorText={touched.invoice_files && errors.invoice_files}
                          /> */}

              <Element
                eletype={inputType.simplefile}
                label="Vendor Invoice"
                isStar="*"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "vendor_invoice",
                }}
                extraArguments={{
                  upload_type: "vendor_invoice",
                }}
                accept=".pdf"
                value={values.vendor_invoice}
                errorText={touched.vendor_invoice && errors.vendor_invoice}
              />
            </Grid>

            <Grid item md={12} pb={2}>
              <Element
                eletype={inputType.input}
                label="Suggested Take Rate"
                isStar="*"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "suggested_take_rate",
                }}
                value={values.suggested_take_rate}
                errorText={
                  touched.suggested_take_rate && errors.suggested_take_rate
                }
              />
            </Grid>
            

            <Grid item md={12} pb={2}>
              <Element
                eletype={inputType.simplefile}
                label="Email PDF"
                isStar="*"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "email_screenshot",
                }}
                extraArguments={{
                  upload_type: "email_screenshot",
                }}
                accept=".pdf"
                value={values.email_screenshot}
                errorText={touched.email_screenshot && errors.email_screenshot}
              />
            </Grid>

            <Grid item md={12} pb={2} sx={{ textAlign: "right" }}>
              <Button
                size="large"
                sx={{ marginRight: "2rem" }}
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                disabled={!submit}
                size="large"
                type="submit"
                variant="contained"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}
