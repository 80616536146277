import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Scrollbar from "./../Scrollbar";
// import UserUpdate from "./UserUpdate";
import PurchaseOrderModal from "../Modal/PurchaseOrderModal";
import { DateTime } from "luxon";
import {
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TableHead,
  Button,
  Grid,
  TableFooter,
  TablePagination,
  Chip,
  IconButton,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import _ from "lodash";
import { css } from "@emotion/css";
import Modal from "../Modal/Modal";
import { Link } from "react-router-dom";
import ListHead from "./../common/ListHead";

import { useEffect, useState } from "react";
import { capitalCase } from "../../utils/change-case";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { fetchAllUsersHandler } from "../../utils/global/user.global";
import useUser from "../../hooks/useUser";
import { roles } from "../../utils/enum";
import { useDispatch, useSelector } from "react-redux";
import { createQueryString, getClientSelectorDataHandler, getVendorSelectorDataHandler } from "../../utils/global/global";
import { createSearchParams } from "react-router-dom";
import {
  fetchPurchaseOrderThunk,
  RequestForApprovalPOThunk,
} from "../../store/slices/project/project.slice";
import { fetchAllCollectionsByProjectThunk } from "../../store/slices/project/project.slice";
import { format } from "date-fns";
import SearchNotFound from "../NotFound/SearchNotFound";
import InfoIcon from "@mui/icons-material/Info";
import POInfoModal from "../Modal/POInfoModal";
import POEditModal from "../Modal/POEditModal";
import { fetchRefundStatusThunk } from "../../store/slices/refundStatus/refundStatus.slice";
import OverviewStatusModal from "../Modal/OverviewStatusModal";


const columns = [

  { id: "refund_amount", label: "Refund amount", alignRight: false },
  {
    id: "client_cancelled_cheque",
    label: "Cancelled cheque",
    alignRight: false,
  },
  { id: "invoice_number", label: "Invoice Number", alignRight: false },
  { id: "reason", label: "Reason", alignRight: false },
  // { id: "rejected_by ", label: "Rejected by", alignRight: false },
  // { id: "Vendor_Invoice_file", label: "Vendor Invoice", alignRight: false },
  // { id: "milestones", label: "Milestones", alignRight: false },
  // {
  //   id: " vendor_invoice_date",
  //   label: "Vendor Invoice Date",
  //   alignRight: false,
  // },
  // {
  //   id: " vendor_invoice_number",
  //   label: "Vendor Invoice Number",
  //   alignRight: false,
  // },

  // { id: "PO_status", label: "PO Status", alignRight: false },
  // { id: "bill_no", label: "Bill No", alignRight: false },
  // { id: "bill_status", label: "Bill Status", alignRight: false },

  // { id: "Rejection_reason", label: "Rejection Reason", alignRight: false },

  // {id: "cn_dn_status", label: "CN and DN status", alignRight: false}

  // { id: "action", label: "Action", alignRight: false },
];

function getInitials(project_name) {
  return capitalCase(project_name.substring(0, 2));
}

const RefundStatus = () => {
  const navigate = useNavigate();
  const [users, setAllUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [performaId, setPerformaId] = useState(0);
  const [rowData, setRowData] = useState({});
  const handleOpen = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    
    setShowModal(false);
  };
  const dispatch = useDispatch();
  const param = useParams();

  //-------- client refund and vender refund button ------------------ //
  // const project = useSelector(({ project }) => project.selectedProject);

  // const [selectedModal, setSelectedModal] = useState("");
  // const [reasonData, setReasonData] = useState("");
  // const [clientData, setClientData] = useState([]);
  // const [vendorData, setVendorData] = useState([]);
  // const [status, setStatus] = useState("");

  // function fncReason(data) {
  //   setReasonData(data);
  // }

  // useEffect(() => {
  //   getClientSelectorDataHandler(param.id).then((response) => {
  //     let data = response?.data.map((user) => ({
  //       label: user?.ci_number,
  //       value: user?.ci_id,
  //     }));
  //     setClientData(data);
  //   });

  //   getVendorSelectorDataHandler(param.id).then((response) => {
  //     let data = response?.data.map((user) => ({
  //       label: "" + user?.bill_no,
  //       value: "" + user?.bill_id,
  //     }));
  //     setVendorData(data);
  //   });
  // }, []);

  // useEffect(() => {
  //   setStatus(project?.project_status);

  
   
  // }, [project]);

  // ----- end code of client refund button and vendor refund button -------- //

  let { purchaseOrder } = useSelector(({ project }) => project);
  let { editSuccess } = useSelector(({ project }) => project);
  const pushingPO = useSelector(({ project }) => project.pushingPO);

  const handleInfoOpen = (id) => {
    setPerformaId(id);
    setShowInfoModal(true);
  };
  const handleInfoClose = () => {
    setShowInfoModal(false);
  };
  const handleEditOpen = (row) => {
    setRowData(row);
    setShowEditModal(true);
  };
  const handleEditClose = () => {
    setShowEditModal(false);
  };

  const handlePOSubmit = (PO) => {
    let project_id = PO.project_id;
    let purchase_order_id = PO.id;
    var data = {
      project_id: PO.project_id,
      status: "requested_for_approval",
    };
    dispatch(
      RequestForApprovalPOThunk({
        id: purchase_order_id,
        data,
        paramID: param.id,
      })
    );
  };

  useEffect(() => {
    fetchAllUsersHandler().then((response) => {
      setAllUsers(response.data);
    });
  }, []);

  useEffect(() => {
    dispatch(fetchPurchaseOrderThunk(param.id));
    dispatch(fetchAllCollectionsByProjectThunk(param.id));
  }, [dispatch, param.id]);

  useEffect(() => {
    if (editSuccess) {
      dispatch(fetchPurchaseOrderThunk(param.id));
      dispatch(fetchAllCollectionsByProjectThunk(param.id));
    }
  }, [editSuccess]);

  useEffect(() => {
    if (!pushingPO) {
      dispatch(fetchPurchaseOrderThunk(param.id));
      dispatch(fetchAllCollectionsByProjectThunk(param.id));
    }
  }, [pushingPO]);

  // refund status thunk
  useEffect(() => {
    dispatch(fetchRefundStatusThunk(
      { 
        page: 1,
        project_id: param.id
      }));
      
  }, []);


  let { RefundStatusData, totalrefundStatusData } = useSelector(
    (state) => state.refundStatus
  );

  return (
    <div>
      <Grid item md={12} pb={2} sx={{ textAlign: "right" }}>
     
      </Grid>
      <Card>
        <Scrollbar>
          {RefundStatusData?.length === 0 ? (
            <Stack pt={5}>
              <SearchNotFound
                src="/static/no-found/project.png"
                title=""
                subtitle="No Refund Request Created Yet."
              />
            </Stack>
          ) : (
            <TableContainer
              sx={{ minWidth: 800, overflowY: "auto", height: "500px" }}
            >
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: "rgb(243, 246, 248)",
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  <TableRow sx={{}}>
                    {columns.map((column) =>
                      column.id != "action" ? (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            top: 57,
                            minWidth: column.minWidth,
                            backgroundColor: "#f3f6f8",
                            paddingLeft: "15px",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ) : (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            backgroundColor: "#f3f6f8",
                            position: "sticky",
                            right: 0,
                          }}
                        >
                          {column.label}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {RefundStatusData.map((row) => {
                    const {
                      amount,
                      client_cancelled_cheque,
                      id,
                      invoice_number,
                      reason,
                      rejected_by,
                      project_id,
                      cn_dn_status,
                      is_editable,
                    } = row;

                    return (
                      <TableRow
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell align="left" style={{ minWidth: "180px" }}>
                          {amount == null ? "-" : amount}
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: "180px" }}>
                          {client_cancelled_cheque == null ? (
                            "-"
                          ) : (
                            <a target="_blank" href={client_cancelled_cheque}>
                               Cheque
                            </a>
                          )}
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: "200px" }}>
                          {invoice_number == null ? "-" : invoice_number}
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: "150px" }}>
                          {reason == null ? "-" : reason}
                        </TableCell>

                        {/* <TableCell align="left" style={{ minWidth: "180px" }}>
                          {cn_dn_status == null ? "-" : cn_dn_status}
                        </TableCell> */}
{/* 
                        <TableCell
                          align="left"
                          style={{
                            minWidth: "150px",
                            backgroundColor: "white",
                            position: "sticky",
                            right: 0,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              cursor:
                                row.is_editable !== true
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                          >
                            <IconButton
                              disabled={row.project_id !== true}
                              aria-label="edit"
                              onClick={() => handleEditOpen(row)}
                              sx={{
                                cursor: "pointer",
                                color: "black",
                                marginRight: "10px",
                              }}
                            >
                              <EditIcon />
                            </IconButton>

                            <Button
                             disabled={row.is_editable!==true}
                              variant="contained"
                              onClick={() => {
                                handlePOSubmit(row);
                              }}
                            >
                              {" "}
                              Req.Approval
                            </Button>
                          </div>
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Scrollbar>
      </Card>

      <Modal
        content={<PurchaseOrderModal closeModal={handleClose} />}
        maxWidth="md"
        isOpen={showModal}
        closeModal={handleClose}
        title="Add Purchase Order"
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{ justifyContent: "center", marginBottom: 2 }}
      />
      <Modal
        maxWidth="md"
        isOpen={showInfoModal}
        closeModal={handleInfoClose}
        title="Vendor Invoice Info"
        content={
          <POInfoModal
            id={performaId}
            projectId={param.id}
            closeModal={handleInfoClose}
          />
        }
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{ justifyContent: "center", marginBottom: 2 }}
      />
      <Modal
        maxWidth="md"
        isOpen={showEditModal}
        closeModal={handleEditClose}
        title="Edit PO"
        content={<POEditModal rowData={rowData} closeModal={handleEditClose} />}
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{ justifyContent: "center", marginBottom: 2 }}
      />

      {/* <Modal
        maxWidth="md"
        isOpen={showEditModal}
        closeModal={handleEditClose}
        title="Refund request"
        content={
          <RefundStatusModel
            rowData={rowData}
            closeModal={handleEditClose}
          />
        }
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{ justifyContent: "center", marginBottom: 2 }}
      /> */}
    </div>
  );
};

export default RefundStatus;
