import React, { useEffect, useState } from "react";
import { Stack, Box, Typography } from "@mui/material";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import GridViewIcon from "@mui/icons-material/GridView";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { projectStatus } from "../../../utils/enum";
import { UserListToolbar } from "../user";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import FormControl from "@mui/material/FormControl";
import Dropdown from "../../Form/Dropdown";
import { fetchAllProjectStatusThunk } from "../../../store/slices/project/project.slice";
import { useSelector, useDispatch } from "react-redux";
import SearchWithInData from "../../Form/SearchWithInData";
import { fetchAllUsersHandler } from "../../../utils/global/user.global";

const ProjecMenu = ({
  type,
  setType,
  handleSearchChange,
  setStatus,
  status,
  fncSearchUserData,
  searchedUser,
  search,
}) => {
  const handleType = (event, newType) => {
    setType(newType);
  };

  const dispatch = useDispatch();
  const [allUsers, setAllUsers] = useState([]);
  const projectStatusDropdown = useSelector(
    ({ project }) => project.projectStatus
  );

  const userInfo = useSelector(({ user }) => user.userData);

  useEffect(() => {
    dispatch(fetchAllProjectStatusThunk());
    fetchAllUsersHandler().then((response) => {
      var data = response.data.map((data) => {
        return {
          label: data.first_name + " " + data.last_name,
          value: data.id,
        };
      });
      setAllUsers(data);
    });
  }, []);

  function fncUserSearchedValue(val) {
    fncSearchUserData(val);
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Typography variant="h4" mb={2}>
          All Projects
        </Typography>
        <Breadcrumb />
      </Box>
      <Stack direction="row" spacing={1} alignItems="center">
        <Box>
          <UserListToolbar
            placeholder="Search projects"
            handleSearchChange={handleSearchChange}
            filterName={search}
          />
        </Box>
        {userInfo?.profile?.is_delivery_admin === true ? (
          <Box>
            {allUsers.length > 0 && (
              <SearchWithInData
                param="PROJECTMENU"
                options={allUsers}
                fncUserSearchedValue={fncUserSearchedValue}
                value={searchedUser}
              />
            )}
          </Box>
        ) : (
          ""
        )}
        <Box>
          <FormControl fullWidth>
            <Dropdown
              styles={{
                position: "relative",
                height: "40px",
                lineHeight: "1.4375em",
                fontSize: "1rem",
                fontWeight: 600,
                color: "#212B36,",
                borderRadius: "8px",
                backgroundColor: "#f3f6f8",
                maxWidth: "160px",
                margin: "1rem 1rem 0 0",
                cursor: "pointer",
              }}
              param="PROJECTMENU"
              options={projectStatusDropdown}
              icons
              inputProps={{
                handleChange: (e) => {
                  setStatus(e.target.value);
                },
              }}
              value={status}
            />
          </FormControl>
        </Box>

        <Box>
          <ToggleButtonGroup
            value={type}
            size="small"
            exclusive
            onChange={handleType}
            aria-label="view projects"
          >
            <ToggleButton value="table" aria-label="table">
              <FormatListNumberedIcon />
            </ToggleButton>
            <ToggleButton value="grid" aria-label="grid">
              <GridViewIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Stack>
    </Stack>
  );
};

export default ProjecMenu;
