import React, { useMemo, useState } from "react";
import Badge from "@mui/material/Badge";
import Table from "./Table";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import Drawer from "./Drawer";
import _ from "lodash";
import { useSelector } from "react-redux";

function ProjectTable() {
  const [showDrawer, setShowDrawer] = useState(false);
  const [timelineState, setTimelineState] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  const { timeline } = useSelector(({ project }) => project.selectedTimeline);

  const mainSectionData = useMemo(() => {
    const newState = _.cloneDeep(timeline.sections);
    const columnId = timeline.columnsIds[0];
    newState.forEach((currentSection) => {
      const rows = currentSection.rows;
      Object.keys(rows).forEach((rowId) => {
        const row = rows[rowId];
        const count = row[timeline.columnsIds[0]].comments_count;
        row[columnId].addRowExtra = {
          icon: (
            <>
              <Badge
                badgeContent={count}
                color="primary"
                sx={{ marginRight: "1rem" }}
              >
                <AddCommentOutlinedIcon
                  sx={{ color: "rgb(157 167 177 / 47%)" }}
                />
              </Badge>
            </>
          ),
          onClick: (data) => {
            setTimelineState(data);
            setShowDrawer(true);
          },
        };
      });
    });
    return newState;
  }, [timeline, isUpdate]);

  

  return (
    <div>
      <Table {...timeline} sections={mainSectionData} />
      {timelineState && (
        <Drawer
          showDrawer={showDrawer}
          setShowDrawer={() => {
            setShowDrawer(false);
            setTimelineState(null);
          }}
          sectionIdx={timelineState?.sectionIdx}
          rowIdx={timelineState?.rowIdx}
        />
      )}
    </div>
  );
}

export default ProjectTable;
