// material
import {
  Box,
  Grid,
  Container,
  Typography,
  Card,
  CardHeader,
  Stack,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// components
import Page from "../components/Page.js";
import ActiveProjectLoader from "../components/Skeletons/ActiveProjectLoader.js";
import { ActiveProject } from "../components/_dashboard/app";
import { fetchProjectMatrixThunk } from "../store/slices/dashboard/dashboard.slice.js";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchProjectMatrixThunk({
        limit: 10,
        from: "DASHBOARD",
      })
    );
  }, [dispatch]);
  const metrics = useSelector(({ dashboard }) => dashboard.main);
  const { main } = useSelector(({ dashboard }) => dashboard);
  const { userData } = useSelector(({ user }) => user);

  const email = userData.profile?.email;

  return (
    <Page title="KAM Dashboard | EMB">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Welcome to KAM Dashboard </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                border: "2px solid #E4E8EB",
                borderRadius: "26px",
                boxShadow: "none",
              }}
            >
              <Grid container>
                <Grid md={3}>
                  <img
                    src="/static/icons/total-projects.svg"
                    width="100"
                    height="100"
                    style={{
                      padding: "15px",
                    }}
                    alt=""
                  />
                </Grid>

                <Grid item md={9}>
                  <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    spacing={2}
                  >
                    <Box>
                      <Box>
                        <CardHeader
                          title="Total Project"
                          pt={2}
                          sx={{ marginLeft: "-30px" }}
                        />
                      </Box>
                      {metrics.total_projects ? (
                        <Typography
                          variant="h5"
                          sx={{
                            pl: 3,
                            color: green[800],
                            marginLeft: "-30px",
                          }}
                        >
                          {metrics.total_projects}
                        </Typography>
                      ) : (
                        <Box pt={0.2}>
                          <Typography
                            variant="h5"
                            sx={{
                              pl: 3,
                              color: green[800],
                              background: "#e5e9eb",
                              width: "100px",
                              borderRadius: "6px",
                              height: "30px",
                              marginRight: "20px",
                            }}
                          ></Typography>
                        </Box>
                      )}
                    </Box>
                    <Box>
                      {!metrics.total_projects && (
                        <img
                          src="/static/icons/lock.svg"
                          width="40"
                          height="40"
                          style={{
                            marginTop: "33px",
                          }}
                          alt=""
                        />
                      )}
                    </Box>
                    <Box sx={{ paddingTop: "40px" }}>
                      <KeyboardArrowRightIcon
                        sx={{ fontSize: "2rem!important" }}
                      />
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                border: "2px solid #E4E8EB",
                borderRadius: "26px",
                boxShadow: "none",
              }}
            >
              <Grid container>
                <Grid md={3}>
                  <img
                    src="/static/icons/accepted-leads.svg"
                    width="100"
                    height="100"
                    style={{
                      padding: "15px",
                    }}
                    alt=""
                  />
                </Grid>
                <Grid item md={9}>
                  <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    spacing={2}
                  >
                    <Box>
                      <Box>
                        <CardHeader
                          title="Active Projects"
                          pt={2}
                          sx={{ marginLeft: "-30px" }}
                        />
                      </Box>

                      <Typography
                        variant="h5"
                        sx={{
                          pl: 3,
                          color: green[800],
                          marginLeft: "-30px",
                        }}
                      >
                        {metrics.active_projects}
                      </Typography>
                    </Box>
                    <Box sx={{ paddingTop: "40px" }}>
                      <KeyboardArrowRightIcon
                        sx={{ fontSize: "2rem!important" }}
                      />
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
            {/* <TotalProjectLoader /> */}
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                border: "2px solid #E4E8EB",
                borderRadius: "26px",
                boxShadow: "none",
              }}
            >
              <Grid container>
                <Grid md={3}>
                  <img
                    src="/static/icons/total-clients.svg"
                    width="100"
                    height="100"
                    style={{
                      padding: "15px",
                    }}
                    alt=""
                  />
                </Grid>
                <Grid item md={9}>
                  <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    spacing={2}
                  >
                    <Box>
                      <Box>
                        <CardHeader
                          title="Closed Projects"
                          pt={2}
                          sx={{ marginLeft: "-30px" }}
                        />
                      </Box>

                      <Typography
                        variant="h5"
                        sx={{
                          pl: 3,
                          color: green[800],
                          marginLeft: "-30px",
                        }}
                      >
                        {metrics.closed_projects}
                      </Typography>
                    </Box>
                    <Box sx={{ paddingTop: "40px" }}>
                      <KeyboardArrowRightIcon
                        sx={{ fontSize: "2rem!important" }}
                      />
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          {/* <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                border: "2px solid #E4E8EB",
                borderRadius: "26px",
                boxShadow: "none",
              }}
            >
              <Grid container>
                <Grid md={3}>
                  <img
                    src="/static/icons/reject-leads.svg"
                    width="100"
                    height="100"
                    style={{
                      padding: "15px",
                    }}
                    alt=""
                  />
                </Grid>
                <Grid item md={9}>
                  <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    spacing={2}
                  >
                    <Box>
                      <Box>
                        <CardHeader
                          title="Draft Projects"
                          pt={2}
                          sx={{ marginLeft: "-30px" }}
                        />
                      </Box>

                      <Typography
                        variant="h5"
                        sx={{
                          pl: 3,
                          color: green[800],
                          marginLeft: "-30px",
                        }}
                      >
                        {metrics.draft_projects}
                      </Typography>
                    </Box>
                    <Box sx={{ paddingTop: "40px" }}>
                      <KeyboardArrowRightIcon
                        sx={{ fontSize: "2rem!important" }}
                      />
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </Grid> */}

          {/* <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                border: "2px solid #E4E8EB",
                borderRadius: "26px",
                boxShadow: "none",
              }}
            >
              <Grid container>
                <Grid md={3}>
                  <img
                    src="/static/icons/pending-leads.svg"
                    width="100"
                    height="100"
                    style={{
                      padding: "15px",
                    }}
                    alt=""
                  />
                </Grid>
                <Grid item md={9}>
                  <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    spacing={2}
                  >
                    <Box>
                      <Box>
                        <CardHeader
                          title="Pending Projects"
                          pt={2}
                          sx={{ marginLeft: "-30px" }}
                        />
                      </Box>

                      <Typography
                        variant="h5"
                        sx={{
                          pl: 3,
                          color: green[800],
                          marginLeft: "-30px",
                        }}
                      >
                        {metrics.pending_projects}
                      </Typography>
                    </Box>
                    <Box sx={{ paddingTop: "40px" }}>
                      <KeyboardArrowRightIcon
                        sx={{ fontSize: "2rem!important" }}
                      />
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </Grid> */}

          <Grid item xs={12} md={6} lg={8}>
            <ActiveProject />
            {/* <ActiveProjectLoader /> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
