import React, { useEffect, useMemo, useState,useCallback } from "react";
import {
  Stack,
  Card,
  Typography,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  TableHead,
  chip,
  Chip,
  Box,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import { Link, useParams } from "react-router-dom";
import Page from "../../../components/Page";
import Scrollbar from "../../../components/Scrollbar";
import Modal from "../../../components/Modal/Modal";
import { UserListToolbar } from "../../../components/_dashboard/user";
import { useDispatch } from "react-redux";
import {
  fetchAllProformaInvoiceThunk,
  createProformaInvoiceThunk,
} from "../../../store/slices/project/project.slice";
import { useSelector } from "react-redux";
import { getFormattedTime } from "../../../utils/time.util";
import SearchNotFound from "../../../components/NotFound/SearchNotFound";
import AddIcon from "@mui/icons-material/Add";
import AddPIModal from "../../../components/Modal/AddPIModal";
import { generateAndDownloadPdf } from "../../../utils/global/global";
import DownloadIcon from "@mui/icons-material/Download";
import { TablePagination } from "@mui/material";
import ApprovalModal from "../../../components/Modal/ApprovalModal";

import { fetchAllUserThunk } from "../../../store/slices/users/addUsers.slice";
import { fetchAllProformaInvoicePeginationThunk } from "../../../store/slices/project/project.slice";

import { getAllApprovalCreditNotes } from "../../../store/slices/project/project.thunk";

import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Style, WidthWideTwoTone } from "@mui/icons-material";
import { debounce } from "lodash";
export default function Approval() {
  const columns = [
    { id: "amount", label: "Amount(Exclusive GST)", alignRight: false },
    { id: "amount", label: "Amount(Inclusive GST)", alignRight: false },
    { id: "approved", label: "Approved ", alignRight: false },
    { id: "cn_dn_files",label:"CN/DN files", alignRight: false},
    { id: "approved_reason", label: "Approved Remarks ", alignRight: false },
    { id: "created_at", label: "Created At", alignRight: false },
    { id: "created_by", label: "Created By", alignRight: false },
    { id: "invoice_number", label: "Invoice Number", alignRight: false },
    { id: "reason", label: "Reason", alignRight: false },
    { id: "refund_type", label: "Refund Type", alignRight: false },
    { id: "updated_at", label: "Updated At", alignRight: false },
    { id: "updated_by", label: "Updated By", alignRight: false },
    
    // { id: "Action", label: "Action", alignRight: false },
  ];

  const [showModal, setShowModal] = useState(false);
  // const proformaInvoice = useSelector(({ project }) => project.proformaInvoice);
  const { approvalData, totalApprovalData,show_approve_button } = useSelector(
    ({ project }) => project
  );

  // console.log("totalApprovalData", approvalData);

  // const projectId = financeRefundData?.all_refunds[0]?.project_id;

  // const project_id = financeRefundData?.all_refunds[0].project_id;

  // console.log("PI Milstone Table", proformaInvoice);
  const dispatch = useDispatch();
  const params = useParams();
  const [selectedModal, setSelectedModal] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const projectId = params.id;

  const handleOpen = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  // useEffect(() => {
  //   dispatch(getAllApprovalCreditNotesThunk(params.id));
  // }, [dispatch, params.id]);

  const url = window.location.search;
  const searchParams = new URLSearchParams(url);
  const [search, setSearch] = useState(
    searchParams.has("search_query") ? searchParams.get("search_query") : ""
  );

  //debonce 
  // const debounce = (func) => {
  //   let timer;
  //   return function (...args) {
  //     const context = this;
  //     if (timer) clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       timer = null;
  //       func.apply(context, args);
  //     }, 500);
  //   };
  // };

  const  handleSearchChange = debounce((e) =>{
    const value = e.target.value;
    setSearch(value);
  },500)
  // useEffect(() =>{
  //   console.log("sertch2",search);
  //   dispatch(
  //     fetchAllProformaInvoicePeginationThunk({
  //       page: 1,
  //       projectId,
  //       rowsPerPage,
  //       term:search,
  //     })
  //   );
  // },[search])

  // const optimizedFn = useCallback(debounce(handleSearchChange), []);

  // ------------------------------------ Pagination on table ----------------------

  const [page, setPage] = useState(0);
  
  const [rowsPerPage] = useState(10);
  // useEffect(() => {
  //   dispatch(
  //     fetchAllProformaInvoicePeginationThunk({
  //       page: 1,
  //       projectId,
  //       rowsPerPage,
  //       term:search,
  //     })
  //   );
  // }, []);

  useEffect(() => {
    if(search == "") {
    
    dispatch(
      fetchAllProformaInvoicePeginationThunk({
        page: 1,
        projectId,
        rowsPerPage,
        term:search,
      })
    )}else {
      
      dispatch(
        fetchAllProformaInvoicePeginationThunk({
          // page: parseInt(searchParams.get("page")),
          page:1,
          projectId,
          rowsPerPage,
          term:search,
        })
      )
      setPage(0);
    };
  }, [search]);


  const handleChangePage = (_, newPage) => {
   
    dispatch(
      fetchAllProformaInvoicePeginationThunk({
        page: newPage + 1,
        projectId,
        rowsPerPage,
        term:search,
      })
    );
    setPage(newPage);
 
  };


  // ------------------------------------------- ends ------------------

  return (
    <>
      <Page title="Proforma Invoice | EMB">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={2}
        >
          <Typography variant="h5" gutterBottom>
            Approval
          </Typography>
          <div className="search-bar">
            <UserListToolbar 
              placeholder="Search Invoice Number"
              handleSearchChange={handleSearchChange}
              // onChange={(e) => optimizedFn(e.target.value)}
              
            />
          </div>
        </Stack>
        {approvalData.length == 0 ? (
          <SearchNotFound
            src="/static/no-found/milestones.png"
            subtitle="No PI to display."
            title=" "
          />
        ) : (
          <Card sx={{ marginBottom: "3rem" }}>
            <Scrollbar>
              <TableContainer component={Paper} sx={{ minWidth: 800 }}>
                <Table aria-label="simple table">
                  <TableHead sx={{ backgroundColor: "rgb(243, 246, 248)" }}>
                    <TableRow>
                      {columns.map((column) =>
                        column.id != "action" ? (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              top: 57,
                              minWidth: column.minWidth,
                              backgroundColor: "#f3f6f8",
                              paddingLeft: "15px",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ) : (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              backgroundColor: "#f3f6f8",
                              position: "sticky",
                              right: 0,
                            }}
                          >
                            {column.label}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody key={new Date().toISOString()}>
                    {approvalData?.map((row) => {
                      const {
                        amount,
                        approved,
                        cn_dn_files,
                        created_at,
                        created_by,
                        invoice_number,
                        reason,
                        refund_type,
                        updated_at,
                        updated_by,
                        invoice_id,
                        id,
                        approval_remarks,
                        zoho_books_credit_note_or_debit_note_url,
                      } = row;

                      return (
                        <TableRow
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell align="left" style={{ minWidth: "200px" }}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              {amount ? amount : "-"}
                            </Stack>
                          </TableCell>
                          <TableCell align="left" style={{ minWidth: "200px" }}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              {amount ? (parseFloat(amount)+parseFloat((amount*0.18))).toFixed(2) : "-"}
                            </Stack>
                          </TableCell>
                          <TableCell  align="left" style={{ minWidth: "150px"}}>
                            <Chip
                              size="small"
                              variant="outlined"
                              label={
                                approved === true
                                  ? "yes"
                                  : approved === false
                                  ? "no"
                                  : show_approve_button === true ? 
                                  (
                                    <div>
                                    <IconButton color="success" component="span">
                                      <TaskAltIcon
                                        id={id}
                                        color="success"
                                        sx={{ 
                                          cursor: "pointer",
                                          padding: "0px" }}
                                        onClick={(e) => {
                                          setShowModal(true);
                                          setSelectedModal("APPROVED");
                                          setInvoiceId(e.currentTarget.id);
                                        }}
                                      />
                                    </IconButton>

                                    <IconButton color="error" component="span">
                                      <HighlightOffIcon
                                        id={id}
                                        sx={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                          setShowModal(true);
                                          setSelectedModal("REJECTED");
                                          setInvoiceId(e.currentTarget.id);
                                        }}
                                      />
                                    </IconButton>
                                    </div>
                                  ) : "-"
                              }
                              sx={{
                                padding: 1,
                                fontSize: "12px",
                                border: "none",
                                textTransform: "capitalize",
                                color:
                                  approved === true ? "#11A54A" : approved === false ? "#fe5353" : "#FFFFFF",
                                backgroundColor:
                                  // approved === true ? "#EFF8F1" : approved === false ? "#FDD8DE" : "#e3c896",
                                  approved === true ? "#EFF8F1" : approved === false ? "#FDD8DE" : "#FFFFFF"
                              }}
                            />
                            {/* {approved ? approved : "-"} */}
                          </TableCell>
                          <TableCell align="left" style={{ minWidth: "180px" }}>
                          {zoho_books_credit_note_or_debit_note_url === null ? "-" :<a href={zoho_books_credit_note_or_debit_note_url} target="_blank" rel="noopener noreferrer">link</a>}
                          </TableCell>

                          <TableCell align="left" style={{ minWidth: "180px" }}>
                            {approval_remarks ? approval_remarks : "-"}
                          </TableCell>

                          <TableCell align="left" style={{ minWidth: "150px" }}>
                            {created_at
                              ? new Date(created_at).toLocaleDateString(
                                  undefined,
                                  {
                                    timeZone: "Asia/Kolkata",
                                  }
                                )
                              : "-"}
                          </TableCell>
                          <TableCell align="left" style={{ minWidth: "150px" }}>
                            {created_by ? created_by : "-"}
                          </TableCell>
                          <TableCell align="left" style={{ minWidth: "150px" }}>
                            {invoice_number ? invoice_number : "-"}
                          </TableCell>
                          <TableCell align="left" style={{ minWidth: "150px" }}>
                            {reason ? reason : "-"}
                          </TableCell>
                          <TableCell align="left" style={{ minWidth: "150px" }}>
                            {refund_type ? refund_type : "-"}
                          </TableCell>
                          <TableCell align="left" style={{ minWidth: "150px" }}>
                            {updated_at
                              ? new Date(updated_at).toLocaleDateString(
                                  undefined,
                                  {
                                    timeZone: "Asia/Kolkata",
                                  }
                                )
                              : "-"}
                          </TableCell>
                          <TableCell align="left" style={{ minWidth: "130px" }}>
                            {updated_by ? updated_by : "-"}
                          </TableCell>

                          {/* {
                            approved === true || approved === false ? (
                              <TableCell align="left" style={{ minWidth: "150px" }}>
                                Action Taken
                              </TableCell>
                            ) : (
                              <TableCell align="left" style={{ minWidth: "150px" }}>
                            <IconButton color="success" component="span">
                              <TaskAltIcon
                                id={id}
                                color="success"
                                sx={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  setShowModal(true);
                                  setSelectedModal("APPROVED");
                                  setInvoiceId(e.currentTarget.id);
                                }}
                              />
                            </IconButton>

                            <IconButton color="error" component="span">
                              <HighlightOffIcon
                                id={id}
                                sx={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  setShowModal(true);
                                  setSelectedModal("REJECTED");
                                  setInvoiceId(e.currentTarget.id);
                                }}
                              />
                            </IconButton>
                          </TableCell>
                            )
                          } */}

                          {/* if(approved === true || approved === false) {
                            
                          }else {
                            <TableCell align="left" style={{ minWidth: "150px" }}>
                            <IconButton color="success" component="span">
                              <TaskAltIcon
                                id={id}
                                color="success"
                                sx={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  setShowModal(true);
                                  setSelectedModal("APPROVED");
                                  setInvoiceId(e.currentTarget.id);
                                }}
                              />
                            </IconButton>

                            <IconButton color="error" component="span">
                              <HighlightOffIcon
                                id={id}
                                sx={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  setShowModal(true);
                                  setSelectedModal("REJECTED");
                                  setInvoiceId(e.currentTarget.id);
                                }}
                              />
                            </IconButton>
                          </TableCell>
                          } */}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                sx={{ float: "right" }}
                count={totalApprovalData}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPageOptions={false}
              />
            </Scrollbar>
          </Card>
        )}

        <Modal
          maxWidth="md"
          isOpen={showModal}
          closeModal={handleClose}
          title="Approval Reason"
          content={
            <ApprovalModal
              closeModal={handleClose}
              clickedFrom={selectedModal}
              projectId={params.id}
              invoice_id={invoiceId}
              page={page + 1}
              rowsPerPage={rowsPerPage}
              setSearch={setSearch}
            />
          }
          dialogProps={{ fullWidth: true }}
          titleStyle={{ textAlign: "center", marginTop: 2 }}
          actionStyle={{ justifyContent: "center", marginBottom: 2 }}
        />
      </Page>
    </>
  );
}
