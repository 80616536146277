import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  inputElement: {
    paddingRight: "47px",
    display: "block",
    height: "50px",
    width: "100%",
    padding: "12px",
    fontSize: "1rem",
    fontWeight: "400",
    color: "#212529",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
    border: "1px solid #ced4da",
    webkitAppearance: "none",
    mozAppearance: "none",
    appearance: "none",
    borderRadius: "0.25rem",
    outline: " none",
    borderLeft: "5px solid rgb(69 170 242/60%)",
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
  },
  formLabel: {
    marginBottom: "0.5rem",
    display: "inline-block",
    color: "grey",
    fontWeight: "bold",
    fontSize: "0.9rem",
  },
  columnHeader: {
    position: "relative",
    verticalAlign: "bottom",
  },
});
