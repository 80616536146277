import {
  Box,
  Stack,
  Grid,
  Typography,
  FormControl,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import ProjectTable from "../../TimelineDashboard/ProjectTable";
// import Kanban from "../../TimelineDashboard/Kanban";
import Kanban from "./Kanban";
import GanttChart from "../../TimelineDashboard/Gantt";
import NewKamTable from "./NewKamTable";
import Dropdown from "../../Form/Dropdown";
import { timelineType } from "../../../utils/enum";
import { capitalCase } from "../../../utils/change-case";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { fetchProjectTimelineThunk } from "../../../store/slices/project/project.slice";
import SearchNotFound from "../../NotFound/SearchNotFound";
import { publishSection } from "../../../utils/timeline.utils";
import SendIcon from "@mui/icons-material/Send";
import { fetchSopCharterByIdThunk } from "../../../store/slices/chatter/chatter.slice";
import { DateTime } from "luxon";

import {
  showFailureToast,
  showSuccessToast,
} from "../../../store/slices/toast/toast.slice";

const timelineItems = {
  table: {
    key: "table",
    label: "Main Table",
    component: <NewKamTable />,
  },
  // gantt: {
  //   key: "gantt",
  //   label: "Gantt",
  //   component: <GanttChart />,
  // },
  kanban: {
    key: "kanban",
    label: "Kanban",
    component: <Kanban />,
  },
  // calendar: {
  //   key: "calendar",
  //   label: "Calendar",
  // },
};

function ActivityTable() {
  const [status, setStatus] = useState(timelineType.table);

  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(fetchSopCharterByIdThunk(params.id));
  }, [dispatch, params.id]);

  const { isProjectTablePublished, selectedTimeline, timelineRef } =
    useSelector(({ charter }) => charter);

  if (!isProjectTablePublished) return <SearchNotFound />;

  const id = selectedTimeline?.timeline?.id;

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Box>
            <Box
              sx={{
                backgroundColor: "#f1f4f9",
                backgroundImage:
                  "linear-gradient(270deg, #ffffff 10%, #f1f4f9 74%)",
                borderLeft: "5px solid #b8c6d3",
                padding: "5px 12px 0 12px",
              }}
            >
              <Typography variant="h5" gutterBottom>
                {capitalCase(status)}
              </Typography>
            </Box>

            <Typography
              variant="body1"
              gutterBottom
              sx={{ paddingLeft: "10px" }}
            >
              {" "}
              Charter Assigned :{timelineRef?.charter_data?.charter_title}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ paddingLeft: "10px" }}
            >
              Assigned at :{""}
              {DateTime.fromISO(
                timelineRef?.charter_data?.assigned_time
              ).toFormat("DD")}
              ,
              {DateTime.fromISO(
                timelineRef.charter_data.assigned_time
              ).toFormat("T")}
            </Typography>
          </Box>

          <Grid item md={4} display="flex" justifyContent="end">
            <FormControl>
              <Dropdown
                styles={{
                  position: "relative",
                  height: "40px",
                  lineHeight: "1.4375em",
                  fontSize: "1rem",
                  fontWeight: 600,
                  color: "#212B36,",
                  borderRadius: "8px",
                  backgroundColor: "#F3F6F8",
                  maxWidth: "150px",
                  margin: "1rem 1rem 0 0",
                  textAlign: "left",
                }}
                param="PROJECTMENU"
                options={[
                  { label: "Main Table", value: timelineType.table },
                  // { label: "Gantt", value: timelineType.gantt },
                  { label: "Kanban", value: timelineType.kanban },
                ]}
                icons
                inputProps={{
                  handleChange: (e) => setStatus(e.target.value),
                }}
                value={status}
              />
            </FormControl>
            {/* <Button
              variant="contained"
              sx={{
                borderColor: "#222f3e",
                color: "#fff",
                height: "40px",
                marginTop: "15px",
              }}
              onClick={() =>
                publishSection(
                  id,
                  () => {
                    dispatch(
                      showSuccessToast({
                        message: "Changes Published Successfully",
                        vertical: "top",
                        horizontal: "right",
                      })
                    );
                  },
                  () => {
                    dispatch(
                      showFailureToast({
                        message: "Something Went Wrong",
                        vertical: "top",
                        horizontal: "right",
                      })
                    );
                  }
                )
              }
            >
              <SendIcon sx={{ fontSize: "20px" }} /> Publish
            </Button> */}
          </Grid>
        </Stack>
      </Box>
      {timelineItems[status].component}
    </>
  );
}

export default ActivityTable;
