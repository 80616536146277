import React, { useEffect, useState } from "react";
import { Box, Grid, Button, Typography, Stack } from "@mui/material";
import Element from "../Form/Element";
import { inputType } from "../../utils/enum";
import useForm from "../../hooks/useForm";
import { useFormik } from "formik";
import { piValidation } from "../../utils/validation";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {} from "./upload.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { showFailureToast } from "../../store/slices/toast/toast.slice";
import {
  createProformaInvoiceThunk,
  fetchAllCollectionsByProjectThunk,
  validatePiAmountThunk,
} from "../../store/slices/project/project.slice";

export default function AddPIModal({ closeModal }) {
  const dispatch = useDispatch();
  //State
  const [milestoneDropdown, SetMilestoneDropdown] = useState([]);

  const milestoneData = useSelector(({ project }) => project.collections);
  const piValidate = useSelector(({ project }) => project.piValidate);

  const [piValidateError, setPiValidateError] = useState("");

  //Use effects
  const params = useParams();
  useEffect(() => {
    dispatch(fetchAllCollectionsByProjectThunk(params.id));
  }, []);

  useEffect(() => {
    if (milestoneData?.length > 0) {
      var arr = [];
      milestoneData.map((data, i) => {
        return arr.push({
          label: data?.milestone_name,
          value: data?.id,
        });
      });
      SetMilestoneDropdown(arr);
    }
  }, [milestoneData]);

  //Form State
  const INIT_STATE = {
    pi_amount: "",
    milestone: "",
    pi_remark: "",
  };

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: INIT_STATE,
      onSubmit: onSubmit,
      validationSchema: piValidation,
    });

  // const params = useParams();

  function onSubmit(data) {
    dispatch(
      createProformaInvoiceThunk({
        project_id: params.id,
        pi_amount: data.pi_amount,
        milestone_id: data.milestone,
        pi_remark: data.pi_remark,
      })
    ).then(closeModal);
    closeModal();
  }

  useEffect(() => {
    dispatch(validatePiAmountThunk({ project_id: params.id }));
  }, []);

  const customHandleChange = (data) => {
    console.log(
      "====",
      data,
      piValidate?.pi_made_exclusive_gst,
      piValidate?.total_deal_amount_exclusive_gst
    );
    if (piValidate) {
      if (
        Number(data) + Number(piValidate?.pi_made_exclusive_gst) >
        Number(piValidate?.total_deal_amount_exclusive_gst)
      ) {
        setPiValidateError("PI amount exceed, enter valid PI amount");
      } else {
        setPiValidateError("");
      }
    }
  };

  console.log("piValidateError", piValidateError);
  return (
    <>
      <Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} px={3}>
            <Grid item md={12} pb={2}>
              <Element
                eletype={inputType.select}
                label="Milestone"
                options={milestoneDropdown}
                inputProps={{
                  name: "milestone",
                  handleChange: handleChange,
                }}
                value={values.milestone}
                errorText={touched.milestone && errors.milestone}
                placeholder="Select Milestone"
                icons={
                  <ArrowDropDownIcon
                    sx={{ position: "absolute", right: 20, bottom: 15 }}
                  />
                }
              />
            </Grid>
            <Grid item md={12} pb={2}>
              <Element
                eletype={inputType.number}
                label=" PI Amount (Excl. of GST)"
                inputProps={{
                  onChange: (e) => {
                    handleChange(e);
                    customHandleChange(e.target.value);
                  },
                  name: "pi_amount",
                }}
                errorText={touched.pi_amount && errors.pi_amount}
                value={values.pi_amount}
              />
              <Box
                sx={{ fontSize: "14px", color: "red", mt: "-15px", ml: "5px" }}
              >
                {piValidateError ? piValidateError : ""}
              </Box>
            </Grid>
            <Grid item md={12} pb={2}>
              <Element
                eletype={inputType.textarea}
                label=" Remarks (to be shown at client PI PDF)"
                inputProps={{
                  onChange: handleChange,
                  name: "pi_remark",
                }}
                value={values.pi_remark}
              />
            </Grid>
            <Grid item md={12} pb={2} sx={{ textAlign: "right" }}>
              <Button
                size="large"
                sx={{ marginRight: "2rem" }}
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                disabled={piValidateError ? true : false}
                size="large"
                type="submit"
                variant="contained"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}
