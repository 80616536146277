
import React, { useState } from "react";


import {
  Box,
  Card,
  Stack,
  Typography,
  Button,
  IconButton,
  Hidden,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import "./confirmation.theme.css";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import data from "@iconify/icons-eva/plus-fill";
import ReasonBox from "./ReasonStatusModal";
import NewTakeRateForm, { TakeRateApproveReason } from "../Form/NewTakeRateForm";
import ApprovalModal from "./ApprovalModal";
import { display } from "@mui/system";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));


const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;


  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};


BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


const PoAggregatedModal = ({
  successCallback,
  closeModal,
  isOpen,
  status,
  headermessage,
  message,
  input,
  ApproveInput,
  //   getreason,
  PoApproveRejectionReason,
  PoInput,
  PoApproveInput,
  takeRateID,
  IsLastPo


}) => {
const [changeTakeRate, setChangeTakeRate] = useState(false)
const [notChangeTakeRate, setNotChangeTakeRate] = useState(false)
function controlCancelButton() {
    setChangeTakeRate(false)
    setNotChangeTakeRate(false)
}


  const dispatch = useDispatch();


  function handleReasonBoxData(data) {
    PoApproveRejectionReason(data);
  }


  function handlePoReasonBoxData(data) {
    PoApproveRejectionReason(data);
  }


  let { POApprovalData, totalapprovalData } = useSelector(
    ({ poapproval }) => poapproval
  );


  let { newPoAggregatedData } = useSelector((state) => state.poAggregated);


  const aggreGatedData = newPoAggregatedData?.data;
 
  const EmbReciAmt =  aggreGatedData?.deal_amount - aggreGatedData?.vendor_amount




  return (
    <>
      <BootstrapDialog
        onClose={closeModal}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={closeModal}
          sx={{ zIndex: "10",  }}
        />


        <DialogContent
          className=""
          sx={{
            p: "0.5rem!important",
             overflow: "scroll"
          }}
        >
          <Stack>
            <Card
              className="dialog-content-confirmation"
              sx={{
                widhthpadding: "10px",
                boxShadow: "none",
                overflow: "hidden",
                position: "relative"
              }}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ paddingLeft: "0rem", paddingRight: "20px" }}
              >
                <div
                  style={
                    PoInput || PoApproveInput
                      ? { display: "none" }
                      : { display: "block" }
                  }
                >
                  <IconButton
                    color="error"
                    component="span"
                    sx={{ backgroundColor: "rgba(255, 72, 66, 0.08)" }}
                  >
                    <HelpOutlineIcon
                      sx={{ fontSize: "2.5rem", color: "#E05353!important" }}
                    />
                  </IconButton>
                </div>
                <div style={{ overflow: "" }}>
                  <Typography
                    sx={
                      PoApproveInput
                        ? {
                            fontSize: "18px",
                            padding: "0px 0px 0px 0px",
                            fontWeight: "bold",
                            overflow: "hidden",
                            textDecoration: "underline",
                            textAlign: "center",
                          }
                        : {
                            fontSize: "18px",
                            padding: "0px 0px 0px 0",
                            fontWeight: "bold",
                            overflow: "hidden",
                          }
                    }
                  >
                    {headermessage}
                  </Typography>


                    <Typography sx={{fontWeight: "600", paddingBottom: "20px", textAlign: "center", fontSize: "20px"}}>
                    Project Information
                  </Typography>


                  <Stack
                    sx={{
                      color: "black",
                      display: "flex",
                      gap: "60px",
                      justifyContent: "center",
                      flexDirection: "row",
                      minWidth: "400px",
                      paddingBottom: "20px",
                    }}
                  >
                    <Typography>
                      <Typography sx={{ paddingBottom: "10px" }}>
                        Deal Amount: {aggreGatedData?.deal_amount}
                      </Typography>
                      <Typography sx={{ paddingBottom: "10px" }}>
                        Vendor Amount: {aggreGatedData?.vendor_amount}
                      </Typography>
                      <Typography sx={{ paddingBottom: "10px" }}>
                        Emb Receivable: {" "}
                        {EmbReciAmt ? EmbReciAmt : "-"}
                      </Typography>
                      <Typography sx={{ paddingBottom: "10px" }}>
                        Project Take Rate: {aggreGatedData?.project_take_rate ? aggreGatedData?.project_take_rate : "-"}
                      </Typography>
                    </Typography>


                    <Typography>
                      <Typography sx={{ paddingBottom: "10px" }}>
                        Total Collection:{" "}
                        {aggreGatedData?.total_collection ? aggreGatedData?.total_collection : "-"}
                      </Typography>
                      <Typography sx={{ paddingBottom: "10px" }}>
                        Total Vendor Release:{" "}
                        {aggreGatedData?.total_vendor_release ? aggreGatedData?.total_vendor_release : "-"}
                      </Typography>
                      <Typography sx={{ paddingBottom: "10px" }}>
                        Emb Realised: {aggreGatedData?.total_emb_realised ? Math.floor(aggreGatedData?.total_emb_realised * 100) / 100 : "-"}
                      </Typography>
                    </Typography>
                  </Stack>


                  <Typography sx={{fontWeight: "600", paddingBottom: "30px", textAlign: "center", fontSize: "20px"}}>
                    PO Information
                  </Typography>
                  <Stack
                     sx={{
                      color: "black",
                      display: "flex",
                      gap: "60px",
                      justifyContent: "center",
                      flexDirection: "row",
                      minWidth: "400px",
                      paddingBottom: "10px",


                    }}
                  >
                    <Typography>
                      <Typography sx={{ paddingBottom: "10px" }}>
                        PO Amount: {aggreGatedData?.po_amount}
                      </Typography>


                      <Typography sx={{ paddingBottom: "10px" }}>
                        System Take Rate: {aggreGatedData?.sys_take_rate}
                      </Typography>


                      <Typography sx={{ paddingBottom: "10px" }}>
                        Suggested Take Rate: {aggreGatedData?.suggested_take_rate ? aggreGatedData?.suggested_take_rate : "0.00"}
                      </Typography>
                    </Typography>


                    <Typography>
                      <Typography sx={{ paddingBottom: "10px" }}>
                        Updated Take Rate:{" "}
                        {aggreGatedData?.updated_take_rate ? aggreGatedData?.updated_take_rate : "0.00"}
                      </Typography>
                      <Typography sx={{ paddingBottom: "10px" }}>
                        PO EMB Realise:{" "}
                        {aggreGatedData?.po_emb_realise_amt}
                      </Typography>
                    </Typography>
                  </Stack>
             
                  <Typography sx={IsLastPo ? {display:"none"} : changeTakeRate ? {display:"none"} : notChangeTakeRate ? {display:"none"} : {display:"block"}}>
                  <Typography sx={{paddingBottom: "60px", paddingTop: "30px"}}>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Want to edit take rate ?
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "20px",
                        gap: "20px",
                       
                       
                      }}
                    >
                      <Button
                        variant="text"
                        autoFocus
                        sx={{
                          backgroundColor: "#00AB55",
                          width: "20%",
                          color: "white",
                          boxShadow:
                            "0px 5px 5px -3px rgb(255 241 240), 0px 8px 10px 1px rgb(255 241 240), 0px 3px 14px 2px rgb(255 241 240)!important",


                          "&:hover": {
                            backgroundColor: "#00AB55",
                            opacity: "0.9",
                          },
                        }}
                        onClick={()=> {
                            setChangeTakeRate(true)
                            setNotChangeTakeRate(false)    
                            }}
                      >
                        Yes
                      </Button>
                      <Button
                        variant="text"
                        autoFocus
                        sx={{
                          backgroundColor: "#f94e4e",
                          color: "white",
                          width: "20%",
                          boxShadow:
                            "0px 5px 5px -3px rgb(255 241 240), 0px 8px 10px 1px rgb(255 241 240), 0px 3px 14px 2px rgb(255 241 240)!important",


                          "&:hover": {
                            backgroundColor: "#f13232",
                            opacity: "0.9",
                          },
                        }}
                        onClick={()=> {
                            setNotChangeTakeRate(true)
                            setChangeTakeRate(false)
                            }}
                      >
                        No
                      </Button>
                    </Typography>
                    </Typography>
                  </Typography>
                 
                  <Typography className="overFLowScrollBarHiiden">


               {changeTakeRate &&
                  <Typography  sx={{ paddingBottom: "10px" }}>
                    <NewTakeRateForm
                    cancelButton={controlCancelButton}
                    projectid={takeRateID}
                    closeModal={closeModal}
                    status="manager_approval_requested"


                    />
                  </Typography>
               }
               { notChangeTakeRate &&
               
                  <Typography>
                  <TakeRateApproveReason
                  cancelButton={controlCancelButton}
                  projectid={takeRateID}
                  closeModal={closeModal}
                  headermessage="Approval Reason *"
                  status="approved"
                  updatedTakeRate={aggreGatedData?.updated_take_rate}


                  />
                  </Typography>
               }
                 
                  { IsLastPo &&
                    <Typography>
                  <TakeRateApproveReason
                  cancelButton={controlCancelButton}
                  projectid={takeRateID}
                  closeModal={closeModal}
                  headermessage="Approval Reason *"
                  status="approved"


                  />
                  </Typography>
                  }




               </Typography>
                  {/* <DialogActions sx={{ marginRight: "1rem !important" }}>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={2}
                      pb={3}
                    >
                      <Button
                        variant="text"
                        autoFocus
                        onClick={closeModal}
                        sx={{
                          color: "#9c9fa1",
                          backgroundColor: "#fff",


                          "&:hover": {
                            backgroundColor: "#fff",
                            opacity: "0.9",
                            color: "#222",
                          },
                        }}
                      >
                        Cancel
                      </Button>


                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#00AB55",
                          boxShadow:
                            "0px 5px 5px -3px rgb(255 241 240), 0px 8px 10px 1px rgb(255 241 240), 0px 3px 14px 2px rgb(255 241 240)!important",


                          "&:hover": {
                            backgroundColor: "#007B55",
                            opacity: "0.9",
                          },
                        }}
                        onClick={successCallback}
                      >
                        Submit
                      </Button>
                    </Stack>
                  </DialogActions> */}


                  {/* <Typography sx={{ color: "#9c9fa1", overflow: "hidden" }}>
                    {message + "" + status}
                  </Typography> */}
                </div>
              </Stack>
            </Card>
          </Stack>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};


export default PoAggregatedModal;






