import React, { useEffect, useState } from "react";
import {
  Typography,
  Container,
  Card,
  Box,
  Grid,
  Stack,
  Button,
} from "@mui/material";

import Avatar from "@mui/material/Avatar";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleIcon from "@mui/icons-material/AddCircle";

// components
import { green } from "@mui/material/colors";
import Page from "./../../components/Page";
import Element from "./../../components/Form/Element";
import { inputType } from "../../utils/enum";
import { questionType } from "../../utils/options";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  addQuestionThunk,
  editQuestionThunk,
  getQuestionDetailsByIdThunk,
  resetSelectedQuestion,
} from "../../store/slices/questions/question.slice";
import { useParams } from "react-router";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const INIT_STATE = {
  question: null,
  type: null,
  choices: [],
};

export default function Review() {
  const [state, setState] = useState(INIT_STATE);
  const dispatch = useDispatch();
  const params = useParams();
  const { selectedQuestion } = useSelector(({ questions }) => questions);

  const handleChange = (event) => {
    if (event.target.name === "type" && event.target.value === "radio") {
      return setState((state) => ({
        ...state,
        [event.target.name]: event.target.value,
        choices: [""],
      }));
    }

    setState((state) => ({
      ...state,
      [event.target.name]: event.target.value,
      choices: [],
    }));
  };

  useEffect(() => {
    if (params.id === "add") dispatch(resetSelectedQuestion());
  }, [params.id, dispatch]);

  useEffect(() => {
    if (!selectedQuestion && params.id !== "add") {
      dispatch(getQuestionDetailsByIdThunk(params.id));
    }
  }, [dispatch, selectedQuestion, params.id]);

  useEffect(() => {
    setState(selectedQuestion);
  }, [selectedQuestion]);

  function handleAddOption() {
    setState((state) => ({
      ...state,
      choices: [...state.choices, ""],
    }));
  }

  function handleSubmit() {
    if (!state?.question || !state?.type) return;
    if (state?.type === questionType.radio && state?.choices.length === 0)
      return;
    const data = { ...state };
    if (state.type === "ratings") {
      data.max_rating = 5;
    }

    if (params.id === "add") {
      dispatch(addQuestionThunk(data))
        .unwrap()
        .then(() => setState(INIT_STATE));
    } else {
      dispatch(
        editQuestionThunk({
          data,
          id: params.id,
        })
      );
    }
  }

  function handleOptionChange(e) {
    const idx = ("" + e.target.name).replace(/\D/g, "");
    setState((state) => {
      const newChoices = [...state.choices];
      newChoices[idx] = e.target.value;
      return {
        ...state,
        choices: newChoices,
      };
    });
  }

  return (
    <Page title="Add Reviews | KAM Dashboard">
      <Container>
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Add Review
          </Typography>
          <Breadcrumb />
        </Stack>
        <Box>
          <Grid container spacing={4} mt={5}>
            <Grid item md={4}>
              <Card sx={{ minHeight: "420px" }}>
                <Stack direction="column" spacing={2} sx={{ p: 3, pr: 0 }}>
                  <Box>
                    <Avatar
                      sx={{
                        marginTop: "10px",
                        width: 144,
                        height: 144,
                        mx: "auto",
                        borderRadius: "50%",
                        border: "1px dashed rgba(145, 158, 171, 0.32)",
                        backgroundColor: "#fff",
                      }}
                    >
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/942/942751.png"
                        width={80}
                        height={"auto"}
                        alt=""
                      />
                    </Avatar>
                  </Box>
                  <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
                    Review
                  </Typography>
                  <Typography
                    px={2}
                    sx={{ color: "#a2a5b9", textAlign: "center" }}
                  >
                    Manage Review Questions
                  </Typography>
                </Stack>
              </Card>
            </Grid>
            <Grid item md={8}>
              <Card sx={{ minHeight: "600px" }}>
                <Container>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    mt={2}
                    ml={3}
                  >
                    Add Review Questions
                  </Typography>
                </Container>
                <Box
                  sx={{
                    width: 900,
                    maxWidth: "100%",
                    display: "flex",
                    alignItems: "center",
                    "& > :not(style)": { mx: 3, my: 2 },
                  }}
                >
                  <Element
                    value={state?.question}
                    eletype={inputType.textarea}
                    label="Question"
                    inputProps={{
                      name: "question",
                      onChange: handleChange,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: 900,
                    maxWidth: "100%",
                    display: "flex",
                    alignItems: "center",
                    "& > :not(style)": { mx: 3, my: 2 },
                  }}
                >
                  <Element
                    eletype={inputType.select}
                    label="Type of Question"
                    options={questionType}
                    inputProps={{
                      name: "type",
                      handleChange: handleChange,
                    }}
                    value={state?.type}
                    placeholder="Select Question"
                    icons={
                      <ArrowDropDownIcon
                        sx={{ position: "absolute", right: 20, bottom: 24 }}
                      />
                    }
                  />
                </Box>
                {state?.choices?.map((value, idx) => {
                  return (
                    <Box
                      sx={{
                        width: 900,
                        maxWidth: "100%",
                        display: "flex",
                        alignItems: "center",
                        "& > :not(style)": { mx: 3, my: 2 },
                      }}
                    >
                      <Stack
                        fullWidth
                        spacing={2}
                        direction="row"
                        alignItems="center"
                      >
                        <Element
                          value={value}
                          eletype={inputType.input}
                          label={`Option ${idx + 1}`}
                          inputProps={{
                            name: `choices[${idx}]`,
                            onChange: handleOptionChange,
                          }}
                        />
                        {idx === state?.choices.length - 1 && (
                          <div
                            style={{ marginTop: "35px", cursor: "pointer" }}
                            onClick={handleAddOption}
                          >
                            <AddCircleIcon />
                          </div>
                        )}
                      </Stack>
                    </Box>
                  );
                })}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "right",
                    margin: "20px 0",
                  }}
                >
                  <Stack direction="row" spacing={1}>
                    <Button
                      color="secondary"
                      startIcon={<SaveIcon />}
                      variant="contained"
                      sx={{
                        background: green[500],
                        mx: 3,
                        ":hover": { background: green[300] },
                      }}
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </Stack>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
}
