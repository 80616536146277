import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAllAggregatedDataHandler } from "./aggregated.thunk";


const INIT_STATE = {
    newPoAggregatedData:[],
    isLoading: false,
  };
  
  export const fetchAggregatedThunk = createAsyncThunk(
    " fetchAllCollectionsMilestoneById",
      fetchAllAggregatedDataHandler
  );



  const aggregatedSlice = createSlice({
    name: "PoAggregatedData",
    initialState: INIT_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
          .addCase(fetchAggregatedThunk.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(fetchAggregatedThunk.fulfilled, (state, action) => {
            state.isLoading = false;
            state.newPoAggregatedData = action.payload;
          })
          .addCase(fetchAggregatedThunk.rejected, (state) => {
            state.isLoading = false;
          })
      },
    });

 export default aggregatedSlice.reducer