// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AggregateCommercials_AC__container__ipTdk {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0 16px;
}
.AggregateCommercials_AC__title__8yoiJ h4 {
  /* font: normal normal bold 26px/36px Open Sans; */
  font-size: 1.25rem;
  font-weight: bold;
  font-family: "Open Sans";
  margin-bottom: 30px;
}
.AggregateCommercials_AC__data__bfr-H {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.AggregateCommercials_AC__data--col__666yW {
  width: 50%;
}
.AggregateCommercials_AC__data--text__EECQP {
  display: flex;
}
.AggregateCommercials_AC__data--text__EECQP h6 {
  /* font: normal normal 600 18px/24px Open Sans; */
  font-size: 1rem;
  font-weight: 600;
  font-family: "Open Sans";
  margin-bottom: 10px;
}
.AggregateCommercials_AC__data--text__EECQP span {
  /* font: normal normal normal 18px/24px Open Sans; */
  font-size: 1rem;
  font-weight: normal;
  font-family: "Open Sans";
}
`, "",{"version":3,"sources":["webpack://./src/pages/Collection/AggregateCommercials.module.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,iBAAiB;EACjB,oBAAoB;EACpB,cAAc;AAChB;AACA;EACE,kDAAkD;EAClD,kBAAkB;EAClB,iBAAiB;EACjB,wBAAwB;EACxB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,UAAU;AACZ;AACA;EACE,aAAa;AACf;AACA;EACE,iDAAiD;EACjD,eAAe;EACf,gBAAgB;EAChB,wBAAwB;EACxB,mBAAmB;AACrB;AACA;EACE,oDAAoD;EACpD,eAAe;EACf,mBAAmB;EACnB,wBAAwB;AAC1B","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap\");\n\n.AC__container {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  padding-top: 20px;\n  padding-bottom: 20px;\n  margin: 0 16px;\n}\n.AC__title h4 {\n  /* font: normal normal bold 26px/36px Open Sans; */\n  font-size: 1.25rem;\n  font-weight: bold;\n  font-family: \"Open Sans\";\n  margin-bottom: 30px;\n}\n.AC__data {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.AC__data--col {\n  width: 50%;\n}\n.AC__data--text {\n  display: flex;\n}\n.AC__data--text h6 {\n  /* font: normal normal 600 18px/24px Open Sans; */\n  font-size: 1rem;\n  font-weight: 600;\n  font-family: \"Open Sans\";\n  margin-bottom: 10px;\n}\n.AC__data--text span {\n  /* font: normal normal normal 18px/24px Open Sans; */\n  font-size: 1rem;\n  font-weight: normal;\n  font-family: \"Open Sans\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AC__container": `AggregateCommercials_AC__container__ipTdk`,
	"AC__title": `AggregateCommercials_AC__title__8yoiJ`,
	"AC__data": `AggregateCommercials_AC__data__bfr-H`,
	"AC__data--col": `AggregateCommercials_AC__data--col__666yW`,
	"AC__data--text": `AggregateCommercials_AC__data--text__EECQP`
};
export default ___CSS_LOADER_EXPORT___;
