import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { Link as RouterLink } from "react-router-dom";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import SearchNotFound from "../../../components/NotFound/SearchNotFound";

// material
import {
  Box,
  Stack,
  Link,
  Card,
  Button,
  Divider,
  Typography,
  Avatar,
  CardHeader,
} from "@mui/material";
// utils
import { useDispatch, useSelector } from "react-redux";
//
import Scrollbar from "../../Scrollbar";
import { projectStatus } from "../../../utils/enum";
import { capitalCase } from "../../../utils/change-case";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { fetchAllProjectsThunk } from "../../../store/slices/project/project.slice";
import { useEffect } from "react";

NewsItem.propTypes = {
  news: PropTypes.object.isRequired,
};

function NewsItem({ news }) {
  const { project_name, description, id } = news;
  function getInitials(description) {
    return capitalCase(description.substring(0, 2));
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      sx={{ pr: 3 }}
      justifyContent="space-between"
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <Avatar
          sx={{
            bgcolor: "#eff8f1",
            color: "#00aa55",
            fontSize: ".8rem",
            fontWeight: "600",
          }}
        >
          {getInitials(project_name)}
        </Avatar>
        <Box sx={{ minWidth: 220 }}>
          <Link
            to={`/dashboard/projects/${id}/overview`}
            color="inherit"
            sx={{ textDecoration: "none" }}
            component={RouterLink}
          >
            <Typography variant="subtitle2" noWrap>
              {capitalCase(project_name)}
            </Typography>
          </Link>
          <Typography
            variant="body1"
            sx={{ color: "text.secondary", maxWidth: 550 }}
            noWrap
          >
            {description}
          </Typography>
        </Box>
      </Stack>
      <Box>
        <Link
          to={`/dashboard/projects/${id}/overview`}
          color="inherit"
          sx={{ textDecoration: "none" }}
          component={RouterLink}
        >
          <ArrowForwardIcon />
        </Link>
      </Box>
    </Stack>
  );
}

export default function ActiveProject() {
  let { projects } = useSelector(({ project }) => project);

  const { userData } = useSelector(({ user }) => user);

  const allActiveProjects = projects?.filter(
    (project) => project.project_status === projectStatus.active
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchAllProjectsThunk({
        term: "",
        type: "active",
        page: 1,
        spoc: -1,
        limit: 10,
      })
    );
  }, [dispatch]);

  return (
    <Card>
      <CardHeader
        title="Active Project"
        action={
          <Box sx={{ py: 2, float: "right", display: "inline-block" }}>
            <Button
              to="/dashboard/projects"
              size="small"
              color="inherit"
              component={RouterLink}
              endIcon={<Icon icon={arrowIosForwardFill} />}
            >
              View all
            </Button>
          </Box>
        }
      />

      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {allActiveProjects?.length === 0 ? (
            <SearchNotFound
              src="/static/no-found/project.png"
              title=""
              subtitle="No projects created yet."
            />
          ) : (
            allActiveProjects?.map((news) => (
              <NewsItem key={news.title} news={news} />
            ))
          )}
        </Stack>
      </Scrollbar>

      <Divider />
    </Card>
  );
}
