import { Box, Button, Stack } from "@mui/material";
import { DragDropContext } from "react-beautiful-dnd";

import Section from "./Section";
import AddIcon from "@mui/icons-material/Add";
import SendIcon from "@mui/icons-material/Send";
// import useSopChatter from "../../../hooks/useSopChatter";
import useSopChatter from "../../../hooks/useSopChatter ";
import { publishSection } from "../../../utils/timeline.utils";
import { useDispatch } from "react-redux";
import {
  showFailureToast,
  showSuccessToast,
} from "../../../store/slices/toast/toast.slice";
import { useState } from "react";

function Table({
  columnsIds,
  columns,
  sections: sectionsData,
  id,
  dispalyIconProperty,
}) {
  const {
    colIds,
    cols,
    sections,
    addRow,
    addNewSection,
    removeSection,
    removeRow,
    addColumn,
    removeColumn,
    updateRowData,
    updateHeaderData,
    addChoice,
    removeChoice,
    shiftRow,
    handleSectionNamChange,
  } = useSopChatter({
    columnsIds,
    columns,
    sections: sectionsData,
    id,
  });

  const dispatch = useDispatch();
  const [isRowDragging, setIsRowDragging] = useState(false);
  const [isColumnDragging, setIsColumnDragging] = useState(false);

  function handleDragEnd(data) {
    const { destination, source, draggableId } = data;
    if (!destination) return;

    shiftRow({
      fromSection: source.droppableId,
      toSection: destination.droppableId,
      rowId: draggableId,
      sourceIndex: source.index,
      destinationIndex: destination.index,
    });

    setIsRowDragging(false);
    setIsColumnDragging(false);
  }

  function handleDragStart(data) {
    const { source } = data;
    if (source.droppableId === "timeline") {
      return setIsColumnDragging(true);
    }

    setIsRowDragging(true);
  }

  return (
    <div style={{ padding: "10px 20px" }}>
      {/* <Box sx={{ marginBottom: 4 }}>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Button
            variant="outlined"
            sx={{ borderColor: "#222f3e", color: "#576574" }}
            onClick={() => addNewSection()}
          >
            <AddIcon sx={{ paddingRight: "5px" }} /> New Section
          </Button>
        </Stack>
      </Box> */}
      <div>
        <DragDropContext
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
        >
          {sections.map((data, idx) => (
            <div key={data.sectionIdx}>
              <Section
                {...data}
                columnIds={colIds}
                columns={cols}
                addRow={addRow}
                removeSection={removeSection}
                removeRow={removeRow}
                addColumn={addColumn}
                removeColumn={removeColumn}
                updateRowData={updateRowData}
                updateHeaderData={updateHeaderData}
                addChoice={addChoice}
                removeChoice={removeChoice}
                isRowDragging={isRowDragging}
                isColumnDragging={isColumnDragging}
                handleSectionNamChange={handleSectionNamChange}
                index={idx}
                dispalyIconProperty={dispalyIconProperty}
              />
            </div>
          ))}
        </DragDropContext>
      </div>
    </div>
  );
}

export default Table;
