import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addUserHandler,
  fetchAllUserHandler,
  deleteUserHandler,
  editUserHandler,
  getUserByIdHandler,
} from "./addUsers.thunk";

const initialState = {
  UserData: [],
  totalUserData: 0,
  selectedUser: {},
};

export const addUserThunk = createAsyncThunk("addUser", addUserHandler);
export const getUserByIdThunk = createAsyncThunk(
  "getUserById",
  getUserByIdHandler
);

export const editUserThunk = createAsyncThunk("editUser", editUserHandler);
export const deleteUserThunk = createAsyncThunk(
  "deleteUser",
  deleteUserHandler
);
export const fetchAllUserThunk = createAsyncThunk(
  "fetchAllUser",
  fetchAllUserHandler
);

const addUserSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addUserThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(addUserThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.UserData.push(action.payload);
        return state;
      })
      .addCase(addUserThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchAllUserThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchAllUserThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.UserData = action.payload.data;
        state.totalUserData = action.payload.total;
        return state;
      })
      .addCase(fetchAllUserThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteUserThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(deleteUserThunk.fulfilled, (state, action) => {
        // state.isLoading = false;
        // var id = action.payload;
        // var newData = state.UserData.filter((user) => user.id !== id);
        return state;
      })
      .addCase(deleteUserThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(editUserThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(editUserThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.UserData.push(action.payload);
        return state;
      })
      .addCase(editUserThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getUserByIdThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(getUserByIdThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedUser = action.payload;
        return state;
      })
      .addCase(getUserByIdThunk.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default addUserSlice.reducer;
