import React, { useState } from "react";
import DatePicker from "react-datepicker";
import ListHead from "./../common/ListHead";
import Scrollbar from "./../Scrollbar";

import {
  Grid,
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Button,
  TableFooter,
  TablePagination,
  Chip,
} from "@mui/material";

var format = require("date-format");

const TABLE_HEAD = [
  { id: "project", label: "Project Name", alignRight: false },
  { id: "services", label: "Services ", alignRight: false },
  { id: "tcv_client", label: "TCV - Client ", alignRight: false },
  {
    id: "tcv_vendor",
    label: "TCV - Vendor ",
    alignRight: false,
  },
  { id: "project_status", label: " Status", alignRight: false },
  { id: "created", label: "Created At", alignRight: false },
];
export default function GoogleCal() {
  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const handleChange = (e) => {
    setIsOpen(!isOpen);
    setStartDate(e);
  };
  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <Grid container spacing={2} mb={5}>
        <Grid item xs={8}></Grid>
        <Grid item xs={4}>
          <button
            style={{
              fontSize: "16px",
              padding: "10px",
              width: "70%",
              backgroundColor: "none",
            }}
            onClick={handleClick}
          >
            {format.asString("dd/MM/yyyy", startDate)}
          </button>
          {isOpen && (
            <div style={{ position: "absolute", zIndex: 99999 }}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={handleChange}
                inline
              />
            </div>
          )}
        </Grid>
      </Grid>

      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ListHead
                headLabel={TABLE_HEAD}
                // rowCount={projects.length}
                styles={{ backgroundColor: "rgb(243, 246, 248)" }}
              />
              <TableBody>
                <TableRow
                  //   key={id}
                  tabIndex={-1}
                  role="checkbox"
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell
                    align="left"
                    style={{ minWidth: "150px", paddingLeft: "25px" }}
                  >
                    Test
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ minWidth: "150px", paddingLeft: "25px" }}
                  >
                    Test
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ minWidth: "150px", paddingLeft: "25px" }}
                  >
                    Test
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ minWidth: "150px", paddingLeft: "25px" }}
                  >
                    Test
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ minWidth: "150px", paddingLeft: "25px" }}
                  >
                    Test
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ minWidth: "150px", paddingLeft: "25px" }}
                  >
                    Test
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </div>
  );
}
