import React, { useEffect, useMemo, useState } from "react";
import { Container, TablePagination, Paper } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import ProjectTable from "./ProjectTable";
import ProjectGrid from "./ProjectGrid";
import ProjectMenu from "./ProjectMenu";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import SearchNotFound from "./../../NotFound/SearchNotFound";
import { fetchAllProjectsThunk } from "../../../store/slices/project/project.slice";
import Page from "../../Page";
import _ from "lodash";

const useStyles = makeStyles({
  menuMarginTop: {
    marginTop: "24px",
  },
});

const typeEnum = {
  table: "table",
  grid: "grid",
};

const AllProjects = () => {
  const url = window.location.search;
  const searchParams = new URLSearchParams(url);

  const [searchParamsRouter, setSearchParamsRouter] = useSearchParams();

  const dispatch = useDispatch();
  const [type, setType] = useState(typeEnum.table);

  const [search, setSearch] = useState(
    searchParams.has("search") ? searchParams.get("search") : ""
  );
  const [status, setStatus] = useState(
    searchParams.has("status") ? searchParams.get("status") : "all"
  );
  const [searchedUser, setSearchUser] = useState(
    searchParams.has("searchedUser") ? searchParams.get("searchedUser") : -1
  );
  const [searchedUserID, setSearchUserID] = useState(
    searchParams.has("searchedUser") ? searchParams.get("searchedUser") : -1
  );
  const classes = useStyles();

  function fncQueryStringData() {
    let searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has("search")) {
      return {
        term: searchParams.get("search"),
        type: searchParams.get("status"),
        page: parseInt(searchParams.get("page")),
        spoc: parseInt(searchParams.get("searchedUser")),
      };
    }

    return {
      term: search,
      type: status,
      page: pageCount,
      spoc: searchedUserID,
    };
  }

  useEffect(() => {
    if (searchParamsRouter.get("search") == null) {
      setSearchUser("");
      setStatus("all");
      setSearch("");
      setPage(0);
      setPageCount(1);
      setSearchUserID(-1);
    }
  }, [searchParamsRouter]);

  useEffect(() => {
    var queryData = fncQueryStringData();
    dispatch(fetchAllProjectsThunk(queryData));
  }, [dispatch]);

  let { projects, totalProjects, projectLimit } = useSelector(
    ({ project }) => project
  );

  function handleSearchChange(e) {
    const value = e.target.value;
    setSearch(value);
  }

  function fncSearchUserData(data) {
    if (data?.label != "") {
      setSearchUser(data.label);
      setSearchUserID(data.value);
    } else {
      setSearchUser("");
      setSearchUserID(-1);
    }
  }

  useEffect(() => {
    setRowsPerPage(projectLimit);
  }, [projectLimit]);

  useEffect(() => {
    var isSame = true;
    var data = searchParams.get("searchedUser");
    if (data != searchedUserID) {
      isSame = false;
      setPageCount(1);
      setPage(0);
    }

    dispatch(
      fetchAllProjectsThunk({
        term: search,
        type: status,
        page: isSame ? pageCount : 1,
        spoc: searchedUserID,
      })
    );
  }, [searchedUserID]);

  // ------------------------------------ Pagination on table ----------------------

  const [page, setPage] = React.useState(
    searchParams.has("page") ? parseInt(searchParams.get("page")) - 1 : 0
  );
  const [pageCount, setPageCount] = React.useState(
    searchParams.has("page") ? parseInt(searchParams.get("page")) : 1
  );
  // const [rowsPerPage] = React.useState(projectLimit);
  const [row, setRowsPerPage] = React.useState(projectLimit);

  const handleChangePage = (_, newPage) => {
    if (search) {
      setPageCount(newPage + 1);
      setPage(newPage);
      return dispatch(
        fetchAllProjectsThunk({
          term: search,
          type: status,
          spoc: searchedUserID,
          page: newPage + 1,
        })
      );
    }
    dispatch(
      fetchAllProjectsThunk({
        term: "",
        type: status,
        spoc: searchedUserID,
        page: newPage + 1,
      })
    );
    setPageCount(newPage + 1);
    setPage(newPage);
  };

  useEffect(() => {
    _searchChange(search, status, searchedUserID);
  }, [search]);

  useEffect(() => {
    dispatch(
      fetchAllProjectsThunk({
        term: search,
        type: status,
        spoc: searchedUserID,
        page: pageCount,
      })
    );
  }, [status]);

  const _searchChange = useMemo(() => {
    return _.debounce((search, status, searchedUserID) => {
      if (!search) {
        return dispatch(
          fetchAllProjectsThunk({
            term: "",
            type: status,
            spoc: searchedUserID,
            page: pageCount,
          })
        );
      }
      setPage(0);
      setPageCount(1);
      dispatch(
        fetchAllProjectsThunk({
          term: search,
          type: status,
          spoc: searchedUserID,
          page: 1,
        })
      );
    }, 500);
  }, []);

  // ------------------------------------------- ends ------------------

  return (
    <Page title="All Projects | EMB">
      <Container>
        <ProjectMenu
          fncSearchUserData={fncSearchUserData}
          type={type}
          setType={setType}
          handleSearchChange={handleSearchChange}
          setStatus={setStatus}
          status={status}
          searchedUser={searchedUserID}
          search={search}
        />
        <div className={classes.menuMarginTop}>
          {type === typeEnum.table ? (
            projects.length === 0 ? (
              <SearchNotFound
                src="/static/no-found/project.png"
                title=""
                subtitle="No projects created yet."
              />
            ) : (
              <>
                <ProjectTable
                  search={search}
                  page={pageCount}
                  status={status}
                  searchedUser={searchedUserID}
                  searchValue={search}
                  data={projects}
                  projectStat={status}
                />
                <Paper sx={{ float: "right" }}>
                  <TablePagination
                    count={totalProjects}
                    rowsPerPage={row}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={false}
                  />
                </Paper>
              </>
            )
          ) : projects.length === 0 ? (
            <SearchNotFound />
          ) : (
            <>
              <ProjectGrid searchValue={search} projectsData={projects} />
              <Paper sx={{ float: "right" }}>
                <TablePagination
                  count={totalProjects}
                  rowsPerPage={row}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={false}
                />
              </Paper>
            </>
          )}
        </div>
      </Container>
    </Page>
  );
};
export default AllProjects;
