import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import {
//   PoRejectionHandler,
//   PoApprovedHandler
// } from "./poapproval.thunk";

import { fetchRefundApprovalHandler, refundApprovedHandler,  refundRejectionHandler} from "./refundApproval.thunk";


const initialState = {
  RefundApprovalData: [],
  totalrefundapprovalData: 0,
};


export const fetchRefundApprovalThunk = createAsyncThunk(
  "FetchefundApproval",
  fetchRefundApprovalHandler
);

export const fetchrefundRejectionThunk = createAsyncThunk(
  "PoRejection",
  refundRejectionHandler
);

export const fetchrefundApprovedThunk = createAsyncThunk(
  "PoApproved",
  refundApprovedHandler
);

const refundApprovalSlice = createSlice({
  name: "refundApproval",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    
      .addCase(fetchRefundApprovalThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchRefundApprovalThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.RefundApprovalData = action.payload.data;
        state.totalrefundapprovalData = action.payload.total;
        return state;
      })
      .addCase(fetchRefundApprovalThunk.rejected, (state) => {
        state.isLoading = false;
        
      })
  },
});

export default refundApprovalSlice.reducer;
