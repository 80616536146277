import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";

import AutocompleteChip from "../../Form/AutocompleteChip";
import {
  allocateUserToProjectHandler,
  deallocateUserFromProject,
} from "../../../utils/global/user.global";
import { getAllAssginedUsersHandler } from "../../../utils/global/global";

function UserUpdate({ projectName, allUsers }) {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getAllAssginedUsersHandler(projectName.id).then((response) => {
      setUsers(() =>
        response.data.map((user) => ({
          label: fncSetUserName(user),
          value: user.id,
        }))
      );
    });
  }, []);

  function handleUserDelete(val) {
    deallocateUserFromProject({
      project_id: projectName.id,
      assignee_id: val.value,
    }).then(() => {
      setUsers((users) => users.filter((user) => user.value !== val.value));
    });
  }

  //------------------------------GET OPTIONS CHANGED ON 8 SEP 2022 --------------------------------
  // Jira Ticket No : SD-08
  // Changed By : Vikrant Kaushik
  // Functionality Added : To make DM's name search case insensitive while assigning the project and do add surnames as well.
  //-------------------------------------------------------------------------------------------------
  // fncSetUserName(user)
  function fncSetUserName(user) {
    var firstName =
      user?.first_name == null || user?.first_name == ""
        ? ""
        : user?.first_name;
    var lastName =
      user?.last_name == null || user?.last_name == "" ? "" : user?.last_name;
    return firstName + " " + lastName;
  }

  function getOptions(search) {
    return allUsers
      .filter((user) =>
        user.first_name.toLowerCase().includes(search.toLowerCase())
      )
      .filter((user) => {
        var isAlreadySelected = false;
        users.forEach((ele) => {
          if (ele.value === user.id) isAlreadySelected = true;
        });
        return !isAlreadySelected;
      })
      .map((user) => ({
        label: fncSetUserName(user),
        value: user.id,
      }));
  }

  function handleUserChange(val) {
    if (!val) return;
    allocateUserToProjectHandler({
      project_id: projectName.id,
      assignee_id: val.value,
    });
    setUsers((data) => [...data, val]);
  }

  return (
    <>
      <Typography variant="h6">{projectName.project_name}</Typography>
      <Box sx={{ marginTop: "20px" }}>
        <AutocompleteChip
          label="Search Users"
          chipsData={users}
          deleteChip={handleUserDelete}
          getOptions={getOptions}
          isChipsVisible
          onChange={handleUserChange}
          placeholder="Search Users"
        />
      </Box>
    </>
  );
}

export default UserUpdate;
