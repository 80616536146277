import React, { useEffect, useState } from "react";
import { Box, Grid, Button, Typography, Stack } from "@mui/material";
import Element from "../Form/Element";
import { inputType } from "../../utils/enum";
import useForm from "../../hooks/useForm";
import { useFormik } from "formik";
import {
  statusChangeValidationSchema,
  statusChangeValidationSchemaClientRefund,
  statusChangeValidationSchemaVendorRefund,
} from "../../utils/validation";
import { useDispatch, useSelector } from "react-redux";
import { createProjectChangeStatusForProjectThunk } from "../../store/slices/project/project.slice";
import { useParams } from "react-router-dom";
import {} from "./upload.css";
import { showFailureToast } from "../../store/slices/toast/toast.slice";
import { ContactlessOutlined } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { refundByUserThunk } from "../../store/slices/project/project.slice";
import {
  getClientSelectorDataHandler,
  getVendorSelectorDataHandler,
} from "../../utils/global/global";

const ReasonType = [
  { label: "Sales Return", value: "Sales Return" },
  { label: "Post Sale Discount", value: "Post Sale Discount" },
  { label: "Deficiency in Services", value: "Deficiency in Services" },
  { label: "Correction in Invoice", value: "Correction in Invoice" },
  { label: "Change in POS", value: "Change in POS" },
  {
    label: "Finalization of provisional assessment",
    value: "Finalization of proviosonal",
  },
  { label: "Other", value: "Other" },
];

export default function OverviewStatusModal({
  selectedStatus,
  closeModal,
  fncReason,
  clickedFrom,
  projectId,
  vendorData,
  clientData,
}) {
  const INIT_STATE = {
    status: selectedStatus,
    refunded_amount: "",
    status_change_reason: "",
    vendor_refunded_amount: "",
    client_reason: "",
    client_refunded_amount: "",
    vendor_change_dropdown: "",
    client_change_dropdown: "",
    client_invoice_number: "",
    vendor_invoice_number: "",
    upload_cancelled_cheque: undefined,
    clickedFrom: clickedFrom,
  };

  const [isClientVisible, setIsClientVisible] = useState(false);
  const [isVendorVisible, setIsVendorVisible] = useState(false);

  const dispatch = useDispatch();
  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: INIT_STATE,
      onSubmit: onSubmit,
      validationSchema:
        clickedFrom == "VENDOR_REFUND"
          ? statusChangeValidationSchemaVendorRefund
          : clickedFrom == "CLIENTS_REFUND"
          ? statusChangeValidationSchemaClientRefund
          : statusChangeValidationSchema,
    });

  const params = useParams();

  //Use effects

  
  function onSubmit(data) {
    
    if(data.clickedFrom === "VENDOR_REFUND" || data.clickedFrom === "CLIENTS_REFUND"){
    var formdata = new FormData();
    formdata.append("project_id", params.id);
    formdata.append("invoice_number",data.clickedFrom === "VENDOR_REFUND"
               ? data.vendor_invoice_number
               : data.client_invoice_number,);
    formdata.append("reason_of_refund_other",data.clickedFrom === "VENDOR_REFUND"
                ? data.status_change_reason
                : data.client_reason,)
    formdata.append("reason_of_refund",data.clickedFrom === "VENDOR_REFUND"
                ? data.vendor_change_dropdown
                : data.client_change_dropdown,)
    formdata.append("refund_amount",data.clickedFrom === "VENDOR_REFUND"
                ? data.vendor_refunded_amount
                : data.client_refunded_amount,)
    formdata.append("refund_type",data.clickedFrom === "VENDOR_REFUND"
                ? "vendor_refund"
                : "client_refund",)
    formdata.append("upload_cancelled_cheque",data.clickedFrom === "VENDOR_REFUND"
                ? null
                : data.upload_cancelled_cheque)
    dispatch(refundByUserThunk(formdata))
    }

    if(data.clickedFrom === "PROJECT_STATUS")
    {
        var statusData ={
          "id": params.id,
          "status":selectedStatus,
          "status_change_reason":values.status_change_reason
      }
      dispatch(createProjectChangeStatusForProjectThunk(statusData))
    }
    
    closeModal();
  }
  useEffect(() => {
    fncReason(values.status_change_reason);
  }, [values.status_change_reason]);

  useEffect(() => {
    if (values.client_change_dropdown.toUpperCase() == "other".toUpperCase()) {
      setIsClientVisible(true);
      handleChange({
        target: {
          name: "client_reason",
          value: "",
        },
      });
    } else {
      setIsClientVisible(false);
    }
  }, [values.client_change_dropdown]);

  useEffect(() => {
    if (values.vendor_change_dropdown.toUpperCase() == "other".toUpperCase()) {
      setIsVendorVisible(true);
    } else {
      handleChange({
        target: {
          name: "status_change_reason",
          value: "",
        },
      });
      setIsVendorVisible(false);
    }
  }, [values.vendor_change_dropdown]);
  return (
    <>
      <Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} px={3}>
            {clickedFrom == "CLIENTS_REFUND" ? (
              <>
                <Grid item md={12} pb={2}>
                  <Element
                    eletype={inputType.number}
                    label="Refund Amount (gst exclusive)"
                    inputProps={{
                      onChange: handleChange,
                      onBlur: handleBlur,
                      
                      name: "client_refunded_amount",
                    }}
                    value={values.client_refunded_amount}
                    errorText={
                      touched.client_refunded_amount &&
                      errors.client_refunded_amount
                    }
                  />
                </Grid>
                <Grid item md={12} pb={2}>
                    <Element
                    eletype={inputType.simplefile}
                    label="Cancel Cheque"
                    inputProps={{
                      onChange: handleChange,
                      onBlur: handleBlur,
                      name: "upload_cancelled_cheque",
                    }}
                    extraArguments={{
                      upload_type: "upload_cancelled_cheque",
                    }}
                    accept=".pdf"
                    value={values.upload_cancelled_cheque}
                    errorText={touched.upload_cancelled_cheque && errors.upload_cancelled_cheque}
                  />
                </Grid>
                <Grid item md={12} pb={2}>
                  <Element
                    eletype={inputType.select}
                    label="Invoice Number"
                    options={clientData}
                    inputProps={{
                      name: "client_invoice_number",
                      handleChange: handleChange,
                    }}
                    value={values.client_invoice_number}
                    errorText={
                      touched.client_invoice_number &&
                      errors.client_invoice_number
                    }
                    placeholder="Select Invoice Number"
                    icons={
                      <ArrowDropDownIcon
                        sx={{
                          position: "absolute",
                          right: 20,
                          bottom: 14,
                        }}
                      />
                    }
                  />
                </Grid>

                <Grid item md={12} pb={2}>
                  <Element
                    eletype={inputType.select}
                    label="Reason"
                    options={ReasonType}
                    inputProps={{
                      name: "client_change_dropdown",
                      handleChange: handleChange,
                    }}
                    value={values.client_change_dropdown}
                    errorText={
                      touched.client_change_dropdown &&
                      errors.client_change_dropdown
                    }
                    placeholder="Select Reason"
                    icons={
                      <ArrowDropDownIcon
                        sx={{
                          position: "absolute",
                          right: 20,
                          bottom: 14,
                        }}
                      />
                    }
                  />
                </Grid>
                {isClientVisible && (
                  <Grid item md={12} pb={2}>
                    <Element
                      eletype={inputType.textarea}
                      label="Reason"
                      inputProps={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "client_reason",
                      }}
                      errorText={touched.client_reason && errors.client_reason}
                      value={values.client_reason}
                    />
                  </Grid>
                )}
              </>
            ) : clickedFrom == "VENDOR_REFUND" ? (
              <>
                <Grid item md={12} pb={2}>
                  <Element
                    eletype={inputType.number}
                    label="Vendor Refund Amount (gst exclusive)"
                    inputProps={{
                      onChange: handleChange,
                      onBlur: handleBlur,
                      name: "vendor_refunded_amount",
                    }}
                    value={values.vendor_refunded_amount}
                    errorText={
                      touched.vendor_refunded_amount &&
                      errors.vendor_refunded_amount
                    }
                  />
                </Grid>

                <Grid item md={12} pb={2}>
                  <Element
                    eletype={inputType.select}
                    label="Invoice Number"
                    options={vendorData}
                    inputProps={{
                      name: "vendor_invoice_number",
                      handleChange: handleChange,
                    }}
                    value={values.vendor_invoice_number}
                    errorText={
                      touched.vendor_invoice_number &&
                      errors.vendor_invoice_number
                    }
                    placeholder="Select Invoice Number"
                    icons={
                      <ArrowDropDownIcon
                        sx={{
                          position: "absolute",
                          right: 20,
                          bottom: 14,
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item md={12} pb={2}>
                  <Element
                    eletype={inputType.select}
                    label="Reason"
                    options={ReasonType}
                    inputProps={{
                      name: "vendor_change_dropdown",
                      handleChange: handleChange,
                    }}
                    value={values.vendor_change_dropdown}
                    errorText={
                      touched.vendor_change_dropdown &&
                      errors.vendor_change_dropdown
                    }
                    placeholder="Select Reason"
                    icons={
                      <ArrowDropDownIcon
                        sx={{
                          position: "absolute",
                          right: 20,
                          bottom: 14,
                        }}
                      />
                    }
                  />
                </Grid>
                {isVendorVisible && (
                  <Grid item md={12} pb={2}>
                    <Element
                      eletype={inputType.textarea}
                      label="Reason"
                      inputProps={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "status_change_reason",
                      }}
                      errorText={
                        touched.status_change_reason &&
                        errors.status_change_reason
                      }
                      value={values.status_change_reason}
                    />
                  </Grid>
                )}
              </>
            ) : (
              <>
           
              <Grid item md={12} pb={2}>
                <Element
                  eletype={inputType.textarea}
                  label="Reason"
                  inputProps={{
                    onChange: handleChange,
                    onBlur: handleBlur,
                    name: "status_change_reason",
                  }}
                  errorText={
                    touched.status_change_reason && errors.status_change_reason
                  }
                  value={values.status_change_reason}
                />
              </Grid>
              </>
            )}

            <Grid item md={12} pb={2} sx={{ textAlign: "right" }}>
              <Button
                size="large"
                sx={{ marginRight: "2rem" }}
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button size="large" type="submit" variant="contained">
                {clickedFrom == "PROJECT_STATUS" ? "Submit":"generate request"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}
