import { uploadUserProfileApi, userApi } from "../../../utils/apis.utils";
import {
  startDashboardLoader,
  stopDashboardLoader,
} from "../dashboard/dashboard.slice";
import {
  FetchRefundApprovalRequests,
  rejectrefund,


} from "../../../utils/apis.utils";
import { createQueryString } from "../../../utils/global/global";
import { showFailureToast, showSuccessToast } from "../toast/toast.slice";
import { defaultAxios } from "../../../utils/axios/default.axios";
import { fetchRefundApprovalThunk } from "./refundApproval.slice";




export const fetchRefundApprovalHandler = async (data, thunkApi) => {

  const { user } = thunkApi.extra.apiService;
  
  const query = createQueryString({
    page: data.page,
  });

  const response = await user.get(FetchRefundApprovalRequests + query +"&limit=10");
  //  console.log("full url", FetchRefundApprovalRequests + query +"&limit=2")
  //  console.log("response --->>", response)
  return {data: response.data, total: response.total}
};

export const refundRejectionHandler = async ({ id, data }, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(rejectrefund + "/"  + id,  {...data} );

    thunkApi.dispatch(stopDashboardLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    
    thunkApi.dispatch(fetchRefundApprovalThunk({
      page: 1,
    }));
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const refundApprovedHandler = async ({ id, data }, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(rejectrefund + "/" + id,  {...data} );

    thunkApi.dispatch(stopDashboardLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    
    thunkApi.dispatch(fetchRefundApprovalThunk({
      page: 1,
    }));
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};



