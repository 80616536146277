import React, { useEffect } from "react";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

import {
  Stack,
  Card,
  Typography,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  IconButton,
  Paper,
  TableHead,
  Box,
} from "@mui/material";

import Page from "../../components/Page";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  fetchAllProjectByProjectIdThunk,
  setSelectedProposal,
} from "../../store/slices/proposal/proposal.slice";
import { useNavigate, useParams } from "react-router-dom";
import SearchNotFound from "../../components/NotFound/SearchNotFound";
import { sentenceCase } from "../../utils/change-case";

export default function Proposal() {
  const proposals = useSelector(({ proposal }) => proposal.data);
 

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    dispatch(fetchAllProjectByProjectIdThunk(params.id));
  }, [dispatch, params]);

  return (
    <Page title="Proposal | EMB">
      <Container>
        <Card>
          {proposals?.length === 0 ? (
            <SearchNotFound />
          ) : (
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead sx={{ backgroundColor: "rgb(243, 246, 248)" }}>
                  <TableRow>
                    <TableCell>Project Name</TableCell>
                    <TableCell align="center">Client Name</TableCell>
                    <TableCell align="center">Client Contract</TableCell>
                    <TableCell align="center">Vendor Name</TableCell>
                    <TableCell align="center">Vendor Contract</TableCell>
                    {/* <TableCell align="center">Proposal Download</TableCell> */}
                    {/* <TableCell align="center">Action</TableCell> */}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {proposals.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {sentenceCase(row?.project?.project_name)}
                      </TableCell>
                      <TableCell align="center">
                        {sentenceCase(row?.client?.client_name)}
                      </TableCell>
                      <TableCell align="center">
                        {row?.proposals?.length === 0 ? (
                          <p>--</p>
                        ) : row?.proposals[0]?.client_contract === "" ? (
                          <p>--</p>
                        ) : (
                          <a
                            href={
                              row?.proposals &&
                              row?.proposals[0]?.client_contract
                            }
                            target="_blank"
                          >
                            <IconButton
                              color="primary"
                              size="small"
                              aria-label="add an alarm"
                            >
                              <DownloadForOfflineIcon />
                            </IconButton>
                          </a>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {sentenceCase(row?.vendor?.vendor_name)}
                      </TableCell>
                      <TableCell align="center">
                        {row?.proposals?.length === 0 ? (
                          <p>--</p>
                        ) : row?.proposals[0]?.vendor_contract === "" ? (
                          <p>--</p>
                        ) : (
                          <a
                            href={
                              row?.proposals &&
                              row?.proposals[0]?.vendor_contract
                            }
                            target="_blank"
                          >
                            <IconButton
                              color="primary"
                              size="small"
                              aria-label="add an alarm"
                            >
                              <DownloadForOfflineIcon />
                            </IconButton>
                          </a>
                        )}
                      </TableCell>
                      {/* <TableCell align="center">
                        {sentenceCase(row?.vendor?.vendor_name)}
                      </TableCell>
                      <TableCell align="center">
                        {row?.proposals?.length === 0 ? (
                          <p>No Proposal Uploaded</p>
                        ) : (
                          <a
                            href={
                              row?.proposals &&
                              row?.proposals[0]?.vendor_contract
                            }
                            target="_blank"
                          >
                            <IconButton
                              color="primary"
                              size="small"
                              aria-label="add an alarm"
                            >
                              <DownloadForOfflineIcon />
                            </IconButton>
                          </a>
                        )}
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Card>
      </Container>
    </Page>
  );
}
