import React, { useEffect, useState } from "react";
import { Box, Grid, Button, Typography } from "@mui/material";
import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";
import useForm from "../../hooks/useForm";
import { useFormik } from "formik";
import { milestoneValidationSchema } from "../../utils/validation";
import { useDispatch, useSelector } from "react-redux";
import {
  createCollectionForProjectThunk,
  markAsInvoiceApproved,
  markAsMilestoneComplete,
  markAsVendorPayRelease,
  updateCollectionForProjectThunk,
  fetchAllCollectionsByProjectThunk,
} from "../../store/slices/project/project.slice";
import { useParams } from "react-router-dom";
import {} from "./upload.css";
import {
  makeVendorReleasedHandler,
  markInvoiceUploadedHandler,
  markMilestoneCompleteHandler,
} from "../../utils/global/global";
import { showFailureToast } from "../../store/slices/toast/toast.slice";
import { changeOutgoingDates, dateKeys } from "../../utils/timeFunctions";
import { fetchAggregateCommercialsByIdThunk } from "../../store/slices/milestoneMetrics/milestoneMetrics.slice";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Modal from "./Modal";
import MilestoneDelayedReasonModal from "./MilestoneDelayedReasonModal";

export default function MilestoneModal({ selectedData, closeModal }) {
  const MilType = [
    { label: "AMC", value: "AMC" },
    { label: "NORMAL", value: "NORMAL" },
  ];
  const [isEdit, setIsEdit] = useState(false);
  // const isEdit = !selectedData ? false : true;
  const INIT_STATE = {
    actual_start_day_of_delivery: "",
    actual_timeline: "",
    expected_start_day_of_delivery: "",
    expected_timeline: "",
    expected_emb_cut: "",
    expected_collection: "",
    expected_release: "",
    number_of_calendar_days_delayed: "",
    milestone_name: "",
    percentage_of_project: "",
    percentage_collection_amount: "",
    milestone_release_amount: "",
    timeline: "",
    expected_collection_month: "",
    expected_delivery_date: "",
    actual_collection: "",
    actual_release: "",
    actual_emb_cut: "",
    actual_delivery_date: "",
    notes: "",
    vendor_invoice: "",
    isEdit,
    account_no: "",
    type: "NORMAL",
  };

  const dispatch = useDispatch();
  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: isEdit
        ? {
            ...selectedData,
            vendor_invoice: selectedData.vendor_invoice,
            actual_delivery_date: selectedData.actual_delivery_date
              ? new Date(selectedData.actual_delivery_date)
              : "",
            expected_delivery_date: selectedData.expected_delivery_date
              ? new Date(selectedData.expected_delivery_date)
              : "",
            actual_start_day_of_delivery:
              selectedData.actual_start_day_of_delivery
                ? new Date(selectedData.actual_start_day_of_delivery)
                : "",
            expected_start_day_of_delivery:
              selectedData.expected_start_day_of_delivery
                ? new Date(selectedData.expected_start_day_of_delivery)
                : "",
          }
        : INIT_STATE,
      onSubmit: onSubmit,
      validationSchema: milestoneValidationSchema,
    });

  const params = useParams();
  const [showInvoice, setShowInvoice] = useState(0);
  const editExpectedPerm = useSelector(
    ({ user }) => user.userData.profile.milestone_update_perm
  );
  const projectData = useSelector(({ project }) => project.selectedProject);

  // const [isInvoiceApproved, setInvoiceApproved] = useState(
  //   selectedData?.invoice_approved
  // );
  // console.log("isInvoiceApproved", isInvoiceApproved);
  // const [isMilestoneCompleted, setMilestoneCompleted] = useState(
  //   selectedData?.milestone_completed
  // );
  // console.log("isMilestoneCompleted", isMilestoneCompleted);
  // const [isVendorPayReleased, setVendorPayReleased] = useState(
  //   selectedData?.vendor_payment_released
  // );
  const [vendorInvoice, setVendorInvoice] = useState(null);

  const [isInvoiceApproved, setInvoiceApproved] = useState(null);

  const [isMilestoneCompleted, setMilestoneCompleted] = useState(null);

  const [isVendorPayReleased, setVendorPayReleased] = useState(null);

  //Use effects

  useEffect(() => {
    setIsEdit(!selectedData ? false : true);

    setVendorInvoice(selectedData?.vendor_invoice);
    setInvoiceApproved(selectedData?.invoice_approved);
    setMilestoneCompleted(selectedData?.milestone_completed);
    setVendorPayReleased(selectedData?.vendor_payment_released);
  }, [selectedData]);

  const formatInputs = (value) => {
    if (!value) return value;
    if (value == parseInt(value)) return parseInt(value);

    let newVal;
    if (typeof value === "number") newVal = value.toString();
    else newVal = value;

    if (typeof newVal == "string") {
      let strArr = newVal.split(".");
      if (strArr[1].length > 2)
        newVal = parseFloat(strArr.join(".")).toFixed(2).toString();

      // console.log("strArr", strArr);
      // console.log("newVal", newVal);
      return newVal;
    }
    // console.log("value", value.toString());
    return value.toString();
  };

  const onBlurHandler = (e) => {
    // console.log("handling on blur");
    handleBlur(e);
    handleChange({
      target: {
        name: e.target.name,
        value: formatInputs(e.target.value),
      },
    });
  };

  const onBlurIntHandler = (e) => {
    handleBlur(e);
    handleChange({
      target: {
        name: e.target.name,
        value: Math.round(e.target.value),
      },
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === ".") e.preventDefault();
  };
  useEffect(() => {
    handleChange({
      target: {
        name: "expected_release",
        value: values.expected_release,
      },
    });
  }, [values.expected_release]);
  useEffect(() => {
    if (!values.expected_collection || !values.expected_release) {
      handleChange({
        target: {
          name: "expected_emb_cut",
          value: "",
        },
      });
      return;
    }
    handleChange({
      target: {
        name: "expected_emb_cut",
        value: formatInputs(
          (values.expected_collection - values.expected_release).toString()
        ),
      },
    });
  }, [values.expected_release, values.expected_collection]);

  // expected_start_day_of_delivery expected_timeline

  useEffect(() => {
    if (
      !values.expected_start_day_of_delivery ||
      !values.expected_timeline ||
      isNaN(
        values.expected_start_day_of_delivery.getDate() +
          parseInt(values.expected_timeline)
      )
    ) {
      handleChange({
        target: {
          name: "expected_delivery_date",
          value: "",
        },
      });
      return;
    }

    let startDateObj = new Date(values.expected_start_day_of_delivery);
    startDateObj.setDate(
      startDateObj.getDate() + parseInt(values.expected_timeline) + 1
    );

    handleChange({
      target: {
        name: "expected_delivery_date",
        value: startDateObj,
      },
    });
  }, [values.expected_start_day_of_delivery, values.expected_timeline]);

  useEffect(() => {
    // values.actual_timeline,
    // if (values.actual_delivery_date !== "") {
    //   dispatch(
    //     showFailureToast({
    //       message: "Actual Delivery Date is Required",
    //       vertical: "top",
    //       horizontal: "right",
    //     })
    //   );
    // }
    if (!values.actual_start_day_of_delivery || !values.actual_delivery_date) {
      handleChange({
        target: {
          name: "actual_timeline",
          value: "",
        },
      });
      return;
    }

    handleChange({
      target: {
        name: "actual_timeline",
        value: Math.ceil(
          (values.actual_delivery_date - values.actual_start_day_of_delivery) /
            (1000 * 3600 * 24)
        ),
      },
    });
  }, [values.actual_delivery_date, values.actual_start_day_of_delivery]);

  const expectationsData = useSelector(
    ({ milestonemetrics }) => milestonemetrics.ExpectationsData
  );

  useEffect(() => {
    if (
      !expectationsData.vendor_amount ||
      !values.percentage_of_project ||
      isNaN(parseInt(expectationsData.vendor_amount)) ||
      isNaN(parseInt(values.percentage_of_project))
    ) {
      handleChange({
        target: {
          name: "expected_release",
          value: "",
        },
      });
      return;
    }

    handleChange({
      target: {
        name: "expected_release",

        value:
          parseFloat(expectationsData.vendor_amount) *
          parseFloat(values.percentage_of_project / 100) *
          1.18,
      },
    });
  }, [expectationsData, values.percentage_of_project]);

  useEffect(() => {
    if (
      !expectationsData.taxable_project_amount ||
      !values.percentage_collection_amount ||
      isNaN(parseInt(expectationsData.taxable_project_amount)) ||
      isNaN(parseInt(values.percentage_collection_amount))
    ) {
      handleChange({
        target: {
          name: "expected_collection",
          value: "",
        },
      });
      return;
    }

    handleChange({
      target: {
        name: "expected_collection",

        value:
          parseFloat(expectationsData.taxable_project_amount) *
          parseFloat(values.percentage_collection_amount / 100) *
          1.18,
      },
    });
  }, [expectationsData, values.percentage_collection_amount]);

  function onSubmit(data) {
    const updatedData = {};
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        updatedData[key] = data[key];
      }
    });
    if (isEdit) {
      return dispatch(
        updateCollectionForProjectThunk({
          project_id: params.id,
          data: {
            ...changeOutgoingDates(
              {
                ...updatedData,
                actual_emb_cut: data.actual_emb_cut,
                percentage_of_project: data.percentage_of_project,
                percentage_collection_amount: data.percentage_collection_amount,
                actual_release: data.actual_release,
                actual_collection: data.actual_collection,
              },
              dateKeys
            ),
            id: selectedData.id,
          },
        })
      )
        .unwrap()
        .then(() => {
          dispatch(fetchAggregateCommercialsByIdThunk(params.id));
        })
        .then(closeModal);
    }
    dispatch(
      createCollectionForProjectThunk({
        ...changeOutgoingDates(
          { ...updatedData, percentage_of_project: data.percentage_of_project },
          dateKeys
        ),
        id: params.id,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(fetchAggregateCommercialsByIdThunk(params.id));
      })
      .then(closeModal);
  }

  // function onSubmit(data) {
  //   const updatedData = {};
  //   Object.keys(data).forEach((key) => {
  //     if (data[key]) {
  //       updatedData[key] = data[key];
  //     }
  //   });
  //   if (isEdit) {
  //     return dispatch(
  //       updateCollectionForProjectThunk({
  //         project_id: params.id,
  //         data: {
  //           ...changeOutgoingDates(updatedData, dateKeys),
  //           id: selectedData.id,
  //         },
  //       })
  //     )
  //       .unwrap()
  //       .then(() => {
  //         dispatch(fetchAggregateCommercialsByIdThunk(params.id));
  //       })
  //       .then(closeModal);
  //   }
  //   dispatch(
  //     createCollectionForProjectThunk({
  //       ...changeOutgoingDates(updatedData, dateKeys),
  //       id: params.id,
  //     })
  //   )
  //     .unwrap()
  //     .then(() => {
  //       dispatch(fetchAggregateCommercialsByIdThunk(params.id));
  //     })
  //     .then(closeModal);
  // }

  function handleInvoiceChange() {
    markInvoiceUploadedHandler(params.id, selectedData.id)
      .then(() => {
        setInvoiceApproved(true);
        dispatch(markAsInvoiceApproved(selectedData.id));
      })
      .catch((err) => {
        dispatch(
          showFailureToast({
            message: err,
            vertical: "top",
            horizontal: "right",
          })
        );
      });
  }

  //Modal Status Change Feedback
  const [showModal, setShowModal] = useState(false);
  const handleOpen = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  //end
  function dataFromChild(data) {
    setMilestoneCompleted(true);
  }

  function handleMilestoneChange() {
    let expected = new Date(values.expected_delivery_date).getTime();
    let actual = new Date(values.actual_delivery_date).getTime();
    if (values.actual_delivery_date == "") {
      dispatch(
        showFailureToast({
          message: "Actual Delivery Date is Required",
          vertical: "top",
          horizontal: "right",
        })
      );
      return;
    }
    if (values.actual_start_day_of_delivery == "") {
      dispatch(
        showFailureToast({
          message: "Actual Start Delivery Date is Required",
          vertical: "top",
          horizontal: "right",
        })
      );
      return;
    }
    if (values.account_no == null) {
      dispatch(
        showFailureToast({
          message: "Project Number is Required",
          vertical: "top",
          horizontal: "right",
        })
      );
      return;
    }

    if (actual > expected) {
      setShowModal(handleOpen);
    } else {
      markMilestoneCompleteHandler(params.id, selectedData.id, "")
        .then(() => {
          setMilestoneCompleted(true);
          dispatch(markAsMilestoneComplete(selectedData.id));
          dispatch(fetchAllCollectionsByProjectThunk(params.id));
        })
        .catch((err) => {
          dispatch(
            showFailureToast({
              message: err,
              vertical: "top",
              horizontal: "right",
            })
          );
        });
    }
  }

  function handleVendorPaymentRelease() {
    makeVendorReleasedHandler(params.id, selectedData.id)
      .then(() => {
        setVendorPayReleased(true);
        dispatch(markAsVendorPayRelease(selectedData.id));
      })
      .catch((err) => {
        dispatch(
          showFailureToast({
            message: err,
            vertical: "top",
            horizontal: "right",
          })
        );
      });
  }

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} px={3}>
            <Grid item md={12}>
              <Typography variant="h5">Milestone Info</Typography>
            </Grid>
            <Grid item md={12}>
              <Element
                eletype={inputType.input}
                label="Milestone Name"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "milestone_name",
                }}
                errorText={touched.milestone_name && errors.milestone_name}
                value={values.milestone_name}
              />
            </Grid>
            <Grid item md={4}>
              <Element
                eletype={inputType.number}
                label="% Release Amount"
                inputProps={{
                  onChange: handleChange,
                  onBlur: onBlurHandler,

                  name: "percentage_of_project",
                }}
                errorText={
                  touched.percentage_of_project && errors.percentage_of_project
                }
                value={values.percentage_of_project}
              />
            </Grid>
            <Grid item md={4}>
              <Element
                eletype={inputType.number}
                label="% Collection Amount"
                inputProps={{
                  onChange: handleChange,
                  onBlur: onBlurHandler,
                  name: "percentage_collection_amount",
                }}
                errorText={
                  touched.percentage_collection_amount &&
                  errors.percentage_collection_amount
                }
                value={values.percentage_collection_amount}
              />
            </Grid>
            <Grid item md={4}>
              <Element
                eletype={inputType.select}
                label="Milestone Type"
                options={MilType}
                inputProps={{
                  name: "type",
                  handleChange: handleChange,
                }}
                value={values.type}
                errorText={touched.type && errors.type}
                placeholder="Select Milestone Type"
                icons={
                  <ArrowDropDownIcon
                    sx={{ position: "absolute", right: 20, bottom: 15 }}
                  />
                }
              />
            </Grid>
            {/* <Grid item md={6}>
              <Element
                eletype={inputType.number}
                label="Mil. Release Amount"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "milestone_release_amount",
                }}
                errorText={
                  touched.milestone_release_amount &&
                  errors.milestone_release_amount
                }
                value={values.milestone_release_amount}
              />
            </Grid> */}
            {/* <Grid item md={6}>
              <Element
                eletype={inputType.number}
                label="No. of Calendar days delayed"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  onKeyDown: handleKeyDown,
                  step: 1,
                  name: "number_of_calendar_days_delayed",
                }}
                errorText={
                  touched.number_of_calendar_days_delayed &&
                  errors.number_of_calendar_days_delayed
                }
                value={values.number_of_calendar_days_delayed}
              />
            </Grid> */}
            {/* <Grid item md={6}>
              <Element
                eletype={inputType.number}
                label="Timeline"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "timeline",
                }}
                errorText={touched.timeline && errors.timeline}
                value={values.timeline}
              />
            </Grid> */}
            <Grid item md={12} pb={2}>
              <Element
                eletype={inputType.textarea}
                label="Notes"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "notes",
                }}
                errorText={touched.notes && errors.notes}
                value={values.notes}
              />
            </Grid>
            {vendorInvoice && isEdit && (
              <>
                <Grid item md={12}>
                  <Element
                    eletype={inputType.overviewfileinput}
                    label="Vendor Invoice"
                    inputProps={{
                      onChange: handleChange,
                      onBlur: handleBlur,
                      name: "vendor_invoice",
                    }}
                    url={`/collection/vendor_invoice_upload/${params.id}/${selectedData.id}`}
                    extraArguments={{
                      upload_type: "vendor_invoice",
                    }}
                    accept=".pdf"
                    value={vendorInvoice}
                  />
                </Grid>
                {vendorInvoice?.url && (
                  <Grid item md={4}>
                    <Element
                      eletype={inputType.switch}
                      label="Invoice Approved"
                      canToggle={false}
                      value={isInvoiceApproved ? "on" : "off"}
                      inputProps={{
                        name: "invoice_approved",
                        onChange: handleInvoiceChange,
                      }}
                    />
                  </Grid>
                )}
                {isInvoiceApproved && (
                  <Grid item md={4}>
                    <Element
                      eletype={inputType.switch}
                      label="Vendor Pay Released"
                      canToggle={false}
                      value={isVendorPayReleased ? "on" : "off"}
                      inputProps={{
                        onChange: handleVendorPaymentRelease,
                        name: "vendor_pay",
                      }}
                    />
                  </Grid>
                )}
                {isInvoiceApproved && isVendorPayReleased && (
                  <Grid item md={4}>
                    <Element
                      eletype={inputType.switch}
                      label="Milest.Completed"
                      canToggle={false}
                      value={isMilestoneCompleted ? "on" : "off"}
                      inputProps={{
                        name: "milestone_completed",
                        onChange: handleMilestoneChange,
                      }}
                    />
                  </Grid>
                )}

                <Modal
                  maxWidth="md"
                  isOpen={showModal}
                  closeModal={handleClose}
                  title="Milestone Delayed"
                  content={
                    <MilestoneDelayedReasonModal
                      closeModal={handleClose}
                      dataFromChild={dataFromChild}
                      selectedData={selectedData}
                    />
                  }
                  dialogProps={{ fullWidth: true }}
                  titleStyle={{ textAlign: "center", marginTop: 2 }}
                  actionStyle={{ justifyContent: "center", marginBottom: 2 }}
                />
              </>
            )}
            {/* //----------------------------- EXPECTED DATA --------------------------------- */}
            <Grid item md={12}>
              <Typography variant="h5">Expected Data</Typography>
            </Grid>

            <Grid item md={6}>
              <Element
                eletype={inputType.number}
                label="Expected Release (Inclusive GST)"
                inputProps={{
                  onChange: handleChange,
                  onBlur: onBlurHandler,
                  name: "expected_release",
                  disabled: true,
                }}
                errorText={touched.expected_release && errors.expected_release}
                value={values.expected_release}
              />
            </Grid>
            <Grid item md={6}>
              <Element
                eletype={inputType.number}
                label="Expected Collection (Inclusive GST)"
                inputProps={{
                  onChange: handleChange,
                  onBlur: onBlurHandler,
                  name: "expected_collection",
                  disabled: true,
                }}
                errorText={
                  touched.expected_collection && errors.expected_collection
                }
                value={values.expected_collection}
              />
            </Grid>
            <Grid item md={6}>
              <Element
                eletype={inputType.number}
                label="Expected EMB Cut"
                inputProps={{
                  onChange: handleChange,
                  onBlur: onBlurHandler,
                  name: "expected_emb_cut",
                  disabled: true,
                }}
                errorText={touched.expected_emb_cut && errors.expected_emb_cut}
                value={values.expected_emb_cut}
              />
            </Grid>
            <Grid item md={6}>
              <Element
                eletype={inputType.number}
                label="Expected Timeline"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  onKeyDown: handleKeyDown,
                  name: "expected_timeline",
                  disabled: !editExpectedPerm,
                }}
                errorText={
                  touched.expected_timeline && errors.expected_timeline
                }
                value={values.expected_timeline}
              />
            </Grid>
            <Grid item md={6}>
              <Element
                eletype={inputType.date}
                label="Exp. Start Date of Delivery"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "expected_start_day_of_delivery",
                  disabled: !editExpectedPerm,
                }}
                errorText={
                  touched.expected_start_day_of_delivery &&
                  errors.expected_start_day_of_delivery
                }
                value={values.expected_start_day_of_delivery}
              />
            </Grid>
            <Grid item md={6}>
              <Element
                eletype={inputType.date}
                label="Exp. Delivery Date"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "expected_delivery_date",
                  disabled: true,
                }}
                errorText={
                  touched.expected_delivery_date &&
                  errors.expected_delivery_date
                }
                value={values.expected_delivery_date}
              />
            </Grid>
            {/* ----------------------------------- Actual Data------------------------- */}
            <Grid item md={12}>
              <Typography variant="h5">Actual Data</Typography>
            </Grid>
            <Grid item md={6}>
              <Element
                eletype={inputType.number}
                label="Actual Release (Inclusive GST)"
                inputProps={{
                  onChange: handleChange,
                  onBlur: onBlurHandler,
                  name: "actual_release",
                  disabled: !projectData?.is_actual_coll_enable,
                }}
                errorText={touched.actual_release && errors.actual_release}
                value={values.actual_release}
              />
            </Grid>
            <Grid item md={6}>
              <Element
                eletype={inputType.number}
                label="Actual Collection (Inclusive GST)"
                inputProps={{
                  onChange: handleChange,
                  onBlur: onBlurHandler,
                  name: "actual_collection",
                  disabled: !projectData?.is_actual_coll_enable,
                }}
                errorText={
                  touched.actual_collection && errors.actual_collection
                }
                value={values.actual_collection}
              />
            </Grid>
            <Grid item md={6}>
              <Element
                eletype={inputType.number}
                label="Actual EMB Cut"
                inputProps={{
                  onChange: handleChange,
                  onBlur: onBlurHandler,
                  name: "actual_emb_cut",
                  disabled: !projectData?.is_actual_coll_enable,
                }}
                errorText={touched.actual_emb_cut && errors.actual_emb_cut}
                value={values.actual_emb_cut}
              />
            </Grid>
            <Grid item md={6}>
              <Element
                eletype={inputType.number}
                label="Actual Timeline"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  onKeyDown: handleKeyDown,
                  name: "actual_timeline",
                  disabled: true,
                }}
                errorText={touched.actual_timeline && errors.actual_timeline}
                value={values.actual_timeline}
              />
            </Grid>

            <Grid item md={6}>
              <Element
                eletype={inputType.date}
                label="Actual Start Date of Delivery"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "actual_start_day_of_delivery",
                }}
                errorText={
                  touched.actual_start_day_of_delivery &&
                  errors.actual_start_day_of_delivery
                }
                value={values.actual_start_day_of_delivery}
              />
            </Grid>
            <Grid item md={6} pb={2}>
              <Element
                eletype={inputType.date}
                label="Actual Delivery Date"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "actual_delivery_date",
                }}
                errorText={
                  touched.actual_delivery_date && errors.actual_delivery_date
                }
                value={values.actual_delivery_date}
              />
              {/* {isActual && (
                <span
                  style={{
                    color: "rgb(223, 68, 68)",
                    fontSize: "0.9rem",
                    bottom: "-10px",
                  }}
                >
                  Actual Delivery Date is Required
                </span>
              )} */}
            </Grid>
            <Grid item md={6} pb={2}>
              <Element
                eletype={inputType.input}
                label="Project No"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "account_no",
                }}
                errorText={touched.account_no && errors.account_no}
                value={values.account_no}
              />
            </Grid>
            <Grid item md={12} pb={2} sx={{ textAlign: "right" }}>
              <Button
                size="large"
                sx={{ marginRight: "2rem" }}
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                disabled={
                  selectedData?.milestone_approval_status ===
                  "requested_for_approval" || selectedData?.milestone_approval_status === "approved"
                }
                size="large"
                type="submit"
                variant="contained"
              >
                Save Milestone
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}
