import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchAggregateCommercialsByIdThunk,fetchAggregateCommercialsByIdThunkOld } from "../../store/slices/milestoneMetrics/milestoneMetrics.slice";
import { commercialsActualCollectionThunk } from "../../store/slices/collections/collections.slice";
import { getAggregateCommercialMetrics } from "../../utils/apis.utils";
import { userAxios } from "../../utils/axios/user.axios";
import classes from "./AggregateCommercials.module.css";
const AggregateCommercial = (props) => {
  //   const [data, setData] = useState({
  //     amount_received: "",
  //     amount_released: "",
  //     emb_net_realised: "",
  //     escrow_amount: "",
  //   });

  const dispatch = useDispatch();
  const commercialData = useSelector(
    ( state ) => state.collections
  );
  const data = useSelector(
    ({ milestonemetrics }) => milestonemetrics.AggregateCommercials
  );
   const projectData = useSelector (({ project }) => project.selectedProject);
  //   const id = useSelector(({ project }) => project.selectedProject.id);
  const { id } = useParams();


  useEffect(() => {
    if(projectData?.is_actual_coll_enable){
      dispatch(fetchAggregateCommercialsByIdThunkOld(id))
    }
    if(!projectData?.is_actual_coll_enable){
      dispatch(fetchAggregateCommercialsByIdThunk(id))
    }
    dispatch(commercialsActualCollectionThunk(id))
  }, [id,projectData]);
  
  return (
    <>
    <div className={classes["AC__container"]}>
      <div className={classes["AC__title"]}>
        <h4>Commercials ( Inclusive GST )</h4>
      </div>
      <div className={classes["AC__data"]}>
        <div className={classes["AC__data--col"]}>
          <div className={classes["AC__data--text"]}>
            <h6>EMB net realised : &nbsp;</h6>
            <span>{data.emb_net_realised}</span>
          </div>
          <div className={classes["AC__data--text"]}>
            <h6>Escrow amount : &nbsp;</h6>
            <span>{projectData?.is_actual_coll_enable ? data.escrow_amount : data.escrow_balance}</span>
          </div>
        </div>
        {/* Amount released == vendor_payment_released+data.emb_net_realised */}
        <div className={classes["AC__data--col"]}>
          <div className={classes["AC__data--text"]}>
            <h6>Amount released : &nbsp;</h6>
            <span>{data.amount_released}</span>
          </div>
          <div className={classes["AC__data--text"]}>
            <h6>Vendor payment release : &nbsp;</h6>
            <span>{projectData?.is_actual_coll_enable ? data.amount_released : data.vendor_payment_released}</span>
          </div>
        </div>
      </div>
    </div>

    <div className={classes["AC__container"]}>
      <div className={classes["AC__title"]}>
        <h4>Actual Collections</h4>
      </div>
      <div className={classes["AC__data"]}>
        <div className={classes["AC__data--col"]}>
          <div className={classes["AC__data--text"]}>
            <h6>Total actual Collections : &nbsp;</h6>
            <span>{commercialData?.newCommercialsData.total_actual_collection}</span>
          </div>
          <div className={classes["AC__data--text"]}>
            <h6>% Actual Collections : &nbsp;</h6>
            <span>{commercialData?.newCommercialsData.collection_percentage}</span>
          </div>
          <div className={classes["AC__data--text"]}>
            <h6>Actual Remaining Balance : &nbsp;</h6>
            <span>{commercialData?.newCommercialsData.balance_collection}</span>
          </div>
        </div>
        {/* Amount released == vendor_payment_released+data.emb_net_realised */}
        <div className={classes["AC__data--col"]}>
          <div className={classes["AC__data--text"]}>
            <h6>Total actual release: &nbsp;</h6>
            <span>{commercialData?.newCommercialsData.total_actual_release}
            </span>
          </div>
          <div className={classes["AC__data--text"]}>
            <h6>% Actual release : &nbsp;</h6>
            <span>{commercialData?.newCommercialsData.release_percentage}
            </span>
          </div>
          <div className={classes["AC__data--text"]}>
            <h6>Balance to release : &nbsp;</h6>
            <span>{commercialData?.newCommercialsData.balance_released}</span>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};
export default AggregateCommercial;
