import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { fetchCharterByIdThunk } from "../../store/slices/chatter/chatter.slice";
import { listViewType } from "../../utils/enum";
import Page from "../../components/Page";
import Chatter from "./Chatter";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

function ViewCharter({ title }) {
  const selectedCharter = useSelector(({ charter }) => charter.selectedCharter);
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCharterByIdThunk(params.id));
  }, [dispatch, params.id]);

  return (
    <Page title=" Project Charter Details | EMB ">
      <Container>
        <Box sx={{ marginBottom: 4 }}>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              {selectedCharter?.title}
            </Typography>
            <Breadcrumb />
          </Stack>
        </Box>
        <Chatter
          title={title}
          saveBtnText="Save Charter"
          updateData={selectedCharter}
          data={selectedCharter?.chatterData}
          type={listViewType.mainCharter}
        />
      </Container>
    </Page>
  );
}

export default ViewCharter;
