import React, { useEffect, useState } from "react";
import { Box, Grid, Button, Typography, Stack } from "@mui/material";
import Element from "../Form/Element";
import { inputType } from "../../utils/enum";
import useForm from "../../hooks/useForm";
import { useFormik } from "formik";
import { piValidation } from "../../utils/validation";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {} from "./upload.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { showFailureToast } from "../../store/slices/toast/toast.slice";
import {
  createProformaInvoiceThunk,
  fetchAllCollectionsByProjectThunk,
  fetchPOInfoThunk,
  deletePOInfoThunk,
} from "../../store/slices/project/project.slice";
import { deletePOInfo } from "../../store/slices/project/project.slice"; 
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    Paper,
    TableHead,
    chip,
    Chip,
    IconButton,
  } from "@mui/material";
  import Scrollbar from "../Scrollbar";

export default function POInfoModal({ closeModal, id, projectId }) {

    const columns = [
        { id: "milestone", label: "Milestone", alignRight: false },
        { id: "amount", label: "Amount (Incl. GST)", alignRight: false },
        { id: "createdby", label: "Created By", alignRight: false },
        { id: "createdat", label: "Created At", alignRight: false },
        
    ];

  const POInfo = useSelector(({ project }) => project.POPreview);

  const dispatch = useDispatch();
  //State
  const [milestoneDropdown, SetMilestoneDropdown] = useState([]);
  //Use effects---
  const params = useParams();
  useEffect(() => {
    dispatch(fetchPOInfoThunk({project_id:projectId, po_id:id}));

    return ()=>{
        dispatch(deletePOInfo())
    }
  }, [id]);

  return (
    <>
      <Box>
      <Card sx={{ marginBottom: "3rem" }}>
            <Scrollbar>
              <TableContainer component={Paper} sx={{ minWidth: 800 }}>
                <Table aria-label="simple table">
                  <TableHead sx={{ backgroundColor: "rgb(243, 246, 248)" }}>
                    <TableRow>
                      {columns.map((column) =>
                        column.id != "action" ? (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              top: 57,
                              minWidth: column.minWidth,
                              backgroundColor: "#f3f6f8",
                              paddingLeft: "15px",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ) : (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              backgroundColor: "#f3f6f8",
                              position: "sticky",
                              right: 0,
                            }}
                          >
                            {column.label}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody key={new Date().toISOString()}>
                    {POInfo?.map(row=>{
                        const {
                            milestone,
                            po_amount_inc_gst,
                            po_uploaded_details,

                          } = row;

                          return (
                            <TableRow
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              sx={{ cursor: "pointer" }}
                            >
                              <TableCell align="left" style={{ minWidth: "150px" }}>
                                {milestone ? milestone : "-"}
                              </TableCell>
                              <TableCell align="left" style={{ minWidth: "150px" }}>
                                {po_amount_inc_gst == null ? "-" : po_amount_inc_gst}
                              </TableCell>
                              <TableCell align="left" style={{ minWidth: "150px" }}>
                                {po_uploaded_details.created_by_email ? po_uploaded_details.created_by_email : "-"}
                              </TableCell>
                              <TableCell align="left" style={{ minWidth: "150px" }}>
                                {po_uploaded_details.created_at ? po_uploaded_details.created_at : "-"}
                              </TableCell>
                            </TableRow>
                          );
                    })
                        
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
      </Box>
    </>
  );
}
