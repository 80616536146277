import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UTCToIST } from "../../../utils/formatTime";
import { changeIncomingDates, dateKeys } from "../../../utils/timeFunctions";
import { getSectionMatrix } from "../../../utils/timeline.utils";
import {
  addCommentHandler,
  addFilesHandler,
  assignCharterToProjectHandler,
  createProjectTimelineHandler,
  fetchAllCommentsForTimelineHandler,
  fetchAllFilesForTimelineHandler,
  fetchAllProjectsHandler,
  fetchProjectCharterByProjectIdHandler,
  fetchProjectTimelineHandler,
  getProjectHandler,
  updateProjectCharterHandler,
  updateProjectTimelineHandler,
  uploadProjectAttachmentHandler,
  getReportedIssue,
  fetchAllSupportDataByTicketIdHanldler,
  createSupportTicketCommentHandler,
  fetchReviewFeedbackHandler,
  updateProjectExpirationDateHandler,
  updateProjectStatusHandler,
  getClientReportedIssue,
  fetchAllCollectionsByProjectHandler,
  createCollectionForProjectHandler,
  updateCollectionForProjectHandler,
  createNewScopeForProjectHandler,
  changeDescriptionForAProjectHandler,
  updateProjectExpectedAmountHandler,
  updateProjectStatusHandlerNew,
  deleteMilestoneHandler,
  updateVendorStatusHandler,
  fetchAllProjectStatusHandler,
  updateInternalCategoryForProjectHandler,
  updateDeliveryProjectStateForProjectHandler,
  createProjectChangeStatusForProjectHandler,
  fetchAllProjectStatusHistoryHandler,
  createProformaInvoiceHandler,
  fetchAllProformaInvoiceHandler,
  addPurchaseOrderHandler,
  fetchPurchaseOrderHandler,
  fetchAllProformaInvoicePeginationHandler,
  fetchAllCollectionHandler,
  refundByUserHandler,
  approvalReasonHandler,
  fetchPIInfoHandler,
  pushPIToZohoHandler,
  fetchPOInfoHandler,
  pushPOToZohoHandler,
  editPOHandler,
  RequestForApprovalPOHandler,

  sendClientEmailMilestoneHandler,

  fetchPOSummationHandler,
  markPosummationToVoidHandler,
  validatePiAmountHandler

  // getAllApprovalCreditNotes,
} from "./project.thunk";

var timeline = {
  sections: [],
  columnsIds: [],
};

const INIT_STATE = {
  projectPagination: {
    query_term: "",
    type: "all",
    page: 1,
    spoc: -1,
  },
  UserData: [],
  totalUserData: 0,
  purchaseOrder: [],
  projects: [],
  totalProjects: [],
  projectLimit: 50,
  selectedProject: {
    comments: {
      comments_data: [],
    },
    tickets_client: [],
    tickets_vendor: [],
  },
  selectedTab: 0,
  selectedCharter: null,
  selectedTimeline: {
    timeline: timeline,
    files: [],
    comments: [],
  },
  gantt: [],
  isLoading: false,
  tickets: [],
  reviewFeedback: [],
  timelineRef: null,
  collections: [],
  isProjectTablePublished: false,
  projectStatusHistory: [],
  proformaInvoice: [],
  financeRefundData: [],
  approvalData: [],
  totalApprovalData: 0,
  show_approve_button:null,
  collectionData:[],
  deal_amount:0,
  PIPreview:[],
  pushingPI:false,
  POPreview:[],
  pushingPO:false,
  editSuccess:false,
  poSummation:{},
  piValidate:{}
};

export const fetchAllProjectStatusThunk = createAsyncThunk(
  "fetchAllProjectStatus",
  fetchAllProjectStatusHandler
);

export const refundByUserThunk = createAsyncThunk(
  "refundByUser",
  refundByUserHandler
);

export const fetchAllProjectsThunk = createAsyncThunk(
  "fetchAllProjects",
  fetchAllProjectsHandler
);

export const fetchReviewFeedbackThunk = createAsyncThunk(
  "fetchReviewFeedback",
  fetchReviewFeedbackHandler
);

export const getProjectThunk = createAsyncThunk(
  "getProject",
  getProjectHandler
);

export const fetchProjectCharterByProjectIdThunk = createAsyncThunk(
  "fetchProjectCharterByProjectId",
  fetchProjectCharterByProjectIdHandler
);

export const fetchProjectTimelineThunk = createAsyncThunk(
  "fetchProjectTimeline",
  fetchProjectTimelineHandler
);

export const fetchAllCommentsForTimelineThunk = createAsyncThunk(
  "fetchAllCommentsForTimeline",
  fetchAllCommentsForTimelineHandler
);

export const fetchAllFilesForTimelineThunk = createAsyncThunk(
  "fetchAllFilesForTimeline",
  fetchAllFilesForTimelineHandler
);

export const addCommentThunk = createAsyncThunk(
  "addComment",
  addCommentHandler
);

export const addFilesThunk = createAsyncThunk("addFiles", addFilesHandler);
export const addPurchaseOrderThunk = createAsyncThunk(
  "addPurchaseOrder",
  addPurchaseOrderHandler
);
export const fetchPurchaseOrderThunk = createAsyncThunk(
  "fetchPurchaseOrder",
  fetchPurchaseOrderHandler
);

export const createProjectTimelineThunk = createAsyncThunk(
  "createProjectTimeline",
  createProjectTimelineHandler
);

export const updateProjectTimelineThunk = createAsyncThunk(
  "updateProjectTimeline",
  updateProjectTimelineHandler
);

export const updateProjectCharterThunk = createAsyncThunk(
  "updateProjectCharter",
  updateProjectCharterHandler
);

export const assignCharterToProjectThunk = createAsyncThunk(
  "assignCharterToProject",
  assignCharterToProjectHandler
);

export const createNewScopeForProjectThunk = createAsyncThunk(
  "createNewScopeForProject",
  createNewScopeForProjectHandler
);

export const uploadProjectAttachmentThunk = createAsyncThunk(
  "uploadProjectAttachment",
  uploadProjectAttachmentHandler
);

export const getReportedIssueThunk = createAsyncThunk(
  "getReportedIssue",
  getReportedIssue
);

export const getClientReportedIssueThunk = createAsyncThunk(
  "getClientReportedIssue",
  getClientReportedIssue
);

export const fetchAllSupportDataByTicketIdtThunk = createAsyncThunk(
  "fetchAllSupportDataByTicketId",
  fetchAllSupportDataByTicketIdHanldler
);

export const createSupportTicketCommentThunk = createAsyncThunk(
  "createSupportTicketComment",
  createSupportTicketCommentHandler
);

export const updateProjectExpirationDateThunk = createAsyncThunk(
  "updateProjectExpirationDate",
  updateProjectExpirationDateHandler
);

export const updateProjectExpectedAmountThunk = createAsyncThunk(
  "updateProjectExpectedAmount",
  updateProjectExpectedAmountHandler
);

export const updateProjectStatusThunk = createAsyncThunk(
  "updateProjectStatus",
  updateProjectStatusHandler
);
export const changeDescriptionForAProjectThunk = createAsyncThunk(
  "changeDescriptionForAProject",
  changeDescriptionForAProjectHandler
);

export const fetchAllCollectionsByProjectThunk = createAsyncThunk(
  "fetchAllCollectionsByProject",
  fetchAllCollectionsByProjectHandler
);

export const fetchPIInfoThunk = createAsyncThunk(
  "fetchPIInfo",
  fetchPIInfoHandler
);

export const pushPIToZohoThunk = createAsyncThunk(
  "pushPIToZoho",
  pushPIToZohoHandler
);

export const fetchPOInfoThunk = createAsyncThunk(
  "fetchPOInfo",
  fetchPOInfoHandler
);
export const fetchPOSummationThunk = createAsyncThunk(
  "fetchPOSummation",
  fetchPOSummationHandler
)
export const markPOSummationToVoidThunk = createAsyncThunk(
  "markPOSummationToVoid",
  markPosummationToVoidHandler
)

export const validatePiAmountThunk = createAsyncThunk(
  "validatePiAmount",
  validatePiAmountHandler
)

export const pushPOToZohoThunk = createAsyncThunk(
  "pushPOToZoho",
  pushPOToZohoHandler
);

export const RequestForApprovalPOThunk = createAsyncThunk(
  "RequestForApprovalPOHandler",
  RequestForApprovalPOHandler
);


export const editPOThunk = createAsyncThunk(
  "editPO",
  editPOHandler
);

export const createCollectionForProjectThunk = createAsyncThunk(
  "createCollectionForProject",
  createCollectionForProjectHandler
);

export const updateCollectionForProjectThunk = createAsyncThunk(
  "updateCollectionForProject",
  updateCollectionForProjectHandler
);

export const updateProjectStatusThunkNew = createAsyncThunk(
  "updateProjectStatusNew",
  updateProjectStatusHandlerNew
);

export const deleteMilestoneThunk = createAsyncThunk(
  "deleteMilestone",
  deleteMilestoneHandler
);
export const sendClientEmailMilestoneThunk = createAsyncThunk(
  "sendClientEmailMilestone",
  sendClientEmailMilestoneHandler
);


export const updateVendorStatusThunkNew = createAsyncThunk(
  "updateVendorStatus",
  updateVendorStatusHandler
);
export const updateInternalCategoryForProjectThunk = createAsyncThunk(
  "updateInternalCategoryForProject",
  updateInternalCategoryForProjectHandler
);
export const updateDeliveryProjectStateForProjectThunk = createAsyncThunk(
  "updateDeliveryProjectStateForProject",
  updateDeliveryProjectStateForProjectHandler
);

export const createProjectChangeStatusForProjectThunk = createAsyncThunk(
  "createProjectChangeStatusForProject",
  createProjectChangeStatusForProjectHandler
);

export const fetchAllProjectStatusHistoryThunk = createAsyncThunk(
  "fetchAllProjectStatusHistory",
  fetchAllProjectStatusHistoryHandler
);
export const createProformaInvoiceThunk = createAsyncThunk(
  "createProformaInvoice",
  createProformaInvoiceHandler
);
export const fetchAllProformaInvoiceThunk = createAsyncThunk(
  "fetchAllProformaInvoice",
  fetchAllProformaInvoiceHandler
);
export const approvalReasonThunk = createAsyncThunk(
  "approvalReason",
  approvalReasonHandler
);

// export const getAllApprovalCreditNotesThunk = createAsyncThunk(
//   "getAllApprovalCreditNotes",
//   getAllApprovalCreditNotes
// );

export const fetchAllProformaInvoicePeginationThunk = createAsyncThunk(
  "fetchAllUser",
  fetchAllProformaInvoicePeginationHandler
);

export const fetchAllCollectionThunk = createAsyncThunk(
  "fetchAllCollection",
  fetchAllCollectionHandler
  
);
const projectSlice = createSlice({
  name: "user",
  initialState: INIT_STATE,
  reducers: {
    deletePOInfo: (state)=>{
      state.POPreview = [];
      return state;
    },
    deletePIInfo: (state)=>{
      state.PIPreview = [];
      return state;
    },
    tabChange: (state, action) => {   
      state.selectedTab = action.payload;
      return state;
    },
    deleteProject: (state, action) => {
      var id = action.payload;
      var newProjects = state.projects.filter((project) => project.id !== id);
      state.projects = newProjects;
      return state;
    },
    setSelectedCharter: (state, action) => {
      state.selectedCharter = action.payload;
      return state;
    },
    startProjectLoader: (state) => {
      state.isLoading = true;
    },
    stopProjectLoader: (state) => {
      state.isLoading = false;
    },
    markAsInvoiceApproved: (state, action) => {
      const id = action.payload;
      const selectedCollection = state.collections.find(
        (collection) => collection.id === id
      );
      selectedCollection.invoice_approved = true;
      return state;
    },
    markAsMilestoneComplete: (state, action) => {
      const id = action.payload;
      const selectedCollection = state.collections.find(
        (collection) => collection.id === id
      );
      selectedCollection.milestone_completed = true;
      return state;
    },
    markAsVendorPayRelease: (state, action) => {
      const id = action.payload;
      const selectedCollection = state.collections.find(
        (collection) => collection.id === id
      );
      selectedCollection.vendor_payment_released = true;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder

    //------PO--------
      .addCase(pushPOToZohoThunk.pending, (state) => {
        state.isLoading = true;
        state.pushingPO = true;
        return state;
      })
      .addCase(pushPOToZohoThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.pushingPO = false;
        return state;
      })
      .addCase(pushPOToZohoThunk.rejected, (state) => {
        state.isLoading = false;
        state.pushingPO = false;
        return state;
      })
        //------PO--------
        .addCase(RequestForApprovalPOThunk.pending, (state) => {
          state.isLoading = true;
          state.pushingPO = true;
          return state;
        })
        .addCase(RequestForApprovalPOThunk.fulfilled, (state, action) => {
          state.isLoading = false;
          state.pushingPO = false;
          return state;
        })
        .addCase(RequestForApprovalPOThunk.rejected, (state) => {
          state.isLoading = false;
          state.pushingPO = false;
          return state;
        })

      

      .addCase(fetchPOInfoThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchPOInfoThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.POPreview = action.payload;
        return state;
      })
      .addCase(fetchPOInfoThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(editPOThunk.pending, (state) => {
        state.isLoading = true;
        state.editSuccess = false;
        return state;
      })
      .addCase(editPOThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.editSuccess = true;
        return state;
      })
      .addCase(editPOThunk.rejected, (state) => {
        state.isLoading = false;
        state.editSuccess = false;
        return state;
      }).addCase(fetchPOSummationThunk.pending,(state) => {
        state.isLoading = true;
        return state
      }).addCase(fetchPOSummationThunk.fulfilled, (state,action) =>{
        state.isLoading = false;
        state.poSummation = action.payload
        return state
      }).addCase(fetchPOSummationThunk.rejected, (state) => {
        state.isLoading = false;
        return state
      }).addCase(markPOSummationToVoidThunk.pending,(state) => {
        state.isLoading = true;
        return state
      }).addCase(markPOSummationToVoidThunk.fulfilled, (state,action) =>{
        state.isLoading = false;
        // state.poSummation = action.payload
        return state
      }).addCase(markPOSummationToVoidThunk.rejected, (state) => {
        state.isLoading = false;
        return state
      }).addCase(validatePiAmountThunk.pending,(state) => {
        state.isLoading = true;
        return state
      }).addCase(validatePiAmountThunk.fulfilled, (state,action) =>{
        state.isLoading = false;
        state.piValidate = action.payload
        return state
      }).addCase(validatePiAmountThunk.rejected, (state) => {
        state.isLoading = false;
        return state
      })

      //----


      //-----PI-------

      .addCase(pushPIToZohoThunk.pending, (state) => {
        state.isLoading = true;
        state.pushingPI = true;
        return state;
      })
      .addCase(pushPIToZohoThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.pushingPI = false;
        return state;
      })
      .addCase(pushPIToZohoThunk.rejected, (state) => {
        state.isLoading = false;
        state.pushingPI = false;
        return state;
      })

      .addCase(fetchPIInfoThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchPIInfoThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.PIPreview = action.payload;
        return state;
      })
      .addCase(fetchPIInfoThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })

      //----

      .addCase(fetchAllProjectsThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchAllProjectsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.projects = action.payload.data;
        state.totalProjects = action.payload.total;
        if (action.payload.from == "PROJECT") {
          state.projectLimit = action.payload.limit;
          state.projectPagination.query_term = action.payload.data_state.term;
          state.projectPagination.type = action.payload.data_state.type;
          state.projectPagination.page = action.payload.data_state.page;
          state.projectPagination.spoc = action.payload.data_state.spoc;
        } else {
          state.projectLimit = action.payload.limit;
        }
      })
      .addCase(fetchAllProjectsThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(getProjectThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(getProjectThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedProject = {
          ...state.selectedProject,
          ...action.payload,
        };
        return state;
      })
      .addCase(getProjectThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(updateProjectExpectedAmountThunk.fulfilled, (state, action) => {
        state.selectedProject.actual_project_amount =
          action.payload.actual_project_amount;
        return state;
      })
      .addCase(fetchProjectCharterByProjectIdThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(
        fetchProjectCharterByProjectIdThunk.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.selectedCharter = action.payload;
          return state;
        }
      )
      .addCase(fetchProjectCharterByProjectIdThunk.rejected, (state) => {
        state.isLoading = false;
        state.selectedCharter = null;
      })
      .addCase(changeDescriptionForAProjectThunk.fulfilled, (state, action) => {
        state.selectedProject.description = action.payload.description;
        return state;
      })
      .addCase(fetchAllCommentsForTimelineThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchAllCommentsForTimelineThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedTimeline.comments = action.payload;
        return state;
      })
      .addCase(fetchAllCommentsForTimelineThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(fetchAllFilesForTimelineThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchAllFilesForTimelineThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedTimeline.files = action.payload;
        return state;
      })
      .addCase(fetchAllFilesForTimelineThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(addCommentThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(addCommentThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedTimeline.comments.unshift(action.payload);
        return state;
      })
      .addCase(addCommentThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(addFilesThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedTimeline.files = [
          ...state.selectedTimeline.files,
          ...action.payload,
        ];
        return state;
      })
      .addCase(addFilesThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(addFilesThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })

      .addCase(addPurchaseOrderThunk.fulfilled, (state, action) => {
        state.purchaseOrder.unshift(action.payload);
        return state;
      })
      .addCase(addPurchaseOrderThunk.pending, (state) => {
        return state;
      })
      .addCase(addPurchaseOrderThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })

      .addCase(fetchPurchaseOrderThunk.fulfilled, (state, action) => {
        state.purchaseOrder = action.payload;
        return state;
      })
      .addCase(fetchPurchaseOrderThunk.pending, (state) => {
        return state;
      })
      .addCase(fetchPurchaseOrderThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(fetchProjectTimelineThunk.pending, (state) => {
        return state;
      })
      .addCase(fetchProjectTimelineThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedTimeline.timeline = getSectionMatrix(action.payload);
        state.timelineRef = action.payload;
        state.isProjectTablePublished = true;

        return state;
      })
      .addCase(fetchProjectTimelineThunk.rejected, (state) => {
        state.isLoading = false;
        state.selectedTimeline = {
          timeline: timeline,
          files: [],
          comments: [],
        };
        state.isProjectTablePublished = false;
        return state;
      })
      .addCase(createProjectTimelineThunk.pending, (state, action) => {
        state.isLoading = true;
        return state;
      })
      .addCase(createProjectTimelineThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedTimeline.timeline = action.payload;
        return state;
      })
      .addCase(createProjectTimelineThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(updateProjectTimelineThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedTimeline.timeline = action.payload;
        return state;
      })
      .addCase(updateProjectTimelineThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(updateProjectTimelineThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(updateProjectCharterThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedCharter = action.payload;
        return state;
      })
      .addCase(updateProjectCharterThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(updateProjectCharterThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(createNewScopeForProjectThunk.fulfilled, (state, action) => {
        state.selectedCharter = action.payload;
      })
      .addCase(assignCharterToProjectThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedCharter = action.payload;
        return state;
      })
      .addCase(assignCharterToProjectThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(assignCharterToProjectThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(uploadProjectAttachmentThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedProject.attachments = [
          ...state.selectedProject.attachments,
          ...action.payload,
        ];
        return state;
      })
      .addCase(uploadProjectAttachmentThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(uploadProjectAttachmentThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(getReportedIssueThunk.fulfilled, (state, action) => {
        state.selectedProject.tickets_vendor = action.payload;
        return state;
      })
      .addCase(getReportedIssueThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(getReportedIssueThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })

      .addCase(getClientReportedIssueThunk.fulfilled, (state, action) => {
        state.selectedProject.tickets_client = action.payload;
        state.isLoading = false;
        return state;
      })
      .addCase(getClientReportedIssueThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(getClientReportedIssueThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(
        fetchAllSupportDataByTicketIdtThunk.fulfilled,
        (state, action) => {
          state.selectedProject.comments = action.payload;
          state.isLoading = false;
          return state;
        }
      )
      .addCase(fetchAllSupportDataByTicketIdtThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchAllSupportDataByTicketIdtThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(createSupportTicketCommentThunk.fulfilled, (state, action) => {
        state.selectedProject.comments.comments_data.push(action.payload);
        return state;
      })
      .addCase(fetchReviewFeedbackThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchReviewFeedbackThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reviewFeedback = action.payload;
        return state;
      })
      .addCase(fetchReviewFeedbackThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(updateProjectExpirationDateThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(updateProjectExpirationDateThunk.fulfilled, (state, action) => {
        state.selectedProject = action.payload;
        state.isLoading = false;
        return state;
      })
      .addCase(updateProjectExpirationDateThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(updateProjectStatusThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(updateProjectStatusThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedProject.project_status = action.payload;
        return state;
      })
      .addCase(updateProjectStatusThunk.rejected, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchAllCollectionsByProjectThunk.fulfilled, (state, action) => {
        state.collections = changeIncomingDates(action.payload, dateKeys);
        return state;
      })
      .addCase(fetchAllCollectionsByProjectThunk.rejected, (state, action) => {
        state.collections = [];
        return state;
      })
      .addCase(createCollectionForProjectThunk.fulfilled, (state, action) => {
        state.collections.push(
          changeIncomingDates([action.payload], dateKeys)[0]
        );
      })
      .addCase(updateCollectionForProjectThunk.fulfilled, (state, action) => {
        var idx = state.collections.findIndex(
          (ele) => ele.id === action.payload.id
        );
        state.collections[idx] = changeIncomingDates(
          [action.payload],
          dateKeys
        )[0];
        return state;
      })
      .addCase(updateProjectStatusThunkNew.fulfilled, (state, action) => {
        var id = action.payload.id;
        var indx = state.selectedProject.tickets_client.findIndex(
          (e) => e.id === id
        );
        state.selectedProject.tickets_client[indx] = action.payload;
        state.isLoading = false;
        return state;
      })
      .addCase(updateProjectStatusThunkNew.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(updateProjectStatusThunkNew.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(updateVendorStatusThunkNew.fulfilled, (state, action) => {
        var id = action.payload.id;
        var indx = state.selectedProject.tickets_vendor.findIndex(
          (e) => e.id === id
        );
        state.selectedProject.tickets_vendor[indx] = action.payload;
        state.isLoading = false;
        return state;
      })
      .addCase(updateVendorStatusThunkNew.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(updateVendorStatusThunkNew.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(deleteMilestoneThunk.fulfilled, (state, action) => {
        var id = action.payload;
        var newData = state.collections.filter((data) => data.id !== id);
        state.collections = newData;
        state.isLoading = false;
        return state;
      })
      .addCase(deleteMilestoneThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(deleteMilestoneThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(sendClientEmailMilestoneThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        return state;
      })
      .addCase(sendClientEmailMilestoneThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(sendClientEmailMilestoneThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(fetchAllProjectStatusThunk.fulfilled, (state, action) => {
        state.projectStatus = action.payload;
        state.isLoading = false;
        return state;
      })
      .addCase(fetchAllProjectStatusThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchAllProjectStatusThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(
        updateInternalCategoryForProjectThunk.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.selectedProject.delivery_internal_category =
            action.payload.delivery_internal_category;
          return state;
        }
      )
      .addCase(
        createProjectChangeStatusForProjectThunk.fulfilled,
        (state, action) => {
          state.selectedProject.project_status = action.payload.project_status;
          return state;
        }
      )
      .addCase(fetchAllProjectStatusHistoryThunk.fulfilled, (state, action) => {
        state.projectStatusHistory = action.payload;
        state.isLoading = false;
        return state;
      })
      .addCase(fetchAllProjectStatusHistoryThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchAllProjectStatusHistoryThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(fetchAllProformaInvoiceThunk.fulfilled, (state, action) => {
        state.proformaInvoice = action.payload;
        state.isLoading = false;
        return state;
      })
      .addCase(fetchAllProformaInvoiceThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchAllProformaInvoiceThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(createProformaInvoiceThunk.fulfilled, (state, action) => {
        state.proformaInvoice.unshift(action.payload);
        return state;
      })
      .addCase(fetchAllProformaInvoicePeginationThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(
        fetchAllProformaInvoicePeginationThunk.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.approvalData = action.payload.data;
          state.totalApprovalData = action.payload.total;
          state.show_approve_button = action.payload.show_approve_button;
          return state;
        }
      )
      .addCase(fetchAllProformaInvoicePeginationThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchAllCollectionThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(
        fetchAllCollectionThunk.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.collectionData = action.payload.data;
          state.deal_amount = action.payload.deal_amount;
          return state;
        }
      )
      .addCase(fetchAllCollectionThunk.rejected, (state) => {
        state.isLoading = false;
      });

    // .addCase(getAllApprovalCreditNotesThunk.fulfilled, (state, action) => {
    //   state.financeRefundData = action.payload;
    //   state.isLoading = false;
    //   return state;
    // })
    // .addCase(getAllApprovalCreditNotesThunk.pending, (state) => {
    //   state.isLoading = true;
    //   return state;
    // })
    // .addCase(getAllApprovalCreditNotesThunk.rejected, (state) => {
    //   state.isLoading = false;
    //   return state;
    // });
  },
});

export const {
  deletePOInfo,
  deletePIInfo,
  tabChange,
  deleteProject,
  setSelectedCharter,
  startProjectLoader,
  stopProjectLoader,
  markAsInvoiceApproved,
  markAsMilestoneComplete,
  markAsVendorPayRelease,
} = projectSlice.actions;

export default projectSlice.reducer;
