export const projectApprovalStatus = {
  accepted: "Accepted",
  rejected: "Rejected",
  pending: null,
};

export const inputType = {
  input: "input",
  select: "select",
  textarea: "textarea",
  checkbox: "checkbox",
  radio: "radio",
  date: "date",
  autocomplete: "autocomplete",
  switch: "switch",
  file: "file",
  number: "number",
  checkboxgroup: "checkboxgroup",
  simplefile: "simplefile",
  overviewfileinput: "overviewfileinput",
};

export const projectServices = {
  desiging: "Desiging",
  development: "Development",
  marketing: "Marketing",
  others: "Others",
};

export const strings = {
  email_verify: "email_verify",
};

export const otpType = {
  email: "email",
  mobile: "mobile",
};

export const projectStatus = {
  all: "all",
  draft: "draft",
  pending: "pending",
  inProgess: "in_progress",
  drop: "drop",
  active: "active",
  closed: "closed",
};

export const chatterType = {
  number: "number",
  text: "text",
  dropdown: "dropdown",
  date: "date",
  file: "file",
  status: "status",
};
export const sopChatterType = {
  number: "number",
  text: "text",
  dropdown: "dropdown",
  date: "date",
  file: "file",
  status: "dropdown",
};

export const listViewType = {
  charter: "charter",
  timeline: "timeline",
  mainCharter: "mainCharter",
  sop: "sop",
};

export const timelineType = {
  table: "table",
  gantt: "gantt",
  kanban: "kanban",
};

export const roles = {
  admin: "admin",
  user: "user",
};

export const poStatus = {
  pending: "PENDING",
  requested_for_approval: "REQUESTED",
  approved: "APPROVED",
  manager_approval_requested: "MANAGER APPROVAL REQUESTED",
  approved_by_manager: "APPROVED BY MANAGER",
  rejected_by_manager: "REJECTED BY MANAGER",
  rejected: "REJECTED",
  issued: "ISSUED",
  billed: "BILLED",
  cancelled: "CANCELLED",
  finance_approval_requested: "FIN APPROVAL REQUESTED",
  approved_by_finance: "APPROVED BY FIN",
  rejected_by_finance: "REJECTED BY FIN",
};
