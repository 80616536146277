import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAllCollectionsServicesHandler,
  deleteCollectionServicesHandler,
  addCollectionsServicesHandler,
  updateCollectionsServicesHandler,
  fetchAllCollectionsMilestoneByIdHandler,
  commercialsActualCollectionHandler
} from "./collections.thunk";

const INIT_STATE = {
  collectionsdata: [],
  newCommercialsData:[],
  isLoading: false,
};

export const fetchCollectionMilestoneByIdApiThunk = createAsyncThunk(
  " fetchAllCollectionsMilestoneById",
  fetchAllCollectionsMilestoneByIdHandler
);

export const fetchAllCollectionsSericesThunk = createAsyncThunk(
  " fetchAllCollectionsServices",
  fetchAllCollectionsServicesHandler
);

export const deleteCollectionsSericesThunk = createAsyncThunk(
  "deleteCollectionsService",
  deleteCollectionServicesHandler
);

export const AddCollectionsSericesThunk = createAsyncThunk(
  "addCollectionsService",
  addCollectionsServicesHandler
);

export const updateCollectionsSericesThunk = createAsyncThunk(
  "updateCollectionsService",
  updateCollectionsServicesHandler
);


export const commercialsActualCollectionThunk = createAsyncThunk(
  "commercialsActualCollection",
  commercialsActualCollectionHandler
);


const collectionsserviceSlice = createSlice({
  name: "collectionsservices",
  initialState: INIT_STATE,
  reducers: {
    startCollectionsServiceLoader: (state) => {
      state.isLoading = true;
    },
    stopCollectionsServiceLoader: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCollectionsSericesThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllCollectionsSericesThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.collectionsdata = action.payload;
      })
      .addCase(fetchAllCollectionsSericesThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase( commercialsActualCollectionThunk.pending, (state) => {
    
      state.isLoading = true;
    })
    .addCase( commercialsActualCollectionThunk.fulfilled, (state, action) => {
    
      state.isLoading = false;
      state.newCommercialsData = action.payload;
    })
    .addCase( commercialsActualCollectionThunk.rejected, (state) => {
    
      state.isLoading = false;
    });
  },
});

export const { startCollectionsServiceLoader, stopCollectionsServiceLoader } =
  collectionsserviceSlice.actions;

export default collectionsserviceSlice.reducer;
