import { Navigate, Outlet, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";

import Login from "./pages/Auth/Login";
import DashboardApp from "./pages/DashboardApp";
import DashboardAppLoader from "./components/Skeletons/DashboardAppLoader";
import User from "./pages/User";
import Users from "./pages/Users/Users";
import AddUser from "./pages/Users/AddUser";
import NotFound from "./pages/Page404";
import ProjectChatter from "./pages/ProjectChatter";
import AuthProtector from "./components/Protector/AuthProtector";
import withAuth from "./hoc/withAuth";
import Review from "./pages/Review/Review";
import AllProjects from "./components/_dashboard/projects/AllProjects";
import ProjectDetails from "./pages/ProjectDetail";
import QuestionTable from "./pages/Questions/QuestionTable";
import Proposal from "./pages/Proposal/Proposal";
import ProposalDetails from "./pages/Proposal/ProposalDetails";
import CreateNew from "./pages/ProjectChatter/CreateNew";
import ViewCharter from "./pages/ProjectChatter/ViewCharter";
import Notification from "./pages/Notification/Notification";
import ChatTicket from "./pages/ProjectDetail/ChatTicket";
import PoApproval from "./pages/PoApproval/PoApproval";
import RefundApproval from "./pages/refundApproval/RefundApproval";

import TakeRateApproval from "./pages/TakeRateApproval/TakeRateApproval";
import TakeRateApprovalIndex from "./pages/TakeRateApproval/index";

import FinanceApproval from "./pages/FinanceApproval/FinanceApproval";

import {
  clientSupport,
  commercialsPath,
  feedbackPath,
  overviewPath,
  proposalPath,
  scopePath,
  tablePath,
  timeline,
  vendorSupport,
  newTable,
  proformaInvoice,
  poTable,
  approval,
  collection,
  refundStatus,
} from "./utils/constant";
import Charter from "./components/ProjectDetails/Charter";
import TimelineDashboard from "./components/TimelineDashboard";
import OverView from "./pages/ProjectDetail/Overview/OverView";
import Timeline from "./pages/ProjectDetail/Timeline/Timeline";
import TicketClient from "./components/ProjectDetails/TicketClient";
import Feedback from "./pages/ProjectDetail/Feedback";
import Collections from "./pages/Collection/Collections";
import Ticket from "./components/ProjectDetails/Ticket";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import NewPassword from "./pages/Auth/NewPassword";
import GoogleCal from "./components/googlecal/GoogleCal";
import { useSelector } from "react-redux";
import NewKamTable from "./components/ProjectDetails/SopTable/NewKamTable";
// import ActivityTable from "./components/TimelineDashboard/ActivityTable";
import ActivityTable from "./components/ProjectDetails/SopTable/ActivityTable";
import ProformaInvoice from "./pages/ProjectDetail/ProformaInvoice/ProformaInvoice";
import PurchaseOrder from "./components/ProjectDetails/PurchaseOrder";
import Approval from "./pages/ProjectDetail/Approval/Approval";
import Collection from "./pages/ProjectDetail/Approval/Collection";
import RefundStatus from "./components/refundStatus/RefundStatus";
import TakeRateApproved from "./pages/TakeRateApproval/TakeRateApproved";
import TakeRateRejected from "./pages/TakeRateApproval/TakeRateRejected";

// ----------------------------------------------------------------------

function NewRoutes() {
  const userInfo = useSelector(({ user }) => user.userData);
  return useRoutes([
    {
      path: "/dashboard",
      element: (
        <AuthProtector>
          <DashboardLayout />
        </AuthProtector>
      ),
      children: [
        {
          path: "",
          element: <Navigate to="/dashboard/app" />,
        },
        {
          path: "app",
          element: <DashboardApp />,
          // <DashboardAppLoader />
        },
        { path: "user", element: <User /> },
        { path: "googlecal", element: <GoogleCal /> },
        { path: "charter", element: <ProjectChatter /> },
        { path: "charter/new", element: <CreateNew /> },
        { path: "charter/:id", element: <ViewCharter /> },
        { path: "questions/:id", element: <Review /> },
        {
          path: "users",
          element: <Outlet />,
          children: [
            {
              path: "",
              element:
                userInfo?.profile?.is_delivery_admin === true ? (
                  <Users />
                ) : (
                  <Navigate to="/404" />
                ),
            },
            {
              path: "adduser",
              element:
                userInfo?.profile?.is_delivery_admin === true ? (
                  <AddUser />
                ) : (
                  <Navigate to="/404" />
                ),
            },
            {
              path: ":id",
              element:
                userInfo?.profile?.is_delivery_admin === true ? (
                  <AddUser />
                ) : (
                  <Navigate to="/404" />
                ),
            },
          ],
        },
        {
          path: "projects",
          element: <Outlet />,
          children: [
            { path: "", element: <AllProjects /> },
            {
              path: ":id",
              element: <Outlet />,
              children: [
                {
                  path: "",
                  element: <ProjectDetails />,
                  children: [
                    { path: overviewPath, element: <OverView /> },
                    { path: proposalPath, element: <Proposal /> },
                    { path: tablePath, element: <TimelineDashboard /> },

                    { path: scopePath, element: <Charter /> },

                    {
                      path: clientSupport,
                      element: <Outlet />,
                      children: [
                        { path: "", element: <TicketClient /> },
                        { path: ":ticketId", element: <ChatTicket /> },
                      ],
                    },
                    {
                      path: vendorSupport,
                      element: <Outlet />,
                      children: [
                        { path: "", element: <Ticket /> },
                        { path: ":ticketId", element: <ChatTicket /> },
                      ],
                    },
                    { path: feedbackPath, element: <Feedback /> },
                    { path: commercialsPath, element: <Collections /> },
                    { path: newTable, element: <ActivityTable /> },
                    { path: proformaInvoice, element: <ProformaInvoice /> },
                    { path: poTable, element: <PurchaseOrder /> },
                    { path: timeline, element: <Timeline /> },
                    { path: approval, element: <Approval /> },
                    { path: collection, element: <Collection /> },
                    { path: refundStatus, element: <RefundStatus /> },
                  ],
                },
              ],
            },
          ],
        },
        { path: "notification", element: <Notification /> },
        { path: "kam", element: <AllProjects /> },
        { path: "kam/1", element: <ProjectDetails /> },
        { path: "questions", element: <QuestionTable /> },
        { path: "proposal", element: <Proposal /> },
        { path: "proposal/:id", element: <ProposalDetails /> },
        { path: "PoApproval", element: <PoApproval /> },
        { path: "RefundApproval", element: <RefundApproval /> },

        {
          path: "TakeRateApproval",
          element: <TakeRateApprovalIndex />,
          children: [
            { path: "allPO", element: <TakeRateApproval /> },
            { path: "approvedbyfinance", element: <TakeRateApproved /> },
            { path: "rejectedbyfinance", element: <TakeRateRejected /> },
          ],
        },

        { path: "FinanceApproval", element: <FinanceApproval /> },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "login", element: <Login /> },
        { path: "404", element: <NotFound /> },
        { path: "forgotpassword", element: <ForgotPassword /> },
        { path: "reset-password", element: <NewPassword /> },
        { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

export default withAuth(NewRoutes);
