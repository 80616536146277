import React from "react";
import {
  Box,
  Card,
  Stack,
  Typography,
  Button,
  IconButton,

  Hidden,

  TextField,

} from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import "./confirmation.theme.css";
import ReasonBox, { PoReasonBox } from "./ReasonStatusModal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import data from "@iconify/icons-eva/plus-fill";
import {
  RefundRejectionReasonThunk,
  addPurchaseOrderReasonThunk,
  addPurchaseOrderThunk,
  approvalReasonThunk,
} from "../../store/slices/project/project.slice";
import { useEffect } from "react";
import { preventDefault } from "@fullcalendar/react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function UserAcceptanceModal({
  successCallback,
  closeModal,
  isOpen,
  status,
  headermessage,
  message,

  input,
  ApproveInput,
  getreason,
  PoApproveRejectionReason,
  PoInput,
  PoApproveInput,
  financeMessage,
  setFinanceMessage,
  comingFrom,
}) {
const [reasonValidation, setReasonValidation] = useState("")
  function handleReasonBoxData(data) {
    getreason(data);
    setReasonValidation(data)
  }

  function handleFinanceReasonBoxData(data) {
    setFinanceMessage(data)
    setReasonValidation(data)

  }

  function handlePoReasonBoxData(data) {
    PoApproveRejectionReason(data)
    setReasonValidation(data)
  }


  

  console.log("keu",reasonValidation)

  return (
    <>
      <BootstrapDialog
        onClose={closeModal}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={closeModal}
          sx={{ zIndex: "10" }}
        />

        <DialogContent sx={{ p: "0.5rem!important", overflow: "hidden" }}>
          <Stack>
            <Card
              className="dialog-content-confirmation"
              sx={{
                widhthpadding: "10px",
                boxShadow: "none",
                overflow: "hidden",
              }}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{ paddingLeft: "2rem" }}
              >
                <div
                  style={
                    input || ApproveInput ||  PoInput || PoApproveInput
                      ? { display: "none" }
                      : { display: "block" }
                  }
                >
                  <IconButton
                    color="error"
                    component="span"
                    sx={{ backgroundColor: "rgba(255, 72, 66, 0.08)" }}
                  >
                    <HelpOutlineIcon
                      sx={{ fontSize: "2.5rem", color: "#E05353!important" }}
                    />
                  </IconButton>
                </div>
                <div style={{ overflow: "hidden" }}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      padding: "0px 0px 10px 0",
                      fontWeight: "bold",
                      overflow: "hidden",
                    }}
                  >
                    {headermessage}
                  </Typography>

                  {input || ApproveInput ? (
                    <Typography sx={{ color: "#9c9fa1", overflow: "hidden" }}>
                      <ReasonBox
                        reasonBoxData={handleReasonBoxData}
                        // poRejectionReason={PoRejectionReasonBoxData}
                      />
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        color: "#9c9fa1",
                        overflow: "hidden",
                        display: "none",
                      }}
                    ></Typography>
                  )}

                  {PoInput || PoApproveInput ? (
                    <Typography sx={{ color: "#9c9fa1", overflow: "hidden" }}>
                      <PoReasonBox
                        PoReasonBoxData={handlePoReasonBoxData}
                        // poRejectionReason={PoRejectionReasonBoxData}
                      />
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        color: "#9c9fa1",
                        overflow: "hidden",
                        display: "none",
                      }}
                    ></Typography>
                  )

                  }

                  <Typography sx={{ color: "#9c9fa1", overflow: "hidden" }}>
                    {message + "" + status}
                  </Typography>
                  <div>
                    {comingFrom === "finance"  ? (
                      <Box
                        component="form"
                        sx={{
                          "& > :not(style)": { m: 1, width: "35ch" },
                        }}
                        noValidate
                      >
                        <TextField
                          id="outlined-basic"
                          label="reason"
                          variant="outlined"
                          value={financeMessage}
                          onChange={(e) =>handleFinanceReasonBoxData(e.target.value)}
                        />
                      </Box>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Stack>
            </Card>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ marginRight: "1rem !important" }}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            pb={3}
          >
            <Button
              variant="text"
              autoFocus
              onClick={closeModal}
              sx={{
                color: "#9c9fa1",
                backgroundColor: "#fff",

                "&:hover": {
                  backgroundColor: "#fff",
                  opacity: "0.9",
                  color: "#222",
                },
              }}
            >
              Cancel
            </Button>
          
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#00AB55",
                  boxShadow:
                    "0px 5px 5px -3px rgb(255 241 240), 0px 8px 10px 1px rgb(255 241 240), 0px 3px 14px 2px rgb(255 241 240)!important",

                  "&:hover": {
                    backgroundColor: "#007B55",
                    opacity: "0.9",
                  },
                }}
                disabled={!reasonValidation}
                onClick={successCallback}
              >
                Submit
              </Button> 
          </Stack>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
