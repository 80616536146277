import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { DateTime } from "luxon";

import { useStyles } from "../Section.theme";
function DatePicker({
  value,
  status,
  columns,
  change,
  styles,
  columnId,
  isAdmin,
}) {
  const classes = useStyles();
  const [dropStatus, setDropStatus] = useState("");
  const [colId, setColId] = useState("");

  function handleChange(date) {
    change(date);
  }

  useEffect(() => {
    if (columns != undefined) {
      for (var i = 0; i < Object.keys(columns).length; i++) {
        if (
          columns[Object.keys(columns)[i]].value.toUpperCase() === "STATUS" &&
          columns[Object.keys(columns)[i]].type.toUpperCase() === "DROPDOWN"
        ) {
          for (var j = 0; j < Object.keys(status).length; j++) {
            if (Object.keys(status)[j] == Object.keys(columns)[i]) {
              setDropStatus(status[Object.keys(status)[j]].value);
            }
          }
        } else if (
          columns[Object.keys(columns)[i]].key.toUpperCase() ===
          "end_date".toUpperCase()
        ) {
          setColId(Object.keys(columns)[i]);
        }
      }
    }
  }, [columns, status]);

  const val = !value ? null : new Date(value);

 

  return (
    <>
      {(dropStatus === "Done" || columnId === colId) && isAdmin == false ? (
        <p className={classes.inputElement} style={styles}>
          {value == "" ? "" : DateTime.fromISO(value).toFormat("DD")}
        </p>
      ) : (
        <ReactDatePicker
          dateFormat="dd/MM/yyyy"
          selected={val}
          onChange={handleChange}
          customInput={
            <input className={classes.inputElement} style={styles} />
          }
        />
      )}
    </>
  );
}

export default DatePicker;
