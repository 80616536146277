import { uploadUserProfileApi, userApi } from "../../../utils/apis.utils";
import {
  startDashboardLoader,
  stopDashboardLoader,
} from "../dashboard/dashboard.slice";
import {
  fetchreqpurchaseorders,
  rejectpoorder,
} from "../../../utils/apis.utils";
import { createQueryString } from "../../../utils/global/global";
import { showFailureToast, showSuccessToast } from "../toast/toast.slice";

import { fetchAllFinanceApprovalThunk } from "./financeApproval.slice";

export const fetchAllFinanceApprovalHandler = async (data, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const query = createQueryString({
    page: data.page,
  });

  const response = await user.get(fetchreqpurchaseorders + query + "&limit=10&fin_user=true");

  return { data: response.data, total: response.total };
};

export const FinanceRejectionHandler = async ({ id, data }, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(rejectpoorder + "/" + id,  {...data} );

    thunkApi.dispatch(stopDashboardLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchAllFinanceApprovalThunk({ page: 1 }));
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const FinanceApprovedHandler = async ({ id, data }, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(rejectpoorder + "/" + id,  {...data} );

    thunkApi.dispatch(stopDashboardLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchAllFinanceApprovalThunk({ page: 1 }));
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};



