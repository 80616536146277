import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { capitalCase } from "../../../utils/change-case";

import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Stack,
  Avatar,
  Paper,
  Button,
  TextField,
  IconButton,
  Input,
} from "@mui/material";
import clsx from "clsx";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import DescriptionIcon from "@mui/icons-material/Description";
import { DateTime } from "luxon";
import { useStyles } from "./Overview.theme";
import MyDropzone from "../../../components/DropZone";
import { useDispatch } from "react-redux";
import {
  changeDescriptionForAProjectThunk,
  updateProjectExpectedAmountThunk,
  updateProjectExpirationDateThunk,
  updateProjectStatusThunk,
  uploadProjectAttachmentThunk,
  fetchAllProjectStatusThunk,
  updateInternalCategoryForProjectThunk,
  updateDeliveryProjectStateForProjectThunk,
} from "../../../store/slices/project/project.slice";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { json, useParams } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ReactDatePicker from "react-datepicker";
import { useState } from "react";
import { inputType, projectStatus } from "../../../utils/enum";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Element from "../../../components/Form/Element";
import OverViewLoader from "../../../components/Skeletons/OverViewLoader";
import FormControl from "@mui/material/FormControl";
import Dropdown from "../../../components/Form/Dropdown";
import Modal from "../../../components/Modal/Modal";
import OverviewStatusModal from "../../../components/Modal/OverviewStatusModal";
import ConfirmationsModal from "../../../components/Modal/ConfirmationsModal";
import UserAcceptanceModal from "../../../components/Modal/UserAcceptanceModal";
import {
  getClientSelectorDataHandler,
  getVendorSelectorDataHandler,
} from "../../../utils/global/global";
import ExpectationData from "../../Collection/ExpectationsData";
import AggregateCommercials from "../../Collection/AggregateCommercials";
import Collections from "../../Collection/Collections";
import ProformaInvoice from "../ProformaInvoice/ProformaInvoice";
import PurchaseOrder from "../../../components/ProjectDetails/PurchaseOrder";
import Collection from "../Approval/Collection";
import { Link } from "react-router-dom";
import { minWidth } from "@mui/system";
import RefundStatus from "../../../components/refundStatus/RefundStatus";
import { forEach, split } from "lodash";

function getFileIcon(ext) {
  ext = ext.toLowerCase();
  if (["jpg", "png", "gif", "jpeg"].includes(ext)) return <PhotoLibraryIcon />;
  if (["pdf"].includes(ext)) return <PictureAsPdfIcon />;
  if (["doc", "docx"].includes(ext)) return <DescriptionIcon />;
}

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

function getInitials(project_name) {
  if (project_name?.toLowerCase() === "crm") return project_name;
  return capitalCase(project_name.substring(0, 2));
}

function DatePicker({ value, change }) {
  function handleChange(date) {
    change(date);
  }



  return (
    <ReactDatePicker
      dateFormat="MM/dd/yyyy"
      selected={value}
      onChange={handleChange}
      customInput={<TextField variant="standard" style={{ minWidth: "166px" }} />}
      placeholderText="Expected Closing Date"
    />
  );
}

function OverView() {
  //Category Dropdown List
  const [category, setCategory] = useState("");
  const [selectedModal, setSelectedModal] = useState("");
  const [reasonData, setReasonData] = useState("");
  const internalCategory = [
    { label: "SELECT CATEGORY", value: "SELECT" },
    { label: "MARKETING", value: "marketing" },
    { label: "ECOMMERCE", value: "ecommerce" },
    { label: "OTHERS", value: "others" },
  ];

  // Project Health Dropdown List
  const [health, setHealth] = useState("");
  const healthCategory = [
    { label: "SELECT HEALTH", value: "SELECT" },
    { label: "HEALTHY", value: "healthy" },
    { label: "VULNERABLE", value: "vulnerable" },
    { label: "SICK", value: "sick" },
  ];

  //User Acceptance
  const [showAcceptanceModal, setShowAceeptanceModal] = useState(false);
  // function handleUserAcceotance() {
  //   setShowAceeptanceModal(true);
  // }

  //Modal Status Change Feedback
  const [showModal, setShowModal] = useState(false);
  const handleOpen = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    if (reasonData == "") {
      setStatus(project?.project_status);
    } else {
      setReasonData("");
    }
    setShowModal(false);
  };
  //end

  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const [expirationDate, setExpirationDate] = useState("");
  const [projectStatusDropdownOption, setProjectStatusDropdownOption] =
    useState([]);
  const [isEditDescription, setEditDescription] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [vendorData, setVendorData] = useState([]);

  const project = useSelector(({ project }) => project.selectedProject);

  
  const projectStatusDropdown = useSelector(
    ({ project }) => project.projectStatus
  );

  const [description, setDescription] = useState(project?.description);

  const [status, setStatus] = useState("");


  const [expectedPayment, setExpectedPayment] = useState({
    amount: "",
    gst: "",
  });

  function addAttachment(file) {
    const formData = new FormData();
    file.file.forEach((ele) => {
      formData.append("file", ele);
    });
    dispatch(
      uploadProjectAttachmentThunk({
        id: params.id,
        file: formData,
      })
    );
  }

  useEffect(() => {
    dispatch(fetchAllProjectStatusThunk());
  }, [params.id]);

  useEffect(() => {
    getClientSelectorDataHandler(params.id).then((response) => {
      let data = response?.data.map((user) => ({
        label: user?.ci_number,
        value: user?.ci_id,
      }));
      setClientData(data);
    });

    getVendorSelectorDataHandler(params.id).then((response) => {
      let data = response?.data.map((user) => ({
        label: "" + user?.bill_no,
        value: "" + user?.bill_id,
      }));
      setVendorData(data);
    });
  }, []);

  useEffect(() => { }, []);

  useEffect(() => {
    setStatus(project?.project_status);

    setCategory(
      project?.delivery_internal_category == null
        ? "SELECT"
        : project?.delivery_internal_category
    );
    setHealth(
      project?.delivery_project_state == null
        ? "SELECT"
        : project?.delivery_project_state
    );
  }, [project]);

  useEffect(() => {
    if (projectStatusDropdown?.length > 0) {
      var arr = [];

      projectStatusDropdown.map((data, i) => {
        if (i != 0) return arr.push(data);

      });

      setProjectStatusDropdownOption(arr);
    }
  }, [projectStatusDropdown]);


  function userAction(feedback) {
    if (feedback) ApiHit();
    setShowAceeptanceModal(false);
  }

  function ApiHit() {
    dispatch(
      updateInternalCategoryForProjectThunk({
        id: params.id,
        category: category,
      })
    );
  }

  //Category Dropdown List data save
  const handlechangeInternalCategory = (value) => {
    setShowAceeptanceModal(true);
    // setCategory(value);
    // dispatch(
    //   updateInternalCategoryForProjectThunk({
    //     id: params.id,
    //     category: value,
    //   })
    // );
  };

  //Health Dropdown List data save
  const handlechangeHealth = (value) => {
    setHealth(value);
    dispatch(
      updateDeliveryProjectStateForProjectThunk({
        id: params.id,
        health: value,
      })
    );
  };

  useEffect(() => {
    setDescription(project.description);
  }, [project.description]);

  useEffect(() => {
    if (project.actual_project_amount) {
      const value = project.actual_project_amount;
      const gst = (Number(value) * 18) / 100;
      setExpectedPayment({
        amount: value,
        gst: gst?.toFixed(2),
      });
    }
  }, [project.actual_project_amount]);

  function handleExpirattionDateChange() {
    dispatch(
      updateProjectExpirationDateThunk({
        id: params.id,
        date: expirationDate,
      })
    )
      .unwrap()
      .then(() => {
        setExpirationDate("");
      });
  }

  function handleProjectClose(proStatus) {
    // dispatch(
    //   updateProjectStatusThunk({
    //     id: params.id,
    //     status: proStatus,
    //   })
    // );
  }

  function handleDescriptionSave() {
    dispatch(
      changeDescriptionForAProjectThunk({
        id: params.id,
        description: description,
      })
    );
    setEditDescription(false);
  }

  function handleAmountChange(e) {
    const value = e.target.value;
    const gst = (Number(value) * 18) / 100;
    setExpectedPayment({
      amount: value,
      gst: gst?.toFixed(2),
    });
  }

  function handleExpectedPaymentSave() {
    dispatch(
      updateProjectExpectedAmountThunk({
        data: {
          actual_project_amount: expectedPayment.amount,
          gst: expectedPayment.gst,
        },
        project_id: params.id,
      })
    );
  }

  function fncReason(data) {
    setReasonData(data);

  }

  // sales spoc 
  // try{
  //   const salesSpocArray = JSON.parse(project?.sales_spoc || "[]");
  //   let splice_condition=1;
  // } catch{
  //   const salesSpocArray=project?.sales_spoc 
  //    splice_condition=0;
  // }

  // Dev team
  let salesSpocArray=[];
  let splice_condition=1;
  try{
   salesSpocArray = JSON.parse(project?.sales_spoc || "[]");
   splice_condition=1;
} catch{
   salesSpocArray=project?.sales_spoc 
   splice_condition=0;
}
  // Dev team
  
  let result = "";

  if (salesSpocArray.length === 0) {
    result = "-";
  } else {
    for (let i = 0; i < salesSpocArray.length; i++) {
      const splitResult = salesSpocArray[i]?.split(' => ')[0];
      if (splitResult) {
        result += splitResult.toUpperCase() + ", ";
      }
    }
    if (splice_condition){
      result = result.slice(0, -2); // Remove the trailing comma and space
    }
    
  }

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card mb={5}>
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography
              gutterBottom
              variant="h5"
              sx={{ marginBottom: 0, fontSize: { xs: "16px", sm: "16px" } }}
            >
              Project Details
            </Typography>
            <Box
              component="span"
              display="inline"
              className={clsx(classes.badge_blue, classes.badge)}
              sx={{ fontSize: { xs: "16px", sm: "16px" } }}
            >
              {capitalCase(project?.project_status)
              }
            </Box>
          </Stack>

          <Typography
            variant="body1"
            sx={{ color: "#a4b0be", fontWeight: "600", paddingBottom: "10px" }}

          >

            <a href={`https://crm.zoho.in/crm/org60006590658/tab/Potentials/${project?.deal_zoho_id}`} target="_blank" style={{ color: '#a4b0be', cursor: "pointer" }}>{project?.deal_zoho_id}</a>

          </Typography>

          <Typography
            variant="body1"
            sx={{ color: "#a4b0be", fontWeight: "600", paddingBottom: "10px" }}
          >
            Project ID: {params.id}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#a4b0be", fontWeight: "600", paddingBottom: "10px" }}
          >
            Description
            <IconButton
              component="span"
              sx={{ color: "#a3b1bf" }}
              onClick={() => setEditDescription(true)}
            >
              <EditOutlinedIcon sx={{ fontSize: "1.25rem" }} />
            </IconButton>
          </Typography>

          <Typography
            variant="body2"
            sx={{
              color: "text.secondary",
              textAlign: "justify",
              fontSize: { xs: "14px", sm: "14px" },
            }}
          >
            {isEditDescription ? (
              <Element
                eletype={inputType.textarea}
                style={{ width: 700 }}
                inputProps={{
                  onChange: (e) => setDescription(e.target.value),
                }}
                value={description}
              />
            ) : (
              project?.description
            )}
            <div>
              {isEditDescription && (
                <>
                  <Button
                    variant="contained"
                    onClick={() => setEditDescription(false)}
                  >
                    Cancel
                  </Button>{" "}
                  <Button variant="contained" onClick={handleDescriptionSave}>
                    Save
                  </Button>
                </>
              )}
            </div>
          </Typography>

          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                sx={{
                  color: "#a4b0be",
                  fontWeight: "600",
                  paddingBottom: "10px",
                }}
                mt={2}
              >
                Services
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Stack direction="row" mb={1} spacing={1}>
                {project?.services?.map((item) => {
                  return (
                    <Paper
                      sx={{
                        color: "text.secondary",
                        borderRadius: "5px",
                        border: "2px solid #f3f6f8",
                        fontWeight: "400",
                        fontSize: "0.875rem",
                        lineHeight: 1.43,
                        letterSpacing: "0.01071em",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      {item?.service_name}
                    </Paper>
                  );
                })}
              </Stack>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={6} md={12}>
              <Box
                component="div"
                mt={3}
                display="flex"
                sx={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "95%",
                }}
              >
                <Stack direction="row">
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-start"
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: { xs: "16px" },
                        color: "#a4b0be",
                        fontWeight: "600",
                      }}
                    >
                      Sales Spoc:
                    </Typography>
                    <Typography
                      variant="body2"
                      ml={1}
                      sx={{
                        paddingRight: "1rem",
                        color: "text.secondary",
                        fontSize: { xs: "14px" },
                      }}
                    >
                      {

                        project?.sales_spoc == null ? "-" : result}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={6} md={12}>
              <Box
                component="div"
                mt={3}
                display="flex"
                sx={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "95%",
                }}
              >
                <Stack direction="row">
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-start"
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: { xs: "16px" },
                        color: "#a4b0be",
                        fontWeight: "600",
                      }}
                    >
                      <AccessTimeIcon sx={{ fontSize: "14px" }} /> Created Date:
                    </Typography>
                    <Typography
                      variant="body2"
                      ml={1}
                      sx={{
                        paddingRight: "1rem",
                        color: "text.secondary",
                        fontSize: { xs: "14px" },
                      }}
                    >
                      {" "}
                      {DateTime.fromISO(project?.created_at).toFormat("DD")}
                    </Typography>
                  </Stack>

                  {/* <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-start"
                    
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: { xs: "16px" },
                        color: "#a4b0be",
                        fontWeight: "600",
                        paddingLeft: "20px"
                      }}
                    >
                      <AccessTimeIcon sx={{ fontSize: "14px" }} /> Due Date:
                    </Typography>
                    <Typography
                      variant="body2"
                      ml={1}
                      sx={{ color: "text.secondary", fontSize: { xs: "14px" } }}
                    >
                      {" "}
                      {project.expected_closing_date
                        ? DateTime.fromHTTP(
                            project.expected_closing_date
                          ).toFormat("DD")
                        : "-"}
                    </Typography>
                  </Stack> */}

                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-start"

                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: { xs: "16px" },
                        color: "#a4b0be",
                        fontWeight: "600",
                        paddingLeft: "20px"
                      }}
                    >
                      <AccessTimeIcon sx={{ fontSize: "14px" }} /> Delivery Handover Tat:
                    </Typography>
                    <Typography
                      variant="body2"
                      ml={1}
                      sx={{ color: "text.secondary", fontSize: { xs: "14px" } }}
                    >
                      {" "}
                      {project?.delivery_handover_tat ? project.delivery_handover_tat : project?.delivery_handover_tat == 0 ? project?.delivery_handover_tat : "-"}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-start"
                    sx={{ paddingLeft: "15px" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: { xs: "16px" },
                        color: "#a4b0be",
                        fontWeight: "600",
                      }}
                    >
                      <AccessTimeIcon sx={{ fontSize: "14px" }} /> Start Delivery Date:
                    </Typography>
                    <Typography
                      variant="body2"
                      ml={1}
                      sx={{ color: "text.secondary", fontSize: { xs: "14px" } }}
                    >
                      {" "}
                      {project.start_Date_of_Delivery
                        ?
                        DateTime.fromHTTP(project.start_Date_of_Delivery).toFormat("DD")

                        : "-"}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          </Grid>

          <Stack
            direction="row"
            justifyContent={
              project.project_status === projectStatus.closed
                ? "flex-end"
                : "space-between"
            }
            sx={{ width: "95%", marginTop: "30px" }}
          >
            {project?.project_status !== projectStatus.closed && (
              <Stack direction="row">
                <Stack direction="row" spacing={3} alignItems="center">
                  <DatePicker
                    value={expirationDate}
                    change={setExpirationDate}
                  />
                  <Box>
                    <Button onClick={handleExpirattionDateChange}>
                      Submit
                    </Button>
                  </Box>
                </Stack>
              </Stack>
            )}

            {/* <Button onClick={handleProjectClose} variant="contained">
              {project.project_status === projectStatus.closed
                ? "Mark As Active"
                : "Mark As Complete"}
            </Button> */}

            <Stack
              direction="row"
              justifyContent="flex-end"
              sx={{ width: "95%", marginTop: "30px", gap: "1.5rem" }}
            >
              <Box>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setShowModal(true);
                    setSelectedModal("CLIENTS_REFUND");
                  }}
                >
                  Client Refund
                </Button>
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setShowModal(true);
                    setSelectedModal("VENDOR_REFUND");
                  }}
                >
                  Vendor Refund
                </Button>
              </Box>
            </Stack>
          </Stack>

          <Stack
            direction="row"
            justifyContent="flex-start"
            spacing={2}
            sx={{ marginTop: "2rem" }}
          >
            <Box>
              <FormControl fullWidth>
                <Dropdown
                  styles={{
                    position: "relative",
                    height: "40px",
                    lineHeight: "1.4375em",
                    fontSize: "1rem",
                    fontWeight: 600,
                    color: "#212B36,",
                    borderRadius: "8px",
                    backgroundColor: "#f3f6f8",
                    maxWidth: "250px",
                    margin: "1rem 1rem 0 0",
                    cursor: "pointer",
                  }}
                  inputLabel="Internal Delivery Category"
                  param="OVERVIEW"
                  options={internalCategory}
                  icons
                  inputProps={{
                    handleChange: (e) => {
                      setCategory(e.target.value);
                      handlechangeInternalCategory(e.target.value);
                    },
                  }}
                  value={category}
                />
                {/* <ConfirmationsModal /> */}
                {showAcceptanceModal && (
                  <UserAcceptanceModal
                    successCallback={() => userAction(true)}
                    closeModal={() => {
                      setShowAceeptanceModal(false);
                      setCategory(project?.delivery_internal_category);
                    }}
                    isOpen={showAcceptanceModal}
                    status={category}
                    headermessage="Are you sure you want to change the category?"
                    message="The category status will changed to"
                  />
                )}
              </FormControl>
            </Box>
            {/* 
            <Box>
              <FormControl fullWidth>
                <Dropdown
                  styles={{
                    position: "relative",
                    height: "40px",
                    lineHeight: "1.4375em",
                    fontSize: "1rem",
                    fontWeight: 600,
                    color: "#212B36,",
                    borderRadius: "8px",
                    backgroundColor: "#f3f6f8",
                    maxWidth: "250px",
                    margin: "1rem 1rem 0 0",
                    cursor: "pointer",
                  }}
                  param="OVERVIEW"
                  inputLabel="Project Health Status"
                  options={healthCategory}
                  icons
                  inputProps={{
                    handleChange: (e) => {
                      setHealth(e.target.value);
                      handlechangeHealth(e.target.value);
                    },
                  }}
                  value={health}
                />
              </FormControl>
            </Box> */}

            <Box>
              <FormControl fullWidth>
                <Dropdown
                  styles={{
                    position: "relative",
                    height: "40px",
                    lineHeight: "1.4375em",
                    fontSize: "1rem",
                    fontWeight: 600,
                    color: "#212B36,",
                    borderRadius: "8px",
                    backgroundColor: "#f3f6f8",
                    maxWidth: "250px",
                    margin: "1rem 1rem 0 0",
                    cursor: "pointer",
                  }}
                  param="OVERVIEW"
                  isHide="OVERVIEW"
                  inputLabel="Status"
                  options={projectStatusDropdownOption}
                  icons
                  inputProps={{
                    handleChange: (e) => {
                      setShowModal(true);
                      setSelectedModal("PROJECT_STATUS");
                      setStatus(e.target.value);
                      handleProjectClose(e.target.value);
                    },
                  }}
                  value={status}
                />

                <Modal
                  maxWidth="md"
                  isOpen={showModal}
                  closeModal={handleClose}
                  title={selectedModal !== "PROJECT_STATUS" ? "Refund Request" : "Comments"}
                  content={
                    <OverviewStatusModal
                      closeModal={handleClose}
                      selectedStatus={status}
                      fncReason={fncReason}
                      clickedFrom={selectedModal}
                      projectId={params.id}
                      clientData={clientData}
                      vendorData={vendorData}
                    />
                  }
                  dialogProps={{ fullWidth: true }}
                  titleStyle={{ textAlign: "center", marginTop: 2 }}
                  actionStyle={{ justifyContent: "center", marginBottom: 2 }}
                />
              </FormControl>
            </Box>
            {/* <Stack justifyContent="center" sx={{ paddingTop: "1rem" }}>
              <Box>
                <Button
                  variant="contained"
                  onClick={() => {
                    setShowModal(true);
                    setSelectedModal("VENDOR_REFUND");
                  }}
                >
                  Vendor Refund
                </Button>
              </Box>
            </Stack> */}
          </Stack>

          {/* <Stack
            direction="row"
            justifyContent="flex-start"
            sx={{ width: "95%", marginTop: "30px" }}
            spacing={2}
          >
            <Box component="form" noValidate autoComplete="off">
              <Input
                value={expectedPayment.amount}
                onChange={handleAmountChange}
                placeholder="Project amount (excl. gst)"
                sx={{ mr: "2rem", width: "200px" }}
              />
              <Input
                placeholder="Project amount (incl. gst)"
                disabled
                defaultValue="GST"
                value={
                  expectedPayment.amount &&
                  Number(expectedPayment.gst) + Number(expectedPayment.amount)
                }
                sx={{ mr: "2rem", width: "200px" }}
              />
            </Box>
            <Box>
              <Button onClick={handleExpectedPaymentSave}>Submit</Button>
            </Box>
          </Stack> */}

          <Typography
            variant="body1"
            display="block"
            mt={5}
            sx={{
              fontSize: { xs: "16px" },
              color: "#a4b0be",
              fontWeight: "600",
            }}
          >
            Attachment
          </Typography>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              md={12}
              sx={{ margin: "20px 50px 50px 0" }}
            >
              <MyDropzone allowAll={true} addAttachment={addAttachment} />
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            {project?.attachments &&
              project?.attachments.map((file, id) => (
                <Grid item key={id} xs={12} sm={6} md={4}>
                  <Box component="div" className={classes.attachFile}>
                    <Box component="div" className={classes.attachMedia}>
                      <Box component="span" className={classes.attachIcon}>
                        {getFileIcon("png")}
                      </Box>
                      <Box component="div" ml={2} sx={{ position: "relative" }}>
                        <a
                          href={file.url}
                          target="blank"
                          style={{ color: "black", textDecoration: "none" }}
                        >
                          <Typography
                            variant="body1"
                            component="h4"
                            className={classes.attachFileHeading}
                            sx={{ fontWeight: "600" }}
                          >
                            {file.filename}
                          </Typography>
                        </a>

                        <Typography
                          variant="caption"
                          sx={{ color: "text.secondary" }}
                        >
                          {DateTime.fromISO(file.timestamp).toFormat("DD")}
                        </Typography>
                      </Box>
                      <Box>
                        <LightTooltip title={file.name} placement="top" arrow>
                          <Avatar
                            sx={{
                              fontSize: ".8rem",
                              fontWeight: "600",
                              backgroundColor: "#f3f6f8",
                              color: "#637281",
                            }}
                          >
                            {getInitials(file.name)}
                          </Avatar>
                        </LightTooltip>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </CardContent>
        <CardContent>
          <Typography
            variant="body1"
            display="block"
            mt={5}
            sx={{
              fontSize: { xs: "24px" },
              color: "#a4b0be",
              fontWeight: "600",
            }}
          >
            Commercials Data
          </Typography>
          {/* <ExpectationData /> */}
          {/* <AggregateCommercials /> */}
          <Collections />

          <Typography
            variant="body1"
            display="block"
            mt={5}
            sx={{
              fontSize: { xs: "24px" },
              color: "#a4b0be",
              fontWeight: "600",
            }}
          >
            ProformaInvoice Data
          </Typography>
          <ProformaInvoice />

          <Typography
            variant="body1"
            display="block"
            mt={5}
            sx={{
              fontSize: { xs: "24px" },
              color: "#a4b0be",
              fontWeight: "600",
            }}
          >
            Purchase Order Data
          </Typography>
          <PurchaseOrder />

          <Typography
            variant="body1"
            display="block"
            mt={5}
            sx={{
              fontSize: { xs: "24px" },
              color: "#a4b0be",
              fontWeight: "600",
            }}
          >
            Credit Notes And Debit Notes
          </Typography>
          <RefundStatus />

          <Typography
            variant="body1"
            display="block"
            mt={5}
            sx={{
              fontSize: { xs: "24px" },
              color: "#a4b0be",
              fontWeight: "600",
            }}
          >
            Collection Data
          </Typography>
          <Collection />
        </CardContent>
      </Card>
    </Grid>
  );
}

export default OverView;
