import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import layersFill from "@iconify/icons-eva/layers-fill";
import messageSquareFill from "@iconify/icons-eva/message-square-fill";
import copyFill from "@iconify/icons-eva/copy-fill";
import user from "@iconify/icons-eva/person-add-fill";
import bag from "@iconify/icons-eva/shopping-bag-outline";
import refund from "@iconify/icons-eva/repeat-fill";

import takeRate from "@iconify/icons-eva/external-link-fill";

import financeIcon from "@iconify/icons-eva/credit-card-outline";

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: getIcon(pieChart2Fill),
  },
  {
    title: "Users",
    path: "/dashboard/users",
    icon: getIcon(user),
  },
  {
    title: "projects",
    // path: "/dashboard/projects?search=&page=1&status=all&searchedUser=-1&isNew=0",
    path: "/dashboard/projects",
    icon: getIcon(layersFill),
  },

  {
    title: "project charter",
    path: "/dashboard/charter",
    icon: getIcon(copyFill),
  },
  {
    title: "Questions",
    path: "/dashboard/questions",
    icon: getIcon(messageSquareFill),
  },
  {
    title: "PO Approval",
    path: "/dashboard/PoApproval",
    icon: getIcon(bag),
  },
  {
    title: "Refund Approval",
    path: "/dashboard/RefundApproval",
    icon: getIcon(refund),
  },
  {
    title: "Take Rate Approval",
    path: "/dashboard/TakeRateApproval/allPO",
    icon: getIcon(takeRate),
  },
  {
    title: "Finance Approval",
    path: "/dashboard/FinanceApproval",
    icon: getIcon(financeIcon),
  },
];

export default sidebarConfig;
