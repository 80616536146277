import React, { useEffect } from "react";
import { Grid, Card, Box, Typography } from "@mui/material";
import { useStyles } from "./Timeline.theme";
import { DateTime } from "luxon";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllProjectStatusHistoryThunk } from "../../../store/slices/project/project.slice";
import { useParams } from "react-router-dom";
import { capitalCase } from "../../../utils/change-case";
export default function Timeline() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    dispatch(fetchAllProjectStatusHistoryThunk(params.id));
  }, [dispatch, params.id]);

  const timeline = useSelector(({ project }) => project.projectStatusHistory);
 

  function getInitials(project_name) {
    if (project_name?.toLowerCase() === "crm") return project_name;
    return capitalCase(project_name.substring(0, 2));
  }

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} md={12}>
          {timeline?.map((data, id) => (
            <>
              <Card className={classes.attachePropsal} key={id}>
                <Box component="div" className={classes.attachFile}>
                  <Box component="div" className={classes.attachMedia}>
                    <Box component="div" ml={2} sx={{ position: "relative" }}>
                      <Typography
                        variant="h6"
                        component="h6"
                        className={classes.attachFileHeading}
                        sx={{ fontWeight: "700" }}
                      >
                        Status : {capitalCase(data?.status)}
                      </Typography>
                      {data?.vendor_refunded_status == "Received" ? (
                        <>
                          <Typography
                            variant="body1"
                            component="h4"
                            className={classes.attachFileHeading}
                            sx={{ fontWeight: "500" }}
                          >
                            Vendor Refund Status :{" "}
                            {data?.vendor_refunded_status}
                          </Typography>
                        </>
                      ) : (
                        ""
                      )}
                      <Typography
                        variant="body1"
                        component="h4"
                        className={classes.attachFileHeading}
                        sx={{ fontWeight: "500" }}
                      >
                        Reason : {data?.status_change_reason}
                      </Typography>
                      <Typography
                        variant="body1"
                        component="h4"
                        className={classes.attachFileHeading}
                        sx={{ fontWeight: "500" }}
                      >
                        Date & Time:{" "}
                        {DateTime.fromISO(data.created_at).toFormat("DD")} ,{" "}
                        {DateTime.fromISO(data.created_at).toFormat("TT")}
                      </Typography>
                      <Typography
                        variant="body1"
                        component="h4"
                        className={classes.attachFileHeading}
                        sx={{ fontWeight: "500" }}
                      >
                        Updated by {data?.updated_by?.first_name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Card>
              <div className={classes.vl}></div>
            </>
          ))}
        </Grid>
      </Grid>
    </>
  );
}
