import {
  charterApi,
  fetchCharterByIdApi,
  fetchSopProjectTimelineByProjectIdApi,
  updateSopTableApi,
} from "../../../utils/apis.utils";
import { listViewType } from "../../../utils/enum";
import { createQueryString } from "../../../utils/global/global";
import {
  startDashboardLoader,
  stopDashboardLoader,
} from "../dashboard/dashboard.slice";
import { showFailureToast, showSuccessToast } from "../toast/toast.slice";
import { fetchSopCharterByIdThunk } from "./chatter.slice";
export const createChatterHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;

    const response = await user.post(charterApi, data);

    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject();
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const fetchAllCharterHandler = async (__, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;

    // const query = createQueryString({
    //   type: listViewType.sop,
    // });
    const response = await user.get(charterApi);

    return response.data;
  } catch (err) {
    return Promise.reject();
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const updateCharterHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const { id, charterData } = data;
    const response = await user.put(charterApi + "/" + id, {
      data: charterData,
    });
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
  } catch (err) {
    return Promise.reject();
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const fetchCharterByIdHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;

    const query = createQueryString({
      type: data.type,
    });
    const response = await user.get(
      fetchCharterByIdApi + "/" + data.id + query
    );

    return response.data;
  } catch (err) {
    return Promise.reject();
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const updateSopTableHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;

    const response = await user.put(updateSopTableApi, data);

    // thunkApi.dispatch(fetchSopCharterByIdThunk());
    // return response
  } catch (err) {
    return Promise.reject();
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const fetchSopCharterByIdHandler = async (id, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    // const query = createQueryString({
    //   project_id: id,
    //   type: listViewType.sop,
    // });
    const response = await user.get(
      fetchSopProjectTimelineByProjectIdApi + "/" + id
    );
    return response.data;
  } catch (err) {
    return Promise.reject();
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const deleteCharterHandler = async (id, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.delete(fetchCharterByIdApi + "/" + id);
    return id;
  } catch (err) {
    return Promise.reject();
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};
