import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  acceptLeadProposalHandler,
  fetchAllProjectByProjectIdHandler,
  fetchProposalByLeadIdHandler,
  getAllProposalsHandler,
  getLeadByIdThunkHandler,
  rejectLeadProposalHandler,
} from "./proposal.thunk";

const INIT_STATE = {
  data: [],
  selectedTab: 0,
  selectedProposal: {
    proposals: [],
  },
};

export const getAllProposalsThunk = createAsyncThunk(
  "getAllProposals",
  getAllProposalsHandler
);

export const getLeadByIdThunk = createAsyncThunk(
  "getLeadById",
  getLeadByIdThunkHandler
);

export const acceptLeadProposalThunk = createAsyncThunk(
  "acceptLeadProposal",
  acceptLeadProposalHandler
);

export const rejectLeadProposalThunk = createAsyncThunk(
  "rejectLeadProposal",
  rejectLeadProposalHandler
);

export const fetchProposalByLeadIdThunk = createAsyncThunk(
  "fetchProposalByLeadId",
  fetchProposalByLeadIdHandler
);

export const fetchAllProjectByProjectIdThunk = createAsyncThunk(
  "fetchAllProjectByProjectId",
  fetchAllProjectByProjectIdHandler
);

const proposalSlice = createSlice({
  name: "proposal",
  initialState: INIT_STATE,
  reducers: {
    tabChange: (state, action) => {
      state.selectedTab = action.payload;
    },
    setSelectedProposal: (state, action) => {
      state.selectedProposal = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProposalsThunk.fulfilled, (state, action) => {
        state.data = action.payload;
        return state;
      })
      .addCase(fetchProposalByLeadIdThunk.fulfilled, (state, action) => {
        state.selectedProposal = action.payload;
        return state;
      })
      .addCase(fetchAllProjectByProjectIdThunk.fulfilled, (state, action) => {
        state.data = action.payload;
        return state;
      });
  },
});

export const { tabChange, setSelectedProposal } = proposalSlice.actions;

export default proposalSlice.reducer;
