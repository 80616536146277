// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormElement_error__tRRWA {
  color: rgb(223, 68, 68);
  font-size: 0.8rem;
  bottom: -20px;
}

.FormElement_label__Dp04Q {
  font-weight: 400;
  font-size: 16px;
  color: rgb(128, 128, 128);
  margin-bottom: 8px;
}

.FormElement_switch__8SfST {
  display: inline-flex;
}
.FormElement_switch__8SfST input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}
.FormElement_switch__8SfST input[type="checkbox"]:checked + label {
  background: green;
}
.FormElement_switch__8SfST input[type="checkbox"]:checked + label::after {
  left: calc(100% - 4px);
  transform: translateX(-100%);
}
.FormElement_switch__8SfST label {
  cursor: pointer;
  width: 48px;
  height: 24px;
  background: grey;
  display: block;
  border-radius: 24px;
  position: relative;
}
.FormElement_switch__8SfST label::after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 16px;
  transition: 0.3s;
}

.FormElement_switch__8SfST p {
  margin-left: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Form/FormElement.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;AACtB;AACA;EACE,SAAS;EACT,QAAQ;EACR,kBAAkB;AACpB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,sBAAsB;EACtB,4BAA4B;AAC9B;AACA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,cAAc;EACd,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".error {\n  color: rgb(223, 68, 68);\n  font-size: 0.8rem;\n  bottom: -20px;\n}\n\n.label {\n  font-weight: 400;\n  font-size: 16px;\n  color: rgb(128, 128, 128);\n  margin-bottom: 8px;\n}\n\n.switch {\n  display: inline-flex;\n}\n.switch input[type=\"checkbox\"] {\n  height: 0;\n  width: 0;\n  visibility: hidden;\n}\n.switch input[type=\"checkbox\"]:checked + label {\n  background: green;\n}\n.switch input[type=\"checkbox\"]:checked + label::after {\n  left: calc(100% - 4px);\n  transform: translateX(-100%);\n}\n.switch label {\n  cursor: pointer;\n  width: 48px;\n  height: 24px;\n  background: grey;\n  display: block;\n  border-radius: 24px;\n  position: relative;\n}\n.switch label::after {\n  content: \"\";\n  position: absolute;\n  top: 4px;\n  left: 4px;\n  width: 16px;\n  height: 16px;\n  background: white;\n  border-radius: 16px;\n  transition: 0.3s;\n}\n\n.switch p {\n  margin-left: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `FormElement_error__tRRWA`,
	"label": `FormElement_label__Dp04Q`,
	"switch": `FormElement_switch__8SfST`
};
export default ___CSS_LOADER_EXPORT___;
