import { projectMatrixApi } from "../../../utils/apis.utils";
import { fetchAllProjectsThunk } from "../project/project.slice";
import { fetchAllNotificationsThunk } from "../init/init.slice";

export const fetchProjectMatrixHandler = async (data, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(projectMatrixApi);

  await thunkApi.dispatch(
    fetchAllProjectsThunk({
      term: "",
      type: "active",
      page: 1,
      spoc: -1,
      limit: data.limit,
      from: data.from,
    })
  );

  return response.data;
};
