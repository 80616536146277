import { useState } from "react";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { capitalCase } from "../../utils/change-case";
import { TablePagination } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Modal from "../../components/Modal/Modal";

import { DateTime } from "luxon";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import Page from "../../components/Page";
import Scrollbar from "../../components/Scrollbar";
import SearchNotFound from "../../components/NotFound/SearchNotFound";
import DeleteAction from "../../components/common/DeleteAction";
import { useDispatch, useSelector } from "react-redux";
import ListHead from "../../components/common/ListHead";
import ListSearchbar from "../../components/common/ListSearchbar";
// import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
// import { deleteUser } from "../../store/slices/user/user.slice";
import { useEffect } from "react";
import {
  fetchPORejectionThunk,
  fetchAllPOApprovalThunk,
  fetchPOApprovedThunk,
} from "../../store/slices/poapproval/poapproval.slice";
import UserAcceptanceModal from "../../components/Modal/UserAcceptanceModal";
import POInfoModal from "../../components/Modal/POInfoModal";
import POEditModal from "../../components/Modal/POEditModal";
import PurchaseOrderModal from "../../components/Modal/PurchaseOrderModal";
import { update } from "lodash";
import { fetchAggregatedThunk } from "../../store/slices/aggregated/aggregated.slice";
import PoAggregatedModal from "../../components/Modal/PoAggregatedModal";
import ApprovalModal from "../../components/Modal/ApprovalModal";
import TakeRateConfirmModal from "../../components/Modal/TakeRateConfirmModal";
import { fetchAllTakeRateApprovalThunk } from "../../store/slices/takeRateApproval/takeRate.slice";
import { TakeRateApproveReason } from "../../components/Form/NewTakeRateForm";
import { poStatus } from "../../utils/enum";

// ----------------------------------------------------------------------

function getInitials(project_name) {
  return capitalCase(project_name?.substring(0, 2));
}

function TakeRateApproval() {
  const dispatch = useDispatch();
  const [showAcceptanceModal, setShowAceeptanceModal] = useState(false);
  const [showAcceptanceModalApproval, setShowAcceptanceModalApproval] =
    useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [performaId, setPerformaId] = useState(0);
  const [rowData, setRowData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [PoApproveRejectReason, setPoApproveRejectReason] = useState();

  const [rejectID, setRejectID] = useState(null);
  const [projectID, setProjectID] = useState(null);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleInfoClose = () => {
    setShowInfoModal(false);
  };
  const handleEditClose = () => {
    setShowEditModal(false);
  };

  const TABLE_HEAD = [
    { id: "project_id", label: "Project ID", alignRight: false },
    { id: "project_name", label: "Project Name", alignRight: false },
    { id: "spoc", label: "Spoc", alignRight: false },
    { id: "PO_status", label: "PO Status", alignRight: false },
    { id: "Requested_by", label: "Requested By", alignRight: false },
    { id: "PO_amount", label: "PO Amount (Excl. GST)", alignRight: false },
    { id: "current_vendor ", label: "Current Vender ", alignRight: false },
    { id: "Vendor_Invoice_file", label: "Vendor Invoice", alignRight: false },
    { id: "milestones", label: "Milestones", alignRight: false },
    {
      id: " vendor_invoice_date",
      label: "Vendor Invoice Date",
      alignRight: false,
    },
    {
      id: " vendor_invoice_number",
      label: "Vendor Invoice Number",
      alignRight: false,
      minWidth: "200px",
    },

    { id: "created_at", label: "Requested Date & Time", alignRight: false },
    { id: "bill_no", label: "Bill No", alignRight: false },

    {
      id: "Rejection_reason",
      label: "Take Rate Update Reason",
      alignRight: false,
    },
    { id: "email_ss", label: "Email pdf", alignRight: false },

    { id: "action", label: "Action", alignRight: false },
  ];

  // function userAction(id) {
  //   setShowAceeptanceModal(false);
  //   var updateData = {
  //     project_id: projectID,
  //     status: "rejected",
  //     approval_rejection_reason: PoApproveRejectReason,
  //   };
  //   dispatch(fetchPORejectionThunk({ id: rejectID, data: updateData }));
  // }

  function getPoApproveRejectionReason(PoApproveRejectReason) {
    // console.log(
    //   "getPoApproveRejectionReason----->funnc--->",
    //   PoApproveRejectReason
    // );
    setPoApproveRejectReason(PoApproveRejectReason);
  }

  // function fncApproval(id) {
  //   setShowAcceptanceModalApproval(false);
  //   var updateData = {
  //     project_id: projectID,
  //     status: "approved",
  //     approval_rejection_reason: PoApproveRejectReason,
  //   };
  //   dispatch(fetchPOApprovedThunk({ id: rejectID, data: updateData }));
  // }

  const IDForTakeRate = {
    project_id: projectID,
    po_id: rejectID,
  };

  // useEffect(() => {
  //   dispatch(
  //     fetchAllPOApprovalThunk({
  //       page: 1,
  //     })
  //   );
  // }, [dispatch]);


  let { TakeRateApprovalData, totalTakeRateapprovalData } = useSelector(

    ({ takeRateApproval }) => takeRateApproval
  );


  const navigate = useNavigate();

  function handleEdit(id) {
    navigate("/dashboard/users/" + id);
  }

  // ------------------------------------ Pagination on table ----------------------

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);

  const handleChangePage = (_, newPage) => {
    dispatch(
      fetchAllPOApprovalThunk({
        page: newPage + 1,
      })
    );
    setPage(newPage);
  };

  // ------------------------------------------- ends ------------------

  function fncRejectPO(project_id, id) {
    // dispatch(deleteUserThunk(id));
    setShowAceeptanceModal(true);
    setRejectID(id);
    setProjectID(project_id);
    poAggregatedDataFunc(project_id, id);
  }

  function poAggregatedDataFunc(project_id, id) {
    const staticID = 363;
    const poId = id;
    const projectID = project_id;
    const Data = {
      poId: id,
      projectId: project_id,
    };

    dispatch(fetchAggregatedThunk({ id: poId, project_id: projectID }));
  }

  function fncApprovePO(project_id, id) {
    setShowAcceptanceModalApproval(true);
    setRejectID(id);
    setProjectID(project_id);
    poAggregatedDataFunc(project_id, id);
  }

  const handleInfoOpen = (id) => {
    setPerformaId(id);
    setShowInfoModal(true);
  };

  const handleEditOpen = (row) => {
    setRowData(row);
    setShowEditModal(true);
  };

  useEffect(() => {
    dispatch(fetchAllTakeRateApprovalThunk({ page: 2 }));
  }, [fetchAllTakeRateApprovalThunk]);


  let totalTakeRateapprovalDataValue = useSelector(

    (TakeRateapproval) => TakeRateapproval.takeRateApproval.TakeRateApprovalData
  );

  return (
    <Page title="KAM Dashboard">
      <Container>
        <Stack mb={2}>
          <Typography variant="h4" gutterBottom>
            Take Rate Approval
          </Typography>
          {/* <Breadcrumb /> */}
        </Stack>

        <Card>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              display: {
                lg: "flex",
                md: "flex",
                sm: "block",
                xs: "block",
                margin: "30px",
                justifyContent: "right",
              },
            }}
          ></Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              { totalTakeRateapprovalDataValue && totalTakeRateapprovalDataValue?.length === 0 ? (
                <TableBody align="center" sx={{ display: "block" }}>
                  <TableRow>
                    <TableCell colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <Table>
                  <ListHead headLabel={TABLE_HEAD} />
                  <TableBody>
                    { totalTakeRateapprovalDataValue && totalTakeRateapprovalDataValue?.map((row) => {
                      const {
                        id,
                        project_name,
                        created_by_full_name,
                        po_amount,
                        po_date,
                        po_number,
                        po_status,
                        zbooks_bill_no,
                        zbooks_bill_status,
                        vendor_invoice_file_url,
                        milestones,
                        vendor_invoice_date,
                        vendor_invoice_number,
                        rejection_reason,
                        created_at,
                        project_id,
                        created_by_email,
                        approval_rejection_reason,
                        email_screenshot,
                        current_vendor,
                      } = row;

                      return (
                        <TableRow
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell
                            align="left"
                            style={{ minWidth: "150px", paddingLeft: "43px" }}
                          >
                            <a
                              target="_blank"
                              href={`/dashboard/projects/${project_id}/overview`}
                              style={{ textDecoration: "none" }}
                            >
                              {project_id == null ? "-" : project_id}
                            </a>
                          </TableCell>

                          <TableCell align="left" style={{ minWidth: "380px" }}>
                            {project_name == null ? "-" : project_name}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ minWidth: "380px" }}
                          >
                            {created_by_full_name == null
                              ? "-"
                              : created_by_full_name}
                          </TableCell>

                          <TableCell align="left" style={{ minWidth: "200px" }}>
                            <Typography sx={{ textTransform: "lowercase" }}>
                              {po_status == null
                                ? "-"
                                : poStatus[`${po_status}`]}
                            </Typography>
                          </TableCell>

                          <TableCell align="left" style={{ minWidth: "180px" }}>
                            {created_by_email == null ? "-" : created_by_email}
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ minWidth: "200px", paddingLeft: "60px" }}
                          >
                            {po_amount == null ? "-" : po_amount}
                          </TableCell>
                          <TableCell align="left" style={{ minWidth: "180px" }}>
                            {current_vendor == null ? "-" : current_vendor}
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ minWidth: "180px", paddingLeft: "50px" }}
                          >
                            {po_status === "draft" ? (
                              <IconButton
                                aria-label="info"
                                onClick={() => handleInfoOpen(id)}
                                sx={{ cursor: "pointer" }}
                              >
                                <InfoIcon />
                              </IconButton>
                            ) : vendor_invoice_file_url == null ? (
                              "-"
                            ) : (
                              <a target="_blank" href={vendor_invoice_file_url}>
                                Invoice
                              </a>
                            )}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ minWidth: "230px", paddingLeft: "40px" }}
                          >
                            <Typography>
                              {milestones[0] == null ? "-" : milestones[0]}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ minWidth: "180px", paddingLeft: "50px" }}
                          >
                            <Typography>
                              {vendor_invoice_date == null
                                ? "-"
                                : vendor_invoice_date}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ minWidth: "200px", paddingLeft: "50px" }}
                          >
                            <Typography>
                              {vendor_invoice_number == null
                                ? "-"
                                : vendor_invoice_number}
                            </Typography>
                          </TableCell>

                          <TableCell align="left" style={{ minWidth: "222px" }}>
                            {created_at
                              ? new Date(created_at).toLocaleDateString(
                                  "en-IN",
                                  {
                                    timeZone: "Asia/Kolkata",
                                    hour12: false,
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                  }
                                )
                              : "-"}
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ minWidth: "150px", paddingLeft: "30px" }}
                          >
                            <Typography>
                              {zbooks_bill_no == null ? "-" : zbooks_bill_no}
                            </Typography>
                          </TableCell>

                          <TableCell align="left" style={{ minWidth: "180px" }}>
                            {approval_rejection_reason == null
                              ? "-"
                              : approval_rejection_reason}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ minWidth: "180px", paddingLeft: "50px" }}
                          >
                            {po_status === "draft" ? (
                              <IconButton
                                aria-label="info"
                                onClick={() => handleInfoOpen(id)}
                                sx={{ cursor: "pointer" }}
                              >
                                <InfoIcon />
                              </IconButton>
                            ) : email_screenshot == null ? (
                              "-"
                            ) : (
                              <a target="_blank" href={email_screenshot}>
                                Email pdf
                              </a>
                            )}
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{
                              minWidth: "150px",
                              backgroundColor: "white",
                              position: "sticky",
                              right: 0,
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <Button
                                onClick={() => {
                                  fncRejectPO(project_id, id);
                                }}
                                style={{
                                  backgroundColor: "red",
                                  marginRight: "20px",
                                  color: "white",
                                }}
                              >
                                Reject
                              </Button>

                              <Button
                                onClick={() => {
                                  fncApprovePO(project_id, id);
                                }}
                                style={{
                                  backgroundColor: "green",
                                  color: "white",
                                }}
                              >
                                Approve
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {/* <TableBody>
                    {POApprovalData?.map((row) => {
                      const {
                        id,
                        project_name,
                        bill_created,
                        created_at,
                        created_by,
                        created_by_email,
                        milestone_id,
                        milestones,
                        po_amount,
                        po_date,
                        po_number,
                        po_push_status,
                        po_status,
                        project_id,
                        rejection_reason,
                        remaining_bill_amount,
                        updated_at,
                        updated_by,
                        vendor_invoice_date,
                        vendor_invoice_file_url,
                        vendor_invoice_number,
                        

                       
                      } = row;

                      return (
                        <TableRow
                          hover
                          key={created_by_email}
                          tabIndex={-1}
                          role="checkbox"
                        >
                          <TableCell component="th" scope="row">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar
                                sx={{
                                  fontSize: ".8rem",
                                  fontWeight: "600",
                                  backgroundColor: "#f3f6f8",
                                  color: "#637281",
                                }}
                              >
                                {getInitials("sourabh")}
                              </Avatar>
                              <Typography variant="subtitle2" noWrap>
                                {project_name}
                              </Typography>
                            </Stack>
                          </TableCell>
                        
                          <TableCell align="left" style={{minWidth: "260px", paddingLeft: "10px"
                        }}>{created_at}
                          </TableCell>

                          <TableCell align="left" style={{minWidth: "230px", paddingLeft: "50px"
                        }}>{created_by}</TableCell>
                          <TableCell align="left" style={{minWidth: "230px"
                        }}>{created_by_email}
                        </TableCell>
                          <TableCell align="left" style={{minWidth: "230px", paddingLeft: "50px"
                        }}>{milestone_id}
                        </TableCell>
                          <TableCell align="left" style={{minWidth: "230px", paddingLeft: "40px"
                        }}>{milestones}
                        </TableCell>
                          <TableCell align="left" style={{minWidth: "230px", paddingLeft: "50px"
                        }}>{po_amount}
                        </TableCell>
                          <TableCell align="left"style={{minWidth: "240px"
                        }}>{po_date}
                        </TableCell>
                          <TableCell align="left" style={{minWidth: "230px", paddingLeft: "40px"
                        }}>{po_number}
                        </TableCell>
                          <TableCell align="left" style={{minWidth: "230px", paddingLeft: "50px"
                        }}>{po_push_status}
                        </TableCell>
                          <TableCell align="left" style={{minWidth: "230px", paddingLeft: "10px"
                        }}>{po_status}
                        </TableCell>
                          <TableCell align="left" style={{minWidth: "230px", paddingLeft: "40px"
                        }}>{project_id}
                        </TableCell>
                          <TableCell align="left" style={{minWidth: "230px", paddingLeft: "10px"
                        }}>{rejection_reason}
                        </TableCell>
                          <TableCell align="left" style={{minWidth: "230px", paddingLeft: "10px"
                        }}>{remaining_bill_amount}
                        </TableCell>
                          <TableCell align="left" style={{minWidth: "230px", paddingLeft: "10px"
                        }}>{updated_at}
                        </TableCell>
                        <TableCell align="left" style={{minWidth: "200px", paddingLeft: "60px"
                        }}>{updated_by}
                          </TableCell>
                          <TableCell align="left" style={{minWidth: "230px", paddingLeft: "50px"
                        }}>{vendor_invoice_date}
                        </TableCell>
                          
                          <TableCell align="left" style={{minWidth: "230px", paddingLeft: "60px"
                        }}>{vendor_invoice_number}
                        </TableCell>
                          <TableCell align="left" style={{minWidth: "70px", paddingLeft: "10px"
                        }}> 
                          
                          </TableCell>

                          <TableCell align="left" style={{minWidth: "200px",
                        }}>
                        
                          <Button onClick={()=>{fncRejectPO(project_id,id)}}
                          style={{backgroundColor: "red", marginRight: "20px", color: "white"}} >
                            Reject
                          </Button>

                          <Button onClick={()=>{fncApprovePO(project_id,id)}} 
                          style={{backgroundColor: "green", color: "white"}}>
                            Approve
                          </Button>

                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody> */}
                </Table>
              )}
            </TableContainer>
            <TablePagination
              sx={{ float: "right" }}
              count={totalTakeRateapprovalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPageOptions={false}
            />
          </Scrollbar>
        </Card>
      </Container>

      {/* {showAcceptanceModal && (
        <UserAcceptanceModal
          successCallback={(data) => userAction(true)}
          closeModal={() => {
            setShowAceeptanceModal(false);
          }}
          isOpen={showAcceptanceModal}
          status=""
          headermessage="Rejection Reason *"
          message=""
          PoApproveRejectionReason={getPoApproveRejectionReason}
          PoInput
        />
      )} */}

      {showAcceptanceModal && (
        <TakeRateConfirmModal
          // successCallback={(data) => userAction(true)}
          closeModal={() => {
            setShowAceeptanceModal(false);
          }}
          isOpen={showAcceptanceModal}
          status="rejected_by_manager"
          headermessage=""
          message="Rejection Reason *"
          PoApproveRejectionReason={getPoApproveRejectionReason}
          PoInput
          takeRateID={IDForTakeRate}
        />
      )}

      {/* {showAcceptanceModalApproval && (
        <UserAcceptanceModal
          successCallback={() => fncApproval(true)}
          closeModal={() => {
            setShowAcceptanceModalApproval(false);
          }}
          isOpen={showAcceptanceModalApproval}
          status=""
          headermessage="Approval Reason *"
          message=""
          PoApproveInput
          PoApproveRejectionReason={getPoApproveRejectionReason}
        />
      )} */}

      {showAcceptanceModalApproval && (
        <TakeRateConfirmModal
          // successCallback={() => fncApproval(true)}
          closeModal={() => {
            setShowAcceptanceModalApproval(false);
          }}
          isOpen={showAcceptanceModalApproval}
          status="approved_by_manager"
          headermessage=""
          message="Approval Reason *"
          PoApproveInput
          PoApproveRejectionReason={getPoApproveRejectionReason}
          takeRateID={IDForTakeRate}
        />
      )}

      <Modal
        content={<PurchaseOrderModal closeModal={handleClose} />}
        maxWidth="md"
        isOpen={showModal}
        closeModal={handleClose}
        title="Add Purchase Order"
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{ justifyContent: "center", marginBottom: 2 }}
      />
      <Modal
        maxWidth="md"
        isOpen={showInfoModal}
        closeModal={handleInfoClose}
        title="Vendor Invoice Info"
        content={
          <POInfoModal
            id={performaId}
            projectId={performaId}
            closeModal={handleInfoClose}
          />
        }
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{ justifyContent: "center", marginBottom: 2 }}
      />
      <Modal
        maxWidth="md"
        isOpen={showEditModal}
        closeModal={handleEditClose}
        title="Edit PO"
        content={<POEditModal rowData={rowData} closeModal={handleEditClose} />}
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{ justifyContent: "center", marginBottom: 2 }}
      />
    </Page>
  );
}

export default TakeRateApproval;
