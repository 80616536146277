import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";

import { listViewType } from "../../utils/enum";
import Chatter from "./Chatter";
import Page from "../../components/Page";

function CreateNew() {
  const location = useLocation();
  const title = location.state?.title;
  const type = location.state?.type;

  if (!title) return <Navigate to="/dashboard/charter" />;

  return (
    <div>
      <Page title="New - Project Charter | EMB ">
        <Container>
          <Box sx={{ marginBottom: 4 }}>
            <Stack
              direction="column"
              // alignItems="center"
              justifyContent="flex-start"
              alignItems="flex-start"
              mb={5}
            >
              <Typography variant="h4" gutterBottom>
                Charter Details
              </Typography>
              <Breadcrumb />
            </Stack>
          </Box>

          <Chatter
            title={title}
            saveBtnText="Create"
            type={type === "charter" ? listViewType.mainCharter : type}
          />
        </Container>
      </Page>
    </div>
  );
}

export default CreateNew;
