import {
  getAggregateCommercialMetrics,
  getAggregateCommercialMetricsOld,
  getProjectExpectationMetrics,
} from "../../../utils/apis.utils";
import {
  startMilestoneMetricsServiceLoader,
  stopMilestoneMetricsServiceLoader,
} from "./milestoneMetrics.slice";

export const fetchExpectationDataByIdHandler = async (id, thunkApi) => {
  thunkApi.dispatch(startMilestoneMetricsServiceLoader());
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(getProjectExpectationMetrics + id);
  thunkApi.dispatch(stopMilestoneMetricsServiceLoader());
  return response.data;
};

export const fetchAggregateCommercialsByIdHandler = async (id, thunkApi) => {
  thunkApi.dispatch(startMilestoneMetricsServiceLoader());
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(getAggregateCommercialMetrics + id);
  thunkApi.dispatch(stopMilestoneMetricsServiceLoader());
  return response.data;
};

export const fetchAggregateCommercialsByIdHandlerOld = async (id, thunkApi) => {
  thunkApi.dispatch(startMilestoneMetricsServiceLoader());
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(getAggregateCommercialMetricsOld + id);
  thunkApi.dispatch(stopMilestoneMetricsServiceLoader());
  return response.data;
};
