import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Dropdown from "../Form/Dropdown";
import {
  updateProjectStatusThunkNew,
  updateVendorStatusThunkNew,
} from "../../store/slices/project/project.slice";
import { useDispatch } from "react-redux";

const options = [
  {
    value: "open",
    label: "Open",
  },
  {
    value: "closed",
    label: "Closed",
  },
];

const TicketStatus = ({ selectedTickets, handleChange, screenFrom }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(
    selectedTickets.selectedOption == "" ? "" : selectedTickets.selectedOption
  );
  function fncCallTicketApi() {
    if (screenFrom == "VENDOR") {
      var data = {
        support_id: selectedTickets.id,
        state: status.toLowerCase(),
      };
      dispatch(updateVendorStatusThunkNew(data))
        .unwrap()
        .then(() => {
          handleChange();
        });
    } else {
      var data = {
        support_id: selectedTickets.id,
        state: status.toLowerCase(),
      };
      dispatch(updateProjectStatusThunkNew(data))
        .unwrap()
        .then(() => {
          handleChange();
        });
    }
  }
  return (
    <>
      <Box sx={{ margin: "30px 0 10px 0" }}>
        <Typography variant="h6">Title: {selectedTickets.name}</Typography>
        <FormControl fullWidth>
          <Dropdown
            options={options}
            label="Select Status"
            icons
            inputProps={{
              handleChange: (e) => setStatus(e.target.value),
            }}
            value={status}
          />
          <Button
            style={{ width: "100px", marginTop: "10px" }}
            size="large"
            type="submit"
            variant="contained"
            onClick={fncCallTicketApi}
          >
            Save
          </Button>
        </FormControl>
      </Box>
    </>
  );
};

export default TicketStatus;
