import { initializeApp } from "firebase/app";
import { getMessaging, getToken,isSupported } from "firebase/messaging";

const config = {
  apiKey: "AIzaSyCjoCQpxyqtkRw1nEk8hBNC_aemYAuUJt0",
  authDomain: "insta-clone-573f7.firebaseapp.com",
  projectId: "insta-clone-573f7",
  storageBucket: "insta-clone-573f7.appspot.com",
  messagingSenderId: "234505442329",
  appId: "1:234505442329:web:d46dfcef6ef449ebccc688",
};

const app = initializeApp(config);


async function setupMessaging() {
  const supported = await isSupported();
  if (supported && 'serviceWorker' in navigator) {
    try {
      const messaging = getMessaging(app);
      // Your messaging setup code here
      console.log("Firebase Messaging is supported and initialized.");
    } catch (error) {
      console.error("Error initializing Firebase Messaging:", error);
    }
  } else {
    console.warn("Firebase Messaging is not supported in this browser.");
  }
}

setupMessaging();


export { app, setupMessaging };



// const messaging = getMessaging(app);

// export const getDeviceToken = () => {
//   return getToken(messaging)
//     .then((value) => {})
//     .catch((err) => {});
// };
