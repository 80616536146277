// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-content-confirmation {
  width: 600px;
}
/* media query */
@media screen and (max-width: 2000px) {
  .dialog-content-confirmation {
    width: 600px;
  }
}

@media screen and (max-width: 1000px) {
  .dialog-content-confirmation {
    width: 600px;
  }
}

@media screen and (max-width: 925px) {
  .dialog-content-confirmation {
    width: 600px;
  }
}
@media screen and (max-width: 700px) {
  .dialog-content-confirmation {
    width: auto;
  }
}

@media screen and (max-width: 600px) {
  .dialog-content-confirmation {
    width: auto;
  }
}

@media screen and (max-width: 500px) {
  .dialog-content-confirmation {
    width: auto;
  }
}

@media screen and (max-width: 400px) {
  .dialog-content-confirmation {
    width: auto;
  }
}

@media screen and (max-width: 330px) {
  .dialog-content-confirmation {
    width: auto;
  }
}

@media screen and (max-width: 300px) {
  .dialog-content-confirmation {
    width: auto;
  }
}

@media screen and (max-width: 260px) {
  .dialog-content-confirmation {
    width: auto;
  }
}


.overFLowScrollBarHiiden .makeStyles-textareaFormControl-6 {
     height: 52px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/confirmation.theme.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA,gBAAgB;AAChB;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,YAAY;EACd;AACF;AACA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF;;;AAGA;KACK,uBAAuB;AAC5B","sourcesContent":[".dialog-content-confirmation {\n  width: 600px;\n}\n/* media query */\n@media screen and (max-width: 2000px) {\n  .dialog-content-confirmation {\n    width: 600px;\n  }\n}\n\n@media screen and (max-width: 1000px) {\n  .dialog-content-confirmation {\n    width: 600px;\n  }\n}\n\n@media screen and (max-width: 925px) {\n  .dialog-content-confirmation {\n    width: 600px;\n  }\n}\n@media screen and (max-width: 700px) {\n  .dialog-content-confirmation {\n    width: auto;\n  }\n}\n\n@media screen and (max-width: 600px) {\n  .dialog-content-confirmation {\n    width: auto;\n  }\n}\n\n@media screen and (max-width: 500px) {\n  .dialog-content-confirmation {\n    width: auto;\n  }\n}\n\n@media screen and (max-width: 400px) {\n  .dialog-content-confirmation {\n    width: auto;\n  }\n}\n\n@media screen and (max-width: 330px) {\n  .dialog-content-confirmation {\n    width: auto;\n  }\n}\n\n@media screen and (max-width: 300px) {\n  .dialog-content-confirmation {\n    width: auto;\n  }\n}\n\n@media screen and (max-width: 260px) {\n  .dialog-content-confirmation {\n    width: auto;\n  }\n}\n\n\n.overFLowScrollBarHiiden .makeStyles-textareaFormControl-6 {\n     height: 52px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
