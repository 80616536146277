// material
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Container,
  Stack,
  Card,
  TextField,
  Link,
} from "@mui/material";
import { forgotPasswordAsycnThunk } from "../../store/slices/auth/auth.slice";

// components
import { MotionContainer } from "../../components/animate";
import { styled } from "@mui/material/styles";
import Page from "../../components/Page";
import useModal from "../../hooks/useModal";
import OTPModal from "../../components/Modal/OtpModal";
import Modal from "../../components/Modal/Modal";
import { MHidden } from "../../components/@material-extend";
import AuthLayout from "../../layouts/AuthLayout";
import { useDispatch } from "react-redux";

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  paddingBottom: "190px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

export default function ForgotPassword() {
  const { isModalOpen, showModal, hideModal } = useModal();
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const handleResetClick = () => {
    dispatch(forgotPasswordAsycnThunk(email));
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        closeModal={hideModal}
        content={<OTPModal />}
      />

      <RootStyle title="forgot password | Minimal-UI">
        <AuthLayout sx={{ mt: 10 }}>
          Don’t have an account? &nbsp;
          <Link underline="none" variant="subtitle2" to="/register">
            Get started
          </Link>
        </AuthLayout>

        <MHidden width="mdDown">
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 20, mb: 5 }}>
              Generate, New Password
            </Typography>
            <img
              src="/static/illustrations/illustration_login.png"
              alt="login"
            />
          </SectionStyle>
        </MHidden>

        <Container maxWidth="sm">
          <MotionContainer initial="initial" open>
            <Box
              sx={{ maxWidth: 500, margin: "auto", mt: 30, textAlign: "left" }}
            >
              <Typography variant="h3" paragraph>
                Forgot your password?
              </Typography>

              <Typography sx={{ color: "text.secondary", fontSize: "1rem" }}>
                Please enter the email address associated with your account and
                We will email you a link to reset your password.
              </Typography>

              <Box sx={{ margin: "20px 0" }}>
                <Stack
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                  spacing={2}
                >
                  <TextField
                    fullWidth
                    value={email}
                    type="email"
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    placeholder="Please enter your email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {/* <TextField
                    fullWidth
                    value="sourabhchaprana2547@gmail.com"
                    type="email"
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    placeholder="Please enter your email"
                  /> */}
                </Stack>
              </Box>

              <Button
                to="/"
                size="large"
                sx={{ mt: 3 }}
                variant="contained"
                fullWidth={true}
                onClick={handleResetClick}
              >
                Reset Password
              </Button>

              <Button to="/" size="large" sx={{ mt: 2 }} fullWidth={true}>
                Back
              </Button>
            </Box>
          </MotionContainer>
        </Container>
      </RootStyle>
    </>
  );
}
