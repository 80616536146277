import React, { useEffect, useMemo, useState } from "react";
import {
  Stack,
  Card,
  Typography,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  TableHead,
} from "@mui/material";
import { useParams } from "react-router-dom";
import Page from "../../components/Page";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import Scrollbar from "../../components/Scrollbar";
import Modal from "../../components/Modal/Modal";
import MilestoneModal from "../../components/Modal/MilestoneModal";
import { useDispatch } from "react-redux";
import {
  fetchAllCollectionsByProjectThunk,
  deleteMilestoneThunk,
  sendClientEmailMilestoneThunk,
} from "../../store/slices/project/project.slice";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import { getFormattedTime } from "../../utils/time.util";
import SearchNotFound from "../../components/NotFound/SearchNotFound";
import DeleteAction from "../../components/common/DeleteAction";
import AggregateCommercial from "./AggregateCommercials";
import ExpectationData from "./ExpectationsData";
import { UTCToIST } from "../../utils/formatTime";
import { fetchAggregateCommercialsByIdThunk } from "../../store/slices/milestoneMetrics/milestoneMetrics.slice";
import { IconButton } from "@mui/material";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import Tooltip from "@mui/material/Tooltip";

export default function Collections() {
  const [showMileModal, setShowMileModal] = useState(false);
  const handleOpen = () => {
    setShowMileModal(true);
  };
  const handleClose = () => {
    setShowMileModal(false);
    setSelectedData(null);
  };
  const dispatch = useDispatch();
  const params = useParams();
  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    dispatch(fetchAllCollectionsByProjectThunk(params.id));
  }, [dispatch, params.id]);

  const collection = useSelector(({ project }) => project.collections);
  console.log("Collection Table", collection);

  const virtualAddValue = useMemo(() => {
    return collection.reduce(
      (acc, val) => acc + Number(val.percentage_release),
      0
    );
  }, [collection]);

  function handleRowClick(row) {
    setSelectedData(row);
    setShowMileModal(true);
  }

  function handleDelete(id) {
    var data = {
      id: parseInt(params.id),
      mid: id,
    };
    dispatch(deleteMilestoneThunk(data)).then((res) => {
      dispatch(fetchAggregateCommercialsByIdThunk(params.id))
        .unwrap()
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    });
  }
  function handleSend(id) {
    var data = {
      id: parseInt(params.id),
      mid: id,
    };
    dispatch(sendClientEmailMilestoneThunk(data)).then((res) => {
      dispatch(fetchAllCollectionsByProjectThunk(params.id))
        .unwrap()
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    });
  }

  return (
    <>
      <ExpectationData />
      <AggregateCommercial />

      <Page title="Proposal | EMB">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          m={2}
        >
          <Typography variant="h5" gutterBottom>
            Milestones (Mil)
          </Typography>
        </Stack>
        {collection.length == 0 ? (
          <SearchNotFound
            src="/static/no-found/milestones.png"
            subtitle="No milestone to display."
            title=" "
          />
        ) : (
          <Card sx={{ marginBottom: "3rem" }}>
            <Scrollbar>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead sx={{ backgroundColor: "rgb(243, 246, 248)" }}>
                    <TableRow>
                      {columns.map((column) =>
                        column.id != "action" ? (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              top: 57,
                              minWidth: column.minWidth,
                              backgroundColor: "#f3f6f8",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ) : (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              backgroundColor: "#f3f6f8",
                              position: "sticky",
                              right: 0,
                            }}
                          >
                            {column.label}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody key={new Date().toISOString()}>
                    {collection.map((row) => {
                      const {
                        id,
                        percentage_of_project,
                        project_id,
                        milestone_name,
                        milestone_amount,
                        milestone_release_amount,
                        percentage_collection_amount,
                        timeline,
                        percentage_release,
                        expected_collection_month,
                        actual_emb_cut,
                        actual_collection,
                        actual_release,
                        vendor_payment_released,
                        milestone_completed,
                        invoice_approved,
                        expected_delivery_date,
                        actual_delivery_date,
                        notes,
                        milestone_delayed_reason,
                        type,
                        milestone_approval_status,
                      } = row;

                      return (
                        <TableRow
                          key={project_id}
                          tabIndex={-1}
                          role="checkbox"
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell
                            onClick={() => handleRowClick(row)}
                            align="left"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              {milestone_name}
                            </Stack>
                          </TableCell>
                          <TableCell
                            onClick={() => handleRowClick(row)}
                            align="center"
                          >
                            {percentage_of_project} %
                          </TableCell>
                          <TableCell
                            onClick={() => handleRowClick(row)}
                            align="center"
                          >
                            {" "}
                            {percentage_collection_amount} %
                          </TableCell>

                          {/* <TableCell
                            onClick={() => handleRowClick(row)}
                            align="center"
                          >
                            ₹ {milestone_release_amount}
                          </TableCell>
                          <TableCell
                            onClick={() => handleRowClick(row)}
                            align="center"
                          >
                            {timeline} days
                          </TableCell> */}
                          {/* <TableCell
                            onClick={() => handleRowClick(row)}
                            align="center"
                          >
                            {getFormattedTime(
                              expected_collection_month,
                              "MM/yyyy"
                            )}
                          </TableCell> */}
                          <TableCell
                            onClick={() => handleRowClick(row)}
                            align="center"
                          >
                            ₹ {actual_collection}
                          </TableCell>
                          <TableCell
                            onClick={() => handleRowClick(row)}
                            align="center"
                          >
                            ₹ {actual_release}
                          </TableCell>
                          <TableCell
                            onClick={() => handleRowClick(row)}
                            align="center"
                          >
                            ₹ {actual_emb_cut}
                          </TableCell>
                          <TableCell
                            onClick={() => handleRowClick(row)}
                            align="center"
                          >
                            {invoice_approved ? "Approved" : "Pending"}
                          </TableCell>
                          <TableCell
                            onClick={() => handleRowClick(row)}
                            align="center"
                          >
                            {vendor_payment_released ? "Approved" : "Pending"}
                          </TableCell>
                          <TableCell
                            onClick={() => handleRowClick(row)}
                            align="center"
                          >
                            {getFormattedTime(expected_delivery_date)}
                          </TableCell>
                          <TableCell
                            onClick={() => handleRowClick(row)}
                            align="center"
                          >
                            {getFormattedTime(actual_delivery_date)}
                          </TableCell>
                          <TableCell
                            onClick={() => handleRowClick(row)}
                            align="center"
                          >
                            {milestone_completed ? "Approved" : "Pending"}
                          </TableCell>
                          <TableCell
                            onClick={() => handleRowClick(row)}
                            align="center"
                          >
                            {type ? type : "-"}
                          </TableCell>
                          <TableCell
                            onClick={() => handleRowClick(row)}
                            align="center"
                          >
                            {notes ? notes : "-"}
                          </TableCell>
                          <TableCell
                            onClick={() => handleRowClick(row)}
                            align="center"
                          >
                            {milestone_delayed_reason
                              ? milestone_delayed_reason
                              : "-"}
                          </TableCell>
                          <TableCell>
                            <Stack
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                            >
                              {milestone_approval_status === "pending" ||
                              milestone_approval_status === "rejected" ? (
                                <Tooltip title="Send for approval">
                                  <IconButton
                                    color="success"
                                    component="span"
                                    onClick={() => handleSend(id)}
                                  >
                                    <ScheduleSendIcon color="success" />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <>
                                {
                                  milestone_approval_status === "requested_for_approval" ? "Requested" : <>{milestone_approval_status}</>
                                }
                                </>
                              )}
                            </Stack>
                          </TableCell>
                          <TableCell
                            align="left"
                            hover
                            style={{
                              position: "sticky",
                              right: 0,
                              background: "white",
                              boxShadow: "5px 2px 5px grey",
                            }}
                            component="th"
                            scope="row"
                          >
                            <Stack
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <DeleteAction
                                handleDelete={() => handleDelete(id)}
                                milestone_approval_status={milestone_approval_status}
                              />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        )}
        {!showMileModal && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            m={2}
          >
            <Button
              size="large"
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
              onClick={handleOpen}
              sx={{ float: "right", mb: "1rem" }}
            >
              Milestone
            </Button>
          </Stack>
        )}
        {/* <Modal
          maxWidth="md"
          isOpen={showMileModal}
          closeModal={handleClose}
          title="Add Milestones"
          content={
            <MilestoneModal
              closeModal={handleClose}
              selectedData={selectedData}
              virtualAddValue={virtualAddValue}
            />
          }
          dialogProps={{ fullWidth: true }}
          titleStyle={{ textAlign: "center", marginTop: 2 }}
          actionStyle={{ justifyContent: "center", marginBottom: 2 }}
        /> */}
        {showMileModal && (
          <MilestoneModal
            closeModal={handleClose}
            selectedData={selectedData}
            virtualAddValue={virtualAddValue}
          />
        )}
      </Page>
    </>
  );
}

var columns = [
  {
    id: "m_name",
    label: "Mil. Name",
    align: "left",
    minWidth: 200,
  },
  {
    id: "percentage_of_project",
    label: "% Release of project",
    align: "center",
    minWidth: 150,
  },
  // {
  //   id: "m_amount",
  //   label: "Mil. Amt.",
  //   align: "center",
  //   minWidth: 150,
  // },
  {
    id: "percentage_collection_amount",
    label: "% Collection Amount",
    align: "center",
    minWidth: 150,
  },
  // {
  //   id: "m_release_amount",
  //   label: "Mil. Release Amt.",
  //   align: "center",
  //   minWidth: 150,
  // },
  // { id: "timeline", label: "Timeline", align: "center", minWidth: 150 },
  // {
  //   id: "expected_collection_month",
  //   label: "Exp. Collection Month",
  //   align: "center",
  //   minWidth: 200,
  // },
  {
    id: "actual_collection ",
    label: "Actual Collection ",
    align: "center",
    minWidth: 150,
  },
  {
    id: "actual_release",
    label: "Actual Release",
    align: "center",
    minWidth: 150,
  },
  {
    id: "actual_emb_ut",
    label: "Actual EMB Cut",
    align: "center",
    minWidth: 150,
  },
  {
    id: "invoice_approved",
    label: "Invoice App.",
    align: "center",
    minWidth: 150,
  },
  {
    id: "vendor_payment_released",
    label: "Vendor Amt. Released",
    align: "center",
    minWidth: 200,
  },
  {
    id: "expected_delivery_date ",
    label: "Exp. Delivery Date ",
    align: "center",
    minWidth: 200,
  },
  {
    id: "actual_delivery_date",
    label: "Delivery Date",
    align: "center",
    minWidth: 200,
  },
  {
    id: "m_completed ",
    label: "Mil. Completed ",
    align: "center",
    minWidth: 200,
  },
  { id: "type", label: "Type", align: "center", minWidth: 150 },
  { id: "notes", label: "Notes", align: "center", minWidth: 200 },
  {
    id: "milestone_delayed_reason",
    label: "Delay Reason",
    align: "center",
    minWidth: 200,
  },
  {
    id: "request_app",
    label: "Request Approval ",
    alignRight: false,
    minWidth: 200,
  },
  { id: "action", label: "Action ", alignRight: false },
];
