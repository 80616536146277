import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  TextField,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  TableHead,
  Stack,
} from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import Page from "../../components/Page";
import {
  encodeValueInSlug,
  validateSlugHandler,
} from "../../utils/global/global";
import {
  deleteCharterThunk,
  fetchAllCharterThunk,
  createChatterThunk,
} from "../../store/slices/chatter/chatter.slice";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import DeleteAction from "../../components/common/DeleteAction";
import FormControl from "@mui/material/FormControl";
import Dropdown from "../../components/Form/Dropdown";
// import { fetchCharterByIdApi } from "../../utils/apis.utils";
import { fetchCharterByIdThunk } from "../../store/slices/chatter/chatter.slice";

var projectStatusDropdown = [
  {
    label: "Project Table",
    value: "charter",
  },
  {
    label: "SOP Table",
    value: "sop",
  },
];

function AllChatter() {
  const [title, setTitle] = useState(null);
  const userInfo = useSelector(({ user }) => user.userData);
  const [status, setStatus] = useState("charter");

  const { charterList } = useSelector(({ charter }) => charter);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchAllCharterThunk());
  }, [dispatch]);

  const deboundedHandleChange = _.debounce((e) => {
    var value = e.target.value;
    var slug = encodeValueInSlug(value);
    validateSlugHandler(slug).then((data) => {
      if (data?.validate) {
        return setTitle(slug);
      }
      setTitle(null);
    });
  }, 500);

  function navigateToCreateNew() {
    if (!title) return;

    // dispatch(createChatterThunk({ data: { type: status } }));
    navigate("new", {
      state: {
        title: title,
        type: status,
      },
    });
  }

  function navigateToViewCharter(data) {
    navigate(data.id);
    dispatch(fetchCharterByIdThunk(data));
  }

  function handleCharterDelete(id) {
    dispatch(deleteCharterThunk(id));
  }

  return (
    <Page title="Create Charter | EMB">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Get Started With Charter</Typography>
        </Box>

        <Grid container spacing={3}>
          <Grid item sm={12} md={12}>
            <Card sx={{ minHeight: "200px", padding: "50px" }}>
              <Stack direction="row" justifyContent="space-evenly" spacing={2}>
                <TextField
                  label="Charter Title"
                  variant="standard"
                  focused
                  fullWidth
                  onChange={deboundedHandleChange}
                  style={{ width: "80%" }}
                />
                <Box>
                  <FormControl>
                    <Dropdown
                      styles={{
                        position: "relative",
                        height: "40px",
                        lineHeight: "1.4375em",
                        fontSize: "1rem",
                        fontWeight: 600,
                        color: "#212B36,",
                        borderRadius: "8px",
                        backgroundColor: "#f3f6f8",
                        maxWidth: "160px",
                        margin: "0rem 1rem 0 0",
                        cursor: "pointer",
                      }}
                      param="PROJECTMENU"
                      options={projectStatusDropdown}
                      icons
                      inputProps={{
                        handleChange: (e) => {
                          setStatus(e.target.value);
                        },
                      }}
                      value={status}
                    />
                  </FormControl>
                </Box>
              </Stack>
            </Card>
            <div style={{ textAlign: "right" }}>
              <Button
                sx={{ mt: 3 }}
                size="medium"
                variant="contained"
                disabled={!title ? "disabled" : false}
                onClick={navigateToCreateNew}
              >
                Next
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={12} md={12}>
            <Card sx={{ mt: 3 }}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead sx={{ backgroundColor: "rgb(243, 246, 248)" }}>
                    <TableRow>
                      <TableCell>Charter Title</TableCell>
                      <TableCell>Created at</TableCell>
                      <TableCell>Type</TableCell>
                      {/* <TableCell>Charter Title</TableCell> */}
                      <TableCell align="center">Charter Slug</TableCell>
                      {userInfo?.profile?.is_delivery_admin === true && (
                        <TableCell align="center">Action</TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {charterList.map((charter) => (
                      <TableRow key={charter._id}>
                        <TableCell
                          component="th"
                          scope="row"
                          onClick={() =>
                            navigateToViewCharter({
                              id: charter._id,
                              type: charter.type,
                            })
                          }
                          sx={{ cursor: "pointer" }}
                        >
                          {charter.title}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ cursor: "pointer" }}
                        >
                          {DateTime.fromISO(charter.created_at).toFormat("DD")}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ cursor: "pointer" }}
                        >
                          {charter.type}
                        </TableCell>
                        <TableCell align="center">{charter.slug}</TableCell>
                        {userInfo?.profile?.is_delivery_admin === true && (
                          <TableCell align="center">
                            <DeleteAction
                              handleDelete={() =>
                                handleCharterDelete(charter._id)
                              }
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default AllChatter;
