import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  assignCharterToProjectThunk,
  createNewScopeForProjectThunk,
  fetchProjectCharterByProjectIdThunk,
} from "../../store/slices/project/project.slice";
import Chatter from "../../pages/ProjectChatter/Chatter";
import { listViewType } from "../../utils/enum";
import {
  Box,
  Button,
  Stack,
  Typography,
  InputBase,
  Card,
  List,
  ListItem,
  ListItemButton,
  Avatar,
  ListItemAvatar,
  ListItemText,
  CardContent,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import _ from "lodash";
import { searchCharterHandler } from "../../utils/global/global";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "600px",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

function Charter() {
  const [charterList, setVendorList] = useState([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [createNew, setCreateNew] = useState(false);

  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(fetchProjectCharterByProjectIdThunk(params.id));
  }, [dispatch, params]);

  const { id } = useSelector(({ project }) => project.selectedProject);

  const handleChange = _.debounce(async (e) => {
    setSelectedVendor(null);
    const value = e.target.value;
    if (!value) {
      setDropdownVisible(false);
      return;
    }
    try {
      const response = await searchCharterHandler(value);
      setVendorList(response);
    } catch (err) {
      setDropdownVisible(false);
    }
  }, 500);
  useEffect(() => {
    if (charterList.length === 0) setDropdownVisible(false);
    else setDropdownVisible(true);
  }, [charterList]);

  function handleVendorClick(vendor) {
    setSelectedVendor(vendor);
    setDropdownVisible(false);
  }

  function handleVendorAssign() {
    dispatch(
      assignCharterToProjectThunk({
        project_id: id,
        charter_id: selectedVendor._id,
      })
    );
  }

  const { selectedCharter } = useSelector(({ project }) => project);
  
  function isCharterPresent() {
    if (!selectedCharter) return false;
    return true;
  }

  function handleCreateNewCharter(data) {
    dispatch(
      createNewScopeForProjectThunk({
        data,
        project_id: params.id,
      })
    )
      .unwrap()
      .then(() => {
        setCreateNew(false);
      });
  }

  return (
    <>
      <Box variant="div" component="div">
        {!isCharterPresent() && !createNew && (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ marginTop: "30px", marginBottom: "50px" }}
          >
            <Typography variant="h4" sx={{ color: "#11a54a" }}>
              Attach Charter To This Project
            </Typography>
            <Typography>
              or choose a category to quickly find the help you need
            </Typography>

            <Box sx={{ marginTop: "20px" }}>
              <Search
                sx={{
                  background: "#fff",
                  "&:hover": {
                    background: "#fff",
                  },
                  borderRadius: "50px",
                  boxShadow:
                    "0 2px 6px 0 rgb(136 148 171 / 20%), 0 24px 20px -24px rgb(71 82 107 / 10%)",
                }}
              >
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  sx={{ width: "100%", height: "60px" }}
                  onChange={handleChange}
                />
              </Search>
            </Box>
            <Box mt={3} px={3} sx={{ maxWidth: "600px", width: "100%" }}>
              {isDropdownVisible && (
                <Card>
                  <List
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                      padding: "1rem",
                    }}
                  >
                    {charterList.map((vendor) => (
                      <ListItem
                        disablePadding
                        onClick={() => handleVendorClick(vendor)}
                      >
                        <ListItemButton>
                          <ListItemAvatar>
                            <Avatar
                              sx={{
                                bgcolor: "#eff8f1",
                                color: "#00aa55",
                                fontSize: ".8rem",
                                fontWeight: "600",
                              }}
                            ></Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={vendor.title} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Card>
              )}
              {selectedVendor && (
                <Card sx={{ marginTop: "30px" }}>
                  <CardContent>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography
                        gutterBottom
                        variant="h4"
                        component="h2"
                        sx={{
                          marginBottom: 0,
                          fontSize: { xs: "16px", sm: "16px" },
                        }}
                      >
                        {selectedVendor.title}
                      </Typography>
                      <Button
                        variant="contained"
                        sx={{ float: "right" }}
                        onClick={handleVendorAssign}
                      >
                        Assign
                      </Button>
                    </Stack>
                  </CardContent>
                </Card>
              )}
            </Box>
            <Box>
              <Button variant="contained" onClick={() => setCreateNew(true)}>
                <AddIcon />
                Create Scope
              </Button>
            </Box>
          </Stack>
        )}
      </Box>
      {isCharterPresent() && (
        <Chatter
          data={selectedCharter?.template?.chatterData}
          updateData={selectedCharter}
          type={listViewType.charter}
          saveBtnText="Save Charter"
        />
      )}
      {createNew && (
        <Chatter
          onSavePress={handleCreateNewCharter}
          onBackPress={() => setCreateNew(false)}
        />
      )}
    </>
  );
}

export default Charter;
