import * as React from "react";
// import DialogActions from '@mui/material/DialogActions';
import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from '@mui/material/DialogTitle';
// import { Box, Typography } from '@mui/system';
import { Box, Typography, Link } from "@mui/material";
import OtpInput from "react-otp-input";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  inputSize: {
    "& input": {
      width: "4em !important",
      height: "4em",
      margin: "14px 10px",
      background: "#00ab5514",
      border: "none",
      borderRadius: "8px",
      color: " #0cab57",
      outline: "none",
      fontWeight: "700",
    },
    "& span": {
      display: "none",
    },
  },
});

export default function ResponsiveDialog() {
  const [otp, setOtp] = React.useState("");

  const handleChange = (otp) => setOtp(otp);

  const classes = useStyles();
  return (
    <DialogContentText align="center" p={4}>
      <Box
        component="img"
        sx={{
          height: 70,
          width: 80,
          margin: "auto",
          marginBottom: "10px",
        }}
        alt="The house from the offer."
        src="https://cdn-icons-png.flaticon.com/512/1824/1824728.png"
      />
      <Box
        component="span"
        display="block"
        sx={{ fontSize: "16px", fontWeight: "600", m: 0.5 }}
      >
        Please Enter OTP
      </Box>

      <Box
        component="div"
        className={classes.inputSize}
        sx={{ display: "table", margin: "auto" }}
      >
        <OtpInput
          value={otp}
          onChange={handleChange}
          numInputs={4}
          separator={<span style={{ margin: "0 8px" }}> </span>}
          align="center"
          sx={{ justifyContent: "center" }}
          inputStyle={{ padding: "2px", fontSize: "16px" }}
        />
      </Box>
      <Typography component="body1">
        Did'nt recieve the OTP? <Link href="#">Resent</Link>{" "}
      </Typography>
    </DialogContentText>
  );
}
