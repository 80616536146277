import { fetchaggregatedData } from "../../../utils/apis.utils";
import { Params } from "react-router-dom";

export const fetchAllAggregatedDataHandler = async (data, thunkApi) => {
    // thunkApi.dispatch(startCollectionsServiceLoader());
   const {id, project_id} = data
try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.get(fetchaggregatedData + "/" + id + `?project_id=${project_id}`);

    // thunkApi.dispatch(stopCollectionsServiceLoader());
  
    return response;

} catch (error) {
    
     console.log("error:", error)
}
    
  };