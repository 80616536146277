import { useState } from "react";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { capitalCase } from "../../utils/change-case";
import { TablePagination } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Modal from "../../components/Modal/Modal";

import { DateTime } from "luxon";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import Page from "../../components/Page";
import Scrollbar from "../../components/Scrollbar";
import SearchNotFound from "../../components/NotFound/SearchNotFound";
import DeleteAction from "../../components/common/DeleteAction";
import { useDispatch, useSelector } from "react-redux";
import ListHead from "../../components/common/ListHead";
import ListSearchbar from "../../components/common/ListSearchbar";
// import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
// import { deleteUser } from "../../store/slices/user/user.slice";
import { useEffect } from "react";
import {
  fetchPORejectionThunk,
  fetchAllPOApprovalThunk,
  fetchPOApprovedThunk,
} from "../../store/slices/poapproval/poapproval.slice";

import {
  fetchRefundApprovalThunk,
  fetchrefundRejectionThunk,
  fetchrefundApprovedThunk,
} from "../../store/slices/RefunApproval/refundApproval.slice";
import UserAcceptanceModal from "../../components/Modal/UserAcceptanceModal";
import POInfoModal from "../../components/Modal/POInfoModal";
import POEditModal from "../../components/Modal/POEditModal";
import PurchaseOrderModal from "../../components/Modal/PurchaseOrderModal";
import { textAlign } from "@mui/system";
import { approvalReasonThunk } from "../../store/slices/project/project.slice";
import ReasonSatusModal from "../../components/Modal/ReasonStatusModal";

// ----------------------------------------------------------------------

function getInitials(project_name) {
  return capitalCase(project_name?.substring(0, 2));
}

function RefundApproval() {
  const dispatch = useDispatch();
  const params = useParams();

  const [showAcceptanceModal, setShowAceeptanceModal] = useState(false);
  const [showAcceptanceModalApproval, setShowAcceptanceModalApproval] =
    useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [performaId, setPerformaId] = useState(0);
  const [rowData, setRowData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [invoiceID, setinvoiceID] = useState(null);
  const [projectID, setProjectID] = useState(null);
  const [approveStatus, setApproveStatus] = useState(null);
  const [idForReason, setIdForReason] = useState();
  const [idApprovalForReason, setIdApprovalForReason] = useState();
  const [RefundApproveRejectReason, setRefundApproveRejectReason] = useState();
  const [ApprovalReason, setApprovalReason] = useState();
  const handleClose = () => {
    setShowModal(false);
  };

  const handleInfoClose = () => {
    setShowInfoModal(false);
  };
  const handleEditClose = () => {
    setShowEditModal(false);
  };

  const TABLE_HEAD = [
    { id: "project_id", label: "Project ID", alignRight: false },
    { id: "project_name", label: "Project Name", alignRight: false },
    {
      id: "created_at",
      label: "Total Project Amount (Inc. GST)",
      alignRight: false,
    },
    {
      id: "Requested_by",
      label: "Total Actual Collection (Inc. GST)",
      alignRight: false,
    },
    {
      id: "PO_number",
      label: "Total Actual Release (Inc. GST)",
      alignRight: false,
    },
    {
      id: "PO_amount",
      label: "Refund From Vendor Amount (Inc. GST)",
      alignRight: false,
    },
    {
      id: "PO_date ",
      label: "Refund To Client Amount (Inc. GST)",
      alignRight: false,
    },
    { id: "Vendor_Invoice_file", label: "Requested By", alignRight: false },
    { id: "milestones", label: "Reason For Cancellation", alignRight: false },
    { id: "milestones", label: "Project Start Date", alignRight: false },
    // { id: "milestones", label: "Project End Date", alignRight: false },
    { id: "action", label: "Action", alignRight: false },
  ];

  function userAction(id) {
    setShowAceeptanceModal(false);
    var updateData = {
      invoice_id: invoiceID,
      approve: approveStatus,
      remarks: "Refund has been rejected",
      refund_approval_rejection_reason: RefundApproveRejectReason,
    };
    dispatch(fetchrefundRejectionThunk({ id: projectID, data: updateData }));
  }

  function getApproveRejectionReason(rejectionReason) {
    setRefundApproveRejectReason(rejectionReason);
  }

  function fncApproval(id) {
    setShowAcceptanceModalApproval(false);
    var updateData = {
      invoice_id: invoiceID,
      approve: approveStatus,
      remarks: "Refund has been approved",
      refund_approval_rejection_reason: RefundApproveRejectReason,
    };
    dispatch(fetchrefundApprovedThunk({ id: projectID, data: updateData }));
  }

  useEffect(() => {
    dispatch(
      fetchRefundApprovalThunk({
        page: 1,
      })
    );
  }, []);

  let { RefundApprovalData, totalrefundapprovalData } = useSelector(
    (state) => state.refundApproval
  );


  // ------------------------------------ Pagination on table ----------------------

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(2);

  const handleChangePage = (_, newPage) => {
    dispatch(
      fetchRefundApprovalThunk({
        page: newPage + 1,
      })
    );

    setPage(newPage);
  };

  // ------------------------------------------- ends ------------------

  function fncRejectPO(project_id, id) {
    // dispatch(deleteUserThunk(id));
    setShowAceeptanceModal(true);
    setinvoiceID(id);
    setProjectID(project_id);
    setApproveStatus(false);
    setIdForReason(project_id);
  }

  function fncApprovePO(project_id, id) {
    setShowAcceptanceModalApproval(true);
    setinvoiceID(id);
    setProjectID(project_id);
    setApproveStatus(true);
    setIdApprovalForReason(project_id);
  }

  const handleInfoOpen = (id) => {
    setPerformaId(id);
    setShowInfoModal(true);
  };

  const handleEditOpen = (row) => {
    setRowData(row);
    setShowEditModal(true);
  };

  return (
    <Page title="KAM Dashboard">
      <Container>
        <Stack mb={2}>
          <Typography variant="h4" gutterBottom>
            Refunds Approval
          </Typography>
          {/* <Breadcrumb /> */}
        </Stack>

        <Card>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              display: {
                lg: "flex",
                md: "flex",
                sm: "block",
                xs: "block",
                margin: "30px",
                justifyContent: "right",
              },
            }}
          ></Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {RefundApprovalData?.length === 0 ? (
                <TableBody align="center" sx={{ display: "block" }}>
                  <TableRow>
                    <TableCell colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <Table>
                  <ListHead headLabel={TABLE_HEAD} />
                  <TableBody>
                    {RefundApprovalData.map((row) => {
                      const {
                        id,
                        project_name,
                        project_id,
                        refund_from_vendor,
                        refund_to_client,
                        project_amount,
                        total_actual_collection,
                        total_actual_release,
                        project_start_date,
                        project_end_date,
                        reason,
                        cancel_requested_by,
                        approved,
                        reason_of_refund_other,
                      } = row;

                      return (
                        <TableRow
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell
                            align="left"
                            style={{ minWidth: "150px", paddingLeft: "43px" }}
                          >
                            <a
                              target="_blank"
                              href={`/dashboard/projects/${project_id}/overview`}
                              style={{ textDecoration: "none" }}
                            >
                              {project_id == null ? "-" : project_id}
                            </a>
                          </TableCell>

                          <TableCell align="left" style={{ minWidth: "350px" }}>
                            {project_name == null ? "-" : project_name}
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ minWidth: "300px", paddingLeft: "50px" }}
                          >
                            {project_amount == null ? "-" : project_amount}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ minWidth: "300px", paddingLeft: "50px" }}
                          >
                            {total_actual_collection == null
                              ? "-"
                              : total_actual_collection}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            style={{ minWidth: "300px", paddingLeft: "50px" }}
                          >
                            {total_actual_release == null
                              ? "-"
                              : total_actual_release}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ minWidth: "350px", paddingLeft: "50px" }}
                          >
                            {refund_from_vendor == null
                              ? "-"
                              : Math.trunc(refund_from_vendor) +
                                (Math.trunc(refund_from_vendor) * 18) / 100}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ minWidth: "350px", paddingLeft: "50px" }}
                          >
                            {refund_to_client == null
                              ? "-"
                              : Math.trunc(refund_to_client) +
                                (Math.trunc(refund_to_client) * 18) / 100}
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ minWidth: "200px", paddingLeft: "30px" }}
                          >
                            {cancel_requested_by == null
                              ? "-"
                              : cancel_requested_by}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ minWidth: "230px", paddingLeft: "40px" }}
                          >
                            <Typography>
                              {reason == null ? "-" : reason}
                            </Typography>
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ minWidth: "230px", paddingLeft: "30px" }}
                          >
                            <Typography>
                              {project_start_date
                                ? new Date(
                                    project_start_date
                                  ).toLocaleDateString(undefined, {
                                    timeZone: "Asia/Kolkata",
                                  })
                                : "-"}
                            </Typography>
                          </TableCell>

                          {/* <TableCell
                            align="left"
                            style={{ minWidth: "230px", paddingLeft: "50px" }}
                          >
                             {project_end_date
                            ? new Date(project_end_date).toLocaleDateString(undefined, {
                                timeZone: "Asia/Kolkata",
                              })
                            : "-"}
                          </TableCell> */}

                          <TableCell
                            align="left"
                            style={{
                              minWidth: "150px",
                              backgroundColor: "white",
                              position: "sticky",
                              right: 0,
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <Button
                                onClick={() => {
                                  fncRejectPO(project_id, id);
                                }}
                                style={{
                                  backgroundColor: "red",
                                  marginRight: "15px",
                                  color: "white",
                                }}
                              >
                                Reject
                              </Button>

                              <Button
                                onClick={() => {
                                  fncApprovePO(project_id, id);
                                }}
                                style={{
                                  backgroundColor: "green",
                                  marginRight: "15px",
                                  color: "white",
                                }}
                              >
                                Approve
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
            <TablePagination
              sx={{ float: "right" }}
              count={totalrefundapprovalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPageOptions={false}
            />
          </Scrollbar>
        </Card>
      </Container>

      {showAcceptanceModal && (
        <UserAcceptanceModal
          successCallback={() => userAction(true)}
          closeModal={() => {
            setShowAceeptanceModal(false);
          }}
          isOpen={showAcceptanceModal}
          status=""
          headermessage="Rejection Reason"
          message=" "
          input
          getreason={getApproveRejectionReason}
          projectId={idForReason}
        />
      )}

      {showAcceptanceModalApproval && (
        <UserAcceptanceModal
          successCallback={() => fncApproval(true)}
          closeModal={() => {
            setShowAcceptanceModalApproval(false);
          }}
          isOpen={showAcceptanceModalApproval}
          status=""
          headermessage="Approval Reason"
          message=""
          ApproveInput
          ApproveProjectId={idApprovalForReason}
          getreason={getApproveRejectionReason}
          // ApprovalReason={getApprovalReason}
        />
      )}

      <Modal
        content={<PurchaseOrderModal closeModal={handleClose} />}
        maxWidth="md"
        isOpen={showModal}
        closeModal={handleClose}
        title="Add Purchase Order"
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{ justifyContent: "center", marginBottom: 2 }}
      />
      <Modal
        maxWidth="md"
        isOpen={showInfoModal}
        closeModal={handleInfoClose}
        title="Vendor Invoice Info"
        content={
          <POInfoModal
            id={performaId}
            projectId={performaId}
            closeModal={handleInfoClose}
          />
        }
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{ justifyContent: "center", marginBottom: 2 }}
      />
      <Modal
        maxWidth="md"
        isOpen={showEditModal}
        closeModal={handleEditClose}
        title="Edit PO"
        content={<POEditModal rowData={rowData} closeModal={handleEditClose} />}
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{ justifyContent: "center", marginBottom: 2 }}
      />
    </Page>
  );
}

export default RefundApproval;
