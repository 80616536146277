import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addQuestionHandler,
  allQuestionHandler,
  deleteQuestionHandler,
  editQuestionHandler,
  getQuestionDetailsByIdHandler,
} from "./question.thunk";

const INIT_STATE = {
  data: [],
  selectedQuestion: null,
};

export const allQuestionThunk = createAsyncThunk(
  "allQuestion",
  allQuestionHandler
);

export const deleteQuestionThunk = createAsyncThunk(
  "deleteQuestion",
  deleteQuestionHandler
);

export const addQuestionThunk = createAsyncThunk(
  "addQuestion",
  addQuestionHandler
);

export const editQuestionThunk = createAsyncThunk(
  "editQuestion",
  editQuestionHandler
);

export const getQuestionDetailsByIdThunk = createAsyncThunk(
  "getQuestionDetailsById",
  getQuestionDetailsByIdHandler
);

const questiontSlice = createSlice({
  name: "user",
  initialState: INIT_STATE,
  reducers: {
    setSelectedQuestion: (state, action) => {
      state.selectedQuestion = action.payload;
      return state;
    },
    resetSelectedQuestion: (state) => {
      state.selectedQuestion = null;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allQuestionThunk.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(deleteQuestionThunk.fulfilled, (state, action) => {
        const id = action.payload;
        var newQuestions = state.data.filter(
          (question) => question.ques_id !== id
        );
        state.data = newQuestions;
        return state;
      })
      .addCase(getQuestionDetailsByIdThunk.fulfilled, (state, action) => {
        state.selectedQuestion = action.payload;
      })
      .addCase(editQuestionThunk.fulfilled, (state, action) => {
        const data = action.payload;
        const idx = state.data.findIndex((ele) => ele.ques_id === data.ques_id);
        state.data[idx] = action.payload;
        return state;
      });
  },
});

export const { setSelectedQuestion, resetSelectedQuestion } =
  questiontSlice.actions;

export default questiontSlice.reducer;
