import React, { useEffect, useState } from "react";
import { Box, Grid, Button, Typography, Stack } from "@mui/material";
import Element from "../Form/Element";
import { inputType } from "../../utils/enum";
import useForm from "../../hooks/useForm";
import { useFormik } from "formik";
import { EditPurchaseOrderValidation } from "../../utils/validation";
import { useDispatch, useSelector } from "react-redux";


import { useParams } from "react-router-dom";
import {} from "./upload.css";
import { showFailureToast } from "../../store/slices/toast/toast.slice";
import { ContactlessOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { editPOThunk } from "../../store/slices/project/project.slice";
import { fetchAllCollectionsByProjectThunk } from "../../store/slices/project/project.slice";
import { fontSize, textTransform } from "@mui/system";
import { updateVendorInvoice } from "../../utils/global/user.global";
import { values } from "lodash";
export const useStyles = makeStyles({
  dropdownIconCss: {
    "& svg": {
      bottom: "14px !important",
    },
  },
  "@media (max-width:600px)": {
    dropdownIconCss: {
      padding: "0px  !important",
    },
  },
});


export default function POEditModal({
  selectedStatus,
  closeModal,
  fncReason,
  clickedFrom,
  rowData,

  poSummation,
}) {

  const collection = useSelector(({ project }) => project.collections);
  const [ddlOption, setDdlOption] = useState([]);
  const [directChange, setDirectChange] = useState(false);
  const [errorForPo, setErrorForPo] = useState(null);
  const [submit, setSubmit] = useState(true);


  const dispatch = useDispatch();
  const INIT_STATE = {
    po_amount: rowData?.po_amount,
    milestones_id: "",
    vendor_invoice: rowData?.vendor_invoice_file_url,

    vendor_invoice_date: new Date(rowData.vendor_invoice_date.split('-').reverse().join('-')),
    vendor_invoice_number: rowData?.vendor_invoice_number,
    suggested_take_rate : rowData?.suggested_take_rate,
    email_screenshot: rowData?.email_screenshot

  };


  const classes = useStyles();


  //   const dispatch = useDispatch();





 
    const { values, touched, errors, handleChange, handleBlur, handleSubmit } =

    useFormik({
      enableReinitialize: true,
      initialValues: INIT_STATE,
      onSubmit: onSubmit,
      validationSchema: EditPurchaseOrderValidation,
    });


  function handleInputBlur(e) {
    if (
      Number(e) + Number(poSummation.total_released_po_amount) >
      Number(poSummation.total_vendor_amount)
    ) {
      setErrorForPo("PO amount exceeded");
      setSubmit(false);
    } else {
      setErrorForPo(null);
      setSubmit(true);
    }
  }


  const params = useParams();


  useEffect(() => {
    var ddl = collection.map((d, i) => {
      return {
        label: d?.milestone_name,
        value: d?.id,
      };
    });
    setDdlOption(ddl);
  }, [collection]);



  useEffect(()=>{
    if(rowData.is_editable && rowData.po_push_status === 'PUSHED_TO_ZOHO_BOOKS'){

      setDirectChange(true);
    }
  }, []);





  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  }


  const [fileData, setFileData] = useState(values.vendorvalues_invoice_date);


  // function uploadVenInvoice(file){
  // console.log("Keshav", file.target.value.name)
  // var formdata = new FormData();
  // formdata.append("file", file.target.value);

  // setFileData(file.target.value.name)

  // const data = {
  // id :params.id,
  // file: formdata
  // }
  // dispatch(updateVendorInvoice(data))
  // }


  function onSubmit(data) {
    // var formdata = new FormData();
    // formdata.append("project_id", params.id);
    // if(data.po_amount) formdata.append("po_amount", data.po_amount);
    // if(data.milestone_id) formdata.append("milestone_id", data.milestone_id);
    // if(data.partner_invoice_file) formdata.append("partner_invoice_file", data.vendor_invoice);
    // if(data.vendor_invoice_date) formdata.append(
    //   "vendor_invoice_date",
    //   convertDate(data.vendor_invoice_date)
    // );
    // if(data.vendor_invoice_number) formdata.append("vendor_invoice_number", data.vendor_invoice_number);



    let editdata = {}


    if(params.id){
        editdata["project_id"] = params.id;

    }
    if (data.po_amount) {
      editdata["po_amount"] = data.po_amount;
    }
    if (data.milestones_id) {
      editdata["milestone_id"] = data.milestones_id;
    } else {
      editdata["milestone_id"] = rowData.milestones_id[0];
    }
    if (data.vendor_invoice) {
      editdata["partner_invoice_file"] = data.vendor_invoice;
    }
    if (data.vendor_invoice_date) {
      editdata["vendor_invoice_date"] = convertDate(data.vendor_invoice_date)
        .split("-")
        .join("/");
    } else {
      editdata["vendor_invoice_date"] = rowData.vendor_invoice_date
        .split("-")
        .join("/");
    }
    if (data.vendor_invoice_number) {
      editdata["vendor_invoice_number"] = data.vendor_invoice_number;
    }
    if (data.current_vendor) {
      editdata["current_vendor"] = data.current_vendor;
    }

    else{
        editdata["vendor_invoice_date"] = rowData.vendor_invoice_date.split('-').join('/');
    }
    if(data.vendor_invoice_number){
        editdata["vendor_invoice_number"] = data.vendor_invoice_number;
    }
    if(data.suggested_take_rate){
      editdata["suggested_take_rate"] = data.suggested_take_rate;
  }


    if(data.email_screenshot) {
      editdata["email_screenshot"] = data?.email_screenshot
    }


    dispatch(editPOThunk({data:{...rowData,...editdata}, zbooks_po_id:rowData.id}));


    dispatch(
      editPOThunk({
        data: { ...rowData, ...editdata },
        zbooks_po_id: rowData.id,
      })
    );
  
    closeModal();
  }


  return (
    <>
      <Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} px={3}>
            <Grid item md={12} pb={2}>
              <Element
                eletype={inputType.number}
                label="PO Amount (Excl. of GST)"
                inputProps={{
                  onChange: (e) => {
                    handleInputBlur(e.target.value);
                    handleChange(e)
                  },
                  onBlur: (e) => {
                    handleInputBlur(e.target.value);
                    handleBlur(e);
                  },
                  name: "po_amount",
                }}
                isStar="*"
                value={values.po_amount}
                errorText={touched.po_amount && errors.po_amount}
              />
              {errors.po_amount ? (
                <></>
              ) : errorForPo ? (
                <div
                  style={{
                    color: "red",
                    fontSize: "14px",
                    marginLeft: "8px",
                    marginTop: "-12px",
                  }}
                >
                  {errorForPo}
                </div>
              ) : (
                <></>
              )}
            </Grid>

            <Grid item md={12} pb={2}>
              <Element
                eletype={inputType.input}
                label="Current Vendor Name"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "current_vendor",
                }}
                isStar="*"
                value={values.current_vendor}
                errorText={
                  touched.current_vendor && errors.current_vendor
                }
              />
            </Grid>

            <Grid item md={12} pb={2}>
              <Element
                eletype={inputType.input}
                label="Vendor Invoice Number"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "vendor_invoice_number",
                }}
                isStar="*"
                value={values.vendor_invoice_number}
                errorText={
                  touched.vendor_invoice_number && errors.vendor_invoice_number
                }
              />
            </Grid>



            <Grid style={{ zIndex:2001}} item md={12} pb={2}>

              <Element
                eletype={inputType.date}
                label="Vendor Invoice Date"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "vendor_invoice_date",
                  placeholder: "DD/MM/YYYY",
                }}
                isStar="*"
                value={values.vendor_invoice_date}
                errorText={
                  touched.vendor_invoice_date && errors.vendor_invoice_date
                }
              />
            </Grid>


            <Grid
              style={{ zIndex: 2000 }}
              item
              md={12}
              className={classes.dropdownIconCss}
            >

              <Element
                eletype={inputType.select}
                label="Milestone dropdown"
                value={values.milestones_id}
                inputProps={{
                  name: "milestones_id",
                  handleChange: handleChange,
                  handleBlur: handleBlur,
                }}
                isStar="*"
                placeholder="Select a MileStone"
                options={ddlOption}
                icons={
                  <ArrowDropDownIcon
                    sx={{
                      position: "absolute",
                      right: 20,
                      bottom: 14,
                    }}
                  />
                }
              />
              <p style={{ color: "gray" }}>
                Previously Selected: {rowData.milestones[0] || "-"}
              </p>
            </Grid>


            <Grid item md={12} pb={2}>

              {/* <Element
                label="Proof of Work"
                inputProps={{
                  type: "file",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "invoice_files",
                }}
                extraArguments={{
                  upload_type: "gst_file",
                }}
                eletype={inputType.file}
                value={values.invoice_files}
                errorText={touched.invoice_files && errors.invoice_files}
                          /> */}


              <Element
                eletype={inputType.file}
                label="Vendor Invoice"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "vendor_invoice",
                }}
                extraArguments={{
                  upload_type: "vendor_invoice",
                }}
                accept=".pdf"
                onFileChange={handleChange}
                value={values.vendor_invoice}
                isStar="*"
                url="/finance/upload_po_invoice_file"
                editId={rowData?.id}
                errorText={touched.vendor_invoice && errors.vendor_invoice}
              />

              <p style={{color:"gray"}}>Previously Selected: {<a target="_blank" href={values.vendor_invoice}>Invoice</a>|| "-"}</p>

            </Grid>


            <Grid item md={12} pb={2}>
              <Element
                eletype={inputType.input}
                label="Suggested Take Rate"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "suggested_take_rate",
                }}
                isStar="*"
                value={values.suggested_take_rate}
                errorText={
                  touched.suggested_take_rate && errors.suggested_take_rate
                }
              />
            </Grid>


            <Grid  item md={12} pb={2}>


              <Element
                eletype={inputType.file}
                label="Email PDF"
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "email_screenshot",
                }}
                extraArguments={{
                  upload_type: "email_screenshot",
                }}
                accept=".pdf"
                onFileChange={handleChange}
                value={values.email_screenshot}
                isStar="*"
                url="/finance/upload_email_screenshot"
                editId={rowData?.id}
                errorText={touched.email_screenshot && errors.email_screenshot}
              />
              <p style={{color:"gray"}}>selected email pdf : {<a target="_blank" href={values.email_screenshot}>preview</a>|| "-"}</p>
              {/* <Grid item md={12} pb={2} sx={{width: "200px", height: "180px", paddingTop: "10px" }}>
              <a target="_blank" href={values.email_screenshot}>
              <img src={values.email_screenshot} alt="" style={{width: "200px", height: "100%", border: "1px solid black", cursor: "pointer"}}/>
              </a>
            </Grid> */}

            </Grid>


            <Grid item md={12} pb={2} sx={{ textAlign: "right" }}>
              {directChange ? (
                <p
                  style={{
                    color: "red",
                    textTransform: "uppercase",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  Changes will directly reflect in ZOHO Books.
                </p>
              ) : (
                <></>
              )}
              <Button
                size="large"
                sx={{ marginRight: "2rem" }}
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                disabled={!submit || Object.keys(errors).length > 0}
                size="large"
                type="submit"
                variant="contained"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}




