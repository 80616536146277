import React from "react";
import { useStyles } from "../Section.theme";
function Dropdown({ value, change, styles, choices = [], isAdmin }) {
  const classes = useStyles();

  return (
    <>
      {value === "Done" && isAdmin == false ? (
        <select className={classes.inputElement} value={value} style={styles}>
          <option disabled selected>
            {value}
          </option>
        </select>
      ) : (
        <select
          className={classes.inputElement}
          value={value}
          onChange={(e) => change(e.target.value)}
          style={styles}
        >
          <option disabled selected>
            --Select--
          </option>
          {choices.map((choice) => {
            return <option value={choice}>{choice}</option>;
          })}
        </select>
      )}
    </>
  );
}

export default Dropdown;
